import epsClient, { getUrl } from './eps.client'
import axios from 'axios'

const epsClientV1 = axios.create({
  ...epsClient.defaults,
  baseURL: `${getUrl()}/api/v1`,
})
epsClient.interceptors?.request?.['handlers']?.forEach(({ fulfilled, rejected }) => {
  epsClientV1.interceptors.request.use(fulfilled, rejected)
})
epsClient.interceptors?.response?.['handlers']?.forEach(({ fulfilled, rejected }) => {
  epsClientV1.interceptors.response.use(fulfilled, rejected)
})

export default epsClientV1
