import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import FieldName from 'components/FieldName'
import FieldValue from 'components/FieldValue'
import Phone from '@hixme-ui/phone'
import SocialSecurity from '@hixme-ui/social-security'
import { FormatUtcDate, FormatDate } from '@hixme-ui/dates'

const ReadonlyField = ({ fieldName, text, value }) => {
  let fieldValue = value
  if (fieldName === 'Smoker') {
    fieldValue = value === 'true' ? 'Yes' : 'No'
  }

  const FieldComponent = () => {
    if (fieldName === 'SSN') {
      return <SocialSecurity bold number={fieldValue} showFull />
    }
    if (fieldName === 'PhoneNumber') {
      return <Phone number={fieldValue} bold />
    }
    if (fieldName === 'AccountCreated') {
      return <FormatUtcDate bold date={fieldValue} />
    }
    if (fieldName === 'DateOfBirth') {
      return <FormatDate bold date={fieldValue} />
    }
    if (fieldName === 'HireDate') {
      return <FormatDate bold date={fieldValue} />
    }
    return <FieldValue>{fieldValue}</FieldValue>
  }

  return (
    <Container margin="15px 0" noPadding flex justifyStart>
      <Container noPadding width="120px" flex justifyStart>
        <FieldName thin>{text}</FieldName>
      </Container>
      <FieldComponent />
    </Container>
  )
}

ReadonlyField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default ReadonlyField

export const AddressSameAsParent = () => (
  <Container margin="15px 0" noPadding flex justifyStart>
    <Container noPadding width="120px" flex justifyStart>
      <FieldName thin>Address</FieldName>
    </Container>
    <FieldValue>Same as employee</FieldValue>
  </Container>
)
