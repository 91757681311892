import React from 'react'
import { array } from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'

import Container from '@hixme-ui/container'

import YearlyAllowanceBarCell from '../YearlyAllowanceBarCell/YearlyAllowanceBarCell'
import classes from './YearlyAllowanceBarHeader.module.scss'

const YearlyAllowanceBarHeader = ({ header }) => (
  <Container className={classes.YearlyAllowanceBarHeaderContainer}>
    <Container flex noPadding alignItems="center" justifySpaceBetween flexWrap>
      {!isEmpty(header) &&
        header.map(({ title, value }) => (
          <YearlyAllowanceBarCell
            title={startCase(title)}
            value={value}
            key={`header-${title}`}
          />
        ))}
    </Container>
  </Container>
)

YearlyAllowanceBarHeader.propTypes = {
  header: array.isRequired,
}

export default YearlyAllowanceBarHeader
