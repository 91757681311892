import React, { useCallback, useState } from 'react'
import ReviewRecordsBase from '../components/ReviewRecordsBase'
import { CensusFileRowTypeEnum } from '../api/enums/census-file-row-type.enum'
import { CellValueChangedEvent, ColDef } from '@ag-grid-community/core'
import { updateCensusFileRows } from '../api/census-enrollment-platform-api-client'
import { useMarkRowDirty } from '../hooks/use-mark-row-dirty'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'
import { CensusFileRowStatusEnum } from '../api/enums/census-file-row-status.enum'
import { CensusFileRowDto } from '../api/dto/census-file-row.dto'
import { mapGridDataToCensusFileRowDto } from '../utils/map-grid-data-to-census-file-row-dto'
import InlineEditActionButtons from '../components/InlineEditActionButtons'
import RejectSelectedButton from '../components/RejectSelectedButton'
import { LoadingOverlay } from '../components/LoadingOverlay/LoadingOverlay'
import ToggleEditModeButton from '../components/ToggleEditModeButton'
import { errorColumnStyling } from '../utils/error-column-styling'
import { configureEditField } from '../utils/configure-edit-field'
import { CensusManagementPageProps } from '../types/census-management-page-props'

// Column to display error keys with tooltips
const errorColumn: ColDef<CensusFileRowGridData> = {
  headerName: 'Errors',
  field: 'errors',
  width: 200,
  pinned: 'left',
  cellStyle: { color: 'red', fontWeight: 'bold' },
  valueGetter: (params) => {
    const errors = params.data?.errors
    return errors ? Object.keys(errors).join(', ') : ''
  },
  tooltipValueGetter: (params) => {
    const errors = params.data?.errors
    if (!errors) return ''

    // Generate tooltip with specific field error messages
    return Object.values(errors)
      .map((error) => `${error.errorMessage}`)
      .join(', ')
  },
}

// Show errors on page
const ReviewRecordsError = (props: CensusManagementPageProps) => {
  const { markRowDirty, dirtyRows, cleanAllRows, undoLastEdit, editCount } = useMarkRowDirty()
  const [loading, setLoading] = useState(false) // Track submission state
  const [mode, setMode] = useState<'edit' | 'submit'>('submit')
  const { importPublicKey: publicKey } = props.routeParams

  // Update rows with new data
  const submitCorrections = async (rowsToSubmit: CensusFileRowGridData[]) => {
    const updatedRows: CensusFileRowDto[] = rowsToSubmit.map(mapGridDataToCensusFileRowDto)
    // Exit 'edit' mode
    setMode('submit')
    await updateCensusFileRows(updatedRows, publicKey)
  }

  const handleCellValueChanged = useCallback((params: CellValueChangedEvent<CensusFileRowGridData>) => {
    markRowDirty(params)
  }, [])

  const modifyColDefs = (baseColDefs: ColDef<CensusFileRowGridData>[]): ColDef<CensusFileRowGridData>[] => {
    return [
      {
        headerCheckboxSelection: mode === 'submit', // Allow selection only in Submit Mode
        checkboxSelection: mode === 'submit',
        width: 50,
        pinned: 'left',
      },
      errorColumn, // Display errors summary
      ...baseColDefs.map((col) => ({
        ...col,
        ...configureEditField(col.field, mode), // Apply dropdown editor config if applicable
        ...errorColumnStyling(col.field, mode), // Apply style on cells marked with an error
          editable: mode === 'edit', // Enable editing only in Edit Mode
        })
      ),
    ]
  }

  return (
    <>
    <LoadingOverlay isLoading={loading} message="Loading..." />
    <ReviewRecordsBase
      tabKey={CensusFileRowTypeEnum.ERROR}
      {...props}
      rowQueryParams={{
        censusFileRowStatuses: [CensusFileRowStatusEnum.IN_PROCESS],
        censusRowType: CensusFileRowTypeEnum.ERROR,
      }}
      modifyColDefs={modifyColDefs} // Pass the callback function here
      actionButton={({ selectedRows, handleSubmit }) => {
        return (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <ToggleEditModeButton dirtyRows={dirtyRows} selectedRows={selectedRows} mode={mode} setMode={setMode} />
            {mode === 'edit' ? (
            <InlineEditActionButtons
              undoLastEdit={undoLastEdit}
              editCount={editCount}
              dirtyRows={dirtyRows}
              handleSubmit={handleSubmit}
              cleanAllRows={cleanAllRows}
              submitCorrections={submitCorrections}
              updateMode={false}
            />
            ) : (
              <>
            <RejectSelectedButton
              publicKey={publicKey}
              selectedRows={selectedRows}
              handleSubmit={() => { setMode('submit'); handleSubmit() }}
              cleanAllRows={cleanAllRows}
              setLoading={setLoading}
            />
              </>
      )}
          </div>
        )
      }}
      handleCellValueChanged={handleCellValueChanged}
    />
      </>
  )
}

export default ReviewRecordsError
