import React from 'react'

import { FormGroup, Label } from '@hixme-ui/forms'
import LocationsDropdown from './LocationsDropdown'

const LocationsFormGroup = (props) => (
  <div>
    <FormGroup>
      <Label>County, State</Label>
      <LocationsDropdown {...props} />
    </FormGroup>
  </div>
)

export default LocationsFormGroup
