import React, { useState, useCallback, useEffect } from 'react'
import { Field } from 'redux-form'
import { LoadingOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import { FieldInput } from 'components/ReduxFormComponents'
// hixme-ui
import FormGroup from '@hixme-ui/form-group'
import {
  CALCULATE_TERMINATION_DATES,
  CONFIRM_EMP_TERMINATION,
  SEND_HRIS_TERMINATION_EVENT,
  START_TERMINATION_PROCESS,
} from 'apollo/queries'
import Select from '@hixme-ui/select'
import moment from 'moment'
import modal from 'modules/app-core/modal'
import { Stack, Typography } from '@sureco/design-system'
import {
  Alert,
  Button,
  Input,
  Spin,
  notification,
} from '@surecompanies/core_components'
import { convertToText } from 'helpers/datetime'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { browserHistory } from 'react-router'
import { CustomActionNames, datadog } from 'helpers/datadog'

const EmpTerminationForm = ({ empData, dispatch }) => {
  const { flags } = useFeatureFlags()
  const isNewTerminationFlow = flags['product.termination.new-flow']
  const [isDisableConfirm, setIsDisableConfirm] = useState(true)
  const [employmentStatus, setEmpStatus] = useState('TERMINATED')
  const [lastDayWorked, setLastDayWorked] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [empStatusConfirm, setempStatusConfirm] = useState()
  const [calcDates, setCalcDates] = useState({
    previousCoverageEndDate: '',
    cobraBenefitsEffectiveDate: '',
    cobraBenefitsEligibleStartDate: '',
    cobraBenefitsEligibleEndDate: '',
  })

  const coverageSummaryUrl = `/employees/${empData.id}/coverage`

  const TerminationStatusList = [
    { id: 'TERMINATED', name: 'Terminated' },
    { id: 'PART_TIME', name: 'PartTime' },
    { id: 'FULL_TIME', name: 'Full Time' },
    { id: 'RETIRED', name: 'Retired' },
    { id: 'TERMINATED_NO_COBRA', name: 'Terminated No COBRA' },
    { id: 'LEAVE', name: 'Leave' },
    { id: 'NON_PAID_LEAVE_OF_ABSENCE', name: 'Non Paid Leave Of Absence' },
  ]
  const [reqCalculateTerminationDates] = useLazyQuery(
    CALCULATE_TERMINATION_DATES
  )
  const [reqConfirmTermination] = useMutation(CONFIRM_EMP_TERMINATION)
  const [triggerTerminationProcess] = useMutation(START_TERMINATION_PROCESS)
  const [sendHrisTerminationEvent] = useMutation(SEND_HRIS_TERMINATION_EVENT)

  const getCalculatedDates = useCallback(
    async () => {
      setError(false)
      if (
        employmentStatus &&
        lastDayWorked &&
        employmentStatus !== '' &&
        lastDayWorked !== ''
      ) {
        setLoading(true)
        try {
          await reqCalculateTerminationDates({
            variables: {
              terminationDate: moment(lastDayWorked).toISOString(),
            },
          }).then((result) => {
            if (result.data && !result.data.computeCobraDates) {
              setError('Error While compute COBRA dates')
            } else {
              setCalcDates({ ...result.data.computeCobraDates })
              setIsDisableConfirm(false)
            }
          })
        } catch (e) {
          setError('Failed to fetch COBRA dates')
        } finally {
          setLoading(false)
        }
      }
    },
    [lastDayWorked]
  )

  useEffect(
    () => {
      getCalculatedDates()
    },
    [employmentStatus, lastDayWorked]
  )

  const onConfirmEmpTermination = useCallback(
    async () => {
      setError(false)
      if (empStatusConfirm === employmentStatus) {
        setLoading(true)
        try {
          const terminationHandler = isNewTerminationFlow
            ? triggerTerminationProcess
            : reqConfirmTermination
          const input = {
            terminationDate: moment(lastDayWorked).toISOString(),
            id: empData.id,
            employmentStatus,
            cobraBenefitsEffectiveDate: calcDates.cobraBenefitsEffectiveDate,
            cobraBenefitsEligibleStartDate:
              calcDates.cobraBenefitsEligibleStartDate,
            cobraBenefitsEligibleEndDate:
              calcDates.cobraBenefitsEligibleEndDate,
          }
          const successNotification = isNewTerminationFlow
            ? 'Request received. Any changes to Employment Status or Benefit End Dates will appear after a SureCo Administrator reviews and completes this update, usually within 1 business day.'
            : `Employee ${empData.fullName} has been terminated.`

          await terminationHandler({
            variables: {
              input,
            },
          })
            .then(async () => {
              await sendHrisTerminationEvent({
                variables: {
                  enrollMeTerminationEventHandlerInput: {
                    employeePublicKey: empData.id,
                    eventDate: new Date(),
                  },
                },
              })
            })
            .then(() => {
              dispatch(modal.actions.hideModal())
              notification.success({
                key: 'term-notify',
                message: successNotification,
                duration: 8,
              })
              datadog.customAction(CustomActionNames.StopCoverage, {
                isNewTerminationFlow,
                target: datadog.extraPersonInfo(empData),
                input,
              })
              browserHistory.push(coverageSummaryUrl)
            })
        } catch (e) {
          if (e.message === '[Terminations] Employee is already terminated') {
            notification.error({
              key: 'term-notify',
              message: `According to our records, this employee is already terminated. If you believe this is in error, please contact the SureCo Health Team at enrollment@sureco.com. `,
              duration: 10,
            })
            dispatch(modal.actions.hideModal())
          } else {
            setError('Failed to confirm termination')
            notification.error({
              key: 'term-notify',
              message: 'Failed to confirm termination2',
              duration: 8,
            })
          }
        } finally {
          setLoading(false)
        }
      } else {
        setError('Please enter valid employment status to confirm')
      }
    },
    [lastDayWorked, employmentStatus, empStatusConfirm]
  )

  const onEmpStatusChange = (e) => {
    setEmpStatus(e.currentTarget.value)
  }
  const onEmpStatusConfirm = (e) => {
    setempStatusConfirm(e.currentTarget.value)
  }
  const onLastDayWrkChange = (date) => {
    if (date) {
      setLastDayWorked(date.target.value)
    }
  }

  const onCancel = (e) => {
    setError(false)
    e.preventDefault()
    dispatch(modal.actions.hideModal())
  }

  return (
    <Stack space={20}>
      <Stack style={{ margin: 50, textAlign: 'center' }}>
        <Typography size={30} bold>
          Stop Coverage
        </Typography>
      </Stack>
      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          style={{ marginBottom: '20px' }}
        />
      )}
      <Stack space={10}>
        <Stack direction="row" space={10} style={{ alignItems: 'center' }}>
          <Typography size={14}>Employee:</Typography>
          <Typography size={16} bold>
            {empData.fullName}
          </Typography>
        </Stack>
        <Stack direction="row" style={{ justifyContent: 'space-between' }}>
          <Stack style={{ width: '100%' }}>
            <FormGroup>
              <Typography size={14}>Employment Status</Typography>
              <Select
                value={employmentStatus}
                list={TerminationStatusList}
                style={{
                  width: '90%',
                }}
                onChange={onEmpStatusChange}
              />
            </FormGroup>
          </Stack>
          <Stack style={{ width: '100%' }}>
            <FormGroup>
              <Typography size={14}>Last Day Worked</Typography>
              <Field
                name="LastDayWorked"
                type="date"
                value={lastDayWorked}
                component={FieldInput}
                onChange={onLastDayWrkChange}
                max={moment().format('YYYY-MM-DD')}
              />
            </FormGroup>
          </Stack>
        </Stack>
      </Stack>

      {loading && (
        <Stack style={{ margin: '10px 0' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </Stack>
      )}
      {calcDates &&
        calcDates.cobraBenefitsEligibleEndDate && (
          <Stack space={20}>
            <Stack
              direction="row"
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography size={16}>Contribution Ends</Typography>
              <Typography bold size={16}>
                {calcDates.previousCoverageEndDate}
                <Typography color="#747474" style={{ marginLeft: '5px' }}>
                  {convertToText(calcDates.previousCoverageEndDate)}
                </Typography>
              </Typography>
            </Stack>
            <Stack
              direction="row"
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography size={16}>COBRA Election Starts</Typography>
              <Typography bold size={16}>
                {calcDates.cobraBenefitsEligibleStartDate}
                <Typography color="#747474" style={{ marginLeft: '5px' }}>
                  {convertToText(calcDates.cobraBenefitsEligibleStartDate)}
                </Typography>
              </Typography>
            </Stack>
            <Stack
              direction="row"
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography size={16}>COBRA Election Ends</Typography>
              <Typography bold size={16}>
                {calcDates.cobraBenefitsEligibleEndDate}
                <Typography color="#747474" style={{ marginLeft: '5px' }}>
                  {convertToText(calcDates.cobraBenefitsEligibleEndDate)}
                </Typography>
              </Typography>
            </Stack>
            <Stack>
              <Typography size={14}>
                Please type{' '}
                <Typography
                  bold
                  size={14}
                >{`"${employmentStatus}"`}</Typography>{' '}
                to confirm
              </Typography>
              <Input
                name="EmpStatusConfirm"
                value={empStatusConfirm}
                onChange={onEmpStatusConfirm}
              />
            </Stack>
          </Stack>
        )}
      <Stack
        direction="row"
        space={10}
        style={{ justifyContent: 'center', marginTop: 20 }}
      >
        <Button onClick={onCancel} secondary>
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={isDisableConfirm}
          onClick={onConfirmEmpTermination}
        >
          Save and close
        </Button>
      </Stack>
    </Stack>
  )
}

export default EmpTerminationForm
