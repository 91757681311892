import type { OptionGroup } from 'react-querybuilder'

export const employmentStatus: OptionGroup[] = [
  {
    label: 'Status',
    status: [
      'Active',
      'Inactive',
      'Terminated',
      'Terminated Severance',
      'Terminated No Cobra',
      'Retired',
      'Leave',
      'Leave - Paid',
      'Leave - Unpaid',
      'Deceased',
    ],
  }
].map(({ label, status }) => ({
  label,
  options: status.map((s) => ({ name: s, label: s })),
}))
