import React from 'react'
import isEmpty from 'lodash/isEmpty'
import Notifications from '../components/Notifications'
import Receipts from '../components/Receipts/Receipts'
import User from '../components/User/User'

const statusMapping = {
  approved: 'Approved',
  inProgress: 'In Progress',
}

export const currencyFormatter = (currency) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(currency)

export const getExpenseGeneralInformation = (expense) => {
  const key = 'Family members'
  return {
    ...(!isEmpty(expense.family) && {
      [key]: expense.family.map((person) => person),
    }),
    notes: expense.note,
  }
}

export const getAllowanceData = (expense, format = true) => {
  const fields = ['approved', 'inProgress']
  const { benefitAmount } = expense
  const initialState = {
    title: 'Annual Allowance',
    value: format ? currencyFormatter(benefitAmount) : benefitAmount,
  }
  const expenseData = Object.keys(expense.sums).reduce(
    (prev, curr) => {
      if (!fields.includes(curr)) return prev
      return [
        ...prev,
        {
          title: statusMapping[curr],
          value: format
            ? currencyFormatter(expense.sums[curr])
            : expense.sums[curr],
        },
      ]
    },
    [initialState]
  )
  const remainingValue = Math.max(
    0,
    benefitAmount - (expense.sums.inProgress || 0) - expense.sums.approved || 0
  )
  return [
    ...expenseData,
    {
      title: 'Remaining',
      value: format ? currencyFormatter(remainingValue) : remainingValue,
    },
  ]
}

export const getExpenseTabsName = ({ reimbursement, expense, benefits }) => [
  {
    name: 'Notifications',
    content: (
      <Notifications
        list={
          reimbursement.notification
            ? reimbursement.notification.split('\n')
            : []
        }
      />
    ),
  },
  {
    name: 'Details',
    content: (
      <User
        {...expense}
        benefits={benefits}
        allowance={getAllowanceData(expense)}
      />
    ),
  },
  {
    name: 'Receipt Uploads',
    content: (
      <Receipts
        receipts={reimbursement.receipts}
        reimbursementId={reimbursement.reimbursementId}
      />
    ),
  },
]
