import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'
import { withRouter } from 'react-router'

import { actions, selectors } from '../'
import { WAITER_GET_BUNDLES } from '../constants'

const mapStateToProps = (state, props) => {
  const enrollmentIdParam = props.params.enrollmentId
  const bundleList = selectors.getBundles(state)
  const enrollmentId = selectors.getEnrollmentId(state)
  const gettingBundleList = getWaiter(
    state,
    `${WAITER_GET_BUNDLES}${enrollmentIdParam}`
  ).isPending
  return {
    bundleList,
    enrollmentId,
    gettingBundleList,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBundles: (EnrollmentId) => {
    dispatch(actions.getBundles(EnrollmentId))
  },
  clearAll: () => {
    dispatch(actions.clearAll())
  },
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)
