import { namespaceActions } from 'modules/app-core/redux-utils'
import { NAME } from './constants'

const LOAD_BENEFIT = 'LOAD_BENEFIT'
const SET_BUNDLES = 'SET_BUNDLES'
const SET_EMPLOYEE_BENEFITS = 'SET_EMPLOYEE_BENEFITS'
const SET_EMPLOYEE_FAMILY = 'SET_EMPLOYEE_FAMILY'
const SET_EMPLOYEE_WAIVED_BENEFITS = 'SET_EMPLOYEE_WAIVED_BENEFITS'
const SET_ENROLLMENT_ID = 'SET_ENROLLMENT_ID'
const SET_ENROLLMENTS = 'SET_ENROLLMENTS'
const SET_TO_INITIAL_STATE = 'SET_TO_INITIAL_STATE'

export default namespaceActions(NAME)([
  LOAD_BENEFIT,
  SET_BUNDLES,
  SET_EMPLOYEE_BENEFITS,
  SET_EMPLOYEE_FAMILY,
  SET_EMPLOYEE_WAIVED_BENEFITS,
  SET_ENROLLMENT_ID,
  SET_ENROLLMENTS,
  SET_TO_INITIAL_STATE,
])
