import React, { ReactNode } from 'react'
import { RouterContextProvider } from 'components/RouterContext'

type PageWrapperProps = {
  children: ReactNode
}

export default function PageWrapper({ children }: PageWrapperProps) {
  return <RouterContextProvider>{children}</RouterContextProvider>
}
