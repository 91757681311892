import styled, { css } from 'styled-components'

export const NavLink = styled.li`
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  a,
  a:visited {
    color: #f2f6f9;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    text-decoration: none;
    position: relative;
    text-decoration: none;
  }
  a:hover {
    opacity: 0.5;
  }
  a[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  ${({ icon }) =>
    icon
      ? css`
          color: white;
          line-height: 45px;
          a {
            vertical-align: middle;
          }
        `
      : `opacity: .7;
      line-height: 40px;
    
      a {
        text-decoration: none;
        border-bottom: 2px solid transparent;
        transition: all ease .25s;
      }
    
      a:hover {
        border-bottom: 2px solid #fff;
      }
    
      a:hover:after {
        position: absolute;
        line-height: 40px;
        padding-left: 4px;
        content: "\\203A";
      }`}
`

export const IconContainer = styled.div`
  width: 22px;
  display: inline-block;
  text-align: center;
  margin-right: 16px;
  img {
    display: inline-block;
    verticalalign: middle;
  }
`
