import AnalyzePlansAndRates from './pages/Analyze/AnalyzePlansAndRates'
import AnalyzeSmokerRates from './pages/Analyze/AnalyzeSmokerRates'
import PlanManagementHome from './pages/Home/PlanManagementHome'
import { PLAN_MANAGEMENT, PLANS_AND_RATES_ANALYZE, SERVICE_AREAS_ANALYZE, SMOKER_RATES_ANALYZE } from './paths'
import PageWrapper from 'enrollment-platform/PageWrapper'
import AnalyzeServiceAreas from './pages/Analyze/AnalyzeServiceAreas'

const PlanManagementRoutes = [
  {
    path: PLAN_MANAGEMENT,
    component: PageWrapper,
    indexRoute: {
      component: PlanManagementHome,
    },
    childRoutes: [{
      path: PLANS_AND_RATES_ANALYZE,
      component: AnalyzePlansAndRates,
    }, {
      path: SMOKER_RATES_ANALYZE,
      component: AnalyzeSmokerRates,
    }, {
      path: SERVICE_AREAS_ANALYZE,
      component: AnalyzeServiceAreas,
    }
    ]
  },
]

export default PlanManagementRoutes
