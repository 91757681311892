import React, { useState } from 'react'
import { Button } from '@sureco/design-system'
import { message } from 'antd'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'
import { CensusFileRowDto } from '../api/dto/census-file-row.dto'
import { updateCensusFileRows } from '../api/census-enrollment-platform-api-client'
import { CensusFileRowStatusEnum } from '../api/enums/census-file-row-status.enum'
import { mapGridDataToCensusFileRowDto } from '../utils/map-grid-data-to-census-file-row-dto'
import { CensusFileRowTypeEnum } from '../api/enums/census-file-row-type.enum'
import { DeleteOutlined } from '@ant-design/icons'

interface RejectSelectedButtonProps {
  selectedRows: CensusFileRowGridData[]
  handleSubmit: (rows: CensusFileRowGridData[]) => void
  cleanAllRows?: () => void
  setLoading: (loading: boolean) => void // Prop to update loading state in parent
  buttonText?: string
  type?: CensusFileRowTypeEnum
  publicKey: string
}

// Set state of rows to Withdrawn
const rejectRows = async (rowsToReject: CensusFileRowGridData[], publicKey: string) => {
  const rejectedRows: CensusFileRowDto[] = rowsToReject.map((gridRow) => ({
    ...mapGridDataToCensusFileRowDto(gridRow),
    status: CensusFileRowStatusEnum.WITHDRAWN,
  }))
  await updateCensusFileRows(rejectedRows, publicKey)
}

const RejectSelectedButton = (
  {
    selectedRows,
    handleSubmit,
    cleanAllRows,
    setLoading,
    buttonText,
    type,
    publicKey,
  }: RejectSelectedButtonProps) => {
  const [loading, setButtonLoading] = useState(false)

  const handleClick = async () => {
    let timer: NodeJS.Timeout | null = null
    let shouldShowLoading = false

    // Start a delayed loading indicator (only if the request takes longer than 1000ms)
    timer = setTimeout(() => {
      setButtonLoading(true)
      setLoading(true)
      shouldShowLoading = true
    }, 800)

    try {
      await rejectRows(selectedRows, publicKey)
      message.success(`${selectedRows.length} records rejected`, 3)
    } catch (error) {
      const msg = `Failed to reject records: '${(error as Error).message}'`
      console.error(msg)
      message.error(msg)
    } finally {
      // Clear loading state if it was set
      if (shouldShowLoading) {
        setButtonLoading(false)
        setLoading(false)
      }
      if (timer) {
        clearTimeout(timer)
      }
      handleSubmit(selectedRows)
      cleanAllRows?.()
    }
  }

  if (selectedRows.length === 0) return null

  const duplicateButton = <Button onClick={handleClick} size= "large" loading={loading}>Got it</Button>

  return (
    type === CensusFileRowTypeEnum.DUPLICATE_EMPLOYEE
    ? duplicateButton
    : <Button onClick={handleClick} variant="danger" size="large" loading={loading}>
        <DeleteOutlined style={{ marginRight: '8px' }} />{loading ? 'Rejecting...' : buttonText || `Reject (${selectedRows.length})`}
    </Button>
  )
}

export default RejectSelectedButton
