import appIcon from './assets/app.png'
import circlePlusIcon from './assets/circle-plus.png'
import homeIcon from './assets/home.png'
import pickaxeIcon from './assets/pickaxe.png'
import lineChartIcon from './assets/line-chart.png'
import buildingIcon from './assets/building.png'
import magnifyingGlassIcon from './assets/maginfying-glass.png'
import censusIcon from './assets/icon_census.png'
import expenseRequestsIcon from './assets/expense-requests.png'
import { EXPENSE_REQUESTS_NAME, LIFE_EVENTS } from '../../routes/path-names'
import { PLAN_MANAGEMENT } from 'enrollment-platform/pages/PlanManagement/paths'
import { CUSTOMER_MANAGEMENT } from 'enrollment-platform/pages/CustomerManagement/paths'

export const HomeTab = {
  href: '/',
  icon: homeIcon,
  text: 'Home',
  sortOrder: 10,
}

export const EmployeesTab = {
  href: '/employees',
  icon: magnifyingGlassIcon,
  text: 'Our people',
  sortOrder: 30,
}

export const EnrollmentDashboardTab = {
  href: '/enrollmentdashboard',
  icon: lineChartIcon,
  text: 'Enrollment',
  sortOrder: 20,
}

export const CustomerManagementTab = {
  href: `/${CUSTOMER_MANAGEMENT}`,
  icon: censusIcon,
  text: 'Customer Configuration',
  sortOrder: 20,
}

export const ExpenseTab = {
  href: EXPENSE_REQUESTS_NAME,
  icon: expenseRequestsIcon,
  text: 'Expense requests',
  sortOrder: 40,
}

export const AppManagerTab = {
  href: '/application-manager',
  icon: appIcon,
  text: 'Application engine',
  sortOrder: 50,
}

export const DataAndReportsTab = {
  href: '/dataandreports',
  icon: lineChartIcon,
  text: 'Data & Reports',
  sortOrder: 60,
}

export const CensusMgmtTab = {
  href: '/census-management',
  icon: censusIcon,
  text: 'Census mgmt',
  sortOrder: 70,
}

export const CensusMgmtV2Tab = {
  href: '/v2-census-management',
  icon: censusIcon,
  text: 'Census mgmt V2',
  sortOrder: 75,
}

export const CensusReview = {
  href: '/census-review',
  icon: censusIcon,
  text: 'Census Review',
  sortOrder: 78,
}

export const CompanyTab = {
  href: '/company',
  icon: buildingIcon,
  text: 'Company profile',
  sortOrder: 80,
}

export const HealthPlanTab = {
  href: '/health-plans-dashboard/plan-summary-list',
  icon: circlePlusIcon,
  text: 'Health plans',
  sortOrder: 90,
}

export const PlanManagementTab = {
  href: PLAN_MANAGEMENT,
  icon: circlePlusIcon,
  text: 'Plan Management',
  sortOrder: 95,
}

export const ProspectsTab = {
  href: '/prospects',
  icon: pickaxeIcon,
  text: 'Prospects',
  sortOrder: 100,
}

export const ToolsTab = {
  href: '/tools',
  icon: lineChartIcon,
  text: 'Tools',
  sortOrder: 110,
}

export const QLETab = {
  href: `/${LIFE_EVENTS}`,
  icon: circlePlusIcon,
  text: 'Qualifying Life Events',
  sortOrder: 120,
}

export const clientLinks = [HomeTab, EmployeesTab, CensusMgmtTab]

export const clientAdminLink = [
  HomeTab,
  EmployeesTab,
  CensusMgmtTab,
  CompanyTab,
]

export const hixmeOnlyLinks = [
  HomeTab,
  EmployeesTab,
  ExpenseTab,
  AppManagerTab,
  DataAndReportsTab,
  CensusMgmtTab,
  CompanyTab,
  HealthPlanTab,
  PlanManagementTab,
  ProspectsTab,
  ToolsTab,
  CensusMgmtV2Tab,
  CensusReview,
]

export const textLinks = []
