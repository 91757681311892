import React from 'react'
import { Stack, Typography } from '@sureco/design-system'
import { Spin } from '@surecompanies/core_components'
import { LoadingOutlined } from '@ant-design/icons'

export default function EmployeeResult({
  fullname,
  relationship,
  age,
  amount,
  isLoading = false,
}: {
  fullname?: string
  relationship?: string
  age?: number
  amount: number
  isLoading?: boolean
}) {
  return (
    <Stack>
      <Typography color="#666" size={14} bold>
        {fullname}
        {relationship &&
          age && (
            <Typography color="#666" size={12}>
              ({relationship}, age {age})
            </Typography>
          )}
      </Typography>
      {amount !== null && !isLoading && <Typography color="#00A0A8" size={14} bold>
        ${amount?.toFixed(2)}{' '}
        <Typography color="#666" size={12}>
          per month
        </Typography>
      </Typography>}
      {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
    </Stack>
  )
}
