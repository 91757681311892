import React from 'react'
import { WrappedFieldMetaProps } from 'redux-form'

import Text from '@hixme-ui/text'

type FieldErrorProps = {
  meta: WrappedFieldMetaProps
}

const FieldError = ({ meta: { touched, error, warning } }: FieldErrorProps) => (
  <div>
    {touched && error && <Text error>{error}</Text>}
    {touched && warning && <Text warning>{warning}</Text>}
  </div>
)

export default FieldError
