import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { MrModalManager } from '@hixme/modal'
import useFeatureFlags from '../../hooks/useFeatureFlags'
import modal from 'modules/app-core/modal'
import notificationManager from 'modules/app-core/notification-manager'
import {
  RequestBoxContainer,
  SystemReportContainer,
} from 'modules/redux-gateway/containers'
import AppLayout from '@hixme-ui/app-layout'
import { modals } from 'modal-constants'
import { Header, SideNav } from 'components'

const { JodalContainer } = modal.containers
const { NotificationManager } = notificationManager.components

const AdminLayout = ({ companies, children, ...restProps }) => {
  const { flags } = useFeatureFlags()

  useEffect(() => {
    if (flags['customer-service.fe.zendesk-widget-enabled']) {
      try {
        window.zE('webWidget', 'show')
      } catch (e) {
        console.error(e)
      }
    }
    return () => {
      try {
        window.zE('webWidget', 'hide')
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  return companies && companies.length > 0 ? (
    <div style={{ height: '100%' }}>
      <NotificationManager />
      <JodalContainer {...restProps} />
      <MrModalManager appElementId="app-layout-node" modals={modals} />
      <div id="app-layout-node" style={{ height: '100%' }}>
        <AppLayout HeaderComponent={Header} SidebarComponent={SideNav}>
          <div>
            <RequestBoxContainer {...restProps} />
            <SystemReportContainer {...restProps} />
            <div>{children}</div>
          </div>
        </AppLayout>
      </div>
    </div>
  ) : null
}

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
  companies: PropTypes.array,
}

AdminLayout.defaultProps = {
  companies: [],
}

export default AdminLayout
