import { createRequest } from 'modules/redux-gateway'
import t from './actionTypes'

export const getProfileSuccess = (profile) => ({
  type: t.GET_PROFILE_SUCCESS,
  payload: profile,
})

export const getProfileFailure = (error) => ({
  type: t.SAVE_PROFILE_FAILURE,
  payload: {
    status: error.response.status,
    statusText: error.response.statusText,
  },
})

export const getProfileRequest = () => ({
  type: t.GET_PROFILE_REQUEST,
})

export const saveProfileSuccess = (data) => ({
  type: t.SAVE_PROFILE_SUCCESS,
  payload: data,
})

export const saveProfileFailure = (error) => ({
  type: t.SAVE_PROFILE_FAILURE,
  payload: {
    status: error.response.status,
    statusText: error.response.statusText,
  },
})

export const saveProfileRequest = () => ({
  type: t.SAVE_PROFILE_REQUEST,
})

export const getProfile = (key) => (dispatch) => {
  dispatch(getProfileRequest())
  return dispatch(
    createRequest({
      route: 'modelmeProspectGetprospectbykeyGet',
      params: {
        prospectPublicKey: key,
      },
    })
  ).then(
    (data) => {
      dispatch(getProfileSuccess(data))
    },
    (error) => {
      dispatch(
        getProfileFailure({
          response: {
            status: error.status,
            statusText: 'Error retrieving profile',
          },
        })
      )
    }
  )
}

export const saveProfile = (data, cleanProfile, complete) => (dispatch) => {
  dispatch(saveProfileRequest())
  return dispatch(
    createRequest({
      route: 'modelmeProspectPost',
      body: cleanProfile ? cleanProfile(data) : data,
    })
  )
    .then(
      complete && typeof complete === 'function'
        ? complete(data).then((newData) => {
            dispatch(saveProfileSuccess(newData))
          })
        : dispatch(saveProfileSuccess(data))
    )
    .catch((error) => {
      dispatch(
        saveProfileFailure({
          response: {
            status: error.status,
            statusText: 'Sorry, there was an error saving the prospect',
          },
        })
      )
    })
}
