const statuses = [
  { id: 'New Request', name: 'New Request', weight: 1 },
  { id: 'Need More Info', name: 'Need More Info', weight: 3 },
  { id: 'In Progress', name: 'In Progress', weight: 2 },
  { id: 'Approved', name: 'Approved', weight: 4 },
  { id: 'Declined', name: 'Declined', weight: 5 },
  { id: 'Delete', name: 'Deleted', weight: 6 },
]

export const getStatuses = () => statuses

export const getMultiStatuses = () =>
  statuses.map((status) => ({ value: status.id, label: status.name }))

export const getStatusSortMap = () =>
  statuses.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.weight }), {})

const mappedStatuses = {
  'New Request': () => [
    { id: 'New Request', name: 'New Request', weight: 1 },
    { id: 'In Progress', name: 'In Progress', weight: 2 },
    { id: 'Delete', name: 'Deleted', weight: 6 },
  ],
  'In Progress': () => [
    { id: 'In Progress', name: 'In Progress', weight: 2 },
    { id: 'Need More Info', name: 'Need More Info', weight: 3 },
    { id: 'Approved', name: 'Approved', weight: 4 },
    { id: 'Declined', name: 'Declined', weight: 5 },
    { id: 'Delete', name: 'Deleted', weight: 6 },
  ],
  Approved: () => [
    { id: 'Approved', name: 'Approved', weight: 4 },
    { id: 'Delete', name: 'Deleted', weight: 6 },
  ],
  Declined: () => [
    { id: 'Declined', name: 'Declined', weight: 5 },
    { id: 'Delete', name: 'Deleted', weight: 6 },
  ],
  'Need More Info': () => [
    { id: 'Need More Info', name: 'Need More Info', weight: 3 },
    { id: 'Delete', name: 'Deleted', weight: 6 },
  ],
}

export const getStatusAvailable = (status) => {
  const statusFn = mappedStatuses[status]
  return statusFn ? statusFn() : statuses
}
