import { namespaceActions } from 'modules/app-core/redux-utils'
import { NAME } from './constants'

const HIDE_NOTIFICATIONS = 'HIDE_NOTIFICATIONS'
const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
const CREATE_ERROR_NOTIFICATION = 'CREATE_ERROR_NOTIFICATION'
const CREATE_SUCCESS_NOTIFICATION = 'CREATE_SUCCESS_NOTIFICATION'

export default namespaceActions(NAME)([
  HIDE_NOTIFICATIONS,
  CREATE_NOTIFICATION,
  CREATE_ERROR_NOTIFICATION,
  CREATE_SUCCESS_NOTIFICATION,
])
