import { gql } from '@apollo/client'

export const DOWNLOAD_CSV_IMPORT = gql`
  query CsvImportTemplate($importType: CsvImportTypeEnum!) {
    csvImportTemplate(importType: $importType) {
      importType
      templateUrl
    }
  }
`

export const REQUEST_CSV_UPLOAD_QUERY = gql`
  query RequestCsvUpload($fileName: String!, $importType: CsvImportTypeEnum!) {
    requestCsvUpload(fileName: $fileName, importType: $importType) {
      id
      importType
      uploadCsvUrl
    }
  }
`

export const ADD_CSV_IMPORT_MUTATION = gql`
  mutation AddCsvImport(
    $id: ID!
    $importType: CsvImportTypeEnum!
    $filters: JSONObject
  ) {
    addCsvImport(id: $id, importType: $importType, filters: $filters) {
      id
      importType
      status
      fileName
      errorCount
      importRowCount
      validationErrorsCsvUrl
      originalCsvUrl
      importFilters
    }
  }
`

/**
 * HRIS Integration queries
 */

export const GET_HRIS_INTEGRATION = gql`
  query GetHrisIntegration($clientPublicKey: String!) {
    getHrisIntegration(clientPublicKey: $clientPublicKey) {
      id
      clientPublicKey
      accessToken
      requestJson
      createdByPersonId
      createdDateTime
      updatedByPersonId
      updatedDateTime
    }
  }
`

export const GET_FINCH_AUTHORIZATION_URI = gql`
  query GetFinchAuthorizationUri(
    $products: [HrisProductEnum!]!
    $redirectUri: URL!
    $isSandbox: Boolean
  ) {
    getFinchAuthorizationUri(
      products: $products
      redirectUri: $redirectUri
      isSandbox: $isSandbox
    )
  }
`

export const CONNECT_HRIS = gql`
  mutation ConnectHris(
    $clientPublicKey: String!
    $code: String!
    $redirectUri: URL!
  ) {
    connectHris(
      clientPublicKey: $clientPublicKey
      code: $code
      redirectUri: $redirectUri
    ) {
      id
      clientPublicKey
      accessToken
      requestJson
      createdByPersonId
      createdDateTime
      updatedByPersonId
      updatedDateTime
    }
  }
`

export const DISCONNECT_HRIS = gql`
  mutation DisconnectHris($clientPublicKey: String) {
    disconnectHris(clientPublicKey: $clientPublicKey) {
      id
      clientPublicKey
      accessToken
      requestJson
      createdByPersonId
      createdDateTime
      updatedByPersonId
      updatedDateTime
    }
  }
`

export const SEND_HRIS_TERMINATION_EVENT = gql`
  mutation EnrollMeTerminationEventHandler(
    $enrollMeTerminationEventHandlerInput: EnrollMeTerminationEventHandlerInput
  ) {
    enrollMeTerminationEventHandler(
      enrollMeTerminationEventHandlerInput: $enrollMeTerminationEventHandlerInput
    ) {
      code
      message
    }
  }
`

export const GET_DASHBOARD_EMBED_URL = gql`
  query GetDashboardUrl($dashboardId: String!) {
    getDashboardUrl(dashboardId: $dashboardId) {
      embedUrl
    }
  }
`

export const GET_QUICKSIGHT_URLS = gql`
  query GetAllQuicksightReports($input: QuicksightReportInput) {
    getAllQuicksightReports(input: $input) {
      dashboardId
      name
      description
      publicKey
      url
      parameters
      createdAt
    }
  }
`
export const CALCULATE_TERMINATION_DATES = gql`
  query ComputeCobraDates($terminationDate: DateTime!) {
    computeCobraDates(terminationDate: $terminationDate) {
      previousCoverageEndDate
      cobraBenefitsEffectiveDate
      cobraBenefitsEligibleStartDate
      cobraBenefitsEligibleEndDate
    }
  }
`
export const CONFIRM_EMP_TERMINATION = gql`
  mutation TerminateEmployee($input: TerminateEmployeeInput) {
    terminateEmployee(input: $input) {
      id
      firstName
      lastName
      clientPublicKey
      terminationDate
      employmentStatus
    }
  }
`

export const START_TERMINATION_PROCESS = gql`
  mutation TriggerTerminateEmployee($input: TerminateEmployeeInput) {
    triggerTerminateEmployee(input: $input) {
      id
    }
  }
`

/*
 * Client payroll management
 */
export const GET_ALL_CLIENT_PAYROLL_PERIOD = gql`
  query GetAllClientPayrollPeriod(
    $clientPublicKey: String!
    $benefitYear: String!
  ) {
    getAllClientPayrollPeriod(
      clientPublicKey: $clientPublicKey
      benefitYear: $benefitYear
    ) {
      id
      publicKey
      clientPublicKey
      firstPayDay
      secondPayDay
      payrollInterval
      cutOffTime
      payrollRunDate {
        date
        weekStartDate
        weekEndDate
        note
      }
      createdAt
    }
  }
`

export const CALCULATE_CLIENT_PAYROLL_PERIOD = gql`
  query CalculateClientPayrollPeriod($input: CalculatePayrollInput!) {
    calculateClientPayrollPeriod(input: $input) {
      firstPayDay
      secondPayDay
      payrollInterval
      payrollRunDate {
        date
        weekStartDate
        weekEndDate
        note
      }
    }
  }
`

export const SAVE_CLIENT_PAYROLL_PERIOD = gql`
  mutation Mutation($input: SavePayrollInput!) {
    saveClientPayrollPeriod(input: $input) {
      id
      publicKey
      clientPublicKey
      firstPayDay
      secondPayDay
      payrollInterval
      payrollRunDate {
        date
        weekStartDate
        weekEndDate
        note
      }
      createdAt
      cutOffTime
    }
  }
`

export const UPDATE_CLIENT_PAYROLL_PERIOD = gql`
  mutation UpdateClientPayrollPeriod(
    $input: UpdatePayrollPayrollRunDateInput!
  ) {
    updateClientPayrollPeriod(input: $input) {
      id
      publicKey
      clientPublicKey
      firstPayDay
      secondPayDay
      payrollInterval
      payrollRunDate {
        date
        weekStartDate
        weekEndDate
        note
      }
      createdAt
      cutOffTime
    }
  }
`
/*
 * END Client payroll management
 */

export const GET_KAHUNA_REPORT_DATA = gql`
  query BenefitByFamilyReport($input: BenefitByFamilyInput!) {
    benefitByFamilyReport(input: $input) {
      sql
      rows {
        employee {
          personId
          employeeId
          companyEmployeeId
          relationship
          firstName
          lastName
          hireDate
          terminationDate
          benefitsEffectiveDate
          benefitYear
          enrollmentStatus
          employmentStatus
          allBenefitsWorkerMonthlyContribution
          allBenefitsEmployerMonthlyContribution
          allBenefitsTotalMonthlyContribution
        }
        medical {
          planType
          planName
          carrier
          metalTier
          memberId
          primary
          sbcLink
          enrollmentTier
          workerMonthlyContribution
          employerMonthlyContribution
          totalMonthlyContribution
          benefitStatus
          waived
        }
        dental {
          groupNumber
          planType
          planName
          carrier
          primary
          sbcLink
          workerMonthlyContribution
          employerMonthlyContribution
          totalMonthlyContribution
          carrierUrl
          carrierPhone
          benefitStatus
          waived
        }
        vision {
          groupNumber
          planType
          planName
          carrier
          primary
          sbcLink
          workerMonthlyContribution
          employerMonthlyContribution
          totalMonthlyContribution
          carrierUrl
          carrierPhone
          benefitStatus
          waived
        }
        life {
          groupNumber
          planType
          planName
          carrier
          sbcLink
          workerMonthlyContribution
          employerMonthlyContribution
          totalMonthlyContribution
          carrierUrl
          carrierPhone
          benefitStatus
          waived
        }
        ltd {
          planType
          planName
          carrier
          sbcLink
          workerMonthlyContribution
          employerMonthlyContribution
          totalMonthlyContribution
          carrierUrl
          carrierPhone
          benefitStatus
          waived
          enrollmentType
        }
        ichra {
          benefitYearAllowance
          approvedAmount
          benefitYearRemaining
        }
      }
      total
    }
  }
`

export const GET_ENROLLMENT_MEMBER_DETAIL_STATUS = gql`
  query GetEnrollmentMemberDetailStatus($input: OpenEnrollmentInput!) {
    enrollmeMemberEnrollmentStatus(input: $input) {
      clientName
      clientPublicKey
      employeePublicKey
      personPublicKey
      employeeId
      firstName
      middleName
      lastName
      benefitYear
      benefitEffectiveDate
      benefitEndDate
      enrollmentStartDate
      enrollmentEndDate
      enrollmeStatus
      complete
      emailAddress
      enrollmeStatusId
      daysUntilDeadline
      location
      dateOfBirth
      lastRefreshedTimestamp
    }
  }
`

export const GET_ENROLLMENT_COST_PER_EMPLOYEE = gql`
  query GetEnrollmentCostPerEmployee($input: OpenEnrollmentInput!) {
    enrollmeCostPerClient(input: $input) {
      clientName
      clientPublicKey
      benefitYear
      shared
      percents
      averagePriceMonth
    }
  }
`

export const GET_ENROLLMENT_TOTAL_COST = gql`
  query GetEnrollmentTotalCost($costOverTimeInput: CostOverTimeInput!) {
    enrollmeTotalCostOverTime(input: $costOverTimeInput) {
      clientPublicKey
      benefitYear
      enrollmentYear
      enrollmentDay
      benefitType
      employeeContribution
      employerContribution
      totalPlanCost
    }
  }
`

export const GET_ENROLLMENT_STATUS_TRACKER = gql`
  query GetEnrollmentStatusTracker($input: OpenEnrollmentInput!) {
    enrollmeStatusTrackerPercent(input: $input) {
      clientName
      clientPublicKey
      benefitYear
      enrollmeStatus
      statusCount
      totalPopulationOe
      overallPercent
    }

    enrollmeTrackerBenefits(input: $input) {
      clientName
      clientPublicKey
      benefitYear
      benefitType
      benefitTypeCount
      statusCount
      totalPopulationOe
      perCalculation
    }
  }
`

export const GET_ENROLLMENT_ELECTION_SUMMARY = gql`
  query GetEnrollmentElectionSummary($input: OpenEnrollmentInput!) {
    enrollmeElectionSummary(input: $input) {
      benefitType
      benefitTypeCount
      totalPopulationOe
    }
  }
`

export const GET_ENROLLMENT_PERIOD = gql`
  query GetEnrollmentPeriod($input: OpenEnrollmentInput!) {
    enrollmeOpenEnrollmentDays(input: $input) {
      clientPublicKey
      benefitYear
      benefitEffectiveDate
      benefitEndDate
      benefitEffectivePolicy
      benefitEffectivePolicyUnit
      numOfDaysPlanYear
      oeDaysLeft
      benefitPeriod
      notCompletedCount
      totalPopulationOe
      enrollmentStartDate
      enrollmentEndDate
    }
  }
`

export const SEND_APPLICATION_SUBMISSION_EVENT = gql`
  mutation ApplicationSubmissionHandler(
    $input: ApplicationSubmissionHandlerInput!
  ) {
    applicationSubmissionHandler(input: $input) {
      code
      message
    }
  }
`

export const GET_LAST_CENSUS_UPLOAD = gql`
  query Query(
    $importTypes: [CsvImportTypeEnum!]!
    $sort: SearchImportsSortInput
    $filters: JSONObject
    $statuses: [CsvImportStatusEnum!]
  ) {
    searchCsvImports(
      importTypes: $importTypes
      sort: $sort
      filters: $filters
      statuses: $statuses
    ) {
      limit
      offset
      total
      imports {
        updateDateTime
      }
    }
  }
`
export const GET_ENROLLMENT_VALIDATION = gql`
  query GetEnrollmentValidation($input: EnrollmentValidationInput!) {
    getEnrollmentValidation(input: $input) {
      benefitsEligible
      clientPublicKey
      enrollmentStartDate
      personEnrollmentStartDate
      companyName
      count
    }
  }
`
