import React from 'react'
import PropTypes from 'prop-types'
import Text from '@hixme-ui/text'

const FieldName = ({ children }) => <Text light>{children}</Text>

FieldName.propTypes = {
  children: PropTypes.string,
}
FieldName.defaultProps = {
  children: '',
}
FieldName.displayName = 'FieldName'

export default FieldName
