import React from 'react'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'
import { withState } from 'recompose'

import Container from '@hixme-ui/container'
import Currency from '@hixme-ui/currency'
import Text from '@hixme-ui/text'
import theme from '@hixme-ui/theme'
import ReceitpLineItemLoader from './ReceiptLineItemLoader'

const enhanced = withState('confirmDelete', 'setConfirmDelete', false)

const ReceiptLineItem = enhanced(
  ({ item, onRemove, onEdit, isLoading, confirmDelete, setConfirmDelete }) => {
    const onDelete = () => {
      setConfirmDelete(true)
    }

    const isDeclined = item.reason
    const style = {
      flexBasis: '33%',
      lineBreak: 'loose',
    }
    return isLoading ? (
      <ReceitpLineItemLoader />
    ) : (
      <Container
        flex
        padding="10px 5px 5px"
        justifyContent="space-between"
        width="100%"
        alignCenter
        style={{textAlign: 'left'}}
      >
        <Text style={style}>{item.description}</Text>
        <Text style={{ ...style, fontStyle: isDeclined ? 'italic' : 'normal' }}>
          {isDeclined ? `${item.status} - ${item.reason}` : item.status}
        </Text>
        <Currency
          style={{
            ...style,
            textDecoration: isDeclined ? 'line-through' : 'none',
            textAlign: 'right',
          }}
        >
          {item.amount}
        </Currency>
        {onEdit && (
          <FontAwesome
            style={{
              cursor: 'pointer',
              marginLeft: '100px',
              color: theme.textColors.light,
              visibility: item.description.includes('insurance')
                ? 'hidden'
                : 'visible',
            }}
            name="pencil-alt"
            size="lg"
            onClick={() => onEdit(item)}
          />
        )}
        {onRemove && (
          <Container flex noPadding alignItems="center">
            <FontAwesome
              style={{
                cursor: 'pointer',
                marginLeft: '100px',
                color: confirmDelete ? 'red' : theme.textColors.light,
              }}
              name="times-circle"
              size="lg"
              onClick={() =>
                confirmDelete || item.local ? onRemove(item) : onDelete()
              }
            />
            {confirmDelete && (
              <Container flex noPadding>
                <Text
                  danger
                  style={{ marginLeft: '5px' }}
                  cursor="pointer"
                  onClick={() => onRemove(item)}
                >
                  Confirm
                </Text>
                <Text
                  style={{ marginLeft: '5px' }}
                  cursor="pointer"
                  onClick={() => setConfirmDelete(false)}
                >
                  Cancel
                </Text>
              </Container>
            )}
          </Container>
        )}
      </Container>
    )
  }
)

ReceiptLineItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    reason: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
  onRemove: PropTypes.func,
}

ReceiptLineItem.defaultProps = {
  isLoading: false,
  onRemove: null,
}

ReceiptLineItem.displayName = 'ReceiptLineItem'

export default ReceiptLineItem
