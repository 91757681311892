import React from 'react'

// hixme-ui
import Container from '@hixme-ui/container'
import Label from '@hixme-ui/label'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'
import RadioButton from '@hixme-ui/radio-button'

// project
import { hasError } from 'modules/redux-form-utils'

const BooleanRadioInput = (field) => {
  const { input, meta, label, tooltipId, Tooltip = null, ...rest } = field

  const options = [{ label: 'Yes', key: true }, { label: 'No', key: false }]
  return (
    <Container noPadding>
      <Label>{label}</Label>
      {tooltipId && (
        <span
          data-tip
          data-for={tooltipId}
          style={{ cursor: 'pointer', marginLeft: '4px' }}
        >
          <Icon info light size={1.3} />
        </span>
      )}

      <Container flexRow padding="20px 0" width="100%">
        {options.map((option) => (
          <Container noPadding marginRight="1.5em">
            <RadioButton
              key={`${input.name}-toggle-${option.label}`}
              {...input}
              {...rest}
              text={option.label}
              value={option.key}
              checked={input.value === option.key}
            />
          </Container>
        ))}
      </Container>
      {hasError(meta) && (
        <Text error style={{ marginRight: '10px' }}>
          {meta.error}
        </Text>
      )}
      {Tooltip && <Tooltip tooltipId={tooltipId} />}
    </Container>
  )
}

export default BooleanRadioInput
