import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Button from '@hixme-ui/button'
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Separator from '@hixme-ui/separator'
import Title from '@hixme-ui/title'

import AllowContainer from 'containers/AllowContainer/AllowContainer'

import ReadonlyField from '../ReadonlyField'

const PersonInfo = ({
  Relationship,
  FullName,
  Gender,
  StreetAddress,
  StreetAddressExt,
  City,
  StateProvince,
  PhoneNumber,
  DateOfBirth,
  AccountCreated,
  HireDate,
  SSN,
  PostalCode,
  County,
  Id,
  handleEdit,
  handleEditDependent,
  isWorker,
}) => (
  <Container flex width="420px" direction="column" padding="10px">
    <Container flex noPadding justifyContent="space-between">
      <Container flex noPadding alignItems="center">
        <Container flex noPadding margin="0 20px 0 0">
          <Icon user light={isWorker} size={1.5} />
        </Container>
        <Title smaller>{FullName}</Title>
      </Container>
      <AllowContainer platformHixmeAdmin platformBroker>
        {isWorker ? (
          <Button mini outline onClick={handleEdit}>
            Edit
          </Button>
        ) : (
          <div>
            <Button mini outline onClick={handleEditDependent}>
              Edit
            </Button>
          </div>
        )}
      </AllowContainer>
    </Container>
    <Separator size="1px" />
    <span id="readonlyFields">
      <ReadonlyField fieldName="Gender" text="Gender" value={Gender} />
      <ReadonlyField
        fieldName="Relationship"
        text="Relationship"
        value={Relationship}
      />
      <ReadonlyField
        fieldName="StreetAddress"
        text="Street address"
        value={StreetAddress}
      />
      <ReadonlyField
        fieldName="StreetAddressExt"
        text="Street address ext"
        value={StreetAddressExt}
      />
      <ReadonlyField fieldName="City" text="City" value={City} />
      <ReadonlyField
        fieldName="StateProvince"
        text="State"
        value={StateProvince}
      />
      <ReadonlyField fieldName="County" text="County" value={County} />
      <ReadonlyField
        fieldName="PostalCode"
        text="Zip code"
        value={PostalCode}
      />
      {SSN && <ReadonlyField fieldName="SSN" text="SSN" value={SSN} />}
      {PhoneNumber && (
        <ReadonlyField
          fieldName="PhoneNumber"
          text="Phone"
          value={PhoneNumber}
        />
      )}
      <ReadonlyField
        fieldName="DateOfBirth"
        text="Date of birth"
        value={DateOfBirth}
      />
      <ReadonlyField
        fieldName="AccountCreated"
        text="Account created"
        value={AccountCreated}
      />
      {HireDate && (
        <ReadonlyField fieldName="HireDate" text="Hire date" value={HireDate} />
      )}
      <ReadonlyField fieldName="Id" text="Id" value={Id} />
    </span>
  </Container>
)

PersonInfo.displayName = 'PersonInfo'

PersonInfo.propTypes = {
  FullName: PropTypes.string,
  Gender: PropTypes.string,
  StreetAddress: PropTypes.string,
  StreetAddressExt: PropTypes.string,
  StateProvince: PropTypes.string,
  City: PropTypes.string,
  County: PropTypes.string,
  PostalCode: PropTypes.string,
  Id: PropTypes.string,
  Relationship: PropTypes.string,
  DateOfBirth: PropTypes.string,
  AccountCreated: PropTypes.string,

  SSN: PropTypes.string,
  PhoneNumber: PropTypes.string,
  HireDate: PropTypes.string,

  handleEdit: PropTypes.func.isRequired,
  handleEditDependent: PropTypes.func.isRequired,
  isWorker: PropTypes.bool,
}

PersonInfo.defaultProps = {
  FullName: '',
  Gender: '',
  StreetAddress: '',
  StreetAddressExt: '',
  StateProvince: '',
  City: '',
  County: '',
  PostalCode: '',
  Id: '',
  Relationship: '',
  DateOfBirth: '',
  AccountCreated: '',
  HireDate: '',
  PhoneNumber: '',
  SSN: '',
  isWorker: false,
}

export default PersonInfo
