import axios from 'axios'
import { isProduction } from '../app-config/environment'

const ENROLLMENT_PLATFORM_API_URL = {
  DEV: 'https://api.dev.surecolabs.com',
  PROD: 'https://api.docday.com'
}

export const getApiUrl = () => {
  if (isProduction()) {
    return ENROLLMENT_PLATFORM_API_URL.PROD
  }
  return ENROLLMENT_PLATFORM_API_URL.DEV
}

/**
 * Sends ICHRA email for batch of member IDs
 */
export const sendIchraBatch = async (memberIds: string[]): Promise<{ [key: string]: boolean | string }> => {
  const response = await axios.post(
    '/api/v1/messaging/members/send-ichra',
    { memberIds },
    { baseURL: getApiUrl() }
  )

  return response.data
}
