import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'
// import Text from '@hixme-ui/text'

const RequestBox = ({ processing, errors }) => (
  <Container
    noPadding
    style={{
      position: 'absolute',
      top: 68,
      left: 0,
      color: 'white',
      fontSize: 11,
    }}
  >
    {processing.length > 0 && (
      <Container blue padding="4px" style={{ display: 'inline-block' }}>
        ...
      </Container>
    )}
    {processing.length === 0 &&
      errors.length > 0 && (
        <Container red padding="4px" style={{ display: 'inline-block' }}>
          *error loading
        </Container>
      )}
  </Container>
)

RequestBox.displayName = 'RequestBox'
RequestBox.propTypes = {
  processing: PropTypes.array,
  errors: PropTypes.array,
}

RequestBox.defaultProps = {
  processing: [],
  errors: [],
}

export default RequestBox
