import React from 'react'
import PropTypes from 'prop-types'

import Toggle from '@hixme-ui/toggle'
import Text from '@hixme-ui/text'

import { hasError } from 'modules/app-core/redux-form-utils'

const FieldToggle = ({ input, meta, ...restProps }) => {
  const isChecked = (typeof input.value === 'boolean' && input.value) || false
  return (
    <div>
      <Toggle {...input} {...restProps} checked={isChecked} />
      {hasError(meta) && <Text error>{meta.error}</Text>}
    </div>
  )
}

FieldToggle.displayName = 'FieldToggle'
FieldToggle.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export default FieldToggle
