import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import style from '../pages/CensusManagement.module.scss'
import { Button } from '@sureco/design-system'
import { Layout as AdLayout } from '@surecompanies/core_components'
import Page from 'layouts/Page'
import PageWrapper from '../../../PageWrapper'
import BreadcrumbNav from 'enrollment-platform/components/Breadcrumbs'
import FileInfo from '../components/FileInfo'
import ReviewTabs from '../components/ReviewTabs'
import { CensusFileRowCountsDto } from '../api/dto/census-file-row-counts.dto'
import { CensusFileDto } from '../api/dto/census-file.dto'
import { getMyClients } from 'enrollment-platform/api/persons'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'
import { CensusFileRowStatusEnum } from '../api/enums/census-file-row-status.enum'
import { CENSUS_MANAGEMENT_PATH } from '../constants'
import { CellValueChangedEvent, ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { SimpleCustomerDto } from 'enrollment-platform/api/persons/dto/simple-customer.dto'
import { getInProcessPageData } from '../utils/get-in-process-page-data'
import { useMarkRowDirtyUpdates } from '../hooks/use-mark-row-dirty-updates'
import RejectSelectedButton from './RejectSelectedButton'
import ToggleEditModeButton from './ToggleEditModeButton'
import { CensusFileRowDto } from '../api/dto/census-file-row.dto'
import { mapGridDataToCensusFileRowDto } from '../utils/map-grid-data-to-census-file-row-dto'
import { updateCensusFileRows } from '../api/census-enrollment-platform-api-client'
import SendSelectedUpdatesForReviewButton from './SendSelectedUpdatesForReviewButton'
import InlineEditActionButtons from './InlineEditActionButtons'

const { Content } = AdLayout

// Todo: try to incorporate RowData as a data structure for the output
interface _RowData {
  field: string;
  liveValue: any;
  newValue: any;
}

interface ReviewRecordsBaseProps {
  routeParams: { importPublicKey: string };
  actionButton?: ({ censusRows, handleSubmit, selectedRows }: {
    censusRows: any[],
    selectedRows: any[],
    handleSubmit: () => void;
  }) => React.ReactElement;
  rowQueryParams: {
    censusRowType: string;
    censusFileRowStatuses: CensusFileRowStatusEnum[];
  };
  handleCellValueChanged?: (cellValueChangedEvent: CellValueChangedEvent) => void;
  modifyColDefs?: (baseColDefs: ColDef<CensusFileRowGridData>[]) => ColDef<CensusFileRowGridData>[];
  setLoading: (loading: boolean) => void
}

function ReviewRecordsBaseUpdate({
  routeParams,
  actionButton,
  rowQueryParams,
  setLoading,
}: ReviewRecordsBaseProps) {
  const { censusRowType: paramCensusRowType, censusFileRowStatuses } = rowQueryParams

  // Declare all hooks at the top level
  const [counts, setCounts] = useState<CensusFileRowCountsDto | undefined>()
  const [censusFile, setCensusFile] = useState<CensusFileDto | undefined>()
  const { importPublicKey: publicKey } = routeParams
  const [censusRowType, setCensusRowType] = useState<string>(paramCensusRowType)
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [censusRows, setCensusRows] = useState<CensusFileRowGridData[]>([])
  const [clients, setClients] = useState<SimpleCustomerDto[]>([])
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({})
  const [mode, setMode] = useState<'edit' | 'submit'>('submit')
  const [currentPage, setCurrentPage] = useState(0) // Start on page 0
  // const [prevRows, setPrevRows] = useState<CensusFileRowGridData[]>([]);
  const gridRef = useRef<GridReadyEvent | undefined>(undefined)
  const clientName = (key) => clients.find((c) => c.id === key)?.name ?? '...'
  const prevRowsRef = useRef<CensusFileRowGridData[]>([])
  // Reset logic (clearing selected rows)
  const resetGrid = useCallback(() => {
    gridRef.current?.api.refreshServerSide({ purge: true })
    gridRef.current?.api.deselectAll()
    setSelectedRows([])
  }, [])

  const pageSize = 5 // Number of employees per page

  // Tab change logic
  const onTabChange = useCallback((tab: string) => {
    setCensusRowType(tab)
    setSelectedRows([])
  }, [])
  const fetchClients = async () => {
    const data = await getMyClients()
    setClients(data.sort((a, b) => a.name.localeCompare(b.name)))
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchClients()
    }, 1)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    setLoading(true)
    // Fetch data when component is mounted or censusRowType changes
    const fetchData = async () => {
      try {
        const {
          rowCounts,
          data,
          pagination: _pagination,
          censusFile,
        } = await getInProcessPageData({ limit: 100, page: 1, publicKey, censusRowType, censusFileRowStatuses })

        setCounts(rowCounts)
        setCensusFile(censusFile)
        const mergedData: CensusFileRowGridData[] = data.map((d) => ({ ...d, ...d.rowData }))
        setCensusRows(mergedData)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false) // End loading
      }
    }

    fetchData()
  }, [censusRowType, publicKey])

  const totalPages = Math.ceil(censusRows.length / pageSize)
  const currentEmployees = censusRows.slice(currentPage * pageSize, (currentPage + 1) * pageSize)

  // Handle next and previous buttons
  const goToNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1)
    }
  }

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const getActiveBenefits = (update: any): string => {
    if (Array.isArray(update.activeBenefits)) {
      // Use reduce to remove duplicates
      const uniqueBenefits = update.activeBenefits.reduce((acc: string[], benefit: string) => {
        if (!acc.includes(benefit)) {
          acc.push(benefit)
        }
        return acc
      }, [])
      return uniqueBenefits.join(', ')
    }
    return '' // Return an empty string if there are no active benefits
  }

  // Process the row data for display
  const populateUpdateRowData = useCallback((row: any): any[] => {
    if (row == null) return []
    const up: any[] = []
      Object.keys(row?.updates?.updateFields).forEach((key) => {
        const update = row.updates.updateFields[key]
        const data = {
          field: key,
          liveValue: update.liveValue ?? '', // Replace undefined or null with empty string
          newValue: update.newValue ?? '', // Replace undefined or null with empty string
          employeeName: `${row.firstName} ${row.lastName}`, // Assuming employeeName is available
          employeeId: row.employeeId,
          rowId: row.id, // Assuming employeeId is available
          proposedInitialValue: update.newValue ?? '',
          allRowData: row
        }
        up.push(data)
      })
    return up
  }, [])

  const finalColDefs = useMemo(() => [
    {
      headerName: 'Field',
      field: 'field',
      flex: 1,
    },
    {
      headerName: 'Current',
      field: 'liveValue',
      flex: 1,
    },
    {
      headerName: 'Proposed',
      field: 'newValue',
      flex: 1,
      editable: mode === 'edit',
    },
  ], [mode])

  // Handle checkbox change for each row
  const handleCheckboxChange = (row) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems }
      // Toggle the checked state for the specific row
      if (newCheckedItems[row.employeeId]) {
        delete newCheckedItems[row.employeeId] // Uncheck the box
      } else {
        newCheckedItems[row.employeeId] = true // Check the box
      }
      return newCheckedItems
    })
  }

  useEffect(() => {
    const newSelectedRows = censusRows.filter(row => checkedItems[row.employeeId])
    setSelectedRows(newSelectedRows) // Update selectedRows based on checkedItems
  }, [checkedItems, censusRows])

  // Submit and Reject handlers
  const handleSubmit = () => {
    // Handle the submit action here, for example:
    resetGrid()
    fetchData()
  }

  useEffect(() => {
    prevRowsRef.current = censusRows
  }, [censusRows])

  const { markRowDirty, dirtyRows, cleanAllRows, undoLastEdit, editCount } = useMarkRowDirtyUpdates(prevRowsRef.current, setCensusRows)

  const onCellValueChanged = useCallback((params: CellValueChangedEvent<any>) => {
    const currentCensusRows = [...censusRows] // Take a snapshot before updating

    prevRowsRef.current = currentCensusRows

    // Optionally, mark the row as dirty after updating state (if necessary)
    markRowDirty(params)

    const updatedRows = [...currentCensusRows]
    const updatedRow = updatedRows.find(row => row.id === params.data.rowId) // Find the edited row
    if (updatedRow) {
      // Access the updates blob inside the row
      const updates = updatedRow?.updates?.updateFields

      // Get the field from params.data (e.g., 'activeBenefits')
      const fieldToUpdate = params.data.field

      if (updates && updates[fieldToUpdate]) {
        // Update the newValue in the updates blob
        updates[fieldToUpdate].newValue = params.newValue // Set the new value for that field

        // Update the censusRows state to trigger a re-render
        setCensusRows(updatedRows) // This will update the grid

        // Optional: Rebuild the row data for AG-Grid if necessary (but this is mostly handled by rowData prop)
      }
    }

    // Refresh the cell to reflect changes (optional)
    params.api.refreshCells({ rowNodes: [params.node] })
    }, [censusRows, markRowDirty])

    const submitCorrections = async (rowsToSubmit: any[]) => {
      const updatedRows: CensusFileRowDto[] = rowsToSubmit.map(data => {
        // Access the actual row (allRowData)
        const { allRowData, field, newValue } = data

        // Ensure that the updates field exists and update the `newValue` for the specific `field`
        if (allRowData && allRowData.updates && allRowData.updates?.updateFields[field]) {
          // Update the `newValue` inside the `updates` blob
          allRowData.updates.updateFields[field].newValue = newValue
          allRowData[field] = newValue
        }

        // Return the updated row (use `mapGridDataToCensusFileRowDto` if needed)
        return mapGridDataToCensusFileRowDto(allRowData)
      })
      // Now send the updated rows to the API for update
      await updateCensusFileRows(updatedRows, publicKey)
    }

    const fetchData = async () => {
      setLoading(true) // Set loading to true while fetching data

      try {
        const {
          rowCounts,
          data,
          pagination: _pagination,
          censusFile,
        } = await getInProcessPageData({ limit: 100, page: 1, publicKey, censusRowType, censusFileRowStatuses })

        setCounts(rowCounts)
        setCensusFile(censusFile)
        const mergedData: CensusFileRowGridData[] = data.map((d) => ({ ...d, ...d.rowData }))
        setCensusRows(mergedData)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false) // End loading
      }
    }

  // Function to generate the clickable employee link
  const generateCoverageSummaryLink = (employeePublicKey: string, firstName: string, lastName: string) => {
      return (
        <a
          href={`https://admin.dev.surecolabs.com/employees/${employeePublicKey}/coverage`}
          target="_blank"
          style={{ textDecoration: 'underline', fontWeight: 'bold', color: '#003146' }}
          title="Link to the coverage summary"
        >
          {firstName} {lastName}
        </a>
      )
  }

  useEffect(() => {
    prevRowsRef.current = censusRows
  }, [censusRows])

  // Render the Ag-Grid
  return (
    <PageWrapper>
      <Page
        title="Review Records"
        action={actionButton && actionButton({
          censusRows,
          selectedRows,
          handleSubmit: resetGrid,
        })}
        breadcrumb={
          <BreadcrumbNav
            items={[
              { title: 'Census Management', path: CENSUS_MANAGEMENT_PATH },
              { title: 'Review Records' },
            ]}
          />
        }
      >
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <ToggleEditModeButton
              dirtyRows={dirtyRows}
              selectedRows={selectedRows}
              mode={mode} // Pass mode based on the state
              setMode={setMode} // Pass the toggle function to update mode
            />
            {mode === 'edit' ? (
              <InlineEditActionButtons
                undoLastEdit={undoLastEdit}
                editCount={editCount}
                dirtyRows={dirtyRows}
                handleSubmit={handleSubmit}
                cleanAllRows={cleanAllRows}
                submitCorrections={submitCorrections}
                updateMode={true}
              />
            ) : (
              <>
                <SendSelectedUpdatesForReviewButton publicKey={publicKey} selectedRows={selectedRows} handleSubmit={handleSubmit} setLoading={setLoading} cleanAllRows={cleanAllRows} />
                <RejectSelectedButton
                  publicKey={publicKey}
                  selectedRows={selectedRows}
                  handleSubmit={handleSubmit}
                  cleanAllRows={cleanAllRows}
                  setLoading={setLoading}
                />
              </>
            )}
            </div>
        <AdLayout>
          <Content className={style.content}>
            <FileInfo data={censusFile} clientName={clientName(censusFile?.clientPublicKey)} style={{ padding: '15px 0 0 15px' }} />
                          {/* Submit and Reject buttons with count of checked items */}
            <ReviewTabs
              publicKey={publicKey}
              counts={counts}
              onTabChange={onTabChange}
              currentTab={censusRowType}
              style={{ padding: '15px 0 0 15px' }}
            />

            {/* Display Employee Name and ID */}

              {currentEmployees.map((row, index) => (
                <div key={row.id} className="ag-theme-alpine" style={{
                  marginBottom: '20px',
                  backgroundColor: checkedItems[row.employeeId] ? 'var(--SureCo-Medium-Blue, #E0EAF1)' : 'white',
                  padding: '15px',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  font: 'SF Pro Text',
                  width: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <div key={index}>
                   {/* Checkbox */}
                  <input
                    type="checkbox"
                    checked={checkedItems[row.employeeId] || false}
                    disabled={mode === 'edit'}
                    onChange={() => handleCheckboxChange(row)} // Only change state for this row
                    style={{ marginRight: '10px', backgroundColor: checkedItems[row.employeeId] ? 'var(--Components-Checkbox-Global-colorWhite, #FFFFFF)' : 'white' }} // Space between checkbox and text
                  />
                    {/* Display Employee Name and ID once at the top of each section */}
                    <div style={{ marginBottom: '20px', fontSize: '16px', fontWeight: 'bold', display: 'inline', padding: '10px' }}>
                    {generateCoverageSummaryLink(row?.updates?.employeePublicKey ?? '', row.firstName, row.lastName)}
                    </div>
                    {/* Display Employee ID with a different color */}
                    <div style={{ marginBottom: '20px', fontSize: '16px', fontWeight: 'bold', color: '#999999', display: 'inline', padding: '10px' }}>
                      (EmployeeID: {row.employeeId})
                    </div>
                    <div style={{ fontSize: '14px', color: '#999999', marginTop: '10px' }}>
                      {getActiveBenefits(row.updates)} {/* Display active benefits */}
                    </div>
                    </div>
                  <div style={{ marginTop: '15px' }}></div>
                  <div className="ag-theme-alpine">

                  {/* Ag-Grid React component */}
                    <AgGridReact
                      columnDefs={finalColDefs}
                      rowData={populateUpdateRowData(row)}
                      domLayout="autoHeight"
                      onCellValueChanged={onCellValueChanged}
                    />
                    </div>
                </div>
              ))}
                {/* Pagination Controls */}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Button onClick={goToPreviousPage} disabled={currentPage === 0}>Previous</Button>
            <span style={{ margin: '0 10px' }}>Page {currentPage + 1} of {totalPages}</span>
            <Button onClick={goToNextPage} disabled={currentPage === totalPages - 1}>Next</Button>
          </div>

          </Content>
        </AdLayout>
      </Page>
    </PageWrapper>
  )
}

export default ReviewRecordsBaseUpdate
