import React from 'react'
import { Stack, Typography } from '@sureco/design-system'
import ContentSection from 'layouts/ContentSection/ContentSection'
import RowSection from './component/RowSection'
import IntegrationForm from './component/IntegrationForm/IntegrationForm'
import useHrisPayroll from './hooks/useHrisPayroll'
import HrisRules from './component/HrisRules/HrisRules'
import PayrollList from './component/PayrollList'

export default function HrisPayrollTab({ id }: { id: string }) {
  const { data, isLoading, updateHrisIntegration, isUpdatingHrisIntegration } = useHrisPayroll(id)
  return (
    <Stack>
      <ContentSection title="HRIS" loading={isLoading}>
        <Stack space={20}>
          <RowSection title="API Integration">
            <Stack>
              <IntegrationForm
                initialValues={{
                  ...data
                }}
                isUpdatingHrisIntegration={isUpdatingHrisIntegration}
                onFormSubmit={updateHrisIntegration}
              />
            </Stack>
          </RowSection>
          <RowSection title="Eligibility Rules">
            {data?.eligibilityRules?.length > 0 ? <HrisRules rule={data?.eligibilityRules} /> : <Typography size={14} color='#999'>No eligibility rules set</Typography>}
          </RowSection>
          <RowSection title="Payroll History" >
            <Stack>
              <Typography size={14}>Download the most recent payroll deductions in CSV format.</Typography>
              <PayrollList list={data?.payrollHistory} />
            </Stack>
          </RowSection>
          <RowSection title="Excluded Benefits" />
        </Stack>
      </ContentSection>
    </Stack>
  )
}
