import { createRequest } from 'modules/redux-gateway'
import t from './actionTypes'
import { GET_LOCATIONS_ROUTE } from './constants'

export const loadLocations = (locations, postalCode) => ({
  type: t.LOAD_LOCATIONS,
  payload: { locations, postalCode },
})

export const getLocations = (postalCode) => (dispatch) => {
  if (postalCode && postalCode.length === 5) {
    const req = dispatch(
      createRequest({
        route: GET_LOCATIONS_ROUTE,
        params: { zipCode: postalCode },
      })
    ).then(
      (data) => {
        dispatch(loadLocations(data, postalCode))
      },
      () => {
        // console.log('failed to retrieve locations data', error)
      }
    )

    return req
  }
  return null
}
