export enum RelationshipEnum {
  EMPLOYEE = 'EMPLOYEE',
  SPOUSE = 'SPOUSE',
  DOMESTIC_PARTNER = 'DOMESTIC_PARTNER',
  CHILD = 'CHILD',
  CHILD_OF_DOMESTIC_PARTNER = 'CHILD_OF_DOMESTIC_PARTNER',
}

const relationshipMap = {
  [RelationshipEnum.EMPLOYEE]: 'Employee',
  [RelationshipEnum.SPOUSE]: 'Spouse',
  [RelationshipEnum.DOMESTIC_PARTNER]: 'Domestic Partner',
  [RelationshipEnum.CHILD]: 'Child',
  [RelationshipEnum.CHILD_OF_DOMESTIC_PARTNER]: 'Child of Domestic Partner',
}

export const RelationshipOptions = Object.values(RelationshipEnum).map((type) => ({
  label: relationshipMap[type],
  value: type,
}))
