import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

// hixme-ui
import { Label, FormGroup } from '@hixme-ui/forms'

import { FieldInput } from 'components/ReduxFormComponents'
import locations from 'modules/locations'

const { LocationsContainer } = locations.containers
const RenderZipAndLocations = ({
  PostalCode,
  County,
  disabled,
  onCustomChange,
}) => {
  return (
    <Row>
      <Col xs={4}>
        <FormGroup>
          <Label medium thin>
            Zip code
          </Label>
          <FieldInput
            {...PostalCode}
            onChange={(e) => {
              PostalCode.input.onChange(e)
              onCustomChange()
            }}
            disabled={disabled}
          />
        </FormGroup>
      </Col>
      <Col xs={4}>
        <FormGroup>
          <Label medium thin>
            County, State
          </Label>
          <LocationsContainer
            {...County.input}
            onChange={(e) => {
              County.input.onChange(e)
              onCustomChange()
            }}
            postalCode={PostalCode.input.value}
            disabled={
              disabled || (PostalCode.meta.touched && PostalCode.meta.invalid)
            }
          />
        </FormGroup>
      </Col>
    </Row>
  )
}

RenderZipAndLocations.propTypes = {
  PostalCode: PropTypes.object,
  County: PropTypes.object,
  disabled: PropTypes.bool,
}

RenderZipAndLocations.defaultProps = {
  PostalCode: {},
  County: {},
  disabled: false,
}

export default RenderZipAndLocations
