import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// hixme-ui
import ContentContainer from '@hixme-ui/content-container'
import CloseButton from '@hixme-ui/close-button'
import Container from '@hixme-ui/container'

// project
import { NAME } from '../../constants'
import * as actions from '../../actions'

export default (options = {}) => {
  // Get overlay options or set defaults options
  let hideCloseButton
  let overlayClickHide
  let routeOnHide
  let background
  /* eslint-disable curly */
  if (options.overlay)
    ({
      hideCloseButton,
      overlayClickHide,
      routeOnHide,
      background,
    } = options.overlay)
  if (hideCloseButton === undefined) hideCloseButton = false
  if (overlayClickHide === undefined) overlayClickHide = false
  if (routeOnHide === undefined) routeOnHide = true

  // Get content options or set defaults
  let position
  let size
  if (options.content) ({ position, size } = options.content)
  if (position === undefined) position = 'middle'
  if (size === undefined) size = 'small'

  return (InnerComponent) => {
    class ModalComponent extends Component {
      static propTypes = {
        modalActive: PropTypes.bool.isRequired,
        showModal: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
      }

      static contextTypes = {
        router: PropTypes.object.isRequired,
      }

      constructor(props) {
        super(props)
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.overlayClicked = this.overlayClicked.bind(this)
      }

      componentDidMount() {
        this.showModal()
      }

      showModal(e) {
        if (e) {
          e.preventDefault()
        }
        this.props.showModal()
      }

      hideModal(e) {
        if (e) {
          e.preventDefault()
        }
        this.props.hideModal()
        if (routeOnHide) {
          this.context.router.goBack()
        }
      }

      overlayClicked(e) {
        if (overlayClickHide && e.target.getAttribute('id') === 'overlay') {
          this.hideModal(e)
        }
      }

      render() {
        let styles = {}

        // Overlay default Styles and overrides
        const overlayStyleDefaults = {
          background: background || '#f7f7f7',
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '1001',
          display: 'flex',
          justifyContent: 'center',
          overflowY: 'auto',
        }
        if (options.overlay) ({ styles } = options.overlay)
        if (styles === undefined) styles = {}
        const overlayStyles = Object.assign(overlayStyleDefaults, styles)

        // Content default styles and overrides
        if (options.content) ({ styles } = options.content)
        if (styles === undefined) styles = {}
        const contentStyles = Object.assign({}, styles)

        // Set how items will be aligned within flex container
        let alignment
        switch (position) {
          case 'top':
            alignment = 'flex-start'
            break
          case 'bottom':
            alignment = 'flex-end'
            break
          case 'middle':
          default:
            alignment = 'center'
        }

        // Set width of content area
        let contentWidth
        switch (size) {
          case 'large':
            contentWidth = '940px'
            break
          case 'smaller':
            contentWidth = '398px'
            break
          case 'small':
          default:
            contentWidth = '654px'
        }

        // Render if modal is active
        if (this.props.modalActive) {
          /* eslint-disable */
          // Silencing no-static-element-interactions
          return (
            <div
              id="overlay"
              onClick={this.overlayClicked}
              style={overlayStyles}
            >
              <ContentContainer contentWidth={contentWidth}>
                <Container flex noPadding alignItems={alignment}>
                  <Container
                    flex
                    noPadding
                    margin={contentStyles.margin || '0'}
                  >
                    <InnerComponent {...this.props} />
                  </Container>
                  {!hideCloseButton && <CloseButton onClick={this.hideModal} />}
                </Container>
              </ContentContainer>
            </div>
          )
          /* eslint-disable */
        }
        return null
      }
    }

    const mapStateToProps = (state) => state[NAME]

    return connect(mapStateToProps, Object.assign({}, actions))(ModalComponent)
  }
}
