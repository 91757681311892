import { namespaceActions } from 'modules/app-core/redux-utils'
import { NAME } from './constants'

const LOAD_VIRTUAL_CARD = 'LOAD_VIRTUAL_CARD'
const UPDATE_VIRTUAL_CARD = 'UPDATE_VIRTUAL_CARD'
const LOAD_CANCEL_VIRTUAL_CARD = 'LOAD_CANCEL_VIRTUAL_CARD'
const GET_VIRTUAL_CARD = 'GET_VIRTUAL_CARD'
const GET_EMPLOYEE_FAMILY_VIRTUAL_CARDS = 'GET_EMPLOYEE_FAMILY_VIRTUAL_CARDS'

export default namespaceActions(NAME)([
  LOAD_VIRTUAL_CARD,
  UPDATE_VIRTUAL_CARD,
  LOAD_CANCEL_VIRTUAL_CARD,
  GET_VIRTUAL_CARD,
  GET_EMPLOYEE_FAMILY_VIRTUAL_CARDS,
])
