/* eslint-disable no-param-reassign */
import { createSelector } from 'reselect'

// project
import { NAME } from './constants'

export const isInitialized = createSelector(
  (state) => state[NAME],
  (module) => module.initialized
)

export const getReceipts = createSelector(
  (state) => state[NAME],
  (module) => module.list
)

export const getLineItems = createSelector(
  (state) => state[NAME],
  (module) => module.lineItems
)

export const getTotalReimbursed = createSelector(getLineItems, (lineItems) =>
  lineItems.reduce((prev, curr) => {
    const total = curr.items.reduce((acc, item) => {
      if (item.reasonId || item.reason) return acc
      acc += Number(item.amount)
      return acc
    }, 0)
    prev += total
    return prev
  }, 0)
)

export const getStatus = createSelector(
  (state) => state[NAME],
  (module) => module.status
)

export const getDescription = createSelector(
  (state) => state[NAME],
  (module) => module.description
)

export const getLoadingLineItems = createSelector(
  (state) => state[NAME],
  (module) => module.loading
)

export const getIsDisabledByStatus = createSelector(
  getStatus,
  getDescription,
  (status, description) =>
    ['Need More Info', 'Declined'].includes(status) && description === ''
)
