import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Row, Col } from 'react-flexbox-grid'

// hixme-ui
import Button from '@hixme-ui/button'
import Container from '@hixme-ui/container'
import Currency from '@hixme-ui/currency'
import { FormGroup, Label } from '@hixme-ui/forms'
import Text from '@hixme-ui/text'

// project
import {
  FieldInput,
  FieldSelect,
  FieldError,
  FieldRadioGroup,
} from 'components/ReduxFormComponents'
import CurrencyInput from 'components/CurrencyInput/CurrencyInput'

const ReceiptLineItemForm = ({
  isDeclined,
  isDisabled,
  resetForm,
  receipt,
  reasons,
  statusOptions,
  Decision,
  receipts,
  isHigherThanReceipt,
  handleSubmit,
  onDecisionChange,
}) => (
  <form onSubmit={handleSubmit} style={{ width: '100%' }}>
    <Row middle="md">
      <Col lg={6} md={6}>
        <FormGroup>
          <Label>Receipt Upload</Label>
          <Field
            name="ReceiptUpload"
            component={FieldSelect}
            disabled={isDisabled}
            list={[
              {
                id: '',
                name: 'Select receipt upload',
              },
              ...receipts,
            ]}
          />
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col md={6}>
        <Text>Receipt Details:</Text>
        {receipt.amount && (
          <Col lg={6} md={6}>
            <Text height="100%">
              {' '}
              {receipt.description} (<Currency>{receipt.amount}</Currency>)
            </Text>
          </Col>
        )}
      </Col>
    </Row>

    <Row between="md">
      <Col md={6} lg={6}>
        <FormGroup>
          <Label>Description</Label>
          <Field
            name="Description"
            component={FieldInput}
            disabled={isDisabled}
          />
        </FormGroup>
      </Col>
      <Col md={3} lg={3}>
        <FormGroup>
          <Label thin>Amount</Label>
          <Field
            name="Amount"
            component={CurrencyInput}
            disabled={isDisabled || isDeclined}
          />
          <Field name="Amount" component={FieldError} />
          <Text
            small
            light
            style={{ visibility: isHigherThanReceipt ? 'visible' : 'hidden' }}
          >
            The amount is higher than receipt total
          </Text>
        </FormGroup>
      </Col>
    </Row>
    <Row between="md" middle="md">
      <Col md={5} lg={5}>
        <FormGroup>
          <Label>Decision</Label>
          <Field
            name="Decision"
            component={FieldRadioGroup}
            options={statusOptions}
            disabled={isDisabled}
            onChange={(_, value) => onDecisionChange(value, receipt.amount)}
          />
        </FormGroup>
      </Col>
      <Col md={5} lg={5}>
        <FormGroup>
          <Label>Decline reason</Label>
          <Field
            name="Reason"
            component={FieldSelect}
            list={[
              {
                id: '',
                name: 'Select a reason',
              },
              ...reasons,
            ]}
            disabled={!Decision || !isDeclined}
          />
        </FormGroup>
      </Col>
    </Row>
    <Row end="md">
      <Col md={6} lg={6}>
        <Container
          flex
          noPadding
          style={{ gap: '20px' }}
          justifyContent="flex-end"
        >
          <Button outline onClick={resetForm} disabled={isDisabled}>
            Clear
          </Button>
          <Button primary type="submit" disabled={isDisabled}>
            Add Line Item
          </Button>
        </Container>
      </Col>
    </Row>
  </form>
)

ReceiptLineItemForm.displayName = 'ReceiptLineItemForm'
ReceiptLineItemForm.propTypes = {
  reasons: PropTypes.array,
  isDeclined: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isHigherThanReceipt: PropTypes.bool,
  statusOptions: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  onDecisionChange: PropTypes.func.isRequired,
  Decision: PropTypes.string,
  receipt: PropTypes.object,
  receipts: PropTypes.array,
}
ReceiptLineItemForm.defaultProps = {
  statusOptions: [],
  reasons: [],
  Decision: '',
  isDeclined: false,
  isDisabled: false,
  isHigherThanReceipt: false,
  receipt: {},
  receipts: [],
}

export default ReceiptLineItemForm
