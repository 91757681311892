import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import notification from 'modules/app-core/notification-manager'
import * as actions from '../actions'
// import { getBenefitsEffectiveDates } from '../selectors'
import { BenefitsEffectiveDates } from '../components'

class BenefitsEffectiveDatesContainer extends Component {
  constructor(props) {
    super(props)
    this.previousHireDate = props.hireDate
  }

  componentWillMount() {
    if (this.props.hireDate) {
      const {
        fields: {
          BenefitsEffectiveDate,
          EnrollmentStartDate,
          EnrollmentEndDate,
        },
      } = this.props
      this.props
        .getBenefitsEffectiveDates({
          accountCreated: this.props.accountCreated,
          employmentStatus: this.props.employmentStatus,
          hireDate: this.props.hireDate,
        })
        .then((response) => {
          BenefitsEffectiveDate.input.onChange(response.BenefitsEffectiveDate)
          EnrollmentStartDate.input.onChange(response.EnrollmentStartDate)
          EnrollmentEndDate.input.onChange(response.EnrollmentEndDate)
        })
    }
  }

  // Reload benefits effective dates if hiredate changes.
  componentWillReceiveProps(nextProps) {
    const {
      fields: { BenefitsEffectiveDate, EnrollmentStartDate, EnrollmentEndDate },
    } = this.props
    if (
      !nextProps.active &&
      nextProps.hireDate &&
      this.previousHireDate !== nextProps.hireDate
    ) {
      this.props
        .getBenefitsEffectiveDates({
          accountCreated: nextProps.accountCreated,
          employmentStatus: nextProps.employmentStatus,
          hireDate: nextProps.hireDate,
        })
        .then((response) => {
          BenefitsEffectiveDate.input.onChange(response.BenefitsEffectiveDate)
          EnrollmentStartDate.input.onChange(response.EnrollmentStartDate)
          EnrollmentEndDate.input.onChange(response.EnrollmentEndDate)
        })
      this.previousHireDate = nextProps.hireDate
    }
  }

  render() {
    return <BenefitsEffectiveDates {...this.props} />
  }
}

BenefitsEffectiveDatesContainer.propTypes = {
  accountCreated: PropTypes.string,
  active: PropTypes.bool, // eslint-disable-line
  employmentStatus: PropTypes.string,
  fields: PropTypes.object,
  getBenefitsEffectiveDates: PropTypes.func.isRequired,
  hireDate: PropTypes.string.isRequired,
}

BenefitsEffectiveDatesContainer.defaultProps = {
  accountCreated: '',
  active: false,
  employmentStatus: '',
  fields: {},
}

export default connect(
  undefined,
  { ...actions, ...notification.actions }
)(BenefitsEffectiveDatesContainer)
