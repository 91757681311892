import { configureEditField } from '../../utils/configure-edit-field'
import { CensusFileRowGridData } from '../../types/census-file-row-grid-data'
import { ColDef } from '@ag-grid-community/core'

export const reviewNewRecordsModifyColDefs = (
  baseColDefs: ColDef<CensusFileRowGridData>[],
  mode?: 'edit' | 'submit',
): ColDef<CensusFileRowGridData>[] => {
  const preferredOrder = [
    'FirstName',
    'LastName',
    'CompanyEmployeeId',
    'HireDate',
    'EnrollmentStartDate',
    'EnrollmentEndDate',
    'BenefitsEffectiveDate',
  ]

  // Turn baseColDefs into a Map for quick lookup
  const colMap = new Map(baseColDefs.map((col) => [col.field, col]))

  // Build the prioritized list in the correct order
  const prioritized: ColDef<CensusFileRowGridData>[] = preferredOrder.map((field) => {
    return colMap.get(field)
  }).filter((col) => col) as ColDef<CensusFileRowGridData>[]

  // Everything else not in preferredOrder
  const remaining = baseColDefs.filter((col) => {
    return col.field && !preferredOrder.includes(col.field)
  })

  const sortedCols = [...prioritized, ...remaining]

  return [
    {
      headerCheckboxSelection: mode === 'submit',
      checkboxSelection: mode === 'submit',
      width: 50,
      pinned: 'left',
    },
    ...sortedCols.map((col) => ({
      ...col,
      editable: mode === 'edit',
      ...configureEditField(col.field, mode),
    })),
  ]
}
