import { createReducer } from 'modules/app-core/redux-utils'
import t from './actionTypes'

// initialState
const initialState = {
  dashboardId: '',
}

export default createReducer(initialState, {
  [t.SHOW_QUICKSIGHT_DASHBOARD]: (state, payload) => ({
    ...state,
    dashboardId: payload,
  }),
  [t.HIDE_QUICKSIGHT_DASHBOARD]: (state) => ({
    ...state,
    dashboardId: '',
  }),
})
