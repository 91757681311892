import React from 'react'
import PropTypes from 'prop-types'
import { MrModal } from '@hixme/modal'

const HistoryModal = ({ data }) => (
  <MrModal medium>
    <div style={{ minHeight: '200px' }}>
      {Object.keys(data).map((key) => (
        <div key={`objItem-${key}`}>
          <b>{key}</b>: {data[key] ? data[key].toString() : ''}
        </div>
      ))}
    </div>
  </MrModal>
)

HistoryModal.propTypes = {
  data: PropTypes.object,
}

HistoryModal.defaultProps = {
  data: {},
}
HistoryModal.displayName = 'HistoryModal'

export default HistoryModal
