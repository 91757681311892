import { CellClassParams } from '@ag-grid-community/core'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'
import style from '../pages/CensusManagement.module.scss'

// apply stying to cells marked as errors
export const errorColumnStyling = (fieldName: string | undefined, mode: 'edit' | 'submit') => {
  return {
    editable: mode === 'edit', // Enable editing only in Edit Mode
    cellClass: (params: CellClassParams<CensusFileRowGridData>) => (
      params.data?.errors?.[fieldName ?? ''] ? style['error-cell'] : ''
    ),

    // Tooltip to show the error message when hovering over a field with an error
    tooltipValueGetter: (params) => {
      const field = fieldName ?? ''
      return params.data.errors?.[field]?.errorMessage || null
    },

    // Show specific error messages directly inside the field
    valueGetter: (params) => {
      const field = fieldName ?? ''
      const errorMessage = params.data.errors?.[field]?.errorMessage
      const hasError = !!errorMessage
      const value = params.data[field]

      // Show 'required' if field is required and has no value
      if (hasError && !value) {
        return /required/i.test(errorMessage) ? 'required' : errorMessage
      }

      return value
    },

    // Apply red and italic styling for fields with an error message
    cellStyle: (params: CellClassParams<CensusFileRowGridData>): Record<string, string> | undefined => {
      const field = fieldName ?? ''
      const errorMessage = params.data?.errors?.[field]?.errorMessage
      const hasError = !!errorMessage
      const value = params.data?.[field]

      if (hasError && !value) {
        return { color: 'red', fontStyle: 'italic' } // Make 'required' italic to indicate it is not the raw value
      } else if (hasError) {
        return { color: 'red' } // Make the invalid value red
      }
      return undefined // No styling for valid fields
    },
  }
}
