import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Separator from '@hixme-ui/separator'

const BenefitCard = ({ children, benefitTitle }) => (
  <Container background="white" heavyShadow animate rounded margin="0 0 20px 0">
    <Container>
      <Container padding="10px 20px" width="100%" height="100%" rounded>
        <Row>
          <Col sm={12} md={3}>
            <Text bold fontSize="12pt">
              {benefitTitle}
            </Text>
          </Col>
          <Col sm={12} md={3}>
            <Text center div light fontSize="10pt">
              {benefitTitle === 'Medical' ? 'Total bundle rate' : 'Total rate'}
            </Text>
            <Text center div light fontSize="10pt" padding={0}>
              (ER+WRK)
            </Text>
          </Col>
          <Col sm={12} md={2}>
            <Text style={{ margin: '0 0 0 9px' }} div light fontSize="10pt">
              Rate for
            </Text>
          </Col>
          <Col sm={12} md={2} style={{ textAlign: 'center' }}>
            <Text light fontSize="10pt">
              {benefitTitle === 'Medical'
                ? 'Composite bundle rate'
                : 'Composite rate'}
            </Text>
          </Col>
          <Col sm={12} md={2}>
            <Text center light fontSize="10pt" div>
              COBRA rate
            </Text>
          </Col>
        </Row>
        <Separator light style={{ height: '1px' }} />
      </Container>
    </Container>
    {children}
  </Container>
)

BenefitCard.displayName = 'BenefitCard'

BenefitCard.propTypes = {
  children: PropTypes.node.isRequired,
  benefitTitle: PropTypes.string.isRequired,
}

export default BenefitCard
