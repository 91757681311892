import * as _actions from './actions'
import * as _constants from './constants'
import _reducer from './reducer'
import * as _selectors from './selectors'
import * as _utils from './utils'

export const actions = _actions
export const constants = _constants
export const reducer = _reducer
export const selectors = _selectors
export const utils = _utils

export default {
  actions,
  constants,
  reducer,
  selectors,
  utils,
}
