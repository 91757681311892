import React, { useEffect } from 'react'
import { getWaiter } from 'redux-waiter'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { MrModal, clearModal } from '@hixme/modal'

// hixme-ui
import Button from '@hixme-ui/button'
import ContentContainer from '@hixme-ui/content-container'
import Container from '@hixme-ui/container'
import Title from '@hixme-ui/title'

import notification from 'modules/app-core/notification-manager'

import { GET_TRANSACTIONS } from 'routes/Employees/Employee/EmployeeCoverage/modules/employee-benefits/constants'
import { VCNService } from 'routes/Employees/Employee/EmployeeCoverage/modules/employee-benefits/api'
import SearchTable from 'components/SearchTable'
import SortableTh from 'components/SortableTh'
import TransactionRow from './TransactionRow'
import useTableState from './hooks/useTableState'
import TransactionModalLoader from './TransactionModalLoader'
import NoTransactions from './NoTransactions'

const TransactionModal = ({
  onClose,
  onError,
  isLoading,
  getCardTransactions,
}) => {
  const {
    sortBy,
    onChangeColumn,
    isSortDescending,
    transactions,
    setData,
  } = useTableState()

  const headers = [
    {
      align: 'left',
      field: 'created_on',
      label: 'Date',
    },
    {
      align: 'center',
      field: 'transaction_amount',
      label: 'Amount',
    },
    {
      align: 'center',
      field: 'status',
      label: 'Status',
    },
    {
      align: 'center',
      field: 'type',
      label: 'Type',
    },
  ]

  useEffect(() => {
    getCardTransactions()
      .then((res) => {
        setData(res.transactions)
      })
      .catch(onError)
  }, [])

  return (
    <MrModal>
      <ContentContainer contentMargin="20px auto 0" contentWidth="1024px">
        <Title margin="20px 0">Transaction History</Title>
        <Container flex style={{ maxHeight: '600px', overflowX: 'auto' }}>
          {isLoading && <TransactionModalLoader />}
          {!isLoading && transactions.length !== 0 && (
            <SearchTable>
              <thead>
                <tr>
                  {headers.map((header) => (
                    <SortableTh
                      bold
                      align={header.align}
                      onClick={() => onChangeColumn(header.field)}
                      sortDescending={isSortDescending}
                      showIcon={sortBy === header.field}
                    >
                      {header.label}
                    </SortableTh>
                  ))}
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction) => (
                  <TransactionRow
                    {...transaction}
                    key={transaction.payment_transaction_id}
                  />
                ))}
              </tbody>
            </SearchTable>
          )}
          {!isLoading && transactions.length === 0 && <NoTransactions />}
        </Container>
        <Container>
          <Button primary onClick={onClose}>
            Close
          </Button>
        </Container>
      </ContentContainer>
    </MrModal>
  )
}

const mapStateToProps = (state) => ({
  isLoading: getWaiter(state, GET_TRANSACTIONS).isPending,
})

const mapDispatchToProps = (dispatch, { data }) => ({
  onClose: () => dispatch(clearModal()),
  getCardTransactions: () => dispatch(VCNService.getTransactions(data.id)),
  onError: () =>
    dispatch(
      notification.actions.createErrorNotification(
        'Something went wrong getting your transactions information.'
      )
    ),
})

TransactionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  getCardTransactions: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionModal)
