import React from 'react'
import { Breadcrumb } from '@surecompanies/core_components'
import { browserHistory } from 'react-router'
import { Typography } from '@sureco/design-system'

interface BreadcrumbItem {
  title: string
  path?: string // Optional path for navigation
}

interface BreadcrumbNavProps {
  items: BreadcrumbItem[]
}

/**
 * create breadrumb with optional links
 * @example
 *
 * import BreadcrumbNav from 'enrollment-platform/components/Breadcrumbs'
 *
 * function ManageRecords(_props) {
 *  return (
 *    <PageWrapper>
 *      <Page title="Review Records"
 *            breadcrumb={<BreadcrumbNav
 *            items={[
 *              { title: 'Census Management', path='/v2-census-management },
 *              { title: 'Manage Records' },
 *            ]}
 *          />}
 *      >
 *        <div> My new page </div>
 *      </Pqge>
 *    </PqgeWrapper>
 *   )
 * }
 */
function BreadcrumbNav({ items }: BreadcrumbNavProps) {
  const handleNavigation = (path?: string) => {
    if (path) {
      browserHistory.push(path)
    }
  }

  return (
    <Breadcrumb style={{ backgroundColor: '#f6f9fc', padding: '16px 0' }}>
      {items.map((item, index) => {
        const isLast = index === items.length - 1
        return (
          <Breadcrumb.Item key={`${item.path}_${item.title}`}>
            {!isLast && item.path ? (
              <Typography
                as="a"
                size={14}
                onClick={() => handleNavigation(item.path)}
              >
                {item.title}
              </Typography>
            ) : (
              <Typography bold size={14}>
                {item.title}
              </Typography>
            )}
          </Breadcrumb.Item>
        )
      })}
    </Breadcrumb>
  )
}

export default BreadcrumbNav
