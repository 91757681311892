import { notification } from '@surecompanies/core_components'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import dayjs, { Dayjs } from 'dayjs'
import client from 'enrollment-platform/api/eps.client'
import { useState } from 'react'

type EnrollmentFormData = {
  id: string
  benefitEffectivePolicy: string
  enrollmentDates: Dayjs[]
  benefitEffectiveDate: number
}

export type EnrollmentConfiguration = {
  id: string | number
  benefitEffectivePolicy: number
  benefitEffectivePolicyUnit: string
  benefitEndDate: string
  enrollmentEndDate: string
  enrollmentStartDate: string
  benefitEffectiveDate: string
  isEditable: boolean
}

type EnrollmentConfigurationResponse = {
  data: EnrollmentConfiguration[]
  isCreating: boolean
  isLoading: boolean
  error: Error | null
  createOrUpdateEnrollmentConfiguration: (enrollmentFormData: EnrollmentFormData) => void
  onNewEnrollmentConfiguration: () => void
  isCreatingOrUpdating: boolean
  selectedEnrollmentId: string | null
  setSelectedEnrollmentId: (id: string | null) => void,
}

export default function useEnrollmentConfiguration(
  id: string
): EnrollmentConfigurationResponse {
  const queryClient = useQueryClient()
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState<string | null>(null)

  async function getEnrollmentConfiguration(): Promise<
    EnrollmentConfiguration[]
  > {
    try {
      const { data } = await client.get<EnrollmentConfiguration[]>(
        `/customers/${id}/configuration/enrollment`,
        {
          params: {
            sortBy: 'benefitEffectiveDate',
            orderBy: 'desc',
          }
        }
      )
      return data
    } catch (error: any) {
      notification.error({
        message: 'Failed to get enrollment configuration',
        description: error?.message ?? 'Unknown error',
      })
      return []
    }
  }

  const createOrUpdateEnrollmentConfiguration = async (enrollmentFormData: EnrollmentFormData) => {
      const enrollmentItem = {
        ...(enrollmentFormData.id ? { id: enrollmentFormData.id } : {}),
        enrollmentStartDate: dayjs(enrollmentFormData.enrollmentDates[0]).format(
          'YYYY-MM-DD'
        ),
        enrollmentEndDate: dayjs(enrollmentFormData.enrollmentDates[1]).format(
        'YYYY-MM-DD'
        ),
        benefitEffectiveDate: dayjs(enrollmentFormData.benefitEffectiveDate).format(
          'YYYY-MM-DD'
        ),
        benefitEffectivePolicy: Number(enrollmentFormData.benefitEffectivePolicy),
        benefitEffectivePolicyUnit: 'Days',
      } as EnrollmentConfiguration

      await enrollmentItem.id ? updateEnrollmentConfigurationMutation(enrollmentItem) : mutate(enrollmentItem)
  }

  const createEnrollmentConfiguration = async (enrollmentConfiguration: EnrollmentConfiguration) => {
    try {
      const { data } = await client.post(`/customers/${id}/configuration/enrollment`, enrollmentConfiguration)
      setIsCreating(false)
      notification.success({
        message: 'Enrollment configuration successfully created',
      })
      queryClient.invalidateQueries({ queryKey: ['getEnrollmentConfiguration', id] })
      return data
    } catch (error: any) {
      notification.error({
        message: 'Failed to create enrollment configuration',
        description: error?.message ?? 'Unknown error',
      })
    }
  }

  const updateEnrollmentConfiguration = async (enrollmentConfiguration: EnrollmentConfiguration) => {
    try {
      const { data } = await client.put(`/customers/${id}/configuration/enrollment/${enrollmentConfiguration.id}`, enrollmentConfiguration)
      notification.success({
        message: 'Enrollment configuration successfully updated',
      })
      setSelectedEnrollmentId(null)
      queryClient.invalidateQueries({ queryKey: ['getEnrollmentConfiguration', id] })
      return data
    } catch (error: any) {
      notification.error({
        message: 'Failed to update enrollment configuration',
        description: error?.message ?? 'Unknown error',
      })
    }
  }

  const { isLoading, error, data } = useQuery({
    queryKey: ['getEnrollmentConfiguration', id],
    queryFn: () => getEnrollmentConfiguration(),
    gcTime: 0
  })

  const { mutate, isPending: isCreatingEnrollmentConfiguration } = useMutation({
    mutationFn: createEnrollmentConfiguration,
    mutationKey: ['createEnrollmentConfiguration', id],
  })

  const { mutate: updateEnrollmentConfigurationMutation, isPending: isEditing } = useMutation({
    mutationFn: updateEnrollmentConfiguration,
    mutationKey: ['updateEnrollmentConfiguration', id],
  })

  const onNewEnrollmentConfiguration = () => {
    setIsCreating((prev) => !prev)
  }

  return {
    isLoading,
    error,
    data: data || [],
    isCreating,
    isCreatingOrUpdating: isCreatingEnrollmentConfiguration || isEditing,
    onNewEnrollmentConfiguration,
    selectedEnrollmentId,
    setSelectedEnrollmentId,
    createOrUpdateEnrollmentConfiguration
  }
}
