import React from 'react'
import PropTypes from 'prop-types'
import uuidV1 from 'uuid/v1'

// project
import { BenefitCard } from 'components/CobraCard'
import { CobraSummary } from '../../../components'

const benefits = ['Medical', 'Dental', 'Vision']

const BenefitCardList = ({ cobraBenefits }) => {
  if (!cobraBenefits) return null

  return (
    <div>
      {benefits.map((benefit) => {
        if (cobraBenefits[benefit] && cobraBenefits[benefit].length > 0) {
          return (
            <BenefitCard key={uuidV1()} benefitTitle={benefit}>
              <CobraSummary benefits={cobraBenefits[benefit]} />
            </BenefitCard>
          )
        }

        return null
      })}
    </div>
  )
}

BenefitCardList.displayName = 'BenefitCardList'

BenefitCardList.propTypes = {
  cobraBenefits: PropTypes.object,
}

BenefitCardList.defaultProps = {
  cobraBenefits: {},
}

export default BenefitCardList
