import React from 'react'
import { Switch } from '@surecompanies/core_components'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Stack, Typography } from '@sureco/design-system'

export default function AdminItem({
  admin,
  onUpdateAdmin,
  isUpdating,
}: {
  admin: any
  onUpdateAdmin: (id: string, value: { isAdmin: boolean }) => void
  isUpdating: boolean
}) {
  return (
    <Stack
      direction="row"
      align="center"
      justify="space-between"
      padding={20}
      style={{ border: '1px solid #E0EAF1', borderRadius: 8 }}
    >
      <Stack direction="row" align="center" space={10}>
        <Typography color="#003146" size={16} bold>
          {admin.firstName} {admin.lastName}
        </Typography>
        <Typography color="#00A0A8" size={16}>
          {admin.email}
        </Typography>
      </Stack>
      <Stack direction="row" align="center" space={10}>
        <Switch
          loading={isUpdating}
          disabled={isUpdating}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          value={admin.isAdmin}
          onChange={(checked) => onUpdateAdmin(admin.id, { isAdmin: checked })}
        />
        <Typography size={16} color="#003146">
          Admin User
        </Typography>
      </Stack>
    </Stack>
  )
}
