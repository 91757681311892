import { createReducer } from 'modules/app-core/redux-utils'
import t from './actionTypes.js'

// initialState
const initialState = {
  profile: null,
  gettingProfile: false,
  profileIsInitialized: false,
  statusText: '',
  savingProfile: false,
  savingProfileText: '',
}

export default createReducer(initialState, {
  [t.GET_PROFILE_REQUEST]: (state) =>
    Object.assign({}, state, {
      gettingProfile: true,
      statusText: 'Getting profile...',
    }),
  [t.GET_PROFILE_SUCCESS]: (state, payload) =>
    Object.assign(
      {},
      state,
      {
        gettingProfile: false,
        profileIsInitialized: true,
        statusText: 'Profile successfully retrieved.',
      },
      { profile: payload }
    ),
  [t.GET_PROFILE_FAILURE]: (state, payload) =>
    Object.assign({}, state, {
      gettingProfile: false,
      statusText: `Authentication Error: ${payload.status} ${
        payload.statusText
      }`,
    }),
  [t.SAVE_PROFILE_REQUEST]: (state) =>
    Object.assign({}, state, {
      savingProfile: true,
      savingProfileText: 'Saving profile...',
    }),
  [t.SAVE_PROFILE_SUCCESS]: (state, payload) => {
    const profile = Object.assign({}, state.profile, payload)
    return Object.assign(
      {},
      state,
      {
        savingProfile: false,
        savingProfileText: 'Profile saved successfully.',
      },
      { profile }
    )
  },
  [t.SAVE_PROFILE_FAILURE]: (state, payload) =>
    Object.assign({}, state, {
      savingProfile: false,
      savingProfileText: `Error saving profile: ${payload.status} ${
        payload.statusText
      }`,
    }),
})
