export const NAME = 'expenseRequestsSearch'

export const LOAD_LIST = 'LOAD_LIST'
export const LOAD_EXPENSE = 'LOAD_EXPENSE'
export const LOAD_RECEIPTS = 'LOAD_RECEIPTS'
export const ADD_TO_LIST = 'ADD_TO_LIST'
export const RESET_STATE = 'RESET_STATE'

// search related action types
export const CHANGE_SORT = 'CHANGE_SORT'
export const RESET_COUNTER = 'RESET_COUNTER'
export const RESET_FILTERS = 'RESET_FILTERS'
export const SET_COMPANY_ID = 'SET_COMPANY_ID'
export const TOGGLE_FILTERS = 'TOGGLE_FILTERS'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const UPDATE_SEARCH = 'UPDATE_SEARCH'
export const VIEW_MORE = 'VIEW_MORE'
export const RESET_PAGINATION = 'RESET_PAGINATION'

// waiter names
export const GET_EXPENSE_REQUESTS_NAME = 'GET_EXPENSE_REQUESTS_NAME'
export const GET_EXPENSE_REQUEST_NAME = 'GET_EXPENSE_REQUEST_NAME'
export const GET_EXPENSE_RECEIPTS_NAME = 'GET_EXPENSE_RECEIPTS_NAME'
export const SEND_EXPENSE_MESSAGE = 'SEND_EXPENSE_MESSAGE'

export default [
  RESET_STATE,
  CHANGE_SORT,
  LOAD_LIST,
  LOAD_EXPENSE,
  LOAD_RECEIPTS,
  ADD_TO_LIST,
  RESET_COUNTER,
  RESET_FILTERS,
  SET_COMPANY_ID,
  TOGGLE_FILTERS,
  UPDATE_FILTER,
  UPDATE_SEARCH,
  VIEW_MORE,
  RESET_PAGINATION,
  SEND_EXPENSE_MESSAGE,
]
