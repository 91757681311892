import { createRequest } from 'modules/redux-gateway'
import moment from 'moment'

import { selectors as userSessionSelectors } from 'store/modules/user-session'

import { POST_BENEFITEFFECTIVEDATES_ROUTE } from './constants'
import { LOAD_BENEFITEFFECTIVEDATES } from './actionTypes'

export const loadBenefitsEffectiveDates = (benefitsEffectiveDates) => ({
  type: LOAD_BENEFITEFFECTIVEDATES,
  payload: benefitsEffectiveDates,
})

export const getBenefitsEffectiveDates = ({
  accountCreated,
  hireDate,
  IsNewHire = true,
  employmentStatus,
}) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(
      createRequest({
        route: POST_BENEFITEFFECTIVEDATES_ROUTE,
        body: {
          ClientPublicKey: userSessionSelectors.getCurrentCompanyId(getState()),
          HireDate: hireDate,
          EmploymentStatus: employmentStatus || 'Full-Time',
          AccountCreated:
            accountCreated || moment(Date.now()).format('YYYY-MM-DD'),
          IsNewHire,
        },
      })
    ).then(
      (data) => {
        dispatch(loadBenefitsEffectiveDates(data))
        resolve(data)
      },
      (error) => reject(error)
    )
  })
