import React from 'react'
import { Button } from '@sureco/design-system'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'
import { CloseCircleFilled, EditFilled } from '@ant-design/icons'

interface ModeToggleButtonProps {
  dirtyRows: CensusFileRowGridData[]
  selectedRows: CensusFileRowGridData[]
  mode: 'edit' | 'submit'
  setMode: (mode: 'edit' | 'submit') => void
}

function ModeToggleButton({ dirtyRows, selectedRows, mode, setMode }: ModeToggleButtonProps) {
  const isDisabled =
    (mode === 'edit' && dirtyRows.length > 0) || // Can't switch to Edit if pending edits exist
    (mode === 'submit' && selectedRows.length > 0) // Can't switch to Submit if rows are selected

  const toggleMode = () => {
    if (isDisabled) return
    const newMode = mode === 'edit' ? 'submit' : 'edit'
    setMode(newMode)
  }

  if (isDisabled) {
    return null
  }
  return mode === 'submit'
  ? (
    <Button
      onClick={toggleMode}
      disabled={isDisabled}
      size="large"
    >
      <EditFilled style={{ marginRight: '8px' }} /> Enter Edit Mode
    </Button>
  )
: (
      <Button
        onClick={toggleMode}
        disabled={isDisabled}
        size="large"
        variant="black"
      >
        <CloseCircleFilled style={{ marginRight: '8px' }} /> Leave Edit Mode
      </Button>
    )
}

export default ModeToggleButton
