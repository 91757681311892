import React from 'react'
import { Space, Typography } from '@surecompanies/core_components'
import { convertToLocalFromUTCISODate } from 'helpers/datetime'

export type FileItemProps = {
  filename: string
  createdAt: string
  url?: string
  onFileClick: (url: string | undefined) => void
}

export default function FileItem({
  filename,
  createdAt,
  url,
  onFileClick,
}: FileItemProps) {
  return (
    <li>
      <Space>
        <Typography.Link title={filename} onClick={() => onFileClick(url)}>
          {convertToLocalFromUTCISODate(createdAt)}
        </Typography.Link>
      </Space>
    </li>
  )
}
