/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect'
import { NAME } from './constants'

const getVirtualCardByBenefitId = createSelector(
  (state) => state[NAME],
  (vcs) => (Id) => vcs.virtualCards.find((vc) => vc.Id === Id)
)

export { getVirtualCardByBenefitId }
