import React from 'react'
import FontAwesome from 'react-fontawesome'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Theme from '@hixme-ui/theme'

const NoTransactions = () => (
  <Container
    flex
    justifyContent="center"
    alignItems="center"
    width="100%"
    margin="20px 0"
    style={{ border: '1px dashed #ccc' }}
  >
    <Text lighter thinner style={{ marginRight: '10px' }}>
      No Transactions
    </Text>
    <FontAwesome
      name="info-circle"
      size="lg"
      style={{
        color: Theme.textColors.lighter,
      }}
    />
  </Container>
)

export default NoTransactions
