import React from 'react'
import { Tabs } from 'antd'
import { browserHistory } from 'react-router'
import { CENSUS_REVIEW_PATH } from '../../constants'
import { CensusFileRowCountsDto } from '../../api/dto/census-file-row-counts.dto'
import { CensusFileRowTypeEnum } from '../../api/enums/census-file-row-type.enum'

const { TabPane } = Tabs

interface ReviewTabsProps {
  counts: CensusFileRowCountsDto | undefined
  publicKey: string
  currentTab: string
  onTabChange: (string) => void
  style?: any
}
const CensusReviewTabs = ({ style, counts, publicKey, currentTab, onTabChange }: ReviewTabsProps) => {
  const onChange = (key: string) => {
    browserHistory.push(`${CENSUS_REVIEW_PATH}/${publicKey}/${key}`)
    onTabChange(key)
  }
  const getCount = (key: string) => {
    return counts?.[key] ?? 0
  }

  return (
    <Tabs activeKey={currentTab} onChange={onChange} type="line" tabBarStyle={{ fontWeight: 'bold', fontSize: '16px', borderBottom: 'none' }} style={style}>
      <TabPane
        tab={
          <span>
            Errors <span style={{ color: 'black', opacity: 0.4 }}>({getCount(CensusFileRowTypeEnum.ERROR)})</span>
          </span>
        }
        key={CensusFileRowTypeEnum.ERROR}
      />
      <TabPane
        tab={
          <span>
            New Records <span style={{ color: 'black', opacity: 0.4 }}>({getCount(CensusFileRowTypeEnum.DRAFT_EMPLOYEE)})</span>
          </span>
        }
        key={CensusFileRowTypeEnum.DRAFT_EMPLOYEE}
      />
      <TabPane
        tab={
          <span>
            Updates With Benefits <span style={{ color: 'black', opacity: 0.4 }}>({getCount(CensusFileRowTypeEnum.UPDATE_EMPLOYEE_BENEFITS)})</span>
          </span>
        }
        key={CensusFileRowTypeEnum.UPDATE_EMPLOYEE_BENEFITS}
      />
      <TabPane
        tab={
          <span>
            Updates Without Benefits <span style={{ color: 'black', opacity: 0.4 }}>({getCount(CensusFileRowTypeEnum.UPDATE_EMPLOYEE_NO_BENEFITS)})</span>
          </span>
        }
        key={CensusFileRowTypeEnum.UPDATE_EMPLOYEE_NO_BENEFITS}
      />
      <TabPane
        tab={
          <span>
            Activity <span style={{ color: 'black', opacity: 0.4 }}>({getCount('rest')})</span>
          </span>
        }
        key={'activity'}
      />
    </Tabs>
  )
}

export default CensusReviewTabs
