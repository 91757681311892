import { connect } from 'react-redux'

// module
import { selectors as ExpenseSelectors } from 'routes/Expenses/modules/expense-requests-search'
import {
  actions,
  selectors,
} from 'routes/Expenses/routes/ExpenseRequestsSummary/modules/receipt-line-items'

import ReceiptLineItems from 'routes/Expenses/routes/ExpenseRequestsSummary/components/ReceiptLineItems/ReceiptLineItems'

const mapStateToProps = (state) => {
  const expense = ExpenseSelectors.getExpense(state)
  const receipts = expense && expense.reimbursement.receipts
  const loadingLineItems = selectors.getLoadingLineItems(state)
  const lineItems = selectors.getLineItems(state).map((lineItem) => {
    const receipt = receipts.find((r) => r.id === lineItem.receipt)
    return {
      receipt,
      items: lineItem.items,
    }
  })
  const totalReimbursed = selectors.getTotalReimbursed(state)

  return {
    loadingLineItems,
    totalReimbursed,
    lineItems,
    reimbursement: expense && expense.reimbursement,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setStatus: (status) => dispatch(actions.updateStatus(status)),
  setReceipts: (receipts) => dispatch(actions.updateReceipts(receipts)),
  removeLineItem: (item) => dispatch(actions.removeLineItem(item)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptLineItems)
