import { namespaceActions } from 'modules/app-core/redux-utils'
import { NAME } from './constants'

const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE'
const SAVE_PROFILE_REQUEST = 'SAVE_PROFILE_REQUEST'
const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS'
const SAVE_PROFILE_FAILURE = 'SAVE_PROFILE_FAILURE'

export default namespaceActions(NAME)([
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  SAVE_PROFILE_REQUEST,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAILURE,
])
