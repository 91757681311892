import t from './actionTypes'

export const setCensusValidationError = (errors) => ({
  type: t.CENSUS_VALIDATION_ERROR,
  payload: errors,
})

export const showCensusErrors = (id) => ({
  type: t.SHOW_ERROR_MODAL,
  payload: id,
})

export const hideCensusErrors = () => ({
  type: t.HIDE_ERROR_MODAL,
})
