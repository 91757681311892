import React from 'react'
import { branch, compose, lifecycle, renderComponent } from 'recompose'
import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'

// module
import { getPersonById } from '../actions'
import { GET_PERSON } from '../constants'
import { personByIdSelector } from '../selectors'

const noop = () => ({})

const personMapStateToProps = (state, ownProps) => {
  const { isCompleted, isPending, isRejected } =
    getWaiter(state, `${GET_PERSON}:${ownProps.personId}`) || {}
  const {
    LoadingView = () => <span>...</span>,
    RejectedView = () => <span>Could not find user</span>,
    showLoadingView = true,
  } = ownProps

  return {
    isCompleted,
    isPending,
    isRejected,
    LoadingView,
    RejectedView,
    person: personByIdSelector(state)(ownProps.personId) || {},
    showLoadingView,
  }
}

const PersonProvider = (mapStateToProps = noop, mapDispatchToProps) => (
  InnerComponent
) =>
  compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    connect(personMapStateToProps),
    lifecycle({
      componentDidMount() {
        const {
          dispatch,
          fullProfile,
          isCompleted,
          isPending,
          personId,
        } = this.props
        if (personId) {
          dispatch((_, getState) => {
            const person = personByIdSelector(getState())(personId)
            if (
              (fullProfile && !isCompleted && !isPending) ||
              (!person && !isPending)
            ) {
              dispatch(getPersonById(personId))
            }
          })
        }
      },
    }),
    branch(
      ({ isPending, showLoadingView }) => isPending && showLoadingView,
      renderComponent(({ LoadingView }) => <LoadingView />)
    ),
    branch(
      ({ isRejected }) => isRejected,
      renderComponent(({ RejectedView }) => <RejectedView />)
    )
  )(InnerComponent)

export default PersonProvider

export const RenderPerson = PersonProvider()(({ children, ...props }) =>
  children(props)
)
