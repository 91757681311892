import { createRequest } from 'modules/redux-gateway'
import { personService } from '@hixme/api'

export function getPersonsByCompanyId(clientPublicKey) {
  return createRequest({
    route: 'searchClientemployeesGet',
    name: `getPersonsByCompanyId:${clientPublicKey}`,
    params: {
      clientPublicKey,
    },
  })
}

export function getPersonById(id) {
  return personService({
    route: `persons/${id}`,
  })
}

export function getFamilyByEmployeeId(id) {
  return personService({
    route: `persons/${id}/family`,
  })
}

export function getAllPersons() {
  return personService({
    route: 'search',
  })
}

export const sendWelcomeEmail = (ids) =>
  personService({
    route: 'persons/welcome-email',
    method: 'POST',
    body: {
      ids,
      invitedDate: new Date().toISOString(),
    },
  })
