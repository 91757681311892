/* eslint-disable no-underscore-dangle, no-console */
import axios from 'axios'
import { selectors } from '@hixme/auth-ui'
import appConfig from 'modules/app-config'

let store
/* eslint-disable */
const _consoleLog = console.log
const _consoleError = console.error
const _consoleWarn = console.warn

const log = function log(...invokeArgs) {
  // only allow console logs in dev
  if (appConfig.isDevelopment()) {
    _consoleLog(...invokeArgs)
  }
}

const logError = function logError(message, config = { type: 'error' }) {
  // pass productions errors to slack
  if (appConfig.isProduction()) {
    const authUser = store
      ? selectors.getCredentials(store.getState())
      : {
          firstname: 'unknown name',
          lastname: '',
          role: 'unknown role',
          username: '0',
        }

    const username = `${authUser.firstname} ${authUser.lastname}`
    const role = authUser.userRole
    const userId = authUser.username

    axios({
      method: 'post',
      url:
        'https://6h2ricwpyh.execute-api.us-west-2.amazonaws.com/dev/errors/receive',
      data: {
        type: config.type,
        host: window.location.host,
        href: window.location.href,
        text: message,
        user: `${username} - ${userId} - ${role}`,
        userAgent: navigator.userAgent,
        timestamp: new Date().getTime() / 1000,
      },
    })
  }
}

log.error = function error(...invokeArgs) {
  _consoleError(...invokeArgs)

  logError([...invokeArgs].join(', '))
}

log.warn = function warn(...invokeArgs) {
  _consoleWarn(...invokeArgs)

  logError([...invokeArgs].join(', '), { type: 'warning' })
}

// bind console functions
console.log = log
console.error = log.error
//console.warn = log.warn

// bind window functions
window.log = log
window.onerror = function onerror(msg, url, lineNo, columnNo, error) {
  const string = msg.toLowerCase()
  const substring = 'script error'
  if (string.indexOf(substring) === -1) {
    const message = [
      `Message: ${msg}`,
      `URL: ${url}`,
      `Line: ${lineNo}`,
      `Column: ${columnNo}`,
      `Error object: ${JSON.stringify(error)}`,
    ].join(' - ')
    logError(message)
  }

  return false
}

const logConfig = (config) => {
  store = config.store
}

export default logConfig
