import PropTypes from 'prop-types'

export default {
  person: PropTypes.shape({
    AccountCreated: PropTypes.string,
    AddressSameAsParent: PropTypes.bool,
    City: PropTypes.string,
    ClientPublicKey: PropTypes.string,
    ContributionGroup: PropTypes.string,
    County: PropTypes.string,
    DateOfBirth: PropTypes.string,
    EmployeePublicKey: PropTypes.string,
    ExistingMedicalPlanWaived: PropTypes.bool,
    FirstName: PropTypes.string,
    FullName: PropTypes.string,
    Gender: PropTypes.string,
    HixmeEmailAlias: PropTypes.string,
    Id: PropTypes.string,
    IncludedInMedical: PropTypes.bool,
    IsActive: PropTypes.bool,
    IsEligibleForEmployeerPaidBenefits: PropTypes.bool,
    IsValid: PropTypes.bool,
    LastName: PropTypes.string,
    PostalCode: PropTypes.string,
    RatingArea: PropTypes.number,
    Relationship: PropTypes.string,
    Smoker: PropTypes.bool,
    SSNLastFour: PropTypes.string,
    StateProvince: PropTypes.string,
    StreetAddress: PropTypes.string,
  }),
}
