import React from 'react'
import { Stack } from '@sureco/design-system'
import FileItem from 'routes/Company/components/PayrollTab/components/FileItem'

export default function PayrollList({ list }: { list: any[] }) {
  return (
    <Stack>
      {list.map((file) => (
        <FileItem
          {...file}
          key={file.filename}
          url={file.s3Path}
          onFileClick={() => {}}
        />
      ))}
    </Stack>
  )
}
