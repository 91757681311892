import { Stack } from '@sureco/design-system'
import React, { memo } from 'react'
import Contact from './Contact'

import './ContactList.scss'

function ContactList({ contacts }) {
  return (
    <Stack space={20} className="contact-wrapper">
      {contacts.map((contact) => (
        <Contact key={`${contact.type}_${contact.fullName}`} {...contact} />
      ))}
    </Stack>
  )
}

export default memo(ContactList)
