import { createReducer } from 'modules/app-core/redux-utils'
import t from './actionTypes'

// initialState
const initialState = {
  userInitialized: false,
  currentCompanyId: null,
  currentCompanyItem: null,
}

export default createReducer(initialState, {
  [t.SET_CURRENT_COMPANY]: (state, payload) => ({
    ...state,
    currentCompanyId: payload !== '' ? payload : state.currentCompanyId,
  }),
  [t.SET_QLE]: (state, payload) => ({
    ...state,
    qleId: payload !== '' ? payload : state.qleId,
  }),
  [t.USER_INITIALIZED]: (state) => ({
    ...state,
    userInitialized: true,
  }),
})
