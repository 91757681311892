import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'
import { withRouter } from 'react-router'

import { actions, selectors } from '../'
import { WAITER_GET_EMPLOYEE } from '../constants'

const mapStateToProps = (state, props) => {
  const employeePublicKeyParam = props.params.id
  const familyMember =
    selectors
      .getFamily(state)
      .find((p) => p.Id === props.router.params.personId) || {}
  const gettingFamily = getWaiter(
    state,
    `${WAITER_GET_EMPLOYEE}${employeePublicKeyParam}`
  ).isPending
  return {
    familyMember,
    gettingFamily,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFamily: (EmployeePublicKey) => {
    dispatch(actions.getEmployeeFamily(EmployeePublicKey))
  },
  clearAll: () => {
    dispatch(actions.clearAll())
  },
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)
