import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router'

// hixme-ui
import Container from '@hixme-ui/container'
import Title from '@hixme-ui/title'

// project
import { Allow } from 'components'
import CartTile from './CardTile'
import useTiles from './hooks/useTiles'
import { titleFontFamily } from '@sureco/design-system'
import { PageTitle } from 'components/PageTitle'

const CardLink = styled(Link)`
  margin: 20px;
  display: inline-block;
  verticalalign: top;
  color: inherit;
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`

const HomeView = () => {
  const tiles = useTiles()
  return (
    <>
      <PageTitle title="Admin" />
      <Container textCenter>
        <Container textCenter>
          <Title center fontFamily={titleFontFamily}>Welcome back to admin.</Title>
        </Container>
        <>
          {tiles?.map(
            ({ href, disabled, visibleForRoles, ...props }) =>
              visibleForRoles ? (
                <Allow key={href} {...visibleForRoles}>
                  <CardLink to={href} disabled={disabled}>
                    <CartTile {...props} />
                  </CardLink>
                </Allow>
              ) : (
                <CardLink key={href} to={href} disabled={disabled}>
                  <CartTile {...props} />
                </CardLink>
              )
          )}
        </>
      </Container>
    </>
  )
}

HomeView.propTypes = {
  profile: PropTypes.object,
}

HomeView.defaultProps = {
  profile: {},
}

export default HomeView
