import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as actions from '../actions'
import * as selectors from '../selectors'

const JodalContainer = ({ displayModal, hideModal, View, ...restProps }) => {
  // hideModal when ESC key is pressed
  window.addEventListener('keydown', (event) => {
    if (event.keyCode === 27) {
      hideModal()
    }
  })

  return <div>{displayModal && View && <View {...restProps} />}</div>
}

JodalContainer.displayName = 'JodalContainer'
JodalContainer.propTypes = {
  displayModal: PropTypes.bool.isRequired,
  View: PropTypes.func,
  hideModal: PropTypes.func.isRequired,
}

JodalContainer.defaultProps = {
  View: null,
}

export default connect(
  (state) => ({
    displayModal: selectors.shouldDisplayModalSelector(state),
    View: selectors.getViewSelector(state),
  }),
  {
    hideModal: actions.hideModal,
  }
)(JodalContainer)
