import React from 'react'
import PropTypes from 'prop-types'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk'
import { useQuery } from '@apollo/client'
import { GET_DASHBOARD_EMBED_URL } from 'apollo/queries'
import { toast } from 'react-toastify'

const QuicksightDashboard = ({
  dashboardId,
  parameters,
  height,
  width,
  loadingHeight,
  loadingWidth,
  undoRedoDisabled,
  resetDisabled,
}) => {
  const getQuickSightDashboardEmbedURL = async () => {
    const { data: dashboardUrl } = await useQuery(
      GET_DASHBOARD_EMBED_URL,
      {
        variables: {
          dashboardId,
        },
      },
      {
        onError: (error) => {
          console.error('DASHBOARDURL Error', error)
          toast('Error getting dashboard URL')
        },
      }
    )

    if (dashboardUrl) {
      console.log('DASHBOARDURL', dashboardUrl.getDashboardUrl.embedUrl)
      const containerDiv = document.getElementById('dashboardContainer')

      embedDashboard({
        url: dashboardUrl.getDashboardUrl.embedUrl,
        undoRedoDisabled,
        resetDisabled,
        container: containerDiv,
        parameters,
        scrolling: 'no',
        height,
        loadingHeight,
        loadingWidth,
        width,
        locale: 'en-US',
        footerPaddingEnabled: false,
      })
    }
  }

  getQuickSightDashboardEmbedURL()

  return (
    <div>
      <div id="dashboardContainer" />
    </div>
  )
}

QuicksightDashboard.displayName = 'QuicksightDashboard'

QuicksightDashboard.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  parameters: PropTypes.object,
  height: PropTypes.string,
  width: PropTypes.string,
  loadingHeight: PropTypes.string,
  loadingWidth: PropTypes.string,
  undoRedoDisabled: PropTypes.bool,
  resetDisabled: PropTypes.bool,
}

QuicksightDashboard.defaultProps = {
  parameters: {},
  undoRedoDisabled: true,
  resetDisabled: true,
  height: 'AutoFit',
  width: '1000px',
  loadingHeight: '500px',
  loadingWidth: '1000px',
}

export default QuicksightDashboard
