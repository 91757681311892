import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import { colorTheme } from '@hixme-ui/theme'

const Badge = ({ line1, line2, color, color2 }) => {
  const style = {
    margin: '0',
    padding: '0',
    height: '35px',
    minWidth: '50px',
    maxWidth: '70px',
    width: '100%',
    border: `1px solid ${colorTheme.light}`,
  }
  if (!line1) return null
  return (
    <Container rounded noMargin background="white" style={style}>
      <Container alignCenter flex flexColumn noPadding margin="0 4px 0 4px">
        <Text center primary color={color} fontWeight="600">
          {line1}
        </Text>
        <Text center primary smallest color={color2} fontWeight="600">
          {line2}
        </Text>
      </Container>
    </Container>
  )
}

Badge.displayName = 'Badge'

Badge.propTypes = {
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string.isRequired,
  color: PropTypes.string,
  color2: PropTypes.string,
}

Badge.defaultProps = {
  color: colorTheme.primary,
  color2: colorTheme.primary,
}

export default Badge
