import type { OptionGroup } from 'react-querybuilder'

export const employmentType: OptionGroup[] = [
  {
    label: 'Type',
    type: [
      'Full-Time',
      'Part-Time',
      'Contractor',
      'Temporary',
      'Seasonal',
    ],
  }
].map(({ label, type }) => ({
  label,
  options: type.map((s) => ({ name: s, label: s })),
}))
