import uuid from 'uuid/v4'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { reduxForm, reset, change } from 'redux-form'
import { getFormValues } from 'redux-form/immutable'
import { compose } from 'recompose'

import { selectors as expenseSelectors } from 'routes/Expenses/modules/expense-requests-search'
import {
  actions,
  selectors,
} from 'routes/Expenses/routes/ExpenseRequestsSummary/modules/receipt-line-items'
import ValidateLineItemForm from 'routes/Expenses/routes/ExpenseRequestsSummary/utils/ValidateLineItemForm'
import ReceiptLineItemForm from 'routes/Expenses/routes/ExpenseRequestsSummary/components/ReceiptLineItemForm/ReceiptLineItemForm'

const mapStateToProps = (state) => {
  const receipts = selectors.getReceipts(state)
  const lineItems = selectors
    .getLineItems(state)
    .map((lineItem) => lineItem.receipt)
  const remainingReceipts = receipts.filter((receipt) =>
    lineItems.every((lineItem) => lineItem !== receipt.id)
  )
  const expense = expenseSelectors.getExpense(state)
  const coInsurance = expense.coinsurance
  const form = getFormValues('ReceiptForm')(state) || {}
  const isDeclined = form.Decision === '20'
  const isDisabled = receipts.length === 0
  const receipt = receipts.find((r) => r.id === form.ReceiptUpload) || {}
  const isHigherThanReceipt = receipt
    ? Number(form.Amount) > Number(receipt.amount)
    : false
  return {
    coInsurance,
    isDisabled,
    isDeclined,
    receipts: remainingReceipts,
    statusOptions: expense.itemStatusTypes.map((item) => ({
      value: `${item.id}`,
      text: item.name,
    })),
    reasons: expense.itemStatusReasonTypes,
    receipt,
    isHigherThanReceipt,
    ...form,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetForm: () => {
    dispatch(reset('ReceiptForm'))
  },
  onDecisionChange: (decision, value) => {
    dispatch(change('ReceiptForm', 'Amount', decision === '20' ? value : 0))
  },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'ReceiptForm',
    validate: ValidateLineItemForm,
    onSubmit(values, dispatch, props) {
      if (!isEmpty(values)) {
        const lineItem = {
          id: uuid(),
          receiptId: values.ReceiptUpload,
          amount: Number(values.Amount),
          description: values.Description,
          statusId: Number(values.Decision),
          status: props.statusOptions.find(
            (option) => option.value === values.Decision
          ).text,
          ...(values.Reason && {
            reasonId: values.Reason,
            reason: props.reasons.find(
              (option) => option.id === Number(values.Reason)
            ).name,
          }),
          local: true,
        }
        if (values.Decision === '10' && props.coInsurance) {
          const description = `Co-insurance(${props.coInsurance * 100}%)`
          const amount = Number(values.Amount) * props.coInsurance * -1
          dispatch(
            actions.addLineItem({
              receipt: lineItem.receiptId,
              items: [
                lineItem,
                {
                  ...lineItem,
                  id: uuid(),
                  description,
                  status: 'Auto-applied',
                  amount,
                  local: true,
                },
              ],
            })
          )
        } else {
          dispatch(
            actions.addLineItem({
              receipt: lineItem.receiptId,
              items: [lineItem],
            })
          )
        }
        dispatch(reset('ReceiptForm'))
      }
    },
  })
)(ReceiptLineItemForm)
