import React from 'react'
import PropTypes from 'prop-types'
import { FieldSelect } from 'components/ReduxFormComponents'
import fundingModel from 'data/funding-model'

// prepend an empty option to the metals list
const select = { id: '-1', name: 'Select' }

export const FundingModelFormGroup = (props) => (
  <FundingModelDropdown {...props} />
)

FundingModelFormGroup.propTypes = {
  label: PropTypes.string,
}

FundingModelFormGroup.defaultProps = {
  label: 'Month',
}

export const FundingModelDropdown = (props) => (
  <FieldSelect {...props} list={[select, ...fundingModel]} />
)

export default { FundingModelDropdown, FundingModelFormGroup }
