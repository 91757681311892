import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

// hixme-ui
import { Label, FormGroup } from '@hixme-ui/forms'

import { FieldInput } from 'components/ReduxFormComponents'
import locations from 'modules/locations'

const { LocationsContainer } = locations.containers
const RenderMailingZipAndLocations = ({
  MailingPostalCode,
  MailingCounty,
  disabled,
}) => (
  <Row>
    <Col xs={4}>
      <FormGroup>
        <Label medium thin>
          Zip code
        </Label>
        <FieldInput {...MailingPostalCode} disabled={disabled} />
      </FormGroup>
    </Col>
    <Col xs={4}>
      <FormGroup>
        <Label medium thin>
          County, State
        </Label>
        <LocationsContainer
          {...MailingCounty.input}
          postalCode={MailingPostalCode.input.value}
          disabled={
            disabled ||
            (MailingPostalCode.meta.touched && MailingPostalCode.meta.invalid)
          }
        />
      </FormGroup>
    </Col>
  </Row>
)

RenderMailingZipAndLocations.propTypes = {
  MailingPostalCode: PropTypes.object,
  MailingCounty: PropTypes.object,
  disabled: PropTypes.bool,
}

RenderMailingZipAndLocations.defaultProps = {
  MailingPostalCode: {},
  MailingCounty: {},
  disabled: false,
}

export default RenderMailingZipAndLocations
