import React from 'react'
import PropTypes from 'prop-types'
import Select from '@hixme-ui/select'

const LocationsDropdown = ({ list, ...props }) => {
  const locations = list.map((loc) => ({
    id: loc.County,
    name: `${loc.County}, ${loc.StateProvince}`,
  }))
  locations.unshift({ id: '', name: 'Select' })

  return <Select list={locations} {...props} />
}
LocationsDropdown.propTypes = {
  list: PropTypes.array,
}
LocationsDropdown.defaultProps = {
  list: [],
}

export default LocationsDropdown
