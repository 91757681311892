import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'

export type UseFeatureFlagsLoginParams = {
  /**
   * The identifying key that identifies a user in our systems, such as a user ID.
   */
  key: string
  /**
   * The user's first name.
   */
  firstName?: string
  /**
   * The user's last name.
   */
  lastName?: string
  /**
   * The user's primary email address.
   */
  email?: string
  /**
   * Custom properties
   */
  custom?: Record<string, string>
}

export type AppEngineConfig = {
  /**
   * Controls if the browser should periodically refresh.
   */
  refreshEnabled: boolean
  /**
   * The rate at which the browser should poll for new data, in milliseconds.
   */
  refreshRateMS: number
}

export type UseFeatureFlags = {
  /**
   * An object containing all feature flags used by the application.
   */
  flags: {
    /**
     * Controls visibility of the QLE v1 feature.
     */
    ['qle-v-1']: boolean
    /**
     * Controls visibility of the Zendesk widget.
     */
    ['customer-service.fe.zendesk-widget-enabled']: boolean
    /**
     * Controls visibility of some HR reports
     */
    ['product.enrollme.q4-hr-reports-enabled']: boolean
    /**
     * Controls visibility of reports tab
     */
    ['admin.show-reports-tab-to-hr-users']: boolean
    /**
     * Controls visibility of enrollment dashboard
     */
    ['admin.show-enrollment-dashboard']: boolean
    /**
     * Controls visibility of the Our People > Send Email action button
     */
    ['our-people-send-email-action']: boolean
    /**
     * Controls visibility of the select-all and individual checkboxes on the enrollment dashboard.
     */
    ['engineering.hr-portal.employee-list-checkboxes-enabled']: boolean
    /**
     * Controls visibility of new Payroll HRIS Sync History
     */
    ['product.admin.payroll-sync-enabled']: boolean
    /**
     * Controls if address should be verification by calling API
    */
    ['feature-address-verification']: boolean
    /**
     * Controls usage of new Termination flow
     */
    ['product.termination.new-flow']: boolean
    /**
     * Configuration of App Engine refresh page. Can enable/disable browser polling, and rate in milliseconds.
     */
    ['product.admin.appengine.config']: AppEngineConfig
    /**
     * Controls if the census tab is available in company profile page
     */
    ['admin.hris.tab-available']: boolean
    /**
     * Controls if the payroll tab is available in company profile page
     */
    ['admin.payroll.tab-available']: boolean
    /**
     * Only effective when user is a broker role. Controls if the broker user has access
     * to "Login as Worker" action
     */
    ['broker.impersonate-as-user.ui']: boolean
    /**
     * Controls user who can't see salary information
     */
    ['admin.salary.unavailable']: boolean
    /**
     * Temp flag to control if the impersonate url point to new domain
     */
    ['temp.ui.admin.new-domain']: boolean
    /**
     * Flag to control if ACH Account Number fields are displayed in app engine / payment info tab
     */
    ['admin.ui.ach-acct']: boolean
    /**
     * Temp flag to control citizenship questions for employee form
     */
    ['temp.ui.citizenship-question']: boolean
    /**
     * Temp flag to allow access to the new Ancillary report
     */
    ['temp.ui.admin.enable-ancillary-report']: boolean
    /**
     * Temp flag to allow access to the new 1095-C report
     */
    ['temp.ui.admin.enable-1095c-report']: boolean
    /**
     * Temp flag to allow access to the medicare report for HR admins
     */
    ['temp.ui.admin.enable-medicare-report']: boolean
    /**
     * Temp flag to allow access to the new medicare report API
     */
    ['temp.ui.admin.enable-medicare-report-v2']: boolean
    /**
     * Temp flag to allow access to the new benefit effective report
     */
    ['temp.ui.admin.enable-new-benefit-effective-report']: boolean
    /**
     * Used to provide OEP end time for enrollment dashboard
     */
    ['enrollment.dashboard.endtime.ui']: string
    /**
     * Flag to control if the new customer module will be available in the UI
     */
    ['ui.customer.new-experience']: boolean
  }
  /**
   * Callback methods used to perform actions on our feature flag storage, such as identifying a user after login.
   */
  methods: {
    /**
     * Identifies a user after a successful login.
     */
    identify: (params: UseFeatureFlagsLoginParams) => void
  }
}

const useFeatureFlags = (): UseFeatureFlags => {
  try {
    const flags = useFlags()
    const ldClient = useLDClient()

    const identify = (data: UseFeatureFlagsLoginParams) => {
      ldClient?.identify(data).catch((e) => console.error('LaunchDarkly - Error identifying user', e))
    }

    return { flags, methods: { identify } } as UseFeatureFlags
  } catch (e) {
    console.error('Error initializing LaunchDarkly', e)
    return defaultFeatureFlags
  }
}

export default useFeatureFlags

const defaultFeatureFlags: UseFeatureFlags = {
  /**
   * An object containing all feature flags used by the application.
   */
  flags: {
    /**
     * Controls visibility of the QLE v1 feature.
     */
    'qle-v-1': false,
    /**
     * Controls visibility of the Zendesk widget.
     */
    'customer-service.fe.zendesk-widget-enabled': false,
    /**
     * Controls visibility of some HR reports
     */
    'product.enrollme.q4-hr-reports-enabled': false,
    /**
     * Controls visibility of reports tab
     */
    'admin.show-reports-tab-to-hr-users': false,
    /**
     * Controls visibility of enrollment dashboard
     */
    'admin.show-enrollment-dashboard': false,
    /**
     * Controls visibility of the Our People > Send Email action button
     */
    'our-people-send-email-action': false,
    /**
     * Controls visibility of the select-all and individual checkboxes on the enrollment dashboard.
     */
    'engineering.hr-portal.employee-list-checkboxes-enabled': false,
    /**
     * Controls visibility of new Payroll HRIS Sync History
     */
    'product.admin.payroll-sync-enabled': false,
    /**
     * Controls if address should be verification by calling API
     */
    'feature-address-verification': false,
    /**
     * Controls usage of new Termination flow
     */
    'product.termination.new-flow': false,
    /**
     * Configuration of App Engine refresh page. Can enable/disable browser polling, and rate in milliseconds.
     */
    'product.admin.appengine.config': {
      refreshEnabled: false,
      refreshRateMS: 10000,
    },
    /**
    * Controls if the census tab is available in company profile page
    */
    'admin.hris.tab-available': false,
    /**
    * Controls if the payroll tab is available in company profile page
    */
    'admin.payroll.tab-available': false,
    /**
    * Only effective when user is a broker role. Controls if the broker user has access
    * to "Login as Worker" action
    */
    'broker.impersonate-as-user.ui': false,
    /**
    * Controls user who can't see salary information
    */
    'admin.salary.unavailable': false,
    /**
     * Temp flag to control if the impersonate url point to new domain
     */
    'temp.ui.admin.new-domain': false,
    /**
     * Flag to control if ACH Account Number fields are displayed in app engine / payment info tab
     */
    'admin.ui.ach-acct': false,
    'temp.ui.citizenship-question': false,

    // Report flags
    'temp.ui.admin.enable-ancillary-report': false,
    'temp.ui.admin.enable-1095c-report': false,
    'temp.ui.admin.enable-medicare-report': false,
    'temp.ui.admin.enable-medicare-report-v2': false,
    'temp.ui.admin.enable-new-benefit-effective-report': false,

    /**
     * Used to provide OEP end time for enrollment dashboard
     */
    'enrollment.dashboard.endtime.ui': '00:00',
    /**
     * Flag to control if the new customer module will be available in the UI
     */
    'ui.customer.new-experience': false
  },
  /**
   * Callback methods used to perform actions on our feature flag storage, such as identifying a user after login.
   */
  methods: {
    /**
     * Identifies a user after a successful login.
     */
    identify: (_params: unknown) => { console.warn('No feature flag provider found.') }
  }
}
