import { connect } from 'react-redux'
import { capitalize } from 'lodash'

import Badge from 'components/Badge/Badge'
import { getVirtualCardByBenefitId } from 'modules/virtual-cards/selectors'
import { getVCNColorByStatus } from '../constants'

const mapStateToProps = (state, props) => {
  const card =
    props.benefitId && getVirtualCardByBenefitId(state)(props.benefitId)
  const badge = card && {
    line1: 'VCN',
    line2: capitalize(card.state),
    color: '#3A4951',
    color2: getVCNColorByStatus(card.state),
  }
  return {
    ...badge,
  }
}

export default connect(mapStateToProps)(Badge)
