import useAllow from './hooks/useAllow'

const Allow = ({ children, isAuth, ...props }) => {
  const isAllowed = useAllow(props)
  if ((isAuth || isAllowed) && children) {
    return children
  }
  return null
}

export default Allow
