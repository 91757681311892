import React, { ReactElement, ReactNode } from 'react'

import styles from './Page.module.scss'
import { Stack, Typography, pageTitleFontFamily } from '@sureco/design-system'
import useWindowDimensions from 'hooks/useWindowDimensions'
import {
  Alert,
  AlertProps,
  BreadcrumbProps,
} from '@surecompanies/core_components'
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb'

type PageProps = {
  title: string | ReactElement<any>
  children: ReactNode
  action?: ReactElement<any>
  breadcrumb?: ReactElement<BreadcrumbProps> | null
  banner?: AlertProps | null
  error?: AlertProps | null
  headerContent?: ReactElement
  subTitle?: ReactElement
  horizontalRule?: boolean
}

export default function Page({
  children,
  title,
  action,
  breadcrumb = <Breadcrumb />,
  error,
  banner,
  headerContent,
  subTitle,
  horizontalRule,
}: PageProps) {
  const { width } = useWindowDimensions()
  return (
    <section className={styles.page}>
      {breadcrumb}
      <Stack
        className={'page-header'}
        direction={width < 600 ? 'col' : 'row'}
        justify="space-between"
        style={{ paddingBottom: '50px' }}
        space={10}
      >
        <Typography
          color="#003146"
          size={32}
          style={{ fontFamily: pageTitleFontFamily }}
        >
          <Stack direction='row' space={10} align='center'>{title}{headerContent}</Stack>
        </Typography>
        {action}
      </Stack>
      { horizontalRule ? (<hr className={'page-hr'}/>) : null }
      { subTitle ? (<Stack direction='row' align='center' className={'subtitle'}>{subTitle}</Stack>) : null }
      {(error || banner) && (
        <Stack margin={'10px 0'}>
          <Alert {...(error ?? banner)} />
        </Stack>
      )}
      {children}
    </section>
  )
}
