import React from 'react'
import ReviewRecordsBase from '../components/CensusReviewRecordsBase'
import { CensusFileRowTypeEnum } from '../../api/enums/census-file-row-type.enum'
import { ColDef } from '@ag-grid-community/core'
import { CensusFileRowGridData } from '../../types/census-file-row-grid-data'
import { CensusFileRowStatusEnum } from '../../api/enums/census-file-row-status.enum'
import { CensusManagementPageProps } from '../../types/census-management-page-props'

const statusColumn: ColDef<CensusFileRowGridData> = {
  headerName: 'Status',
  field: 'status',
  width: 150,
  sortable: true,
  pinned: 'left',
  valueFormatter: (params) => {
    if (!params.value) return ''
    return params.value
      .toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' ')
  },
  cellStyle: (params): Record<string, string> => {
    switch (params.value) {
      case CensusFileRowStatusEnum.IN_REVIEW:
        return { color: '#D48806', fontWeight: '500' } // Yellow/Orange
      case CensusFileRowStatusEnum.WITHDRAWN:
      case CensusFileRowStatusEnum.REJECTED:
      case CensusFileRowStatusEnum.DELETED:
        return { color: '#CE6C6D', fontWeight: '500' } // Red
      case CensusFileRowStatusEnum.COMPLETE:
        return { color: '#28A745', fontWeight: '500' } // Green
      default:
        return {}
    }
  }
}

const typeColumn: ColDef<CensusFileRowGridData> = {
  headerName: 'Type',
  field: 'type',
  width: 100,
  sortable: true,
  valueFormatter: (params) => {
    if (!params.value) return ''

    const customLabels: Record<string, string> = {
      [CensusFileRowTypeEnum.DRAFT_EMPLOYEE]: 'New',
      [CensusFileRowTypeEnum.UPDATE_EMPLOYEE_BENEFITS]: 'Update w/Benefits',
      [CensusFileRowTypeEnum.UPDATE_EMPLOYEE_NO_BENEFITS]: 'Update No Benefits'
    }
    // Return custom label if defined
    if (params.value in customLabels) {
      return customLabels[params.value as CensusFileRowTypeEnum]
    }
    // Default: Convert underscore to spaces and capitalize
    return params.value
      .toLowerCase()
      .split('_') // Split by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize
      .join(' ') // Rejoin as a string
  },
  cellStyle: (): Record<string, string> => ({
    fontWeight: '500'
  })
}

const updatedAtCol: ColDef<CensusFileRowGridData> = {
  headerName: 'Last Updated',
  field: 'updatedAt',
  width: 200,
  sortable: true,
  valueFormatter: (params) => {
    const date = new Date(params.value)
    if (isNaN(date.getTime())) {
      return params.value
    }
    return date.toLocaleString()
  }
}

// Show errors on page
const CensusReviewRecordsActivity = (props: CensusManagementPageProps) => {
  const modifyColDefs = (baseColDefs: ColDef<CensusFileRowGridData>[]): ColDef<CensusFileRowGridData>[] => {
    return [
      statusColumn,
      typeColumn,
      updatedAtCol,
      ...baseColDefs,
    ]
  }

  return (
    <>
    <ReviewRecordsBase
      tabKey={'activity'}
      {...props}
      rowQueryParams={{
        censusFileRowStatuses: [
          CensusFileRowStatusEnum.IN_PROCESS,
          CensusFileRowStatusEnum.WITHDRAWN,
          CensusFileRowStatusEnum.REJECTED,
          CensusFileRowStatusEnum.DELETED,
          CensusFileRowStatusEnum.COMPLETE,
        ],
      }}
      modifyColDefs={modifyColDefs} // Pass the callback function here
    />
      </>
  )
}

export default CensusReviewRecordsActivity
