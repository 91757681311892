import { reset } from 'redux-form'
import { browserHistory } from 'react-router'
import { reimbursementService } from '@hixme/api'

// hixme-ui
import { clearModal } from '@hixme/modal'

// project
import {
  createErrorNotification,
  createSuccessNotification,
} from 'modules/app-core/notification-manager/actions'
import t from './actionTypes'
import { NAME } from './constants'
import { selectors } from '.'
import {
  getExpenseRequestsData,
  getExpenseRequestData,
  getReceiptsByReimbursement,
  sendReimbursementMessage,
} from './api'

import { updateExpense } from '../../routes/ExpenseRequestsSummary/modules/receipt-line-items/api'

export const resetState = () => ({
  type: t.RESET_STATE,
})

export const loadList = (list) => ({
  type: t.LOAD_LIST,
  payload: list,
})

export const addToList = (list) => ({
  type: t.ADD_TO_LIST,
  payload: list,
})

export const loadExpense = (list) => ({
  type: t.LOAD_EXPENSE,
  payload: list,
})

export const loadExpenseReceipt = (payload) => ({
  type: t.LOAD_RECEIPTS,
  payload,
})

export const getExpenseRequests = (isViewMore = false) => async (
  dispatch,
  getState
) => {
  try {
    const { status, ...remainingFilters } = selectors.getFilters(getState())
    let filter = { ...remainingFilters }
    if (status) {
      const statusFilter = status && status.map((item) => item.value).join(',')
      filter = { ...remainingFilters, status: statusFilter }
    }
    const pagination = selectors.getPagination(getState())
    const expenseRequests = await dispatch(
      getExpenseRequestsData({ ...filter, ...pagination })
    )
    dispatch(
      isViewMore ? addToList(expenseRequests) : loadList(expenseRequests)
    )
  } catch (err) {
    dispatch(
      createErrorNotification(
        'We had trouble getting the expense requests. Please reload the page'
      )
    )
    throw err
  }
}

export const getExpenseRequest = (id) => async (dispatch) => {
  try {
    const expenseRequest = await dispatch(getExpenseRequestData(id))
    if (expenseRequest.reimbursement.status === 'New Request') {
      await dispatch(
        updateExpense(
          {
            status: 'In Progress',
          },
          {
            reimbursementId: id,
            employeeId: expenseRequest.reimbursement.employeePublicKey,
            clientId: expenseRequest.reimbursement.clientPublicKey,
          }
        )
      )
      const expense = await dispatch(getExpenseRequestData(id))
      dispatch(loadExpense(expense))
      return
    }
    dispatch(loadExpense(expenseRequest))
  } catch (err) {
    dispatch(
      createErrorNotification(
        'We had trouble getting the expense. Please reload the page'
      )
    )
    throw err
  }
}

export const sendMessage = (body) => async (dispatch, getState) => {
  try {
    const expense = selectors.getExpense(getState())
    if (!expense) {
      throw new Error()
    }
    await dispatch(
      sendReimbursementMessage(body, {
        reimbursementId: expense.reimbursement.id,
      })
    )
    dispatch(createSuccessNotification(`Message successfully sent`))
    dispatch(getExpenseRequest(expense.reimbursement.id))
    dispatch(clearModal())
  } catch (err) {
    dispatch(createErrorNotification(`There was a problem sending the message`))
  }
}

export const deleteExpense = (id) => async (dispatch) => {
  try {
    await reimbursementService({
      route: 'reimbursements/admin/reimbursementId/{reimbursementPublicKey}',
      method: 'DELETE',
      params: {
        reimbursementPublicKey: id,
      },
    })
    browserHistory.push('/admin-expense-requests')
  } catch (err) {
    dispatch(
      createErrorNotification(
        `There was a problem deleting the expense request`
      )
    )
  }
}

export const getReceitpsByReimbursementId = (reimbusementId) => async (
  dispatch
) => {
  try {
    const receipts = await dispatch(getReceiptsByReimbursement(reimbusementId))
    dispatch(loadExpenseReceipt(receipts))
  } catch (error) {
    dispatch(
      createErrorNotification(`There was a problem updating your Expense.
      Error: ${error}`)
    )
    dispatch(loadExpenseReceipt({ id: reimbusementId, message: error.message }))
  }
}

// ---------- search related action creators and async actions ---------- //

export const clearPagination = () => ({
  type: t.RESET_PAGINATION,
})

export const viewMore = () => ({
  type: t.VIEW_MORE,
})

export const toggleFilters = (event) => {
  // prevent navigation to # on filtered x button
  event && event.preventDefault()
  return {
    type: t.TOGGLE_FILTERS,
  }
}

export const updateFilter = (values) => ({
  type: t.UPDATE_FILTER,
  payload: values,
})

export const setCompanyId = (companyId) => ({
  type: t.SET_COMPANY_ID,
  payload: companyId,
})

export const updateSearch = (searchKeyword) => ({
  type: t.UPDATE_SEARCH,
  payload: searchKeyword,
})

export const resetFilters = () => (dispatch) => {
  dispatch(reset(NAME))
  dispatch({ type: t.RESET_FILTERS })
}

export const changeSort = (sortBy) => ({
  type: t.CHANGE_SORT,
  payload: sortBy,
})
