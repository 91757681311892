import React, { useRef } from 'react'
import { Stack } from '@sureco/design-system'
import Page from 'layouts/Page'
import CustomerForm, { FormRef } from '../../components/CustomerForm'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { Button, Spin } from '@surecompanies/core_components'
import { CUSTOMER_MANAGEMENT } from '../../paths'
import useViewCustomer from '../ViewCustomer/hooks/useViewCustomer'
import useEditCustomer from './hooks/useEditCustomer'
import BreadcrumbNav from 'enrollment-platform/components/Breadcrumbs'

type EditCustomerProps = {
    params: {
        [k: string]: any
    }
    router: any
}

export default function EditCustomer({ params, router }: EditCustomerProps) {
  const { width } = useWindowDimensions()
  const { isLoading, data } = useViewCustomer({ id: params.id })
  const { mutate, isPending } = useEditCustomer({ id: params.id })
  const buttonSize = width > 600 ? 'middle' : 'large'
  const formRef = useRef<FormRef>(null)
  return isLoading ? <Spin spinning fullscreen /> : (
    <Page title='Edit Customer' action={
        <Stack direction="row" space={10}>
          <Button
            type='default'
            color="default"
            size={buttonSize}
            onClick={() => router.push(`/${CUSTOMER_MANAGEMENT}`)}
          >
            Cancel
          </Button>
          <Button type="primary" size={buttonSize} htmlType="submit" onClick={() => formRef?.current?.submit()} loading={isPending}>
            Save Customer
          </Button>
        </Stack>
      }
      breadcrumb={
        <BreadcrumbNav
          items={[
            { title: 'Customers', path: `/${CUSTOMER_MANAGEMENT}` },
            { title: data?.name },
          ]}
        />
      }>
     {!isLoading && <CustomerForm
        ref={formRef}
        initialValues={data as object}
        onFormSubmit={(data) => mutate(data)}
        isLoading={false}
      />}
    </Page>
  )
}
