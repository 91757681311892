import { connect } from 'react-redux'

// module
import { selectors } from 'routes/Expenses/modules/expense-requests-search'
import ReceiptLineItemHeader from 'routes/Expenses/routes/ExpenseRequestsSummary/components/ReceiptLineItemHeader/ReceiptLineItemHeader'

const mapStateToProps = (state) => {
  const expense = selectors.getExpense(state)
  return {
    reimbursement: expense && expense.reimbursement,
  }
}

export default connect(mapStateToProps)(ReceiptLineItemHeader)
