
import React, { useCallback, useState } from 'react'
import ReviewRecordsBase from '../components/ReviewRecordsBase'
import { CensusFileRowTypeEnum } from '../api/enums/census-file-row-type.enum'
import {
  CellValueChangedEvent,
  ColDef,
} from '@ag-grid-community/core'
import { updateCensusFileRows } from '../api/census-enrollment-platform-api-client'
import { useMarkRowDirty } from '../hooks/use-mark-row-dirty'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'
import { CensusFileRowDto } from '../api/dto/census-file-row.dto'
import { mapGridDataToCensusFileRowDto } from '../utils/map-grid-data-to-census-file-row-dto'
import ToggleEditModeButton from '../components/ToggleEditModeButton'
import InlineEditActionButtons from '../components/InlineEditActionButtons'
import SendSelectedForReviewButton from '../components/SendSelectedForReviewButton'
import { LoadingOverlay } from '../components/LoadingOverlay/LoadingOverlay'
import RejectSelectedButton from '../components/RejectSelectedButton'
import { CensusFileRowStatusEnum } from '../api/enums/census-file-row-status.enum'
import { configureEditField } from '../utils/configure-edit-field'

const submitCorrections = async (rowsToSubmit: CensusFileRowGridData[], publicKey: string) => {
  const updatedRows: CensusFileRowDto[] = rowsToSubmit.map(mapGridDataToCensusFileRowDto)
  await updateCensusFileRows(updatedRows, publicKey)
}

const ReviewRecordsDraftEmployee = (props: Record<string, never> & { routeParams: never }) => {
  const { markRowDirty, dirtyRows, cleanAllRows, undoLastEdit, editCount } = useMarkRowDirty()
  const [mode, setMode] = useState<'edit' | 'submit'>('submit')
  const [loading, setLoading] = useState(false) // Track submission state
  const { importPublicKey: publicKey } = props.routeParams

  const handleCellValueChanged = useCallback((params: CellValueChangedEvent<CensusFileRowGridData>) => {
    markRowDirty(params)
  }, [])

  // Add checkbox column and make cells editable based on mode
  const modifyColDefs = (baseColDefs: ColDef<CensusFileRowGridData>[]): ColDef<CensusFileRowGridData>[] => {
    return [
      {
        headerCheckboxSelection: mode === 'submit', // Allow selection only in Submit Mode
        checkboxSelection: mode === 'submit',
        width: 50,
        pinned: 'left',
      },
      ...baseColDefs.map((col) => ({
        ...col,
        editable: mode === 'edit', // Enable editing only in Edit Mode
        ...configureEditField(col?.field, mode) // Apply 'Select' dropdown on fields
      })),
    ]
  }

  return (
    <>
  <LoadingOverlay isLoading={loading} message="Loading..." />
  <ReviewRecordsBase
      tabKey={CensusFileRowTypeEnum.DRAFT_EMPLOYEE}
      {...props}
      rowQueryParams={{
        censusFileRowStatuses: [CensusFileRowStatusEnum.IN_PROCESS],
        censusRowType: CensusFileRowTypeEnum.DRAFT_EMPLOYEE,
      }}
      modifyColDefs={modifyColDefs}
      actionButton={({ selectedRows, handleSubmit }) => {
        return (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <ToggleEditModeButton dirtyRows={dirtyRows} selectedRows={selectedRows} mode={mode} setMode={setMode} />
            {mode === 'edit' ? (
                <InlineEditActionButtons
                  undoLastEdit={undoLastEdit}
                  editCount={editCount}
                  dirtyRows={dirtyRows}
                  handleSubmit={handleSubmit}
                  cleanAllRows={cleanAllRows}
                  submitCorrections={(rows) => submitCorrections(rows, publicKey)}
                  updateMode={false}
                />
            ) : (
              <>
                <SendSelectedForReviewButton
                  publicKey={publicKey}
                  selectedRows={selectedRows}
                  handleSubmit={handleSubmit}
                  cleanAllRows={cleanAllRows}
                  setLoading={setLoading}
                />
                <RejectSelectedButton
                  publicKey={publicKey}
                  selectedRows={selectedRows}
                  handleSubmit={handleSubmit}
                  cleanAllRows={cleanAllRows}
                  setLoading={setLoading}
                />
              </>
            )}
          </div>
        )
      }}
      handleCellValueChanged={handleCellValueChanged}
    />
    </>
  )
}

export default ReviewRecordsDraftEmployee
