import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'
import { withRouter } from 'react-router'

import { actions, selectors } from '../'
import { WAITER_GET_BUNDLES } from '../constants'

const mapStateToProps = (state, props) => {
  const enrollmentIdParam = props.params.enrollmentId
  const bundleIdParam = props.params.bundleId
  const groupIndexParam = props.params.groupIndex
  const bundle = selectors.getBundle(state)(bundleIdParam, groupIndexParam)
  const gettingBundle = getWaiter(
    state,
    `${WAITER_GET_BUNDLES}${enrollmentIdParam}`
  ).isPending
  return {
    bundle,
    gettingBundle,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBundles: (EnrollmentId) => {
    dispatch(actions.getBundles(EnrollmentId))
  },
  clearAll: () => {
    dispatch(actions.clearAll())
  },
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)
