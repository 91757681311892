import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/core.scss'

// Note: Stateless/function components *will not* hot reload!
// react-transform *only* works on component classes.
//
// Since layouts rarely change, they are a good place to
// leverage React's new Stateless Functions:
// https://facebook.github.io/react/docs/reusable-components.html#stateless-functions
//
// CoreLayout is a pure function of its props, so we can
// define it with a plain javascript function...
function CoreLayout({ children }) {
  return <div>{children}</div>
}

CoreLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CoreLayout
