import { selectors as authSelectors } from '@hixme/auth-ui'
import roles from '@hixme/role-policy'
import { useSelector } from 'react-redux'

// this hook receives roles as params and returns a valid/allowed role
// it's used like <Allow platformHixmeAdmin /> which means only platformHixmeAdmin role will be allowed
export default function useAllow(allowedRoles) {
  const lowerFirstLetter = (str) => {
    const [char, ...rest] = [...str]
    return [char.toLowerCase(), ...rest].join('')
  }

  const roleValues = Object.values(roles)
    .filter((val) => typeof val === 'string')
    .map((val) => lowerFirstLetter(val))

  const foundAuths = Object.keys(allowedRoles).reduce(
    (prev, key) => (roleValues.includes(key) ? [key, ...prev] : prev),
    []
  )

  if (foundAuths.length === 0) return false
  const role = lowerFirstLetter(useSelector(authSelectors.getRole))

  return allowedRoles[role]
}
