import React from 'react'
import PropTypes from 'prop-types'
import { Field, Fields } from 'redux-form'
import { Row, Col } from 'react-flexbox-grid'

import {
  FieldInput,
  FieldSelect,
  FieldError,
  FieldCheckbox,
} from 'components/ReduxFormComponents'
import {
  RenderZipAndLocations,
  RenderMailingZipAndLocations,
} from 'store/modules/persons/components'

import { hasError } from 'modules/redux-form-utils'

// hixme-ui
import Button from '@hixme-ui/button'
import Checkbox from '@hixme-ui/checkbox'
import Container from '@hixme-ui/container'
import FormGroup from '@hixme-ui/form-group'
import Icon from '@hixme-ui/icon'
import Input from '@hixme-ui/input'
import Label from '@hixme-ui/label'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'
import Toggle from '@hixme-ui/toggle'
import { FormatDate } from '@hixme-ui/dates'

import {
  ROLE_EMPLOYEE,
  ROLE_PLATFORM_HIXME_ADMIN,
  ROLE_CLIENT_ADMIN,
  ROLE_BROKER,
} from '@hixme/role-policy'

// project
import { ContributionGroupsContainer } from './containers'
import {
  FormItem,
  GenderRadioInput,
  PhoneNumberInput,
  SocialSecurityInput,
} from './components'
import BooleanRadioInput from './BooleanRadioInput'
import { AddressAutocomplete } from './components/AddressAutocomplete'
import { RadioOption } from './RadioOption'
import useFeatureFlags from 'hooks/useFeatureFlags'

const normalize = (value) => /true/.test(value)

const RoleTypeList = [
  { id: '', name: 'Select' },
  { id: ROLE_EMPLOYEE, name: 'Employee' },
  { id: ROLE_PLATFORM_HIXME_ADMIN, name: 'SureCo Admin' },
  { id: ROLE_CLIENT_ADMIN, name: 'HR Admin' },
  { id: ROLE_BROKER, name: 'Broker' },
]
const PhoneTypeList = [
  { id: '', name: 'Select' },
  { id: 'Mobile', name: 'Mobile' },
  { id: 'Home', name: 'Home' },
  { id: 'Work', name: 'Work' },
  { id: 'Unknown', name: 'Unknown' },
]
const MarriageStatusList = [
  { id: '', name: 'Select' },
  { id: 'Single', name: 'Single' },
  { id: 'Married', name: 'Married' },
  { id: 'Domestic Partner', name: 'Domestic Partner' },
  { id: 'Divorced', name: 'Divorced' },
  { id: 'Widowed', name: 'Widowed' },
  { id: 'Other', name: 'Other' },
]
const EmploymentStatusList = [
  { id: '', name: 'Select' },
  { id: 'Full-Time', name: 'Full-Time' },
  { id: 'Part-Time', name: 'Part-Time' },
  { id: 'Retired', name: 'Retired' },
  { id: 'Terminated', name: 'Terminated' },
  { id: 'Terminated No Cobra', name: 'Terminated No Cobra' },
  { id: 'Leave', name: 'Leave' },
]
const PayrollFreqList = [
  { id: 0, name: 'Select' },
  { id: 12, name: '12 (Monthly)' },
  { id: 24, name: '24 (Semi-monthly)' },
  { id: 26, name: '26 (Bi-weekly)' },
  { id: 52, name: '52 (Weekly)' },
]

const BenefitClassList = [
  { id: '', name: 'Select' },
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
  { id: '7', name: '7' },
  { id: '8', name: '8' },
]

const renderDatesInput = ({ meta, input, disabled }) => (
  <div>
    <Input type="date" disabled={disabled} error={hasError(meta)} {...input} />
    <FieldError meta={meta} />
  </div>
)

renderDatesInput.displayName = 'RenderDatesInput'
renderDatesInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}
renderDatesInput.defaultProps = {
  input: {},
  disabled: false,
}

const renderBEDCheckbox = (field) => (
  <FormGroup>
    <Row center="xs" start="sm">
      <Col xs={12} sm={2}>
        <Container noPadding textCenter>
          <Icon alert warning size={4.5} />
        </Container>
      </Col>
      <Col xs={12} sm={10}>
        <Text thin>
          This worker’s Benefits Effective Date is about to be recorded as{' '}
          {FormatDate.format({ date: field.benefitsEffectiveDate })}. This is no
          longer a valid Benefits Effective Date for most worker scenarios. Are
          you sure you want to proceed?
        </Text>
      </Col>
    </Row>
    <Row center="xs">
      <Container padding="35px 0">
        <Label bold medium>
          <Checkbox {...field.input} />I understand, submit changes anyway
        </Label>
        <FieldError meta={field.meta} />
      </Container>
    </Row>
  </FormGroup>
)

const renderToggle = ({ input, noMargin, ...restProps }) => {
  if (noMargin) {
    return <Toggle withIcons checked={input.value} {...input} {...restProps} />
  }
  return (
    <div style={{ marginTop: '8px' }}>
      <Toggle withIcons checked={input.value} {...input} {...restProps} />
    </div>
  )
}

renderToggle.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  noMargin: PropTypes.bool,
}

renderToggle.defaultProps = {
  input: {},
  noMargin: false,
}

const EmployeeForm = ({
  companyId,
  CompanyName,
  fetchingBenefitEffectiveDates,
  getBenefitsEffectiveDates,
  onAddressOptionSelected,
  onMailAddressOptionSelected,
  onStreetAddressHasChanged,
  isGetting,
  isProcessing,
  onCancel,
  originalHireDate,
  action,
  LoadingView,
  handleSubmit,
  submitFailed,
  showBEDCheckbox,
  personBED,
  formValues,
  isHixmeAdmin,
  isPlatformAdmin,
  isSalaryUnavailable,
}) => {
  const { flags } = useFeatureFlags()
  const isCitizenshipEnabled = flags['temp.ui.citizenship-question']

  // For admins, they don't want address validation blocking edit
  // if they didn't change the address fields (may be incorrect because of old record)
  const onAddressInputChanged = () => {
    onStreetAddressHasChanged(true)
  }

  return isGetting ? (
    <LoadingView />
  ) : (
    <Container noPadding flexColumn width="692px">
      <form onSubmit={handleSubmit}>
        <Container noPadding marginBottom="33px" flex justifyCenter>
          <Title>
            {action === 'edit' ? 'Edit employee profile' : 'Add new employee'}
          </Title>
        </Container>

        <Row middle="xs">
          <Col xs={4}>
            <Field
              name="CompanyEmployeeId"
              label="Company Employee ID"
              component={FormItem}
              disabled={isPlatformAdmin}
            />
          </Col>
          {CompanyName && (
            <Col xs={8}>
              <Container>
                <Title smallest>{CompanyName}</Title>
              </Container>
            </Col>
          )}
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                User Role
              </Label>
              <Field
                name="UserRole"
                disabled
                component={FieldSelect}
                list={RoleTypeList}
              />
            </FormGroup>
          </Col>
        </Row>

        <Container noPadding margin="36px 0 3px">
          <Title smallest>Contact information</Title>
        </Container>
        <Row middle="xs">
          <Col xs={6}>
            <FormGroup>
              <Label medium thin>
                Email address
              </Label>
              <Field
                name="EmailAddress"
                type="email"
                component={FieldInput}
                disabled={action === 'edit'}
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <Container padding="20px 0 0 20px">
              <Text thin>
                An{' '}
                <Text primary bold>
                  email
                </Text>{' '}
                or{' '}
                <Text primary bold>
                  phone
                </Text>{' '}
                is required
              </Text>
            </Container>
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Phone number
              </Label>
              <Field
                name="PhoneNumber"
                component={PhoneNumberInput}
                disabled={isPlatformAdmin}
              />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Phone number type
              </Label>
              <Field
                name="PhoneType"
                component={FieldSelect}
                list={PhoneTypeList}
                disabled={isPlatformAdmin}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Alt phone number
              </Label>
              <Field
                name="AlternatePhoneNumber"
                component={PhoneNumberInput}
                disabled={isPlatformAdmin}
              />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Alt phone number type
              </Label>
              <Field
                name="AlternatePhoneType"
                component={FieldSelect}
                list={PhoneTypeList}
                disabled={isPlatformAdmin}
              />
            </FormGroup>
          </Col>
        </Row>

        <Container noPadding margin="36px 0 3px">
          <Title smallest>Employee information</Title>
        </Container>
        <Row>
          <Col xs={4}>
            <Field
              name="FirstName"
              label="First Name"
              disabled={isPlatformAdmin}
              component={FormItem}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="LastName"
              label="Last name"
              disabled={isPlatformAdmin}
              component={FormItem}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Date of birth
              </Label>
              <Field
                name="DateOfBirth"
                type="date"
                disabled={isPlatformAdmin}
                component={FieldInput}
              />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Social security
              </Label>
              <Field
                disabled={isPlatformAdmin}
                name="SSN"
                component={SocialSecurityInput}
              />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Gender
              </Label>
              <Field
                disabled={isPlatformAdmin}
                name="Gender"
                component={GenderRadioInput}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Disability
              </Label>
              <Field
                disabled={isPlatformAdmin}
                name="IsDisabled"
                normalize={normalize}
                component={BooleanRadioInput}
              />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Medicare Eligible
              </Label>
              <Field
                disabled={isPlatformAdmin}
                name="IsMedicareEligible"
                normalize={normalize}
                component={BooleanRadioInput}
              />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Already Enrolled in Medicare
              </Label>
              <Field
                disabled={isPlatformAdmin}
                name="IsMedicareAlreadyCovered"
                normalize={normalize}
                component={BooleanRadioInput}
              />
            </FormGroup>
          </Col>
        </Row>

        {isCitizenshipEnabled && (
          <>
            <Row>
              <Col xs={12} sm={12}>
                <FormGroup>
                  <Label medium thin>
                    U.S. citizenship status
                  </Label>
                  <Field
                    disabled={isPlatformAdmin}
                    name="Citizenship"
                    options={[
                      { label: 'U.S. citizen', value: 'us_citizen' },
                      {
                        label: 'Lawfully present immigrant',
                        value: 'legal_immigrant',
                      },
                      { label: 'Other', value: 'other' },
                    ]}
                    component={RadioOption}
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12}>
                <FormGroup>
                  <Label medium thin>
                    Are you currently enrolled in an Individual Health Plan that
                    is not managed by SureCo?
                  </Label>
                  <Field
                    disabled={isPlatformAdmin}
                    name="ExchangePlan"
                    component={BooleanRadioInput}
                    normalize={normalize}
                  />
                </FormGroup>
              </Col>
            </Row>

            {formValues?.ExchangePlan ? (
              <Row>
                <Col xs={12} sm={12}>
                  <FormGroup>
                    <Label medium thin>
                      Are you currently receiving a premium tax credit or
                      subsidy for this plan?
                    </Label>
                    <Field
                      disabled={isPlatformAdmin}
                      name="PremiumTaxCredit"
                      component={BooleanRadioInput}
                      normalize={normalize}
                    />
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
          </>
        )}

        <Container noPadding margin="36px 0 3px">
          <Title smallest>Residential</Title>
        </Container>

        <Row>
          <Col xs={4}>
            <Field
              name="StreetAddress"
              label="Street address"
              component={AddressAutocomplete}
              // custom props for AutoSuggest used in AddressAutocomplete
              dropdownProps={{ disabled: isPlatformAdmin }}
              labelParser={(entry) => `${entry.premise} ${entry.street}`}
              render={(entry) => entry.fullAddress}
              optionKey={(entry) => entry.fullAddress}
              onSelect={onAddressOptionSelected}
              onChange={onAddressInputChanged}
            />
            <Field
              name="StreetAddressValidationResult"
              component={({ input }) => <input {...input} type="hidden" />}
            />
            <Field
              name="StreetInputHasChanged"
              component={({ input }) => <input {...input} type="hidden" />}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="StreetAddressExt"
              label="Apt/suite"
              component={FormItem}
              disabled={isPlatformAdmin}
              onChange={onAddressInputChanged}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="City"
              label="City"
              component={FormItem}
              disabled={isPlatformAdmin}
              onChange={onAddressInputChanged}
            />
          </Col>
        </Row>

        <Fields
          names={['PostalCode', 'County']}
          component={RenderZipAndLocations}
          disabled={isPlatformAdmin}
          onCustomChange={onAddressInputChanged}
        />

        <Row>
          <Field
            name="MailingAddressSameAsResidential"
            component={FieldCheckbox}
            text="Mailing address same as residential"
            value={formValues?.MailingAddressSameAsResidential ?? true}
            disabled={isPlatformAdmin}
          />
        </Row>

        {!formValues?.MailingAddressSameAsResidential && (
          <>
            <Container noPadding margin="36px 0 3px">
              <Title smallest>Mailing</Title>
            </Container>

            <Row>
              <Col xs={4}>
                <Field
                  name="MailingStreetAddress"
                  label="Street address"
                  component={AddressAutocomplete}
                  // custom props for AutoSuggest used in AddressAutocomplete
                  labelParser={(entry) =>
                    entry.street
                      ? `${entry.premise} ${entry.street}`
                      : entry.addressLine1
                  }
                  render={(entry) => entry.fullAddress}
                  optionKey={(entry) => entry.fullAddress}
                  onSelect={onMailAddressOptionSelected}
                  excludePOBox={false}
                  dropdownProps={{ disabled: isPlatformAdmin }}
                />
                <Field
                  name="MailingStreetAddressValidationResult"
                  component={({ input }) => <input {...input} type="hidden" />}
                />
              </Col>
              <Col xs={4}>
                <Field
                  name="MailingStreetAddressExt"
                  label="Apt/suite"
                  component={FormItem}
                  disabled={isPlatformAdmin}
                />
              </Col>
              <Col xs={4}>
                <Field
                  name="MailingCity"
                  label="City"
                  component={FormItem}
                  disabled={isPlatformAdmin}
                />
              </Col>
            </Row>

            <Fields
              names={['MailingPostalCode', 'MailingCounty']}
              component={RenderMailingZipAndLocations}
              disabled={isPlatformAdmin}
            />
          </>
        )}

        <Container noPadding margin="36px 0 3px">
          <Title smallest>Additional information</Title>
        </Container>

        <Row>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Employment status
              </Label>
              <Field
                name="EmploymentStatus"
                list={EmploymentStatusList}
                component={FieldSelect}
                disabled={isPlatformAdmin}
              />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Hire date
              </Label>
              <Field
                name="HireDate"
                type="date"
                onChange={isHixmeAdmin ? () => {} : getBenefitsEffectiveDates}
                component={FieldInput}
                disabled={isPlatformAdmin}
              />
            </FormGroup>
          </Col>
          {originalHireDate && (
            <Col xs={4}>
              <FormGroup>
                <Label medium thin>
                  Original Hire date
                </Label>
                <Text div medium style={{ padding: '10px 0' }}>
                  {originalHireDate}
                </Text>
              </FormGroup>
            </Col>
          )}
          <Col xs={(!originalHireDate && 4) || 12}>
            <FormGroup>
              <Label medium thin>
                Job title
              </Label>
              <Field
                name="JobTitle"
                disabled={isPlatformAdmin}
                component={FieldInput}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            {!isSalaryUnavailable && (
              <FormGroup>
                <Label medium thin>
                  Annual pay
                </Label>
                <Field
                  name="Salary"
                  type="number"
                  placeholder="$0.00"
                  component={FieldInput}
                  disabled={isPlatformAdmin}
                />
              </FormGroup>
            )}
          </Col>
          <Col xs={4}>
            <FormGroup style={{ margin: '0 20px' }}>
              <Label medium thin>
                Salaried employee?
              </Label>
              <Field
                name="IsExempt"
                truthyText="Yes"
                falsyText="No"
                component={renderToggle}
                parse={(value) => !!value}
                disabled={isPlatformAdmin}
              />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Payroll Frequency
              </Label>
              <Field
                name="PayrollCycle"
                list={PayrollFreqList}
                component={FieldSelect}
                normalize={(value) => value && Number(value)}
                disabled={isPlatformAdmin}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Marriage status
              </Label>
              <Field
                name="MarriageStatus"
                list={MarriageStatusList}
                component={FieldSelect}
              />
            </FormGroup>
          </Col>
          {isHixmeAdmin && (
            <Col xs={2} style={{ margin: '0 0 0 13px' }}>
              <FormGroup>
                <Label medium thin>
                  Smoker?
                </Label>
                <Field
                  name="Smoker"
                  component={renderToggle}
                  parse={(value) => !!value}
                />
              </FormGroup>
            </Col>
          )}
          {isHixmeAdmin && (
            <Col xs={3}>
              <FormGroup>
                <Label medium thin>
                  Benefits eligible?
                </Label>
                <Field
                  name="BenefitsEligible"
                  component={renderToggle}
                  parse={(value) => !!value}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Container
          rounded
          borderColor="1px solid #DBE2E8"
          style={{ background: '#DBE2E8' }}
        >
          <Row>
            {isHixmeAdmin && (
              <Col xs={12}>
                <Button
                  submitting={fetchingBenefitEffectiveDates}
                  mini
                  type="submit"
                  onClick={getBenefitsEffectiveDates}
                >
                  Calculate Dates
                </Button>
              </Col>
            )}

            <Col xs={4}>
              <FormGroup>
                <Label small thin secondary>
                  Benefits effective date
                </Label>
                <Field
                  name="BenefitsEffectiveDate"
                  disabled={!isHixmeAdmin && isPlatformAdmin}
                  component={renderDatesInput}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label small thin secondary>
                  Special enrollment start date
                </Label>
                <Field
                  name="EnrollmentStartDate"
                  disabled={!isHixmeAdmin && isPlatformAdmin}
                  component={renderDatesInput}
                />
              </FormGroup>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Label small thin secondary>
                  Special enrollment end date
                </Label>
                <Field
                  name="EnrollmentEndDate"
                  disabled={!isHixmeAdmin && isPlatformAdmin}
                  component={renderDatesInput}
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
        <Row top="xs">
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Contribution group
              </Label>
              <Field
                name="ContributionGroup"
                companyId={companyId}
                component={ContributionGroupsContainer}
              />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Division code
              </Label>
              <Field name="DivisionCode" component={FieldInput} />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Benefit Class
              </Label>
              <Field
                name="BenefitClass"
                list={BenefitClassList}
                component={FieldSelect}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row top="xs">
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Company Code
              </Label>
              <Field name="CompanyCode" component={FieldInput} />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Department Code
              </Label>
              <Field name="DepartmentCode" component={FieldInput} />
            </FormGroup>
          </Col>
          <Col xs={4}>
            <FormGroup>
              <Label medium thin>
                Department
              </Label>
              <Field name="Department" component={FieldInput} />
            </FormGroup>
          </Col>
        </Row>

        <Container
          padding="0 17px"
          flex
          justifySpaceBetween
          alignCenter
          style={{
            width: '100%',
            height: '56px',
            padding: '0 17px',
            margin: '12px 0 48px',
            background: '#DBE2E8',
            borderRadius: '8px',
          }}
        >
          <Text medium thin>
            Test user?
          </Text>
          <Field
            name="IsTestUser"
            noMargin
            component={renderToggle}
            parse={(value) => !!value}
          />
        </Container>
        {showBEDCheckbox && (
          <Field
            name="BEDCheckbox"
            component={renderBEDCheckbox}
            benefitsEffectiveDate={personBED}
          />
        )}

        <Container noPadding flex justifyCenter alignCenter>
          <Button
            type="button"
            onClick={onCancel}
            secondary
            style={{ width: '80px', margin: '10px' }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            submitting={isProcessing}
            submittingText="Submitting..."
            style={{ margin: '10px' }}
          >
            Submit
          </Button>
        </Container>
      </form>
      <div style={{ marginBottom: '211px' }}>
        {submitFailed && (
          <Container textCenter>
            <Text error>Please fix all errors</Text>
          </Container>
        )}
      </div>
    </Container>
  )
}

EmployeeForm.propTypes = {
  // project
  CompanyName: PropTypes.string.isRequired,
  LoadingView: PropTypes.any.isRequired,
  action: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  fetchingBenefitEffectiveDates: PropTypes.bool.isRequired,
  getBenefitsEffectiveDates: PropTypes.func,
  isGetting: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  originalHireDate: PropTypes.string,
  personBED: PropTypes.string,
  showBEDCheckbox: PropTypes.bool.isRequired,
  formValues: PropTypes.object,
  isHixmeAdmin: PropTypes.bool.isRequired,
  isPlatformAdmin: PropTypes.bool.isRequired,

  // redux-form
  handleSubmit: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool,
}

EmployeeForm.defaultProps = {
  formValues: undefined,
  initialValues: undefined,
  originalHireDate: '',
  personBED: '',
  submitFailed: false,
  isHixmeAdmin: true,
  isPlatformAdmin: false,
  getBenefitsEffectiveDates: () => {},
}

EmployeeForm.displayName = 'EmployeeForm'

export default EmployeeForm
