import { notification } from '@surecompanies/core_components'
import { useMutation, useQuery } from '@tanstack/react-query'
import client from 'enrollment-platform/api/eps.client'

export default function useHrisPayroll(id: string) {
  async function getHrisPayroll(id: string) {
    const { data } = await client.get(`/customers/${id}/configuration/hris`)
    return data
  }

  async function updateHrisPayroll(values: any) {
    try {
      const { data } = await client.put(
        `/customers/${id}/configuration/hris`,
        {...values, eligibilityRules: ''}
      )
      notification.success({
        message: 'HRIS integration updated successfully',
      })
      return data
    } catch (error) {
      notification.error({
        message: 'Failed to update HRIS integration',
      })
      throw error
    }
  }

  const { data, isLoading } = useQuery({
    queryKey: ['hrisPayroll', id],
    queryFn: () => getHrisPayroll(id),
  })

  const {
    mutate: updateHrisIntegrationMutation,
    isPending: isUpdatingHrisIntegration,
  } = useMutation({
    mutationFn: updateHrisPayroll,
    mutationKey: ['updateHrisPayroll', id],
  })

  return {
    data,
    isLoading,
    updateHrisIntegration: updateHrisIntegrationMutation,
    isUpdatingHrisIntegration,
  }
}
