import flattenDeep from 'lodash/flattenDeep'
import { callWaiter } from 'redux-waiter'
import { createErrorNotification } from 'modules/app-core/notification-manager/actions'
import { companyMapSelector } from '../companies/selectors'

import * as personsAPI from './api'
import {
  GET_ALL_PERSONS,
  GET_ALL_PERSONS_BY_COMPANY_ID,
  GET_PERSON,
  GET_FAMILY,
  SEND_WELCOME_EMAIL,
} from './constants'

import t from './actionTypes.js'

export const receivePersonList = (persons) => ({
  type: t.RECEIVE_PERSON_LIST,
  payload: persons,
})

export const receivePerson = (person) => ({
  type: t.RECEIVE_PERSON,
  payload: person,
})

export const updatePerson = (payload) => ({
  type: t.UPDATE_PERSON,
  payload,
})

export const getPersonsByCompanyAPI = (clientPublicKey) => (dispatch) =>
  dispatch(personsAPI.getPersonsByCompanyId(clientPublicKey))
    // load list for company
    .then((list) => {
      dispatch(receivePersonList(list))
      return list
    })
    .catch((error) => {
      dispatch(
        createErrorNotification(
          'We had trouble getting workers for this company. Please reload the page.'
        )
      )
      throw error
    })

export const getPersonsByCompanyId = (clientPublicKey) => (dispatch) =>
  dispatch(
    callWaiter(GET_ALL_PERSONS_BY_COMPANY_ID, {
      requestCreator: () =>
        // get all Persons for Company
        dispatch(getPersonsByCompanyAPI(clientPublicKey)),
    })
  )

export const getPersonsFromAllCompanies = () => (dispatch, getState) => {
  const companiesMap = companyMapSelector(getState())
  const companyIds = Object.keys(companiesMap)

  return Promise.all(
    companyIds.map((id) => dispatch(getPersonsByCompanyAPI(id)))
  )
    .then((data) => dispatch(receivePersonList(flattenDeep(data))))
    .catch((error) => {
      dispatch(
        createErrorNotification(
          'We had trouble getting workers for this company. Please reload the page.'
        )
      )
      throw error
    })
}

export const getFamilyByEmployeeId = (id) => (dispatch) =>
  dispatch(
    callWaiter(`${GET_FAMILY}:${id}`, {
      requestCreator: () =>
        personsAPI
          .getFamilyByEmployeeId(id)
          .then((person) => {
            dispatch(receivePersonList(person.Persons))
            return person
          })
          .catch((error) => {
            dispatch(
              createErrorNotification(
                'We had trouble getting the family. Please reload the page.'
              )
            )
            throw error
          }),
    })
  )

export const getPersonById = (personId) => (dispatch) =>
  dispatch(
    callWaiter(`${GET_PERSON}:${personId}`, {
      requestCreator: () =>
        personsAPI
          .getPersonById(personId)
          .then((person) => {
            dispatch(receivePerson(person))
            return person
          })
          .catch((e) => {
            dispatch(
              createErrorNotification(
                'We had trouble getting this person. Please try again.'
              )
            )
            throw e
          }),
    })
  )

export const getAllPersons = () => (dispatch) =>
  dispatch(
    callWaiter(GET_ALL_PERSONS, {
      requestCreator: () =>
        personsAPI
          .getAllPersons()
          .then((response) => {
            dispatch(receivePersonList(response.Persons))
            return response
          })
          .catch((e) => {
            dispatch(
              createErrorNotification(
                'We had trouble loading persons. Please reload the page'
              )
            )
            throw e
          }),
    })
  )

export const sendWelcomeEmail = (ids) => (dispatch) =>
  dispatch(
    callWaiter(SEND_WELCOME_EMAIL, {
      requestCreator: () =>
        personsAPI
          .sendWelcomeEmail(Array.isArray(ids) ? ids : [ids])
          .then((response) => {
            const payload = Object.entries(response).reduce(
              (prev, [key, value]) => ({
                ...prev,
                [key]: {
                  InvitedDate: value,
                },
              }),
              {}
            )
            dispatch(updatePerson(payload))
            return response
          })
          .catch((e) => {
            dispatch(
              createErrorNotification(
                'We had trouble sending welcome email. Please try again'
              )
            )
            throw e
          }),
    })
  )
