import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import { Stack, Typography } from '@sureco/design-system'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { EnrollmentSchema } from '../enrollment.schema'
import RHFDatePicker from 'enrollment-platform/components/form/hookform/DatePicker/RHFDatePicker'
import RHFInput from 'enrollment-platform/components/form/hookform/Input/RHFInput'
import RHFRangePicker from 'enrollment-platform/components/form/hookform/RangePicker/RangePicker'

export type FormRef = {
  submit: () => void
}

type EnrollmentFormProps = {
  onFormSubmit?: (data: any) => void
  initialValues?: Record<string, any>
  loading?: boolean
}

type FormData = z.infer<typeof EnrollmentSchema>

const EnrollmentForm = forwardRef<FormRef, EnrollmentFormProps>(({ onFormSubmit, initialValues }, ref) => {
  const { handleSubmit, control } = useForm<FormData>({
    defaultValues: initialValues ?? {},
    resolver: zodResolver(EnrollmentSchema),
  })
  const formRef = useRef<HTMLFormElement>(null)

  useImperativeHandle(ref, () => ({
    submit: () => {
      formRef?.current?.requestSubmit()
    },
  }))

  return (
    <Stack space={20}>
        <form
          onSubmit={handleSubmit((data) => onFormSubmit?.(data))}
          ref={formRef}
        >
            <Stack direction="row" align="center" space={50}>
                <Stack direction="row" align="center" space={20}>
                    <RHFRangePicker
                        control={control}
                        isBeforeTodayAvailable={false}
                        name="enrollmentDates"
                        placeholder={['Select Enrollment Start Date', 'Select Enrollment End Date']}
                        label="Open Enrollment Period Dates"
                />
                </Stack>
                <RHFDatePicker
                        control={control}
                        name="benefitEffectiveDate"
                        placeholder="Select Benefit Effective Date"
                        label="Benefit Effective Date"
                    />
                    <Stack direction="row" align="center" space={10}>
                        <RHFInput
                            control={control}
                            name="benefitEffectivePolicy"
                            placeholder="60"
                            type='number'
                            min={0}
                            label="Benefit Effective Policy"
                        />
                        <Typography style={{transform: 'translateY(10px)'}}>days</Typography>
                    </Stack>
            </Stack>
        </form>
    </Stack>
  )
})

export default EnrollmentForm
