/* eslint-disable import/prefer-default-export */
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('userSession')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}
