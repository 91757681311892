import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Input from '@hixme-ui/input'

// project
import { hasError } from 'modules/redux-form-utils'
import { FieldError } from 'components/ReduxFormComponents'

const FieldInput = (props) => {
  const { input, meta, ...restProps } = props
  return (
    <div>
      <Input error={hasError(meta)} {...input} {...restProps} />
      <FieldError meta={meta} />
    </div>
  )
}
FieldInput.displayName = 'FieldInput'
FieldInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export default FieldInput
