import React from 'react'
import PropTypes from 'prop-types'
import { MrModal } from '@hixme/modal'

// hixme-ui
import Title from '@hixme-ui/title'
import Container from '@hixme-ui/container'
import { FormGroup, Select, Input, Label, Checkbox } from '@hixme-ui/forms'

// project
import { FieldComponent } from 'components/ReduxFormComponents'

const editInputValue = (value) => (value ? value.replace('-', '') : '')

const styles = {
  disabledInputs: {
    borderStyle: 'none',
    background: 'rgb(247, 247, 247)',
  },
  hiddenInput: {
    TerminationDate: {
      display: 'none',
      position: 'absolute',
      top: '39px',
      zIndex: '-11',
    },
    CobraLetterPostDate: {
      display: 'none',
      position: 'absolute',
      top: '39px',
      zIndex: '-11',
    },
  },
}

const showCobraInfo = (formValues) => {
  const values = ['Terminated', 'Part-Time', 'Non-paid LOA']

  return values.includes(formValues.EmploymentStatus)
}

const StopCoverage = ({
  editDate,
  formValues,
  getEffectiveDates,
  handleSubmit,
  initialValues,
  isProcessing,
  onEmployeeStateChange,
  resetCobraInfo,
}) => (
  <MrModal
    dialog
    confirmLabel="Save"
    confirmSubmittingText="Saving..."
    confirmProcessing={isProcessing}
    confirmHandler={handleSubmit}
  >
    <Container flex noPadding justifyContent="center" margin="0 0 50px 0">
      <Title>Stop Coverage</Title>
    </Container>
    <Container flex noPadding justifyContent="flex-start" margin="0 145px 50px">
      <div style={{ width: '100%' }}>
        <Container flex noPadding margin="52px 0 0 0">
          <Container flex padding="10px" basis="200px">
            <FormGroup style={{ width: '100%' }}>
              <FieldComponent
                name="EmploymentStatus"
                label="Employment Status"
                inputComponent={Select}
                onChange={onEmployeeStateChange}
                list={[
                  { id: '', name: '' },
                  { id: 'Terminated', name: 'Terminated' },
                  { id: 'Part-Time', name: 'Part-Time' },
                  { id: 'Non-paid LOA', name: 'Non-paid LOA' },
                ]}
              />
            </FormGroup>
          </Container>
          <Container flex noPadding margin="10px" basis="200px">
            <FormGroup style={{ width: '100%' }}>
              <FieldComponent
                name="CobraStatus"
                label="Cobra Status"
                inputComponent={Select}
                list={[
                  { id: '', name: '' },
                  { id: 'Pending', name: 'Pending' },
                  { id: 'Elected', name: 'Elected' },
                  { id: 'Declined', name: 'Declined' },
                  { id: 'Ineligible', name: 'Ineligible' },
                  {
                    id: 'Canceled - Non-payment',
                    name: 'Canceled - Non-payment',
                  },
                ]}
                disabled={!formValues.EmploymentStatus}
                onChange={resetCobraInfo}
              />
            </FormGroup>
          </Container>
        </Container>
        {formValues.CobraStatus === 'Elected' && (
          <Container flex noPadding>
            <Container flex noPadding margin="10px">
              <FormGroup style={{ width: '100%' }}>
                <Label>Which benefits will continue under COBRA?</Label>
                <div
                  style={{
                    position: 'relative',
                    marginTop: '-50px',
                    zIndex: '-1',
                  }}
                >
                  <FieldComponent
                    name="CobraContinueBenefits"
                    style={{
                      border: 'none',
                      color: 'transparent',
                    }}
                    disabled
                  />
                </div>
                {['Medical', 'Vision', 'Dental', 'FSA'].map((benefit, i) => (
                  <FieldComponent
                    key={`benefit-type-${i + 10}`}
                    name={`CobraContinueBenefits[${benefit}]`}
                    inputComponent={Checkbox}
                    defaultChecked={
                      formValues.CobraContinueBenefits
                        ? formValues.CobraContinueBenefits[benefit]
                        : false
                    }
                    text={benefit}
                  />
                ))}
              </FormGroup>
            </Container>
          </Container>
        )}
        {showCobraInfo(formValues) && (
          <div>
            <Container flex padding="10px">
              <Title smaller>Cobra Information</Title>
            </Container>
            <Container flex noPadding>
              <Container flex padding="10px" basis="200px">
                <FormGroup style={{ width: '100%', position: 'relative' }}>
                  <Label>Last Day Worked</Label>
                  <Input
                    name="TerminationDate"
                    date
                    onChange={getEffectiveDates}
                    value={editInputValue(initialValues.TerminationDate)}
                  />
                  <FieldComponent
                    name="TerminationDate"
                    style={styles.hiddenInput.TerminationDate}
                    bindFocus
                    tabIndex="-1"
                    onFocus={editDate}
                  />
                </FormGroup>
              </Container>
              <Container flex padding="10px" basis="200px">
                <FormGroup style={{ width: '100%', position: 'relative' }}>
                  <Label style={{ whiteSpace: 'nowrap' }}>
                    Employer Contribution End
                  </Label>
                  <FieldComponent
                    name="PreviousCoverageEndDate"
                    date
                    style={styles.disabledInputs}
                    disabled
                  />
                </FormGroup>
              </Container>
              {formValues.CobraStatus !== 'Ineligible' && (
                <Container flex noPadding margin="10px" basis="200px">
                  <FormGroup style={{ width: '100%', position: 'relative' }}>
                    <Label>Letter Posted On</Label>
                    <Input
                      name="CobraLetterPostDate"
                      date
                      onChange={getEffectiveDates}
                      value={editInputValue(initialValues.CobraLetterPostDate)}
                    />
                    <FieldComponent
                      name="CobraLetterPostDate"
                      style={styles.hiddenInput.CobraLetterPostDate}
                      bindFocus
                      tabIndex="-1"
                      onFocus={editDate}
                    />
                  </FormGroup>
                </Container>
              )}
            </Container>
            {formValues.CobraStatus !== 'Ineligible' && (
              <Container flex noPadding>
                <Container flex noPadding margin="10px" basis="200px">
                  <FormGroup style={{ width: '100%' }}>
                    <FieldComponent
                      name="CobraBenefitsEligibleStartDate"
                      label="COBRA election starts"
                      date
                      style={styles.disabledInputs}
                      disabled
                    />
                  </FormGroup>
                </Container>
                <Container flex noPadding margin="10px" basis="200px">
                  <FormGroup style={{ width: '100%' }}>
                    <FieldComponent
                      name="CobraBenefitsEligibleEndDate"
                      label="COBRA election ends"
                      date
                      style={styles.disabledInputs}
                      disabled
                    />
                  </FormGroup>
                </Container>
                <Container flex noPadding margin="10px" basis="200px">
                  <FormGroup style={{ width: '100%' }}>
                    <FieldComponent
                      name="CobraBenefitsEffectiveDate"
                      label="COBRA benefits begins"
                      date
                      style={styles.disabledInputs}
                      disabled
                    />
                  </FormGroup>
                </Container>
              </Container>
            )}
          </div>
        )}
      </div>
    </Container>
  </MrModal>
)

StopCoverage.displayName = 'StopCoverage'

StopCoverage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  resetCobraInfo: PropTypes.func.isRequired,
  getEffectiveDates: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  editDate: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  onEmployeeStateChange: PropTypes.func.isRequired,
}

export default StopCoverage
