import { notification } from '@surecompanies/core_components'
import { useMutation } from '@tanstack/react-query'
import client from 'enrollment-platform/api/eps.client'
import { BatchType } from 'enrollment-platform/pages/PlanManagement/types'

const batchTypeToEndpoint = {
    [BatchType.PLANS_AND_RATES]: 'plans-and-rates',
    [BatchType.PLAN_BENEFITS]: 'plan-benefits',
    [BatchType.SERVICE_AREAS]: 'service-areas',
    [BatchType.SMOKER_RATES]: 'smoker-rates',
}

// This function is used to download the CSV file from the API
export default function useDownloadCsv({ batchType, planYear, state }: { batchType?: BatchType, planYear: number; state: string }): () => void {
    const downloadCsv = async (): Promise<void> => {
        try {
            const endpoint = batchTypeToEndpoint[batchType ?? BatchType.PLANS_AND_RATES]
            const url = state ? `/plan-management/${endpoint}/download/${planYear}/${state}` : `/plan-management/${endpoint}/download/${planYear}`
            const response = await client.get(url, {
                responseType: 'blob',
            })

            const urlObject = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = urlObject
            link.setAttribute('download', `${endpoint}-${planYear}${state ? `-${state}` : ''}.csv`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (error) {
            notification.error({
                message: 'Something went wrong downloading the CSV',
            })
            throw error
        }
    }

    const { mutate } = useMutation<void, Error, void>({
        mutationFn: downloadCsv,
        mutationKey: ['downloadCsv', planYear, state],
    })

    return mutate
}
