import { gql } from '@apollo/client'

export const GET_ADDRESS_AUTOCOMPLETE = gql`
  query AddressAutoComplete($address: String!, $filters: AddressGraph_AutoCompleteRequestFilters) {
    addressAutoComplete(address: $address, filters: $filters) {
      fullAddress
      street
      addressLine1
      addressLine2
      premise
      city
      stateCode
      county
      zipCode
      suiteOrAptNumber
    }
  }
`

export const GET_ADDRESS_VALIDATION = gql`
  query AddressValidate($input: AddressGraph_AddressValidateRequest!) {
    addressValidate(input: $input) {
      result
      reasons
    }
  }
`
