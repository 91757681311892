import React from 'react'
import PropTypes from 'prop-types'

import { MrModal } from '@hixme/modal'

// hixme-ui
import Container from '@hixme-ui/container'
import ContentContainer from '@hixme-ui/content-container'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'

import ReceiptLineItemFormContainer from './containers/ReceiptLineItemFormContainer'
import ReceiptLineItemHeaderContainer from './containers/ReceiptLineItemHeaderContainer'
import ReceiptLineItemFooterContainer from './containers/ReceiptLineItemFooterContainer'
import ReceiptLineItemsContainer from './containers/ReceiptLineItemsContainer'
import YearlyAllowanceBarContainer from './containers/YearlyAllowanceBarContainer'
import { LineItemsContainer } from './style'

// project
// module

const ReceiptItemsModal = ({ reimbursement }) => (
  <MrModal>
    <ContentContainer contentMargin="20px auto 0" contentWidth="1024px">
      <Container flex direction="column" alignItems="center">
        <Title>Reimbursement</Title>
        <Container
          flex
          noPadding
          margin="20px 0"
          justifyContent="flex-start"
          width="100%"
        >
          <Text jumbo boldest>
            {reimbursement.name}
          </Text>
        </Container>
        <YearlyAllowanceBarContainer />
        <ReceiptLineItemHeaderContainer />
        <Container flex noPadding justifyContent="flex-start" width="100%">
          <Text large>Line Items</Text>
        </Container>
        <LineItemsContainer>
          <ReceiptLineItemFormContainer />
        </LineItemsContainer>
        <ReceiptLineItemsContainer />
        <ReceiptLineItemFooterContainer />
      </Container>
    </ContentContainer>
  </MrModal>
)

ReceiptItemsModal.propTypes = {
  reimbursement: PropTypes.object.isRequired,
}

export default ReceiptItemsModal
