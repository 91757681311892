import React from 'react'
import { z } from 'zod'
import { Stack, TextColors, Typography } from '@sureco/design-system'
import RHFToggle from 'enrollment-platform/components/form/hookform/Toogle/RHFToogle'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { IntegrationSchema } from './integration.schema'
import RHFInput from 'enrollment-platform/components/form/hookform/Input/RHFInput'
import { Button } from '@surecompanies/core_components'

type FormData = z.infer<typeof IntegrationSchema>
type IntegrationFormProps = {
  initialValues: any
  isUpdatingHrisIntegration: boolean
  onFormSubmit: (data: FormData) => void
}

export default function IntegrationForm({ initialValues, isUpdatingHrisIntegration, onFormSubmit }: IntegrationFormProps) {
  const { control, handleSubmit, watch } = useForm<FormData>({
    defaultValues: initialValues ?? {},
    resolver: zodResolver(IntegrationSchema),
      })

    const onSubmit = (data: FormData) => {
      onFormSubmit(data)
    }

    const isCensusSyncEnabled = watch('isCensusSyncEnabled')
    const isHRISNotificationDisabled = watch('isHRISNotificationDisabled')
    const automaticStopCoverage = watch('automaticStopCoverage')

  return (
      <form>
        <Stack space={20}>
          <Stack direction="row" align="center" justify="space-between">
            <Stack direction="row" align="center" space={10}>
              <RHFToggle name="isCensusSyncEnabled" control={control} />
            <Typography size={16} color='#003146'>API Enabled <Typography color={isCensusSyncEnabled ? TextColors.primary : TextColors.error} size={16} bold>{isCensusSyncEnabled ? 'ON' : 'OFF'}</Typography></Typography>
          </Stack>
          <Stack direction="row" align="center" space={10}>
            <RHFToggle name="isHRISNotificationDisabled" control={control} />
            <Typography size={16} color='#003146'>Notification Disabled <Typography color={isHRISNotificationDisabled ? TextColors.primary : TextColors.error} size={16} bold>{isHRISNotificationDisabled ? 'ON' : 'OFF'}</Typography></Typography>
          </Stack>
          <Stack direction="row" align="center" space={10}>
            <RHFToggle name="automaticStopCoverage" control={control} />
            <Typography size={16} color='#003146'>Automatic Stop Coverage <Typography color={automaticStopCoverage ? TextColors.primary : TextColors.error} size={16} bold>{automaticStopCoverage ? 'ON' : 'OFF'}</Typography></Typography>
          </Stack>
        </Stack>
        <Stack direction="row" align="center" space={20}>
          {/* @ts-ignore */}
          <RHFInput name="webHookUrl" control={control} label="Web Hook URL" style={{ fieldSizing: 'content' }} />
          <Button type="primary" onClick={handleSubmit(onSubmit)} style={{transform: 'translateY(10px)'}} loading={isUpdatingHrisIntegration}>Save</Button>

        </Stack>
        </Stack>
      </form>
  )
}
