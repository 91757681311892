import { clearModal } from '@hixme/modal'
import { createErrorNotification } from 'modules/app-core/notification-manager/actions'

// project
import t from './actionTypes'
import { selectors } from '.'
import {
  selectors as expenseSelectors,
  actions as expenseActions,
} from '../../../../modules/expense-requests-search'

import { createLineItems, deleteLineItem, updateExpense } from './api'
import { getIsDescriptionAvailable } from '../../utils/Status'

export const updateStatus = (status) => ({
  type: t.UPDATE_STATUS,
  payload: status,
})

export const updateReceipts = (receipts) => ({
  type: t.UPDATE_RECEIPTS,
  payload: receipts,
})

export const updateDescription = (description) => ({
  type: t.UPDATE_DESCRIPTION,
  payload: description,
})

export const addLineItem = (lineItem) => ({
  type: t.ADD_LINE_ITEM,
  payload: lineItem,
})

export const removeLineItemReimbursement = (lineItem) => ({
  type: t.REMOVE_LINE_ITEM,
  payload: lineItem,
})

export const clearLineItems = () => ({
  type: t.CLEAR_LINE_ITEMS,
})

export const reset = () => ({
  type: t.RESET,
})

export const addLoading = (id, value = true) => ({
  type: t.ADD_LOADING,
  payload: {
    id,
    value,
  },
})

export const updateReimbursement = (status) => async (dispatch, getState) => {
  try {
    const isDescriptionAvailable = getIsDescriptionAvailable(status)
    const description = selectors.getDescription(getState())
    const expense = expenseSelectors.getExpense(getState())
    await dispatch(
      updateExpense(
        {
          status,
          ...(isDescriptionAvailable && { message: description }),
        },
        {
          reimbursementId: expense.reimbursement.id,
          employeeId: expense.reimbursement.employeePublicKey,
          clientId: expense.reimbursement.clientPublicKey,
        }
      )
    )
  } catch (err) {
    dispatch(createErrorNotification(`There was a problem updating the status`))
  }
}

export const closeModal = () => async (dispatch, getState) => {
  try {
    const expense = expenseSelectors.getExpense(getState())
    dispatch(clearLineItems())
    dispatch(clearModal())
    dispatch(expenseActions.getExpenseRequest(expense.reimbursement.id))
  } catch (err) {
    dispatch(createErrorNotification(`There was a problem`))
  }
}

export const removeLineItem = (item) => async (dispatch, getState) => {
  try {
    const expense = expenseSelectors.getExpense(getState())
    if (!item.local) {
      dispatch(addLoading(item.id))
      await dispatch(
        deleteLineItem({
          reimbursementId: expense.reimbursement.id,
          receiptId: item.receiptId,
          lineItemId: item.id,
        })
      )
    }
    dispatch(addLoading(item.id, false))
    dispatch(removeLineItemReimbursement(item))
  } catch (err) {
    dispatch(addLoading(item.id, false))
    dispatch(
      createErrorNotification(`There was a problem deleting your line item`)
    )
  }
}

export const createLineItemsReimbursement = (status) => async (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    const lineItems = selectors.getLineItems(getState())
    const expense = expenseSelectors.getExpense(getState())
    let promises = []
    if (lineItems && lineItems.length) {
      promises = lineItems.map(async (lineItem) => {
        const { receipt, ...rest } = lineItem
        const items = rest.items.filter((item) => item.local)
        if (items.length) {
          return dispatch(
            createLineItems(items, {
              reimbursementId: expense.reimbursement.id,
              receiptId: receipt,
            })
          )
        }
        return false
      })
    }
    Promise.all(promises)
      .then(async () => {
        if (status !== expense.reimbursement.status) {
          await dispatch(updateReimbursement(status))
        }

        dispatch(closeModal())
        resolve()
      })
      .catch((error) => {
        dispatch(
          createErrorNotification(error.message || `There was a problem creating your items`)
        )
        reject(error.message)
      })
  })
