import React from 'react'
import PropTypes from 'prop-types'
import { FieldSelect } from 'components/ReduxFormComponents'
import payrollCycle from 'data/payroll-cycle'

// prepend an empty option to the metals list
const select = { id: '-1', name: 'Select' }

export const PayrollCycleFormGroup = (props) => (
  <PayrollCycleDropdown {...props} />
)

PayrollCycleFormGroup.propTypes = {
  label: PropTypes.string,
}

PayrollCycleFormGroup.defaultProps = {
  label: 'Month',
}

export const PayrollCycleDropdown = (props) => (
  <FieldSelect {...props} list={[select, ...payrollCycle]} />
)

export default { PayrollCycleDropdown, PayrollCycleFormGroup }
