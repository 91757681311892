import { notification } from '@surecompanies/core_components'
import { useMutation } from '@tanstack/react-query'
import client from 'enrollment-platform/api/eps.client'

export interface PlansAndRatesDiffDataRow {
    id: number;
    rowNumber: number;
    hiosPlanId: string;
    carrier: string;
    planName: string;
    planType: string;
    planYear: string;
    baseRate: string;
    level: string;
    state: string;
    isForSale: string;
    hsa: string;
    diffStatus: string;
}

interface UseAnalyzeDiffProps {
    importBatchId: string;
    batchType: string;
}

interface UseAnalyzeDiffResult {
    mutate: () => void;
    data: PlansAndRatesDiffDataRow[] | undefined;
    isLoading: boolean;
    error: any;
}

interface DiffResponse {
    rows: PlansAndRatesDiffDataRow[];
}

// This function is used to get the diff data from the API
export default function usePlansAndRatesDiff({ importBatchId, batchType }: UseAnalyzeDiffProps): UseAnalyzeDiffResult {
    const getDiff = async (): Promise<PlansAndRatesDiffDataRow[]> => {
        try {
            const result = await client.post<DiffResponse>(`/plan-management/${batchType}/diff`, {
                importBatchId,
            })
            return result.data.rows
        } catch (error) {
            notification.error({
                message: 'Something went wrong getting the diff',
            })
            throw error
        }
    }

    const { mutate, data, status, error } = useMutation<PlansAndRatesDiffDataRow[], Error, void>({
        mutationFn: getDiff,
        mutationKey: ['generatediff', importBatchId],
    })

    return {
        mutate,
        data,
        isLoading: status === 'pending',
        error,
    }
}
