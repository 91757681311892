import React from 'react'
import { bool, func, array } from 'prop-types'
import { isEmpty } from 'lodash'

// hixme-ui
import CardDrawer from '@hixme-ui/card-drawer'
import Container from '@hixme-ui/container'

import YearlyAllowanceBarHeader from './YearlyAllowanceBarHeader/YearlyAllowanceBarHeader'
import YearlyAllowanceBarBody from './YearlyAllowanceBarBody/YearlyAllowanceBarBody'

const YearlyAllowanceBar = ({
  collapsible,
  hasOlderReimbursements,
  header,
  body,
  open,
  onClickView,
  onViewOlder,
}) => {
  if (isEmpty(header)) {
    return null
  }
  return (
    <Container width="100%" noPadding>
      <CardDrawer
        cardHeader={<YearlyAllowanceBarHeader header={header} />}
        collapsible={collapsible}
        open={open}
      >
        {collapsible && (
          <YearlyAllowanceBarBody
            hasOlderReimbursements={hasOlderReimbursements}
            data={body}
            onClickView={onClickView}
            onViewOlder={onViewOlder}
          />
        )}
      </CardDrawer>
    </Container>
  )
}

YearlyAllowanceBar.propTypes = {
  onViewOlder: func,
  onClickView: func,
  collapsible: bool,
  open: bool,
  body: array,
  hasOlderReimbursements: bool,
  header: array,
}

YearlyAllowanceBar.defaultProps = {
  onClickView: () => {},
  onViewOlder: () => {},
  collapsible: false,
  open: false,
  hasOlderReimbursements: true,
  header: [{}],
  body: [],
}

YearlyAllowanceBar.displayName = 'YearlyAllowanceBar'

export default YearlyAllowanceBar
