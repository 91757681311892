import { CensusFileRowGridData } from '../types/census-file-row-grid-data'
import { CensusFileRowDto } from '../api/dto/census-file-row.dto'

// Update `rowData` with updated data. Return CensusFileRowDto for use in update endpoint.
export const mapGridDataToCensusFileRowDto = (gridDataRow: CensusFileRowGridData): CensusFileRowDto => ({
  rowData: Object.keys(gridDataRow.rowData).reduce((acc, key) => {
    if (key in gridDataRow) acc[key] = gridDataRow[key]
    return acc
  }, {}),
  id: gridDataRow.id,
  censusFileId: gridDataRow.censusFileId,
  clientPublicKey: gridDataRow.clientPublicKey,
  firstName: gridDataRow.FirstName || gridDataRow.firstName,
  lastName: gridDataRow.LastName || gridDataRow.lastName,
  rowNumber: gridDataRow.rowNumber,
  employeeId: gridDataRow.employeeId,
  type: gridDataRow.type,
  status: gridDataRow.status,
})
