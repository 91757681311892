import apiClient from 'enrollment-platform/api/enrollment-platform-client/api-client'
import { CensusFilesResponseDto } from './dto/census-files-response.dto'
import { CensusFilesRequestDto } from './dto/census-files-request.dto'
import { CensusFileRowsRequestDto } from './dto/census-file-rows-request.dto'
import { CensusFileRowsResponseDto } from './dto/census-file-rows-response.dto'
import { CensusFileRowDto } from './dto/census-file-row.dto'
import { UpdateCensusFileRowsResponseDto } from './dto/update-census-file-rows-response.dto'
import { CensusFileRowCountsDto } from './dto/census-file-row-counts.dto'
import { CensusFileRowTypeCountsRequestDto } from './dto/census-file-row-type-counts-request.dto'
import { PatchActionEnum } from './enums/patch-action.enum'
import { CreateMembersResponseDto } from './dto/create-members-response.dto'

export const createCensusFile = async (body: FormData) => {
  const ret = await apiClient.post('/census-files', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return ret.data
}

export const getCensusFiles = async (params?: CensusFilesRequestDto): Promise<CensusFilesResponseDto> => {
  const url = '/census-files'
  const ret = await apiClient.get<CensusFilesResponseDto>(url, { params: params ?? {} })
  return ret.data
}

export const getCensusFileRows = async (params: CensusFileRowsRequestDto): Promise<CensusFileRowsResponseDto> => {
  const { publicKey, ...other } = params
  const url = `census-files/${publicKey}/rows`
  const ret = await apiClient.get<CensusFileRowsResponseDto>(url, {
      params: other,
    },
  )
  return ret.data
}

export const getCensusFileRowTypeCounts = async (params: CensusFileRowTypeCountsRequestDto): Promise<CensusFileRowCountsDto> => {
  const { publicKey, ...other } = params
  const url = `census-files/${publicKey}/rows/type-counts`
  const ret = await apiClient.get<CensusFileRowCountsDto>(url, {
      params: other,
    },
  )
  return ret.data
}

export const updateCensusFileRows = async (rows: CensusFileRowDto[], publicKey: string, action?: PatchActionEnum):
  Promise<UpdateCensusFileRowsResponseDto
  | { data: CreateMembersResponseDto }> => {
  let url = `census-files/${publicKey}/rows`
  if (action) {
    url += `?action=${action}`
  }
  console.log(url)
  const ret = await apiClient.patch(url, {
      params: rows,
    },
  )
  return ret.data
}
