import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import { Input } from '@hixme-ui/forms'
import Text from '@hixme-ui/text'

// project
import { hasError } from 'modules/redux-form-utils'

const propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

const defaultProps = {
  disabled: false,
}

const inputStyles = (isDisabled) => ({
  width: '100%',
  paddingLeft: '35px',
  textAlign: 'right',
  opacity: isDisabled ? '0.5' : undefined,
})
const dollarSignStyle = {
  position: 'absolute',
  marginTop: '11px',
  left: '0',
  width: '30px',
}

const CurrencyInput = ({ input, disabled, meta }) => (
  <div style={{ position: 'relative' }}>
    <Input
      type="number"
      min="0"
      step="any"
      {...input}
      error={hasError(meta)}
      style={inputStyles(disabled)}
      disabled={disabled}
    />
    <Text right light={disabled} fontSize="16px" style={dollarSignStyle}>
      $
    </Text>
  </div>
)

CurrencyInput.propTypes = propTypes
CurrencyInput.defaultProps = defaultProps

export default CurrencyInput
