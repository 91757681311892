/* eslint-disable no-param-reassign */
import { createSelector } from 'reselect'

// project
import { NAME } from './constants'

export const isInitialized = createSelector(
  (state) => state[NAME],
  (module) => module.initialized
)

export const getExpensesList = createSelector(
  (state) => state[NAME],
  (module) => module.list
)

export const getExpensesCount = createSelector(
  (state) => state[NAME],
  (module) => Number(module.count)
)

export const getPagination = createSelector(
  (state) => state[NAME],
  (module) => module.pagination
)

export const getFilters = createSelector(
  (state) => state[NAME].filters,
  (filters) =>
    Object.keys(filters).reduce((prev, curr) => {
      if (!filters[curr]) return prev
      return { ...prev, [curr]: filters[curr] }
    }, {})
)

export const getFiltersCount = createSelector(
  getFilters,
  (filters) => Object.keys(filters).length || null
)

export const getExpense = createSelector(
  (state) => state[NAME].expense,
  (expense) => expense
)

export const getExpenseReceipts = createSelector(
  getExpense,
  (expense) => expense.receipts
)

export const getSortBy = createSelector(
  (state) => state[NAME].sortBy,
  (sortBy) => sortBy
)

export const getSortDescending = createSelector(
  (state) => state[NAME].sortDescending,
  (sortDescending) => sortDescending
)

export const hasMore = createSelector(
  getExpensesList,
  getExpensesCount,
  (list, count) => count > list.length
)

export const searchKeywordSelector = createSelector(
  (state) => state[NAME],
  (state) => state.searchKeyword
)

export const getCompanyId = createSelector(
  (state) => state[NAME],
  (state) => state.companyId
)

export const showFilters = createSelector(
  (state) => state[NAME],
  (module) => module.showFilters
)

export const getTotalReimbursed = createSelector(getExpense, (expense) => {
  if (!expense || !expense.reimbursement) return 0
  const {
    reimbursement: { receipts },
  } = expense
  return receipts.reduce((prev, curr) => {
    const total = curr.lineItems.reduce((acc, item) => {
      if (item.reason) return acc
      acc += Number(item.amount)
      return acc
    }, 0)
    prev += total
    return prev
  }, 0)
})

export const hasLineItems = createSelector(getExpense, (expense) => {
  if (!expense || !expense.reimbursement) return false
  const {
    reimbursement: { receipts },
  } = expense
  return receipts.map((receipt) => receipt.lineItems).flat().length > 0
})

export const getTotalAmountRequested = createSelector(getExpense, (expense) => {
  if (!expense || !expense.reimbursement) return 0
  const {
    reimbursement: { receipts },
  } = expense
  return receipts
    .reduce((prev, receipt) => prev + Number(receipt.amount), 0)
    .toFixed(2)
})
