import React, { useCallback, useState } from 'react'
import { Button } from '@sureco/design-system'
import { message } from 'antd'
import { CensusFileRowGridData } from '../../types/census-file-row-grid-data'
import { CensusFileRowDto } from '../../api/dto/census-file-row.dto'
import { updateCensusFileRows } from '../../api/census-enrollment-platform-api-client'
import { mapGridDataToCensusFileRowDto } from '../../utils/map-grid-data-to-census-file-row-dto'
import { PatchActionEnum } from '../../api/enums/patch-action.enum'
import { CheckCircleOutlined } from '@ant-design/icons'
import { CreateMembersResponseDto } from '../../api/dto/create-members-response.dto'

interface SendSelectedForReviewButtonProps {
  publicKey: string;
  selectedRows: CensusFileRowGridData[]
  handleSubmit: (rows: CensusFileRowGridData[]) => void
  cleanAllRows: () => void
  setLoading: (loading: boolean) => void // Prop to update loading state in parent
}

const sendForApproval = async (rowsToSubmit: CensusFileRowGridData[], publicKey: string): Promise<CreateMembersResponseDto> => {
  const acceptedRows: CensusFileRowDto[] = rowsToSubmit.map((gridRow) => ({
    ...mapGridDataToCensusFileRowDto(gridRow),
  }))
  const { data: counts } = await updateCensusFileRows(acceptedRows, publicKey, PatchActionEnum.CREATE_MEMBERS)
  return counts as CreateMembersResponseDto
}

const ApproveNewRecordsButton = ({
    selectedRows,
    publicKey,
    handleSubmit,
    cleanAllRows,
    setLoading
  }: SendSelectedForReviewButtonProps) => {
  const [loading, setButtonLoading] = useState(false)

  const handleClick = useCallback(async () => {
    let timer: NodeJS.Timeout | null = null
    let shouldShowLoading = false

    timer = setTimeout(() => {
      setButtonLoading(true)
      setLoading(true)
      shouldShowLoading = true
    }, 800)

    try {
      const {
        successfulCreateRowIds,
        validationErrors,
        createMemberErrors,
        unsuccessfulCreateRowIds,
        duplicateRows
      } = await sendForApproval(selectedRows, publicKey)

      const totalErrors = validationErrors + createMemberErrors + unsuccessfulCreateRowIds + duplicateRows

      if (totalErrors > 0) {
        message.error(`An error occurred attempting to approve ${totalErrors} records`, 4)
      }
      if (successfulCreateRowIds > 0) {
        message.success(`${successfulCreateRowIds} records submitted successfully`, 3)
      }
    } catch (error) {
      message.error(`Failed to submit records: '${(error as Error).message}'`)
    } finally {
      // Clear loading state if it was set
      if (shouldShowLoading) {
        setButtonLoading(false)
        setLoading(false)
      }

      // Ensure the timer doesn't trigger the loading state if completed early
      if (timer) {
        clearTimeout(timer)
      }

      handleSubmit(selectedRows)
      cleanAllRows()
    }
  }, [selectedRows, publicKey, setLoading, handleSubmit, cleanAllRows])

  if (selectedRows.length === 0) return null

  return (
    <Button onClick={handleClick} size="large" loading={loading}>
      <CheckCircleOutlined style={{ marginRight: '8px' }} />{loading ? 'Submitting...' : `Approve (${selectedRows.length})`}
    </Button>
  )
}

export default ApproveNewRecordsButton
