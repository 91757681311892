import { createSelector } from 'reselect'
import { isEmployee } from '@hixme/person-utils'

import { NAME } from './constants'

export const getBenefits = createSelector(
  (state) => state[NAME],
  (employee) => employee.benefits
)

export const getBenefit = createSelector(
  (state) => state[NAME],
  (employee) => (benefitIdParam) =>
    employee.benefits.find((b) => b.Id === benefitIdParam) || {}
)

export const getBundle = createSelector(
  (state) => state[NAME],
  (employee) => (bundleIdParam, groupIndexParam) => {
    const bundle =
      employee.bundles.length > 0 &&
      employee.bundles[groupIndexParam].Bundles.find(
        (b) => b.BundlePublicKey === bundleIdParam
      )
    return bundle || {}
  }
)

export const getBundles = createSelector(
  (state) => state[NAME],
  (employee) => employee.bundles
)

export const getEnrollments = createSelector(
  (state) => state[NAME],
  (employee) => employee.enrollments
)

export const getEnrollmentId = createSelector(
  (state) => state[NAME],
  (employee) => employee.enrollmentId
)

export const getFamily = createSelector(
  (state) => state[NAME],
  (employee) => employee.family
)

export const getEmployee = createSelector(
  getFamily,
  (family) => family.find((person) => isEmployee(person)) || {}
)

export const getWaivedBenefits = createSelector(
  (state) => state[NAME],
  (employee) => employee.waivedBenefits
)
