import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getPerson = createSelector(
  (state) => state[NAME],
  (employeeBenefit) => employeeBenefit.person
)

export const getBenefits = createSelector(
  (state) => state[NAME],
  (employeeBenefit) => employeeBenefit.benefits
)

export const getCobraSummary = createSelector(
  (state) => state[NAME],
  (employeeBenefit) => employeeBenefit.cobraBenefits
)
