import React from 'react'
import PropTypes from 'prop-types'

const getDisabled = (props) => {
  const { disabled } = props

  if (typeof disabled === 'function') {
    return disabled ? disabled(props) : false
  }

  return disabled
}

const PureFieldComponent = (props) => {
  const {
    input,
    bindBlur,
    bindDragStart,
    bindDrop,
    bindFocus,
    style,
    InputComponent,
    ...restProps
  } = props

  const filteredInput = Object.keys(input).reduce((prev, key) => {
    switch (key) {
      case 'onBlur':
        if (!bindBlur) return prev
        break
      case 'onDragStart':
        if (!bindDragStart) return prev
        break
      case 'onDrop':
        if (!bindDrop) return prev
        break
      case 'onFocus':
        if (!bindFocus) return prev
        break
      default:
    }
    return Object.assign(prev, { [key]: input[key] })
  }, {})

  return (
    <InputComponent
      disabled={getDisabled(props)}
      style={style}
      {...filteredInput}
      {...restProps}
    />
  )
}

PureFieldComponent.propTypes = {
  input: PropTypes.object,
  InputComponent: PropTypes.func,
  style: PropTypes.object,
  bindBlur: PropTypes.bool,
  bindDragStart: PropTypes.bool,
  bindDrop: PropTypes.bool,
  bindFocus: PropTypes.bool,
}

PureFieldComponent.defaultProps = {
  input: {},
  InputComponent: null,
  style: {},
  bindBlur: false,
  bindDragStart: false,
  bindDrop: false,
  bindFocus: false,
}
export default PureFieldComponent
