import React from 'react'
import {
  Controller,
  Control,
  UseControllerProps,
  FieldValues,
  Path,
} from 'react-hook-form'
import { Select, SelectProps } from '@surecompanies/core_components'
import { Stack, TextColors, Typography } from '@sureco/design-system'

export type RHFSelectProps<
  TFieldValues extends FieldValues = FieldValues
> = Omit<SelectProps, 'name'> & {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  label?: string
  rules?: UseControllerProps<TFieldValues>['rules']
}

export default function RHFSelect<
  TFieldValues extends FieldValues = FieldValues
>({ name, control, rules, label, ...rest }: RHFSelectProps<TFieldValues>) {
  return (
    <Controller
      name={name as Path<TFieldValues>}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <Stack space={2}>
            {label && <Typography>{label}</Typography>}
            <Select
              status={fieldState.error ? 'error' : undefined}
              onBlur={field.onBlur}
              value={field.value}
              onChange={(event) => {
                field.onChange(event)
              }}
              placeholder="Select"
              ref={field.ref}
              {...rest}
            />
            {fieldState.error && (
              <Typography color={TextColors.error}>
                {fieldState.error.message}
              </Typography>
            )}
          </Stack>
        )
      }}
    />
  )
}
