import React from 'react'
import { BenefitsPrice } from '../components'

const CobraPriceList = (cobra) => {
  if (Object.keys(cobra).length === 0) return <div />
  return <BenefitsPrice {...cobra} />
}

CobraPriceList.displayName = 'CobraPriceList'

export default CobraPriceList
