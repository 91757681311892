import { SortDirection } from '../../../types/sorted-request.dto'
import { CensusFileRowStatusEnum } from '../api/enums/census-file-row-status.enum'
import { CensusFileRowTypeEnum } from '../api/enums/census-file-row-type.enum'
import { getCensusFileRows, getCensusFileRowTypeCounts } from '../api/census-enrollment-platform-api-client'

// Get census file and row data and row type counts to populate tabs and tables
// for records that are currently IN_PROCESS
export async function getInProcessPageData({
    limit,
    page,
    publicKey,
    sort,
    order,
    censusRowType,
    censusFileRowStatuses,
  }: {
    limit: number;
    page: number;
    publicKey: string;
    sort?: string;
    order?: SortDirection;
    censusRowType?: string;
    censusFileRowStatuses: CensusFileRowStatusEnum[];
}) {
  const countQueryTypes: CensusFileRowTypeEnum[] = [
    CensusFileRowTypeEnum.DUPLICATE_EMPLOYEE,
    CensusFileRowTypeEnum.UPDATE_EMPLOYEE_BENEFITS,
    CensusFileRowTypeEnum.UPDATE_EMPLOYEE_NO_BENEFITS,
    CensusFileRowTypeEnum.DRAFT_EMPLOYEE,
    CensusFileRowTypeEnum.ERROR,
  ]
  const countQueryStatus: CensusFileRowStatusEnum[] = [CensusFileRowStatusEnum.IN_PROCESS]
  const censusFileRows = getCensusFileRows({
    limit,
    page,
    publicKey,
    sort,
    order,
    type: censusRowType as never,
    status: censusFileRowStatuses as CensusFileRowStatusEnum[],
  })
  const counts = getCensusFileRowTypeCounts({ publicKey, type: countQueryTypes, status: countQueryStatus })
  const [rowResponse, rowCounts] = await Promise.all([
    censusFileRows,
    counts,
  ])
  const { data, meta: pagination, censusFile } = rowResponse
  return { rowCounts, data, pagination, censusFile }
}
