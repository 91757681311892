import type from './actionTypes'

export const hideNotifications = () => ({
  type: type.HIDE_NOTIFICATIONS,
})

export const createNotification = (notification) => ({
  type: type.CREATE_NOTIFICATION,
  payload: notification,
})

export const createErrorNotification = (notification) => ({
  type: type.CREATE_NOTIFICATION,
  payload: {
    type: 'error',
    message: notification,
  },
})

export const createSuccessNotification = (notification) => ({
  type: type.CREATE_NOTIFICATION,
  payload: {
    type: 'success',
    message: notification,
  },
})
