import { CensusFileRowRawDataDto } from '../api/dto/census-file-row.dto'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'
import { ColDef } from '@ag-grid-community/core'

// These are your known required fields — ideally exported from one place
const REQUIRED_FIELDS: string[] = [
  'CompanyEmployeeId',
  'Salary',
  'DateOfBirth',
  'HireDate',
  'Smoker',
  'EmailAddress',
  'PhoneNumber',
  'PhoneType',
  'Gender',
  'PostalCode',
  'SSN',
  'EmploymentStatus',
  'FirstName',
  'LastName',
  'Relationship',
  'StateProvince',
  'StreetAddress',
  'City',
  'IsExempt',
  'PayrollCycle',
]

export const colDefsFromData = (
  firstRow: CensusFileRowRawDataDto
): ColDef<CensusFileRowGridData>[] => {
  const colBlacklist = ['clientPublicKey', 'id']
  if (!firstRow) return []

  // Get all keys from the data except blacklisted ones
  const dataKeys = Object.keys(firstRow).filter(
    (key) => !colBlacklist.includes(key)
  )

  // Add any missing required fields
  const allKeys = Array.from(
    new Set([...REQUIRED_FIELDS, ...dataKeys]) // Set removes duplicates
  )

  return allKeys.map((key) => ({
    field: key,
    sortable: true,
    resizable: true,
    sortPrefix: 'rowData:',
  }))
}
