import { createReducer } from 'modules/app-core/redux-utils'
import type from './actionTypes'

const initialState = {
  notifications: [],
  showNotifcations: false,
}

export default createReducer(initialState, {
  [type.HIDE_NOTIFICATIONS]: (state) =>
    Object.assign({}, state, {
      showNotifcations: false,
    }),
  [type.CREATE_NOTIFICATION]: (state, payload) =>
    Object.assign({}, state, {
      notifications: [payload],
      showNotifcations: true,
    }),
  [type.CREATE_ERROR_NOTIFICATION]: (state, payload) =>
    Object.assign({}, state, {
      notifications: [payload],
      showNotifcations: true,
    }),
  [type.CREATE_SUCCESS_NOTIFICATION]: (state, payload) =>
    Object.assign({}, state, {
      notifications: [payload],
      showNotifcations: true,
    }),
})
