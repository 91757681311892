import { connect } from 'react-redux'
import { selectors as companySelectors } from 'store/modules/companies'
import {
  selectors as userSessionSelectors,
  actions as userSessionActions,
} from 'store/modules/user-session'
import { HeaderCompanySelector } from './HeaderCompanySelector'
import { Company } from 'types/company'

const mapStateToProps = (state) => {
  const companyList: Company[] = Object.values(
    companySelectors.companyCollectionSelector(state)
  )

  return {
    companyList: companyList,
    currentCompanyId: userSessionSelectors.getCurrentCompanyId(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (companyId: string) => {
      if (companyId) {
        dispatch(userSessionActions.setCurrentCompany(companyId))
      }
    },
  }
}

export const HeaderCompanySelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderCompanySelector)
