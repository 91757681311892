
import React, { useState } from 'react'
import ReviewRecordsBase from '../components/ReviewRecordsBase'
import { CensusFileRowTypeEnum } from '../api/enums/census-file-row-type.enum'
import { LoadingOverlay } from '../components/LoadingOverlay/LoadingOverlay'
import { CensusFileRowStatusEnum } from '../api/enums/census-file-row-status.enum'
import { CensusManagementPageProps } from '../types/census-management-page-props'

// const _submitCorrections = async (rowsToSubmit: CensusFileRowGridData[]) => {
// const updatedRows: CensusFileRowDto[] = rowsToSubmit.map(mapGridDataToCensusFileRowDto)
// await updateCensusFileRows(updatedRows)
// }
import RejectSelectedButton from '../components/RejectSelectedButton'

const ReviewRecordsDuplicateEmployee = (props: CensusManagementPageProps) => {
  const [loading, setLoading] = useState(false) // Track submission state
  const { importPublicKey } = props.routeParams

  return (
    <>
  <LoadingOverlay isLoading={loading} message="Loading..." />
  <div style={{ marginTop: '50px', marginRight: '30px', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
    </div>
  <ReviewRecordsBase
      tabKey={CensusFileRowTypeEnum.DUPLICATE_EMPLOYEE}
      {...props}
      rowQueryParams={{
        censusFileRowStatuses: [CensusFileRowStatusEnum.IN_PROCESS],
        censusRowType: CensusFileRowTypeEnum.DUPLICATE_EMPLOYEE,
      }}
      actionButton={({ censusRows, handleSubmit }) => {
        return (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <RejectSelectedButton
                  publicKey={importPublicKey}
                  selectedRows={censusRows}
                  handleSubmit={handleSubmit}
                  setLoading={setLoading}
                  type={CensusFileRowTypeEnum.DUPLICATE_EMPLOYEE}
                />
            </div>
        )
        }}
    />
    </>
  )
}

export default ReviewRecordsDuplicateEmployee
