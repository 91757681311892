import { notification } from '@surecompanies/core_components'
import { useMutation } from '@tanstack/react-query'
import client from 'enrollment-platform/api/eps.client'
import { CUSTOMER_MANAGEMENT } from 'enrollment-platform/pages/CustomerManagement/paths'
import { browserHistory } from 'react-router'

export default function useEditCustomer({ id }: { id: number }) {
  const updateCustomer = async (data: any) => {
    try {
      await client.put(`/customers/${id}`, {
        ...data,
        status: data.isActive ? 'ACTIVE' : 'INACTIVE',
      })
      notification.success({
        message: 'Customer was successfully updated',
      })
      browserHistory.push(`/${CUSTOMER_MANAGEMENT}/${id}/view`)
    } catch (error: any) {
      console.log(error?.response?.data?.message, 'error')
      notification.error({
        message: 'Something went wrong updating the customer',
      })
    }
  }

  const { mutate, isPending } = useMutation({
    mutationFn: updateCustomer,
    mutationKey: ['updateCustomer', id],
  })
  return {
    mutate,
    isPending,
  }
}
