import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import CloseButton from '@hixme-ui/close-button'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import { colorTheme } from '@hixme-ui/theme'

const StyledAlert = styled.div`
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  background-color: ${({ type }) => {
    if (type === 'general') return colorTheme.blue
    if (type === 'success') return colorTheme.green
    if (type === 'error') return colorTheme.error
    return colorTheme.blue
  }};
`

const Alert = ({ onClose, type, message, autoClose, timeToClose }) => {
  let timeoutId

  function doClose() {
    clearTimeout(timeoutId)
    onClose()
  }

  if (autoClose) {
    timeoutId = setTimeout(doClose, timeToClose)
  }

  return (
    <StyledAlert type={type}>
      <Container
        flex
        noPadding
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Container flex noPadding margin="0 0 0 30px">
          <Text id="message" white fontSize="18pt">
            {message}
          </Text>
        </Container>
      </Container>
      {!autoClose && (
        <Container flex noPadding alignSelf="center" margin="0 25px 0 0">
          <CloseButton onClick={doClose} noLabel />
        </Container>
      )}
    </StyledAlert>
  )
}

Alert.propTypes = {
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  message: PropTypes.string.isRequired,
  autoClose: PropTypes.bool,
  timeToClose: PropTypes.number,
}

Alert.defaultProps = {
  autoClose: true,
  timeToClose: 5000,
  type: 'general',
}

export default Alert
