import { namespaceActions } from 'modules/app-core/redux-utils'
import { NAME } from './constants'

const USER_INITIALIZED = 'USER_INITIALIZED'
const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY'
const SET_QLE = 'SET_QLE'

export default namespaceActions(NAME)([
  USER_INITIALIZED,
  SET_CURRENT_COMPANY,
  SET_QLE,
])
