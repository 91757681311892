export const NAME = 'censusSummary'

export const CENSUS_VALIDATION_ERROR = 'CENSUS_VALIDATION_ERROR'
export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL'
export const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL'

export const STATUSES = {
  NO_ERROR: 'No errors.',
  CENSUS_VALIDATION_ERROR: 'There are errors in the Census.',
  SHOW_ERROR_MODAL: 'Census Error Modal On',
}

export const DISPLAY_STATUS_LIST = [
  STATUSES.CENSUS_VALIDATION_ERROR,
  STATUSES.SHOW_ERROR_MODAL,
]

export default [CENSUS_VALIDATION_ERROR, HIDE_ERROR_MODAL, SHOW_ERROR_MODAL]
