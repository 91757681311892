import { connectRequest, getRequestByName } from 'modules/redux-gateway'

export default ({
  resourceDispatchAction,
  resource,
  resourceName,
  resourceId,
  resourceSelector,
  requestName,
  LoadingView,
}) => (InnerComponent) => {
  if (resource) {
    /* eslint-disable no-param-reassign */
    resourceId = `${resource}Id`
    resourceName = resource
  }

  return connectRequest({
    name: (state, props) => {
      const n = `${requestName}:${props[resourceId]}`
      return n
    },
    onMount: (props, dispatch) => {
      dispatch((_, getState) => {
        const request = getRequestByName(
          getState(),
          `${requestName}:${props[resourceId]}`
        )
        const item = resourceSelector(getState(), props)
        if (!item && resourceDispatchAction && !request.isProcessing) {
          dispatch(resourceDispatchAction(props))
        }
      })
    },
    mapStateToProps: (state, ownProps) => ({
      [resourceName]: resourceSelector(state, ownProps),
    }),
    processingView: LoadingView,
  })(InnerComponent)
}
