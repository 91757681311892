import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import styles from './CustomerForm.module.scss'
import { Stack, TextColors, Typography } from '@sureco/design-system'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import useWindowDimensions from 'hooks/useWindowDimensions'
import ContentSection from 'layouts/ContentSection/ContentSection'
import RHFInput from 'enrollment-platform/components/form/hookform/Input/RHFInput'
import RHFSelect from 'enrollment-platform/components/form/hookform/Select/RHFSelect'
import ContactForm from './ContactForm'
import { Button, Empty } from '@surecompanies/core_components'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { CustomerSchema } from './customer.schema'
import StateSelect from 'enrollment-platform/components/form/StateSelect'
import { ContactType } from '../enums/contact.type'
import HrisSelect from 'enrollment-platform/components/form/HrisSelect'
import IndustrySelect from 'enrollment-platform/components/form/IndustrySelect/IndustrySelect'
import RHFToggle from 'enrollment-platform/components/form/hookform/Toogle/RHFToogle'
import UploadButton from './UploadButton'

export type FormRef = {
  submit: () => void
}

type CustomerFormProps = {
  initialValues?: Record<string, any>
  onFormSubmit: (data: any) => void
  isLoading: boolean
}

type FormData = z.infer<typeof CustomerSchema>

const CustomerForm = forwardRef<FormRef, CustomerFormProps>(({ initialValues, onFormSubmit }, ref) => {
  const { handleSubmit, control, setValue } = useForm<FormData>({
    defaultValues: initialValues ?? {},
    resolver: zodResolver(CustomerSchema),
  })
  const form = useWatch({
    control,
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
    shouldUnregister: true,
  })
  const formRef = useRef<HTMLFormElement>(null)
  const { width } = useWindowDimensions()
  const isSmallUI = width < 600

  useImperativeHandle(ref, () => ({
    submit: () => {
      formRef?.current?.requestSubmit()
    },
  }))

  const handleImageUploaded = (base64: string) => {
    setValue('logo', base64)
  }

  return (
    <Stack space={20}>
      <ContentSection title="Customer Details">
        <form
          onSubmit={handleSubmit((data) => onFormSubmit(data))}
          ref={formRef}
        >
          <Stack space={20}>
            <section className={styles.formLayout}>
              <Stack>
                <Typography size={14}>
                  Company Name{' '}
                  <Typography color={TextColors.error}>*</Typography>
                </Typography>
                <RHFInput
                  control={control}
                  name="name"
                  placeholder="Company Name"
                  disabled={!!initialValues}
                  size={isSmallUI ? 'large' : 'middle'}
                />
              </Stack>
              <Stack>
                <Typography size={14}>Phone number</Typography>
                <RHFInput
                  control={control}
                  name="phone"
                  size={isSmallUI ? 'large' : 'middle'}
                />
              </Stack>
              <Stack>
                <Typography size={14}>Payroll/HRIS Vendor</Typography>
                <HrisSelect<FormData>
                  control={control}
                  name="payrollVendorName"
                  size={isSmallUI ? 'large' : 'middle'}
                />
              </Stack>
              <Stack>
                <Typography size={14}>SureCo Internal Company Name</Typography>
                <RHFInput
                  control={control}
                  name="surecoInternalCompany"
                  size={isSmallUI ? 'large' : 'middle'}
                />
              </Stack>
              <Stack>
                <Typography size={14}>StreetAddress</Typography>
                <RHFInput
                  control={control}
                  name="address.streetAddressLine1"
                  size={isSmallUI ? 'large' : 'middle'}
                />
              </Stack>
              <Stack>
                <Typography size={14}>Logo URL</Typography>
                <UploadButton onImageUploaded={handleImageUploaded} />
                <RHFInput
                  control={control}
                  name="logo"
                  type="hidden"
                />
              </Stack>
              <Stack>
                <Typography size={14}>Industry</Typography>
                <IndustrySelect<FormData>
                  control={control}
                  name='industry'
                />
              </Stack>
              <Stack>
                <Typography size={14}>Street Address 2</Typography>
                <RHFInput
                  control={control}
                  name="address.streetAddressLine2"
                  size={isSmallUI ? 'large' : 'middle'}
                />
              </Stack>
              <Stack>
                <Typography size={14}>Website URL</Typography>
                <RHFInput
                  control={control}
                  name="webSiteUrl"
                  placeholder="https://"
                  size={isSmallUI ? 'large' : 'middle'}
                />
              </Stack>
              <Stack>
                <Typography size={14}>Bussiness Structure</Typography>
                <RHFSelect<FormData>
                  control={control}
                  name="businessStructure"
                  options={[
                    { label: 'C Corp', value: 'CCORP' },
                    { label: 'S Corp', value: 'SCORP' },
                    { label: 'LLC', value: 'LLC' },
                    {
                      label: 'Sole Proprietorship',
                      value: 'SOLEPROPRIETORSHIP',
                    },
                    { label: 'Parnetship', value: 'PARTNERSHIP' },
                  ]}
                  size={isSmallUI ? 'large' : 'middle'}
                />
              </Stack>
              <Stack
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                  columnGap: 20,
                }}
              >
                <Stack>
                  <Typography size={14}>City</Typography>
                  <RHFInput
                    control={control}
                    placeholder="Chicago"
                    name="address.city"
                    size={isSmallUI ? 'large' : 'middle'}
                  />
                </Stack>
                <Stack>
                  <Typography size={14}>County</Typography>
                  <RHFInput
                    control={control}
                    placeholder="Cook"
                    name="address.county"
                    size={isSmallUI ? 'large' : 'middle'}
                  />
                </Stack>
                <Stack>
                  <Typography size={14}>State</Typography>
                  <StateSelect<FormData>
                    control={control}
                    name="address.stateProvince"
                    placeholder="Select"
                    size={isSmallUI ? 'large' : 'middle'}
                  />
                </Stack>
                <Stack>
                  <Typography size={14}>Zip</Typography>
                  <RHFInput
                    control={control}
                    placeholder="00000"
                    name="address.postalCode"
                    size={isSmallUI ? 'large' : 'middle'}
                  />
                </Stack>
              </Stack>
              <Stack>
                <Typography size={16} color='#003146'>Make Active on Save</Typography>
                <RHFToggle
                  control={control}
                  name="isActive"
                />
              </Stack>
            </section>
          </Stack>
        </form>
      </ContentSection>
      <ContentSection title="Contact Details">
        {fields.length === 0 ? (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            description={<Typography>No contacts yet</Typography>}
          >
            <Button
              type="primary"
              onClick={() =>
                append({
                  type: ContactType.HR,
                  email: '',
                  title: '',
                  phone: {
                    number: ''
                  },
                  fullName: '',
                  isPrimary: false,
                  fundingReportDistribution: false
                })
              }
            >
              Create Now
            </Button>
          </Empty>
        ) : (
          <Stack space={20}>
            {fields.map((field, index) => (
              <div key={field.id}>
                <ContactForm
                  name={`contacts.${index}`}
                  control={control}
                  onRemove={() => remove(index)}
                  // @ts-ignore
                  contacts={form.contacts}
                />
              </div>
            ))}
            <div style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={() =>
                  append({
                    type: ContactType.HR,
                    email: '',
                    title: '',
                    phone: {
                      number: ''
                    },
                    fullName: '',
                    isPrimary: false,
                    fundingReportDistribution: false
                  })
                }
              >
                Add Another Contact
              </Button>
            </div>
          </Stack>
        )}
      </ContentSection>
    </Stack>
  )
})

export default CustomerForm
