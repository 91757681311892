function download(href, filename) {
  const el = document.createElement('a')
  el.href = href
  el.download = filename

  // Explicitly add created element to the DOM for Firefox
  document.body.appendChild(el)

  el.click()
  el.remove()
}

export function downloadFile(data, fileName, type) {
  const file = new Blob([data], { type })
  download(URL.createObjectURL(file), fileName)
}

export function downloadLink(link, filename) {
  download(link, filename)
}

export function downloadJSONasCSV(data, filename = 'data.csv') {
  // Convert JSON array to CSV
  const keys = Object.keys(data[0])
  const csvRows = [keys.join(',')] // Add headers as the first row

  // Map each JSON object to a CSV row
  data.forEach((item) => {
    const values = keys.map((key) => {
      const value = item[key] ?? '' // Don't allow null/undefined
      let escaped = ('' + value).replace(/"/g, '""') // Escape double quotes

      // Ensure values that might be interpreted as numbers are stored as text
      if (/^0\d+/.test(value)) {
        return `="${escaped}"` // Force Excel to interpret as text
      }

      return `"${escaped}"` // Wrap values in double quotes for CSV
    })
    csvRows.push(values.join(','))
  })

  // Join all rows with newline character
  const csvContent = csvRows.join('\n')

  // UTF-8 BOM added to help charset with excel file
  const BOM = '\uFEFF'
  const file = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' })

  // Initiate download
  download(URL.createObjectURL(file), filename)
}
