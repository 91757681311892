import React from 'react'
import { Space, Typography } from 'antd'
import { FileOutlined, UploadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { CensusFileDto } from '../api/dto/census-file.dto'

const { Text } = Typography

function FileInfo({ data, clientName, style }: { style?: any, data: CensusFileDto | undefined, clientName: string }) {
  const { rowCount = '...', fileName = '...', createdAt: uploadDateTime } = data ?? {}
  const pastDate = dayjs(uploadDateTime)
  const now = dayjs()
  const lastModified = uploadDateTime ? pastDate.from(now) : '...'
  return (
    <>
    <Space direction="vertical" size="small" style={style}>
      <Text strong style={{ fontSize: '24px' }}>
        {clientName} <span style={{ color: '#b0b0b0' }}>({rowCount} Records)</span>
      </Text>
      <div style={{ color: '#b0b0b0', display: 'flex', alignItems: 'center' }}>
        <FileOutlined style={{ fontSize: '14px', marginRight: '8px' }} />
        <Text>{fileName}</Text>
      </div>
      <div style={{ color: '#b0b0b0', display: 'flex', alignItems: 'center' }}>
        <UploadOutlined style={{ fontSize: '14px', marginRight: '8px' }} />
        <Text>{lastModified}</Text>
      </div>
    </Space>
    </>
  )
}

export default FileInfo
