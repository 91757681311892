import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Input from '@hixme-ui/input'

import { FieldError } from 'components/ReduxFormComponents'

const PhoneNumberInput = ({ input, meta, ...restProps }) => (
  <div>
    <Input phone name="card" {...input} {...restProps} />
    <FieldError meta={meta} />
  </div>
)

PhoneNumberInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
}

PhoneNumberInput.defaultProps = {
  input: {},
  meta: {},
}

export default PhoneNumberInput
