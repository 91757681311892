import { connect } from 'react-redux'

import { selectors as censusSelectors } from 'store/modules/census'
import CsvErrorsModal from 'components/CsvErrorsModal'

import { selectors } from '../modules/census-summary'

const mapStateToProps = (state) => {
  const censusId = selectors.getId(state)
  const { Validation } = censusSelectors.censusByIdSelector(state)(censusId)
  const errorsRaw =
    Validation && (Validation instanceof Array ? Validation : [Validation])
  const errors =
    (errorsRaw &&
      errorsRaw.map(({ name, relationship, columnName, message }) => ({
        row: name,
        columnName,
        message: `${relationship}: ${message}`,
      }))) ||
    []
  return {
    errors,
  }
}

export default connect(mapStateToProps)(CsvErrorsModal)
