import React from 'react'
import uuidV1 from 'uuid/v1'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import { Row, Col } from 'react-flexbox-grid'

const tableGrey = '#F7F9FA'

const tableColor = (i) => {
  if (i < 0) return null

  return i % 2 === 0 ? tableGrey : ''
}

const BenefitsPrice = (cobra) => (
  <div
    style={{
      borderLeft: '1px solid rgb(185, 197, 205)',
      padding: '0 0 0 10px',
    }}
  >
    <Container flex noPadding width="100%" direction="column">
      {cobra.Tiers.map((item, i) => (
        <div key={uuidV1()} style={{ backgroundColor: tableColor(i) }}>
          <Row style={{ margin: '0', padding: '5px 0' }}>
            <Col sm={3} md={4}>
              <Container flex noPadding width="110px" direction="column">
                {item.Tier.map((tier) => {
                  const personType = tier === 'Employee' ? 'Worker' : tier
                  return (
                    <Text
                      key={uuidV1()}
                      style={{ margin: '0 0 0 28px' }}
                      fontSize="10pt"
                    >
                      {personType}
                    </Text>
                  )
                })}
              </Container>
            </Col>
            <Col sm={3} md={4}>
              <Container flex noPadding width="144px" direction="column">
                <Text center primary fontSize="10pt">
                  {item.Rate ? `$${item.Rate.toFixed(2)}` : 'Special'}
                </Text>
              </Container>
            </Col>
            <Col sm={3} md={4}>
              <Container flex noPadding width="110px" direction="column">
                <div style={{ marginLeft: '48px' }}>
                  <Text center primary fontSize="10pt">
                    {item.FullRate ? `$${item.FullRate.toFixed(2)}` : 'Special'}
                  </Text>
                </div>
              </Container>
            </Col>
          </Row>
        </div>
      ))}
    </Container>
  </div>
)

BenefitsPrice.displayName = 'BenefitsPrice'

export default BenefitsPrice
