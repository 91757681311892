import { personService } from '@hixme/api'

export function postCensus(body) {
  return personService({
    route: 'census/post',
    method: 'POST',
    body,
  })
}

export function archiveCensus(Id) {
  return personService({
    route: 'census/archive',
    method: 'POST',
    body: {
      Id,
    },
  })
}

export function importCensus(Id) {
  return personService({
    route: 'census/import',
    method: 'POST',
    body: {
      Id,
    },
  })
}

export function getCensusById(Id) {
  return personService({
    route: 'census/get',
    params: {
      Id,
    },
  })
}

export function downloadImport(params) {
  return personService({
    route: 'census/download',
    params,
  })
}

export function downloadCsv(params) {
  return personService({
    route: 'census/download',
    params,
  })
}

export function getAllCensus() {
  return personService({
    route: 'census/search',
    method: 'POST',
  })
}
