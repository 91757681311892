import React, { useState } from 'react'
import { CensusFileRowTypeEnum } from '../api/enums/census-file-row-type.enum'
import { LoadingOverlay } from '../components/LoadingOverlay/LoadingOverlay'
import { CensusFileRowStatusEnum } from '../api/enums/census-file-row-status.enum'
import ReviewRecordsBaseUpdate from '../components/ReviewUpdatesRecordsBase'

const ReviewRecordsUpdateEmployee = (props: Record<string, never> & { routeParams: never }) => {
  const [loading, setLoading] = useState(false)

  return (
    <div>
      <LoadingOverlay isLoading={loading} message="Loading updates..." />

      <ReviewRecordsBaseUpdate
        {...props}
        rowQueryParams={{
          censusFileRowStatuses: [CensusFileRowStatusEnum.IN_PROCESS], // Only include rows with this status (as per the Update tab)
          censusRowType: CensusFileRowTypeEnum.UPDATE_EMPLOYEE_BENEFITS, // Use the correct `censusRowType` for the Update tab
        }}
        setLoading={setLoading}
      />
    </div>
  )
}

export default ReviewRecordsUpdateEmployee
