import { createReducer } from 'modules/app-core/redux-utils'
import t from './actionTypes'
import companyReducer from './company-reducer'

// initialState
const initialState = {
  list: {},
}

export default createReducer(initialState, {
  [t.RESET_COMPANY_LIST]: () => initialState,
  [t.RECEIVE_COMPANY_LIST]: (state, payload) => {
    const companyMap = payload.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.id]: companyReducer(state.list[curr.id], {
          type: t.RECEIVE_COMPANY,
          payload: curr,
        }),
      }),
      {}
    )

    return Object.assign({}, state, {
      list: Object.assign({}, state.list, companyMap),
    })
  },
  [t.RECEIVE_COMPANY]: (state, payload) => {
    const company = companyReducer(state.list[payload.id], {
      type: t.RECEIVE_COMPANY,
      payload,
    })
    const companyMap = Object.values(state.list).reduce(
      (prev, curr) => ({
        ...prev,
        [curr.id]: curr,
      }),
      {}
    )

    companyMap[company.id] = company

    return Object.assign({}, state, {
      list: Object.assign({}, state.list, companyMap),
    })
  },
})
