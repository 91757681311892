import React from 'react'
import PropTypes from 'prop-types'
import Text from '@hixme-ui/text'

const FieldValue = ({ children }) => <Text bold>{children}</Text>

FieldValue.propTypes = {
  children: PropTypes.node,
}
FieldValue.defaultProps = {
  children: null,
}
FieldValue.displayName = 'FieldValue'

export default FieldValue
