import React from 'react'
import PropTypes from 'prop-types'
import { MrModal } from '@hixme/modal'
import { Field } from 'redux-form'
import { Row, Col } from 'react-flexbox-grid'

// hixme-ui
import { Label, FormGroup } from '@hixme-ui/forms'

import { FieldInput } from 'components/ReduxFormComponents'

const EditContactInfo = ({ handleSubmit, isProcessing }) => (
  <MrModal
    medium
    dialog
    title="Edit Contact Info"
    confirmHandler={handleSubmit}
    confirmProcessing={isProcessing}
    confirmLabel="Submit"
  >
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={6} sm={4}>
          <FormGroup>
            <Label>First name</Label>
            <Field name="ContactFirstName" component={FieldInput} />
          </FormGroup>
        </Col>
        <Col xs={6} sm={4}>
          <FormGroup>
            <Label>Last name</Label>
            <Field name="ContactLastName" component={FieldInput} />
          </FormGroup>
        </Col>
        <Col xs={6} sm={4}>
          <FormGroup>
            <Label>Email</Label>
            <Field name="ContactEmailAddress" component={FieldInput} />
          </FormGroup>
        </Col>
        <Col xs={6} sm={4}>
          <FormGroup>
            <Label>Phone number</Label>
            <Field name="ContactPhoneNumber" component={FieldInput} phone />
          </FormGroup>
        </Col>
      </Row>
    </form>
  </MrModal>
)

EditContactInfo.displayName = 'EditContactInfo'
EditContactInfo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool,
}

EditContactInfo.defaultProps = {
  isProcessing: false,
}

export default EditContactInfo
