import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@surecompanies/core_components'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'
import Button from '@hixme-ui/button'
import { colors } from '@hixme-ui/theme'

import {
  getVirtualCardsForEmployee,
  reuseVirtualCard,
} from 'modules/virtual-cards/actions'

const NoCard = ({
  benefitId,
  employeePublicKey,
  onGenerateCard,
  isCreateAvailable,
  currentHealthPlanId,
}) => {
  const availableCards = useSelector(
    (state) => state.virtualCards.employeeFamilyVirtualCards
  )
  const dispatch = useDispatch()
  const [selectedCard, setSelectedCard] = useState(null)
  const [isRelatedCardsLoading, setIsRelatedCardsLoading] = useState(true)

  useEffect(() => {
    dispatch(getVirtualCardsForEmployee(employeePublicKey)).finally(() => {
      setIsRelatedCardsLoading(false)
    })
  }, [])

  const onVCNSelect = (vcnID) => {
    setSelectedCard(vcnID)
  }

  const onReuseVCN = () => {
    const card = availableCards.filter((cards) => cards.id === selectedCard)[0]

    if (!card) {
      toast.error(
        'There was an error trying to reuse the selected virtual card',
        {
          hideProgressBar: true,
        }
      )
      return
    }

    dispatch(
      reuseVirtualCard({
        Id: benefitId,
        cardId: card.userPublicKey,
        cardPublicKey: card.id,
        UserPublicKey: employeePublicKey,
      })
    )
  }

  return (
    <Container
      flex
      padding="10px"
      style={{ opacity: isCreateAvailable ? 1 : '0.4', gap: 8 }}
      alignItems="center"
    >
      <span style={{ marginTop: 8 }}>
        <Icon light creditCard size={2} />
      </span>
      {isRelatedCardsLoading && (
        <Text muted small>
          Retrieving VCNs...
        </Text>
      )}
      {!isRelatedCardsLoading &&
        Array.isArray(availableCards) &&
        availableCards.length > 0 && (
          <Container
            flex
            direction="row"
            alignItems="center"
            noPadding
            style={{ gap: 4 }}
          >
            <Select
              style={{ width: 200 }}
              placeholder="Select VCN"
              optionLabelProp="label"
              onChange={onVCNSelect}
              dropdownMatchSelectWidth={false}
              dropdownRender={(menu) => (
                <Container style={{ padding: '4px 4px 12px' }}>
                  <Container
                    noPadding
                    flex
                    direction="row"
                    style={{ marginBottom: 4 }}
                  >
                    <Text
                      small
                      bolder
                      lighter
                      style={{ width: 176, padding: '0px 8px' }}
                    >
                      VCN
                    </Text>
                    <Text
                      lighter
                      small
                      bolder
                      style={{ width: 76, padding: '0px 8px' }}
                    >
                      HIOS
                    </Text>
                    <Text
                      lighter
                      small
                      bolder
                      style={{ width: 144, padding: '0px 8px' }}
                    >
                      Member
                    </Text>
                    <Text
                      lighter
                      small
                      bolder
                      style={{ width: 144, padding: '0px 8px' }}
                    >
                      Current Carrier
                    </Text>
                    <Text
                      lighter
                      small
                      bolder
                      style={{ width: 144, padding: '0px 8px' }}
                    >
                      VCN Status
                    </Text>
                  </Container>
                  {menu}
                </Container>
              )}
            >
              {availableCards.map((opt) => (
                <Select.Option
                  style={{ margin: 0, padding: 0, minHeight: 'unset' }}
                  key={opt.id}
                  label={opt.card}
                  value={opt.id}
                >
                  <Container
                    noPadding
                    flex
                    direction="row"
                    alignItems="center"
                    style={{ height: '100%' }}
                  >
                    <Container
                      flex
                      noPadding
                      direcion="column"
                      justifyContents="center"
                      style={{
                        width: 176,
                        padding: '4px 8px',
                        borderRight: `1px solid ${colors.grey120}`,
                      }}
                    >
                      <Text muted>{opt.card}</Text>
                    </Container>
                    <Container
                      flex
                      noPadding
                      direcion="column"
                      justifyContents="center"
                      style={{
                        width: 76,
                        padding: '4px 8px',
                        borderRight: `1px solid ${colors.grey120}`,
                      }}
                    >
                      <Text muted>{opt.HIOS.substr(-5)}</Text>
                    </Container>
                    <Container
                      flex
                      noPadding
                      direcion="column"
                      justifyContents="center"
                      style={{ width: 144, padding: '4px 8px' }}
                    >
                      <Text muted>{opt.name}</Text>
                    </Container>
                    <Container
                      flex
                      noPadding
                      direcion="column"
                      justifyContents="center"
                      style={{
                        width: 144,
                        padding: '4px 8px',
                        borderRight: `1px solid ${colors.grey120}`,
                      }}
                    >
                      <Text muted>
                        {opt.HIOS === currentHealthPlanId ? 'YES' : 'NO'}
                      </Text>
                    </Container>
                    <Container
                      flex
                      noPadding
                      direcion="column"
                      justifyContents="center"
                      style={{
                        width: 144,
                        padding: '4px 8px',
                        borderRight: `1px solid ${colors.grey120}`,
                      }}
                    >
                      <Text muted>{opt.status}</Text>
                    </Container>
                  </Container>
                </Select.Option>
              ))}
            </Select>
            {selectedCard && (
              <Button mini onClick={onReuseVCN} style={{ minWidth: 88 }}>
                Reuse VCN
              </Button>
            )}
          </Container>
        )}
      <Text
        cursor={isCreateAvailable ? 'pointer' : 'not-allowed'}
        onClick={() => isCreateAvailable && onGenerateCard()}
        style={{ marginLeft: '10px' }}
        primary
      >
        Generate VCN
      </Text>
    </Container>
  )
}

NoCard.propTypes = {
  onGenerateCard: PropTypes.func.isRequired,
  isCreateAvailable: PropTypes.bool.isRequired,
  benefitId: PropTypes.string.isRequired,
  employeePublicKey: PropTypes.string,
  currentHealthPlanId: PropTypes.string,
}

NoCard.defaultProps = {
  availableCards: [],
  employeePublicKey: '',
  currentHealthPlanId: '',
}

export default NoCard
