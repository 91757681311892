import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getWaiter, connectWaiter, getWaiterResponse } from 'redux-waiter'
import { healthPlanService } from '@hixme/api'
import { actions } from 'modules/app-core/notification-manager'
import { ContributionGroupsSelect } from '../components'

const waiterName = 'get-client-contribution-groups'

const fallbackContributionGroups = [
  'F/T Salaried Exempt',
  'F/T Salaried Non-Exempt',
  'F/T Hourly Non-Exempt',
  'P/T Hourly Non-Exempt',
  'No Contribution',
  'default',
]

export default compose(
  connect((state) => {
    const response = getWaiterResponse(state, waiterName)
    return {
      // hardcoded fallback
      list: response
        ? response.map((opt) => opt.Name)
        : fallbackContributionGroups,
      isPending: getWaiter(state, waiterName).isPending,
    }
  }),
  connectWaiter({
    name: waiterName,
    clearOnUnmount: true,
    requestOnMountParams: ({ companyId }) => ({ ClientPublicKey: companyId }),
    requestCreator: (_, dispatch) =>
      healthPlanService({
        route: 'contribution-group',
      })
        .then((res) => res)
        .catch(() => {
          dispatch(
            actions.createErrorNotification(
              'Sorry, we could not retrieve contribution groups.'
            )
          )
        }),
  })
)(ContributionGroupsSelect)
