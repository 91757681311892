import { SimpleCustomerDto } from '../../../api/persons/dto/simple-customer.dto'
import { Modal, message } from 'antd'
import ClientSelect from '../../../../components/ClientSelect'
import { Typography, Button } from '@sureco/design-system'
import React, { useEffect, useRef, useState } from 'react'
import { getMyClients } from '../../../api/persons'

function CensusManagementUploadModal({ open, onOk, onCancel }: {
  open: boolean
  onOk: (FormData) => void
  onCancel: () => void
}) {
  const [clients, setClients] = useState<SimpleCustomerDto[]>([])
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [selectedClient, setSelectedClient] = useState<SimpleCustomerDto | undefined>()

  useEffect(() => {
    const fetchClients = async () => {
      const data = await getMyClients()
      const sortedClients = data.sort((a, b) => a.name.localeCompare(b.name))
      setClients(sortedClients)
    }
    fetchClients()
  }, [])

  const handleCompanyChange = (value: string) => {
    const c = clients.find((c) => c.id === value)
    setSelectedClient(c)
  }
  useEffect(() => {
    setSelectedFile(null)
  }, [open])

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setSelectedFile(file)
    }
  }

  const openFileDialog = () => {
    fileInputRef.current?.click() // Programmatically open the file dialog
  }

  const handleUpload = async () => {
    if (!selectedFile || !selectedClient) {
      message.error('Please select a file and a client before uploading.')
      return
    }

    try {
      const formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('clientId', selectedClient.id)
      onOk(formData)
    } catch (error) {
      message.error('Failed to upload file')
    }
  }

  return (
    <Modal title="Upload Census" open={open} onOk={handleUpload} onCancel={onCancel} okText="Upload CSV">
      <div>
        <Typography size={14}>Company:</Typography>
        <ClientSelect
          companies={clients.map(({ name, id }) => ({ companyName: name, id: id }))}
          defaultCompanyId={selectedClient?.id}
          onChange={handleCompanyChange}
        />
      </div>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileSelect} />
      <Button style={{ marginTop: '1em' }} onClick={openFileDialog}>
        Select File
      </Button>
      {selectedFile && <p>Selected File: {selectedFile.name}</p>}
    </Modal>
  )
}

export default CensusManagementUploadModal
