import { connect } from 'react-redux'
import { compose } from 'recompose'
import { reduxForm } from 'redux-form'
import modal from 'modules/app-core/modal'
import EmpTerminationForm from '../../components/EmployeeForm/components/EmpTerminationForm'

const mapDispatchToProps = (dispatch) => ({
  onCancel(e) {
    e.preventDefault()
    dispatch(modal.actions.hideModal())
  },

  onConfirm: () => {},
})

const composedEmpTerminationForm = compose(
  connect(mapDispatchToProps),
  reduxForm({
    form: 'Emp_Termination_Form',
    enableReinitialize: true,
  })
)
export default composedEmpTerminationForm(EmpTerminationForm)
