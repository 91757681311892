import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import UploadBox from '@hixme-ui/upload-box'

// project
import FieldError from '../FieldError'

const FieldUploadBox = (props) => {
  const { input, meta, handleDrop, ...restProps } = props

  const onDrop = (files) => {
    handleDrop(input, files)
  }
  const onChange = () => {}

  return (
    <div>
      <UploadBox
        {...input}
        {...restProps}
        onDrop={onDrop}
        onChange={onChange}
      />
      <FieldError meta={meta} />
    </div>
  )
}
FieldUploadBox.displayName = 'FieldUploadBox'
FieldUploadBox.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  handleDrop: PropTypes.func.isRequired,
}
export default FieldUploadBox
