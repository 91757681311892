/* eslint-disable import/prefer-default-export */
import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import FormGroup from '@hixme-ui/form-group'
import Select from '@hixme-ui/select'
import Label from '@hixme-ui/label'

export const ClientServicesRepFormGroup = (props) => (
  <FormGroup>
    <Label>Client services rep</Label>
    <ClientServicesRepDropdown {...props.input} list={props.list} />
  </FormGroup>
)

ClientServicesRepFormGroup.displayName = 'ClientServicesRepFormGroup'
ClientServicesRepFormGroup.propTypes = {
  input: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
}

const ClientServicesRepDropdown = ({ list, ...props }) => {
  const reps = [
    { id: '', name: 'Select' },
    ...list.map((rep) => ({
      id: rep.ClientServiceRepId,
      name: `${rep.ClientServiceRepFirstName} ${rep.ClientServiceRepLastName}`,
    })),
  ]

  return <Select list={reps} {...props} />
}

ClientServicesRepDropdown.displayName = 'ClientServicesRepDropdown'
ClientServicesRepDropdown.propTypes = {
  list: PropTypes.array.isRequired,
}
