import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'
import { withRouter } from 'react-router'

import { actions, selectors } from '../'
import { WAITER_GET_EMPLOYEE_BENEFITS, WAITER_PUT_BENEFIT } from '../constants'

const mapStateToProps = (state, props) => {
  const benefitIdParam = props.params.benefitId
  const employeePublicKeyParam = props.params.id
  const benefit = selectors.getBenefit(state)(benefitIdParam)
  const gettingBenefit = getWaiter(
    state,
    `${WAITER_GET_EMPLOYEE_BENEFITS}${employeePublicKeyParam}`
  ).isPending
  const puttingBenefit = getWaiter(state, `${WAITER_PUT_BENEFIT}${benefit.Id}`)
    .isPending
  return {
    benefit,
    gettingBenefit,
    puttingBenefit,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBenefits: (EmployeePublicKey, EffectiveOn) => {
    dispatch(actions.getEmployeeBenefits(EmployeePublicKey, EffectiveOn))
  },
  putBenefit: ({ Id, BenefitEffectiveDate, BenefitEndDate }) => {
    dispatch(actions.putBenefit({ Id, BenefitEffectiveDate, BenefitEndDate }))
  },
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)
