import { browserHistory } from 'react-router'
import client from 'enrollment-platform/api/eps.client'
import { useMutation } from '@tanstack/react-query'
import { CUSTOMER_MANAGEMENT } from 'enrollment-platform/pages/CustomerManagement/paths'
import { notification } from '@surecompanies/core_components'

export default function useNewCustomer() {
  const createCustomer = async (data: any) => {
    try {
      const response = await client.post('/customers', {
        ...data,
        status: data.isActive ? 'ACTIVE' : 'INACTIVE',
      })
      notification.success({
        message: 'Customer successfully created',
      })
      browserHistory.push(`/${CUSTOMER_MANAGEMENT}/${response.data.id}/view`)
    } catch (error) {
      notification.error({
        message: 'Error creating customer',
      })
    }
  }

  const { mutate, isPending } = useMutation({
    mutationFn: createCustomer,
    mutationKey: ['createCustomer'],
  })
  return {
    mutate,
    isPending,
  }
}
