import React, { useRef } from 'react'
import { Stack } from '@sureco/design-system'
import { Button } from '@surecompanies/core_components'
import ContentSection from 'layouts/ContentSection/ContentSection'
import ContentPane from './components/ContentPane'
import useEnrollmentConfiguration from './hooks/useEnrollmentConfiguration'
import EnrollmentItem from './EnrollmentItem'
import EnrollmentForm, { FormRef } from './components/EnrollmentForm'
import dayjs from 'dayjs'
import EditableEnrollmentButton from './components/EditableEnrollmentButton'

type EnrollmentTabProps = {
  id: string;
};

export default function EnrollmentTab({ id }: EnrollmentTabProps) {
  const {
    data,
    onNewEnrollmentConfiguration,
    isCreating,
    isLoading,
    isCreatingOrUpdating,
    selectedEnrollmentId,
    setSelectedEnrollmentId,
    createOrUpdateEnrollmentConfiguration
  } = useEnrollmentConfiguration(id)
  const formRef = useRef<FormRef>(null)

  return (
    <Stack space={20}>
      <ContentSection
        loading={isLoading}
        title="Enrollment Configuration"
        actions={
          <Button
            variant={isCreating ? 'outlined' : 'solid'}
            type={isCreating ? 'default' : 'primary'}
            onClick={onNewEnrollmentConfiguration}
          >
            {isCreating ? 'Cancel' : 'Add New'}
          </Button>
        }
      >
        {isCreating && (
          <ContentPane
            title="Add New Enrollment"
            header={
              <Button
                variant="solid"
                type="primary"
                onClick={() => formRef?.current?.submit()}
                loading={isCreatingOrUpdating}
              >
                Save
              </Button>
            }
          >
            <EnrollmentForm
              ref={formRef}
              loading={isCreatingOrUpdating}
              onFormSubmit={createOrUpdateEnrollmentConfiguration}
            />
          </ContentPane>
        )}
        {data?.map((enrollment) => (
          <ContentPane
            key={enrollment.id ?? enrollment.benefitEffectiveDate}
            title={`Enrollment ${dayjs(enrollment.benefitEffectiveDate).format(
              'YYYY'
            )}`}
            header={
              enrollment.isEditable && enrollment.id ? (
                <Stack direction="row" space={10}>
                  <EditableEnrollmentButton
                    isEditing={selectedEnrollmentId === enrollment?.id?.toString()}
                    onEdit={() => setSelectedEnrollmentId(enrollment?.id?.toString())}
                    onCancel={() => setSelectedEnrollmentId(null)}
                  />
                  {selectedEnrollmentId === enrollment?.id?.toString() && <Button type="primary" onClick={() => formRef?.current?.submit()} loading={isCreatingOrUpdating}>
                    Save
                  </Button>}
                </Stack>
              ) : undefined
            }
          >
            {selectedEnrollmentId === enrollment?.id?.toString() ? (
              <EnrollmentForm
                ref={formRef}
                initialValues={{
                  ...enrollment,
                  benefitEffectivePolicy: enrollment.benefitEffectivePolicy.toString(),
                  enrollmentDates: [
                    dayjs(enrollment.enrollmentStartDate, 'YYYY-MM-DD'),
                    dayjs(enrollment.enrollmentEndDate, 'YYYY-MM-DD'),
                  ],
                  benefitEffectiveDate: dayjs(enrollment.benefitEffectiveDate, 'YYYY-MM-DD').valueOf(),
                }}
                loading={isCreatingOrUpdating}
                onFormSubmit={createOrUpdateEnrollmentConfiguration}
              />
            ) : (
              <EnrollmentItem config={enrollment} />
            )}
          </ContentPane>
        ))}
      </ContentSection>
    </Stack>
  )
}
