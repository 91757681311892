import React from 'react'
import PropTypes from 'prop-types'

// project
import { FieldSelect } from 'components/ReduxFormComponents'

const ContributionGroupsSelect = ({ isPending, list, ...props }) => {
  const groups = list.map((group) => ({
    id: group,
    name: group,
  }))
  return (
    <FieldSelect
      list={groups}
      {...props}
      loading={isPending}
      disabled={!list || list.length === 0}
      placeholder="Select..."
    />
  )
}

ContributionGroupsSelect.propTypes = {
  isPending: PropTypes.bool,
  list: PropTypes.array,
}

ContributionGroupsSelect.defaultProps = {
  isPending: true,
  list: [],
}

export default ContributionGroupsSelect
