import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Select from '@hixme-ui/select'

// project
import LoadingSelect from 'components/LoadingSelect'

const CompanyPlansSelect = ({ list, ...props }) => {
  const prependedOption = [{ id: '', name: 'Select' }]
  if (list === undefined) {
    return <LoadingSelect />
  } else if (list.length === 0) {
    return <Select list={prependedOption} disabled />
  }
  const plans = list.map(({ Name }) => ({
    id: Name,
    name: Name,
  }))
  return (
    <Select list={[...prependedOption, ...plans]} {...props} {...props.input} />
  )
}

CompanyPlansSelect.propTypes = {
  list: PropTypes.array,
  input: PropTypes.object,
}

CompanyPlansSelect.defaultProps = {
  list: [],
  input: {},
}

export default CompanyPlansSelect
