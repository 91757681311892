import React, { useEffect } from 'react'

import { Dropdown, DropdownProps } from '@surecompanies/core_components'
import { Company } from 'types/company'

export type HeaderCompanySelectorProps = {
  companyList: Company[]
  currentCompanyId?: string
  includeProspects?: boolean
  includeTestCompanies?: boolean
  includeInactive?: boolean
  onChange?: (companyId: string) => void
} & Omit<DropdownProps, 'menu' | 'dropdownRender'>

export const HeaderCompanySelector = ({
  companyList = [],
  currentCompanyId,
  includeProspects = true,
  includeTestCompanies = true,
  includeInactive = true,
  onChange,
  ...props
}: HeaderCompanySelectorProps) => {
  const list = companyList
    .filter(({ isProspect, isActive, isTestClient }) => {
      if (
        (!includeProspects && isProspect) ||
        (!includeTestCompanies && isTestClient) ||
        (!includeInactive && !isActive)
      ) {
        return false
      }
      return true
    })
    .sort((a, b) => {
      const aName = a.companyName.toLowerCase()
      const bName = b.companyName.toLowerCase()
      if (aName > bName) return 1
      if (aName < bName) return -1
      return 0
    })

  useEffect(() => {
    // in case where `userSessionActions.setCurrentCompany` call was faulty (like in bug/SAFE-321)
    // then set user selected company to the first available company
    if (!currentCompanyId && list.length > 0) {
      onChange?.(list[0].id)
    }
  }, [currentCompanyId])

  return (
    <Dropdown
      disabled={props.disabled || list.length === 0}
      trigger={['click']}
      menu={{
        items: list.map((com) => ({
          key: com.id,
          label: com.companyName,
          onClick: () => {
            onChange?.(com.id)
          },
        })),
        selectable: true,
        defaultSelectedKeys: currentCompanyId ? [currentCompanyId] : undefined,
      }}
      dropdownRender={(menu) => (
        <div
          style={{
            maxHeight: 480,
            overflow: 'auto',
            boxShadow: '0px 3px 10px 0px #AAB5BD',
          }}
        >
          {menu}
        </div>
      )}
      {...props}
    />
  )
}
