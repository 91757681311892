import React from 'react'
import styled from 'styled-components'

// hixme-ui
import Text from '@hixme-ui/text'
import Icon from '@hixme-ui/icon'

export type SortableThProps = React.HTMLAttributes<HTMLTableCellElement> & {
  showIcon?: boolean
  sortDescending?: boolean
  light?: boolean
  bold?: boolean
  align?: 'center' | 'left' | 'right' | 'justify' | 'char'
}

const Th = styled.th<{ align: string }>`
  text-align: ${(props) => props.align ?? 'left'};
  user-select: none;
`
const IconWrapper = styled.span<
  Pick<SortableThProps, 'showIcon' | 'sortDescending'>
>`
  ${(props) => (!props.showIcon ? 'display: none;' : '')}
  position: relative;
  span {
    position: relative;
    top: 3px;
  }
  svg {
    left: 2px;
    top: 3px;
    transform: ${(props) =>
      props.sortDescending ? 'none' : 'rotate(0.5turn)'};
    position: absolute;
  }
`

const ContentWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
`

const SortableTh = ({
  light = false,
  bold = false,
  showIcon = false,
  sortDescending = true,
  align = 'center',
  children,
  ...restProps
}: SortableThProps) => (
  <Th align={align} {...restProps}>
    <ContentWrapper>
      <Text light={light} fontWeight={bold ? '600' : '200'}>
        {children}
      </Text>
      <IconWrapper showIcon={showIcon} sortDescending={sortDescending}>
        <Icon arrowDown light />
      </IconWrapper>
    </ContentWrapper>
  </Th>
)

export default SortableTh
