import validator from 'validator'
import apolloClient from 'apollo/client'
import { initializeLDClient, getLDClient } from 'helpers/launchDarklyClient'
import { GET_ADDRESS_VALIDATION } from 'apollo/address'
import { change } from 'redux-form'

export default (values) => {
  const errors = {}

  const {
    AlternatePhoneNumber,
    AlternatePhoneType,
    City,
    CompanyEmployeeId,
    County,
    DateOfBirth,
    EmploymentStatus,
    EmailAddress,
    BEDCheckbox,
    EnrollmentStartDate,
    EnrollmentEndDate,
    ExistingMedicalPlanEnrollmentTier,
    ExistingMedicalPlanName,
    ExistingMedicalPlanWaived,
    FirstName,
    Gender,
    HireDate,
    Id,
    LastName,
    MarriageStatus,
    PayrollCycle,
    PhoneNumber,
    PhoneType,
    PostalCode,
    Salary,
    SSN,
    StreetAddress,
    MailingAddressSameAsResidential,
    MailingStreetAddress,
    MailingCity,
    MailingPostalCode,
    MailingCounty,
  } = values

  /* eslint-disable */
  const exposePoBox = /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i
  /* eslint-disable */
  const validName = /^[a-z ,.'-]+$/i
  const validPhone = /\(\d{3}\) \d{3} - \d{4}|\d{10}/

  // If Id has value, we are validating the edit form
  const validatingEditForm = Id || false

  if (!EmailAddress && !PhoneNumber) {
    errors.EmailAddress = 'Valid email address or phone is required'
  } else if (EmailAddress && !validator.isEmail(EmailAddress)) {
    errors.EmailAddress = 'Valid email address is required'
  }

  if (AlternatePhoneNumber && !validPhone.test(AlternatePhoneNumber)) {
    errors.AlternatePhoneNumber = 'Valid phone number is required'
  } else if (AlternatePhoneNumber && validPhone.test(AlternatePhoneNumber)) {
    if (!AlternatePhoneType) {
      errors.AlternatePhoneType = 'Phone type required'
    }
  }
  if (!City) {
    errors.City = 'City is required'
  }
  if (!CompanyEmployeeId) {
    errors.CompanyEmployeeId = 'Company ID is required'
  }
  if (!County || typeof County !== 'string' || County === '') {
    errors.County = 'County/State selection is required'
  }
  if (!isCurrentDateInRange(DateOfBirth)) {
    errors.DateOfBirth = 'Valid date of birth is required'
  }
  if (!EmploymentStatus) {
    errors.EmploymentStatus = 'Employment status is required'
  }
  if (!BEDCheckbox) {
    errors.BEDCheckbox =
      'Please check the box or change the benefits effective date'
  }
  if (EnrollmentStartDate && !validator.isDate(EnrollmentStartDate)) {
    errors.EnrollmentStartDate = 'Valid date is required'
  }
  if (EnrollmentEndDate && !validator.isDate(EnrollmentEndDate)) {
    errors.EnrollmentEndDate = 'Valid date is required'
  }
  if (ExistingMedicalPlanWaived === false) {
    if (!ExistingMedicalPlanName) {
      errors.ExistingMedicalPlanName = 'Plan name is required'
    }
    if (!ExistingMedicalPlanEnrollmentTier) {
      errors.ExistingMedicalPlanEnrollmentTier = 'Selection is required'
    }
  }
  if (!FirstName || !validName.test(FirstName)) {
    errors.FirstName = 'First Name is required'
  }
  if (!Gender) {
    errors.Gender = 'Gender is required'
  }
  if (!isCurrentDateInRange(HireDate)) {
    errors.HireDate = 'Valid hire date is required'
  }
  if (!LastName || !validName.test(LastName)) {
    errors.LastName = 'Last Name is required'
  }
  if (!MarriageStatus) {
    errors.MarriageStatus = 'Marriage status is required'
  }
  if (PhoneNumber && !validPhone.test(PhoneNumber)) {
    errors.PhoneNumber = 'Valid phone number is required'
  } else if (PhoneNumber && validPhone.test(PhoneNumber)) {
    if (!PhoneType) {
      errors.PhoneType = 'Phone type required'
    }
  }
  if (
    !PostalCode ||
    !validator.isNumeric(PostalCode) ||
    !validator.isLength(PostalCode, { min: 5, max: 5 })
  ) {
    errors.PostalCode = 'A valid 5 digit Postal Code is required'
  }

  const validSSN = /\d{3} - \d{2} - \d{4}/
  const ssnErrorMessage = 'A valid social security number is required.'
  if (SSN) {
    // Check if a mask char exists in SSN, i.e. a hyphen '-'
    if (SSN.indexOf('-') !== -1 && !validSSN.test(SSN)) {
      errors.SSN = ssnErrorMessage

      // SSN does not have mask chars, so just check length
    } else if (
      SSN.indexOf('-') === -1 &&
      (!validator.isNumeric(SSN) ||
        !validator.isLength(SSN, { min: 9, max: 9 }))
    ) {
      errors.SSN = ssnErrorMessage
    }
  } else {
    errors.SSN = ssnErrorMessage
  }
  if (!Salary) {
    errors.Salary = 'Amount is required'
  }
  if (!StreetAddress) {
    errors.StreetAddress = 'Street Address is required'
  }
  if (exposePoBox.test(StreetAddress)) {
    errors.StreetAddress = 'PO Boxes are not allowed'
  }
  if (PayrollCycle === 0) {
    errors.PayrollCycle = 'Payroll cycle is required'
  }

  // mailing address error
  if (!MailingAddressSameAsResidential) {
    if (!MailingStreetAddress) {
      errors.MailingStreetAddress = 'Mailing Street Address is required'
    }

    if (
      !MailingPostalCode ||
      !validator.isNumeric(MailingPostalCode) ||
      !validator.isLength(MailingPostalCode, { min: 5, max: 5 })
    ) {
      errors.MailingPostalCode = 'A valid 5 digit Postal Code is required'
    }

    if (!MailingCity) {
      errors.MailingCity = 'City is required'
    }

    if (
      !MailingCounty ||
      typeof MailingCounty !== 'string' ||
      MailingCounty === ''
    ) {
      errors.MailingCounty = 'County/State selection is required'
    }
  }

  return errors
}

/**
 * Validates if a date is recent enough to the current date.
 * @param {string} dateString - The date string in "YYYY-MM-DD" format
 * @param {number} allowedYearsInThePast - The number of years allowed in the past considered valid
 * @returns {boolean} - True if the date is valid and within range of the current date, otherwise false
 */
function isCurrentDateInRange(dateString, allowedYearsInThePast = 100) {
  const inputDate = new Date(dateString)

  // Check if this is a real date
  if (isNaN(inputDate.getTime())) {
    return false
  }

  const maxDate = new Date()
  const minDate = new Date(maxDate)
  minDate.setFullYear(maxDate.getFullYear() - allowedYearsInThePast)

  // Check if the current date is recent enough to now and the oldest year allowed
  return inputDate >= minDate && inputDate <= maxDate
}

const requestAddressValidation = (payload) => {
  return apolloClient
    .query({
      query: GET_ADDRESS_VALIDATION,
      variables: {
        input: {
          address: payload.address,
          filters: payload.filters ?? {
            excludePOBox: true,
            acceptPartialAddresses: true,
          },
        },
      },
      fetchPolicy: 'network-only',
    })
    .then((r) => r.data?.addressValidate)
}

const runAddressValidation = (type) => async (values, dispatch, props) => {
  const dataKeyMapper = {
    residential: {
      street: 'StreetAddress',
      streetExt: 'StreetAddressExt',
      city: 'City',
      county: 'County',
      zip: 'PostalCode',
      state: 'StateProvince',
      warningFieldName: 'StreetAddressValidationResult',
    },
    mailing: {
      street: 'MailingStreetAddress',
      streetExt: 'MailingStreetAddressExt',
      city: 'MailingCity',
      county: 'MailingCounty',
      zip: 'MailingPostalCode',
      state: 'MailingStateProvince',
      warningFieldName: 'MailingStreetAddressValidationResult',
    },
  }

  const data = {
    addressLine1: values[dataKeyMapper[type].street],
    addressLine2: values[dataKeyMapper[type].streetExt],
    city: values[dataKeyMapper[type].city],
    county: values[dataKeyMapper[type].county],
    zipCode: values[dataKeyMapper[type].zip],
  }

  const { countyHash } = props
  const stateProvince = countyHash[data.county]?.StateProvince ?? ''

  const isAllAddressFieldsFilled =
    data.addressLine1 && data.city && data.zipCode && stateProvince

  if (!isAllAddressFieldsFilled) {
    // incomplete data so don't trigger yet
    return Promise.resolve(null)
  }

  const validationResult = await requestAddressValidation({
    address: {
      ...data,
      stateCode: stateProvince,
    },
    filters: {
      excludePOBox: type === 'residential',
      acceptPartialAddresses: true,
    },
  })

  dispatch(
    change(
      props.form,
      dataKeyMapper[type].warningFieldName,
      validationResult.result ?? 'NOT_VERIFIED'
    )
  )

  return validationResult
}

export const validateAddress = async (values, dispatch, props) => {
  let isAddressVerificationEnabled = false
  try {
    await initializeLDClient()
    const ldClient = getLDClient()
    // Check a feature flag
    isAddressVerificationEnabled = ldClient.variation(
      'feature-address-verification',
      false
    )
  } catch (e) {
    console.error('Initializing LD Client (No React) Failed')
    console.error(e)
  }

  // When there are values in StreetAddress && street input fields has been changed, then trigger validation
  // Otherwise skip validation as admins should not be blocked due to old invalid street address
  //
  // The case where no street address (add employee) will be handled by regular validation (street address is required etc)
  const shouldValidate =
    Boolean(values.StreetInputHasChanged) && Boolean(values.StreetAddress)

  if (!isAddressVerificationEnabled || !shouldValidate) {
    return Promise.resolve({})
  }

  const residentialValidationResult = await runAddressValidation('residential')(
    values,
    dispatch,
    props
  )
  const mailingValidationResult = await runAddressValidation('mailing')(
    values,
    dispatch,
    props
  )

  const residentialError =
    residentialValidationResult?.result === 'NOT_VERIFIED'
      ? { StreetAddress: 'Please double check your address' }
      : null
  const mailingError =
    mailingValidationResult?.result === 'NOT_VERIFIED'
      ? { MailingStreetAddress: 'Please double check your mailing address' }
      : null

  if (!residentialError && !mailingError) {
    return Promise.resolve({})
  }

  return Promise.reject({
    ...mailingError,
    ...residentialError,
  })
}

export const checkAddressWarning = (values) => {
  const warnings = {}
  if (values.StreetAddressValidationResult === 'PARTIALLY_VERIFIED') {
    warnings.StreetAddress = 'We could not fully verify this address'
  }
  if (values.MailingStreetAddressValidationResult === 'PARTIALLY_VERIFIED') {
    warnings.MailingStreetAddress =
      'We could not fully verify this mailing address'
  }

  return warnings
}
