import React from 'react'
import { FieldValues } from 'react-hook-form'
import RHFSelect, { RHFSelectProps } from './hookform/Select/RHFSelect'
import statesList from 'data/states-list'

export default function StateSelect<
  TFieldValues extends FieldValues = FieldValues
>({ control, name, ...rest }: RHFSelectProps<TFieldValues>) {
  return (
    <RHFSelect
      control={control}
      name={name}
      {...rest}
      options={statesList.map((state) => ({
        label: state.abbreviation,
        value: state.id,
      }))}
    />
  )
}
