export const NAME = 'receiptLineItems'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const UPDATE_RECEIPTS = 'UPDATE_RECEIPTS'
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION'
export const ADD_LINE_ITEM = 'ADD_LINE_ITEM'
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM'
export const CLEAR_LINE_ITEMS = 'CLEAR_LINE_ITEMS'
export const RESET = 'RESET'
export const ADD_LOADING = 'ADD_LOADING'

// Waiter
export const CREATE_LINE_ITEMS_REIMBURSEMENT = 'CREATE_LINE_ITEMS_REIMBURSEMENT'
export const DELETE_LINE_ITEMS_REIMBURSEMENT = 'DELETE_LINE_ITEMS_REIMBURSEMENT'
export const UPDATE_REIMBURSEMENT = 'UPDATE_REIMBURSEMENT'

export default [
  NAME,
  UPDATE_STATUS,
  UPDATE_RECEIPTS,
  UPDATE_DESCRIPTION,
  ADD_LINE_ITEM,
  REMOVE_LINE_ITEM,
  CREATE_LINE_ITEMS_REIMBURSEMENT,
  UPDATE_REIMBURSEMENT,
  CLEAR_LINE_ITEMS,
  RESET,
  ADD_LOADING,
]
