import React from 'react'
import { Select } from 'antd'

const { Option } = Select

interface ClientSelectProps {
  companies: { id: string; companyName: string }[];
  defaultCompanyId?: string;
  selectedCompanyId?: string;
  selectPlaceholder?: string;
  onChange: (selectedCompanyId: string) => void;
  allCompaniesPlacholder?: { id: string, companyName: string };
}

function ClientSelect({ allCompaniesPlacholder, companies, selectedCompanyId, defaultCompanyId, onChange, selectPlaceholder = 'Select a company' }: ClientSelectProps) {
  let selectCompanies = [...companies]
  selectCompanies.sort((a, b) => b.companyName.toLowerCase() > a.companyName.toLowerCase() ? -1 : 1)
  if (allCompaniesPlacholder) {
    selectCompanies = [allCompaniesPlacholder, ...selectCompanies]
  }

  const handleCompanyChange = (value: string) => {
    onChange(value)
  }

  return (
    <Select
      showSearch
      optionFilterProp="children" // filter by what's rendered (companyName)
      placeholder={selectPlaceholder}
      style={{ width: 200 }}
      onChange={handleCompanyChange}
      value={selectedCompanyId || defaultCompanyId}
      defaultValue={defaultCompanyId || undefined}
    >
      {selectCompanies.map(({ id, companyName }) => (
        <Option key={id} value={id}>
          {companyName}
        </Option>
      ))}
    </Select>
  )
}

export default ClientSelect
