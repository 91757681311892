import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { selectors } from '@hixme/auth-ui'
import styled from 'styled-components'

const HeaderContainer = styled.section`
  margin-top: 10px;
  color: white;
  p {
    opacity: 0.7;
  }
`

const NavigationHeader = () => {
  const userName = useSelector((state) => {
    const { firstname, lastname } = selectors.getCredentials(state)
    return `${firstname} ${lastname}`
  }, shallowEqual)
  return (
    <HeaderContainer>
      <p>Signed in</p>
      <h4>{userName}</h4>
    </HeaderContainer>
  )
}

export default NavigationHeader
