import { z } from 'zod'

const URL_REGEX = /^(https?):\/\/(?=.*\.[a-z]{2,})[^\s$.?#].[^\s]*$/i

export const IntegrationSchema = z.object({
  isCensusSyncEnabled: z.boolean(),
  isHRISNotificationDisabled: z.boolean(),
  automaticStopCoverage: z.boolean(),
  webHookUrl: z.string().refine((val) => {
    if (!val.length) return true
    return URL_REGEX.test(val)
  }, {
    message: 'Please enter a valid webhook URL',
  }),
})
