import { namespaceActions } from 'modules/app-core/redux-utils'
import { NAME } from './constants'

const SHOW_QUICKSIGHT_DASHBOARD = 'SHOW_QUICKSIGHT_DASHBOARD'
const HIDE_QUICKSIGHT_DASHBOARD = 'HIDE_QUICKSIGHT_DASHBOARD'

export default namespaceActions(NAME)([
  SHOW_QUICKSIGHT_DASHBOARD,
  HIDE_QUICKSIGHT_DASHBOARD,
])
