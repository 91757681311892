import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Text from '@hixme-ui/text'

const BulletItem = ({ id, item }) => (
  <div key={id} style={{ lineHeight: '25px' }}>
    <Text style={{ margin: '0 5px 0 0' }} thin light>
      {item.title}
    </Text>
    <Text>{item.text}</Text>
  </div>
)

BulletItem.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
}

export default BulletItem
