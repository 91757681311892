import React from 'react'
import PropTypes from 'prop-types'
import { MrModal } from '@hixme/modal'

// hixme-ui
import Container from '@hixme-ui/container'
import Table from '@hixme-ui/table'
import Text from '@hixme-ui/text'

/* eslint-disable react/no-array-index-key */
const CsvErrorsModal = ({ errors }) => (
  <MrModal medium title="CSV Upload Errors">
    <Container>
      <Text p>
        The following errors prevented the CSV from being uploaded. To proceed,
        please fix the errors and re-upload.
      </Text>
    </Container>
    <Container
      marginBottom="10px"
      noPadding
      maxHeight="500px"
      style={{ overflowY: 'auto' }}
    >
      <Table striped>
        <thead>
          <tr>
            <th>Row</th>
            <th>Column Name</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {errors.map((error, index) => (
            <tr key={`csv-error-${index}`}>
              <td>{error.row}</td>
              <td>{error.columnName}</td>
              <td>
                {error.message.replace(/^\w/, (char) => char.toUpperCase())}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  </MrModal>
)

CsvErrorsModal.propTypes = {
  errors: PropTypes.array,
}
CsvErrorsModal.defaultProps = {
  errors: [],
}
CsvErrorsModal.displayName = 'CsvErrorsModal'

export default CsvErrorsModal
