import React from 'react'
import { browserHistory } from 'react-router'
import useFeatureFlags from 'hooks/useFeatureFlags'
import PageWrapper from 'enrollment-platform/PageWrapper'

export default function CustomerGate({
  children,
}: {
  children: React.ReactNode
}) {
  const { flags } = useFeatureFlags()
  if (!flags['ui.customer.new-experience']) {
    browserHistory.push('/')
  }
  return <PageWrapper>{children}</PageWrapper>
}
