import { createReducer } from 'modules/app-core/redux-utils'

import personReducer from './person-reducer'
import t from './actionTypes'

// initialState
const initialState = {
  list: {},
  status: {},
}

export default createReducer(initialState, {
  [t.RECEIVE_PERSON_LIST]: (state, payload) => {
    if (payload && payload.length) {
      const list = payload.reduce(
        (memo, person) => {
          // performance is lost when newing up a new list to return for each iteration
          // eslint-disable-next-line no-param-reassign
          memo[person.Id] = personReducer(state.list[person.Id], {
            type: t.RECEIVE_PERSON,
            payload: person,
          })
          return memo
        },
        { ...state.list }
      )

      return { ...state, list }
    }

    return state
  },
  [t.LOAD_PERSON_STATUS]: (state, payload) => ({
    ...state,
    status: payload,
  }),
  [t.RECEIVE_PERSON]: (state, payload) => {
    const person = personReducer(state.list[payload.Id], {
      type: t.RECEIVE_PERSON,
      payload,
    })
    const list = {
      ...state.list,
      [payload.Id]: person,
    }

    return { ...state, list }
  },
  [t.UPDATE_PERSON]: (state, payload) => {
    const [person] = Object.entries(payload)
    const [id, values] = person
    if (!state.list[id]) return state
    return {
      ...state,
      list: {
        ...state.list,
        [id]: {
          ...state.list[id],
          ...values,
        },
      },
    }
  },
})
