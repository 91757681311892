import './CensusReview.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { Layout } from '@surecompanies/core_components'
import Page from 'layouts/Page'
import PageWrapper from '../../../../PageWrapper'
import { SimpleCustomerDto } from '../../../../api/persons/dto/simple-customer.dto'
import { getMyClients } from 'enrollment-platform/api/persons'
import ClientSelect from '../../../../../components/ClientSelect'
import NewAndUpdatedRecords from '../components/NewAndUpdatedRecords'
import ActivityHistory from '../components/ActivityHistory'
import BreadcrumbNav from '../../../../components/Breadcrumbs'
import { withRouter } from 'react-router'

const { Content } = Layout

function CensusReview() {
  const [selectedClient, setSelectedClient] = useState<SimpleCustomerDto | undefined>()
  const [clients, setClients] = useState<SimpleCustomerDto[]>([])

  const handleCompanyChange = (value: string) => {
    const c = clients.find((c) => c.id === value)
    setSelectedClient(c)
  }

  const fetchClients = async () => {
    const data = await getMyClients()
    setClients(data.sort((a, b) => a.name.localeCompare(b.name)))
  }

  const clientNameLookup = useMemo(() => {
    return clients.reduce((acc, client) => {
      acc[client.id] = client.name
      return acc
    }, {})
  }, [clients])

  useEffect(() => {
    fetchClients()
  }, [])

  return (
    <PageWrapper>
      <Page title="Census Review"
            horizontalRule={true}
            action={(
              <ClientSelect
                allCompaniesPlacholder={{ id: '', companyName: 'All Companies' }}
                companies={clients.map(({ name, id }) => ({ companyName: name, id: id }))}
                defaultCompanyId={''}
                selectedCompanyId={selectedClient?.id}
                onChange={handleCompanyChange}
              />
            )}
            breadcrumb={<BreadcrumbNav
              items={[
                { title: 'Census Review' },
              ]}
            />}
      >
        <Layout style={{ backgroundColor: '#f6f9fc' }}>
          <Content style={{}}>
            <NewAndUpdatedRecords
              selectedClientId={selectedClient?.id}
              clientNameLookup={clientNameLookup}
            />
            <ActivityHistory
              selectedClientId={selectedClient?.id}
              clientNameLookup={clientNameLookup}
            />
          </Content>
        </Layout>
      </Page>
    </PageWrapper>
  )
}

export default withRouter(CensusReview)
