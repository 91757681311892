/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// hixme tools
import adminGateway from '@hixme/admin-api-gateway'
import {
  AuthenticatedComponent,
  logout,
  selectors as authSelectors,
} from '@hixme/auth-ui'
import {
  ROLE_BROKER,
  ROLE_PLATFORM_HIXME_ADMIN,
  ROLE_CLIENT_ADMIN,
  ROLE_HIXME_SALES_REP,
} from '@hixme/role-policy'
import { setGateway } from 'modules/redux-gateway'
import appConfig from 'modules/app-config'

// project
import { selectors as companySelectors } from 'store/modules/companies'
import { actions as layoutActions } from 'modules/layout'
import userSession from 'store/modules/user-session'

import AdminLayoutComponent from './AdminLayout'

// allowed roles for login

class AdminLayout extends Component {
  UNSAFE_componentWillMount() {
    const { dispatch } = this.props
    // TODO: remove this when the API can hinder logins for other roles
    // Redirect user to login if not a hixme admin
    if (
      ![
        ROLE_PLATFORM_HIXME_ADMIN,
        ROLE_CLIENT_ADMIN,
        ROLE_HIXME_SALES_REP,
        ROLE_BROKER,
      ].includes(this.props.credentials.userRole)
    ) {
      this.props.dispatch(this.props.logout())
      window.location = '/login'
    }

    // turn off session until user roles are defined
    this.initializeAPIGateway(this.props)
    dispatch(userSession.actions.initializeUser())
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.token !== this.props.token) {
      this.initializeAPIGateway(props)
    }
  }

  initializeAPIGateway(props) {
    // Setup AWS API Gateway and redux-gateway
    const { credentials } = props
    if (credentials) {
      const gateway = adminGateway.newClient(
        this.props.credentials,
        appConfig.getStage()
      )
      setGateway(gateway)
    }
  }

  render() {
    const { children, ...restProps } = this.props
    return (
      <AdminLayoutComponent {...restProps}>{children}</AdminLayoutComponent>
    )
  }
}

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
  credentials: PropTypes.object,
  token: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  companies: PropTypes.array,
}

AdminLayout.defaultProps = {
  credentials: {},
  token: null,
  companies: [],
}

const mapStateToProps = (state) => ({
  companies: companySelectors.companyCollectionSelector(state),
  credentials: authSelectors.getCredentials(state),
  token: authSelectors.getToken(state),
})

export default connect(
  mapStateToProps,
  (dispatch) =>
    Object.assign(bindActionCreators(layoutActions, dispatch), {
      dispatch,
      logout,
    })
)(AuthenticatedComponent(AdminLayout))
