import React from 'react'
import { Stack, Typography } from '@sureco/design-system'
import ContentSection from 'layouts/ContentSection/ContentSection'
import AdminItem from './components/AdminItem'
import useAdmins from './hooks/useAdmins'
import { AutoComplete } from '@surecompanies/core_components'
export default function AdminTab({ id }: { id: string }) {
  const { admins, onUpdateAdmin, isUpdatingAdminId, isLoading, onSearchPerson, suggestion } = useAdmins(id)
  return (
    <Stack space={20}>
      <ContentSection loading={isLoading}>
        <Stack space={20}>
          <Stack>
          <AutoComplete
            options={suggestion}
            style={{ width: 300 }}
            onSelect={(_, option) => onUpdateAdmin({ id: option.id, value: { isAdmin: true } })}
            placeholder="Search for an admin"
            onSearch={(text) => onSearchPerson(text)}
            notFoundContent={<Typography size={14}>No persons found</Typography>}
          />
          </Stack>
          {admins.length ? (
            admins?.map((admin) => (
              <AdminItem
                key={admin.id}
                admin={admin}
                isUpdating={isUpdatingAdminId === admin.id}
                onUpdateAdmin={(id, value) => onUpdateAdmin({ id, value })}
              />
            ))
          ) : (
            <Stack align="center" justify="center" >
              <Typography size={14}>
                No Admins found
              </Typography>
            </Stack>
          )}
        </Stack>
      </ContentSection>
    </Stack>
  )
}
