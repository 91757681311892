export const NAME = 'census'

// Waiter call names
export const ARCHIVE_CENSUS = 'ARCHIVE_CENSUS'
export const DOWNLOAD_CENSUS = 'DOWNLOAD_CENSUS'
export const GET_ALL_CENSUS = 'GET_ALL_CENSUS'
export const GET_CENSUS = 'GET_CENSUS'
export const IMPORT_CENSUS = 'IMPORT_CENSUS'
export const IMPORT_CENSUS_DONE = 'IMPORT_CENSUS_DONE'
export const IMPORT_CENSUS_STATUS = 'IMPORT_CENSUS_STATUS'
export const IMPORT_CENSUS_STATUS_DONE = 'IMPORT_CENSUS_STATUS_DONE'
export const POST_CENSUS = 'POST_CENSUS'
export const RECEIVE_CENSUS = 'RECEIVE_CENSUS'
export const RECEIVE_CENSUS_LIST = 'RECEIVE_CENSUS_LIST'
export const IMPORT_CENSUS_STATUS_DONE_VIEW = 'IMPORT_CENSUS_STATUS_DONE_VIEW'

export default [
  ARCHIVE_CENSUS,
  DOWNLOAD_CENSUS,
  GET_ALL_CENSUS,
  GET_CENSUS,
  IMPORT_CENSUS,
  IMPORT_CENSUS_DONE,
  IMPORT_CENSUS_STATUS,
  IMPORT_CENSUS_STATUS_DONE,
  IMPORT_CENSUS_STATUS_DONE_VIEW,
  POST_CENSUS,
  RECEIVE_CENSUS,
  RECEIVE_CENSUS_LIST,
]
