export const ENVIROMENT_DEV = 'dev'
export const ENVIROMENT_TEST = 'test'
export const ENVIROMENT_QA = 'qa'
export const ENVIROMENT_DEMO = 'demo'
export const ENVIROMENT_STAGE = 'stage'
export const ENVIROMENT_PROD = 'prod'

export const ENVIROMENT_PREFIX_DEV = 'dev-'
export const ENVIROMENT_PREFIX_TEST = 'test-'
export const ENVIROMENT_PREFIX_QA = 'qa-'
export const ENVIROMENT_PREFIX_DEMO = 'demo-'
export const ENVIROMENT_PREFIX_STAGE = 'stage-'
export const ENVIROMENT_PREFIX_PROD = ''
const { hostname } = window.location
let environment = 'prod'

// localhost and dev-hostname result in a dev environment. stage-hostname will be stage environment
// ie: localhost:3000 or dev-app.hixme.com will be dev
// ie: stage-app.hixme.com will be stage
// default is prod
export function setEnvironmentWithHostname(useHostname = hostname) {
  if (
    useHostname === 'localhost' ||
    useHostname.startsWith(ENVIROMENT_PREFIX_DEV) ||
    useHostname.includes(`.${ENVIROMENT_DEV}`) ||
    useHostname.startsWith('192.168')
  ) {
    environment = ENVIROMENT_DEV
  } else if (useHostname.startsWith(ENVIROMENT_PREFIX_TEST)) {
    environment = ENVIROMENT_TEST
  } else if (useHostname.startsWith(ENVIROMENT_PREFIX_DEMO)) {
    environment = ENVIROMENT_DEMO
  } else if (useHostname.startsWith(ENVIROMENT_PREFIX_STAGE)) {
    environment = ENVIROMENT_STAGE
  } else if (useHostname.startsWith(ENVIROMENT_PREFIX_QA)) {
    environment = ENVIROMENT_QA
  }
}

// set default environment
setEnvironmentWithHostname()

// helper functions
// Here dev and test are considered development environments
// Prod and demo are considered production level environments
export const isDevelopment = () =>
  environment === ENVIROMENT_DEV ||
  environment === ENVIROMENT_TEST ||
  environment === ENVIROMENT_QA
export const isStaging = () => environment === ENVIROMENT_STAGE
export const isTesting = () => environment === ENVIROMENT_TEST
export const isDemo = () => environment === ENVIROMENT_DEMO
export const isProduction = () =>
  environment === ENVIROMENT_PROD ||
  environment === ENVIROMENT_DEMO ||
  environment === ENVIROMENT_STAGE

export const getEnvironment = () => environment

export const isLocal = (useHostname = hostname) =>
  useHostname === 'localhost' || useHostname.startsWith('192.168')

// prefix used for url building, to keep testing consistent
// link from dev-admin.hixme.com can link to dev-enrollme.hixme.com
export const getEnvironmentPrefix = () => {
  if (environment === ENVIROMENT_DEV) {
    return ENVIROMENT_PREFIX_DEV
  } else if (environment === ENVIROMENT_TEST) {
    return ENVIROMENT_PREFIX_TEST
  } else if (environment === ENVIROMENT_QA) {
    return ENVIROMENT_PREFIX_QA
  } else if (environment === ENVIROMENT_DEMO) {
    return ENVIROMENT_PREFIX_DEMO
  } else if (environment === ENVIROMENT_STAGE) {
    return ENVIROMENT_PREFIX_STAGE
  }

  // production doesn't require a prefix
  return ''
}

export const getPaymentServiceKey = () => {
  if (hostname === 'localhost') {
    return 'bWivhomTtd1ClVayfGMeAa15hWTmTgSq3Tdf6mej'
  }
  if (hostname.startsWith(ENVIROMENT_PREFIX_DEV)) {
    return 'TF0npF7kdtaWA4iZwlvqE1pVlaBMqoMU2W4lZbOs'
  }
  return ''
}

export const getPaymentServiceURL = () => {
  if (hostname === 'localhost') {
    return 'https://paymentservice.dev.surecolabs.com'
  }
  if (hostname.startsWith(ENVIROMENT_PREFIX_DEV)) {
    return 'https://paymentservice.qa.surecolabs.com'
  }
  return 'https://paymentservice.docday.com'
}

export const getLaunchDarklyConfig = () => {
  const LAUNCH_DARKLY_CONFIG_MAP = {
    dev: {
      clientSideID: '64791f86860a6912eed67bf6',
    },
    test: {
      clientSideID: '647e58b03eb05612f56036db',
    },
    demo: {
      clientSideID: '647e58b98fc16e13548ccd38',
    },
    stage: {
      clientSideID: '64791fcabd0c3a13ba779f84',
    },
    prod: {
      clientSideID: '64791f86860a6912eed67bf7',
    },
  }

  return LAUNCH_DARKLY_CONFIG_MAP[environment]
}

export default {
  isDevelopment,
  isStaging,
  isTesting,
  isDemo,
  isProduction,
  isLocal,
  getEnvironment,
  getEnvironmentPrefix,
  getPaymentServiceURL,
  getPaymentServiceKey,
  getLaunchDarklyConfig,
}
