import React from 'react'

import NavigationContainer from './components/NavigationContainer/NavigationContainer'
import NavigationHeader from './components/NavigationHeader/NavigationHeader'
import useNavigationLinks from './hooks/useNavigationLinks'

const SideNav = () => {
  const { links, textLinks } = useNavigationLinks()
  return (
    <>
      <NavigationHeader />
      <section role="navigation">
        <NavigationContainer links={links} />
        <NavigationContainer links={textLinks} />
      </section>
    </>
  )
}
export default SideNav
