import PropTypes from 'prop-types'

export default {
  census: PropTypes.shape({
    CensusDate: PropTypes.number,
    FileName: PropTypes.string,
    Id: PropTypes.string,
    CompanyId: PropTypes.string,
    DependentsAdded: PropTypes.number,
    WorkersAdded: PropTypes.number,
    Rejected: PropTypes.number,
    UploadDate: PropTypes.string,
    UploadBy: PropTypes.string,
    UploadById: PropTypes.string,
  }),
}
