import React, { useState } from 'react'
import { Button } from '@sureco/design-system'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'
import ConfirmCellChangesModal from './ConfirmCellChangesModal'

interface EditActionsProps {
  undoLastEdit: () => void
  editCount: number
  dirtyRows: CensusFileRowGridData[]
  handleSubmit: (rows: CensusFileRowGridData[]) => void
  cleanAllRows: () => void
  submitCorrections: (rows: CensusFileRowGridData[]) => Promise<void>
  updateMode: boolean
}

function InlineEditActionButtons(
  {
    undoLastEdit,
    editCount,
    dirtyRows,
    handleSubmit,
    cleanAllRows,
    submitCorrections,
    updateMode,
  }: EditActionsProps) {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  if (editCount === 0) return null

  const handleSaveEdits = async () => {
    setLoading(true)
    await submitCorrections(dirtyRows)
    handleSubmit(dirtyRows)
    cleanAllRows()
    setLoading(false)
    setModalVisible(false)
  }

  return (
    <>
      <Button onClick={undoLastEdit} size="large" variant="danger">
        Undo Last Edit
      </Button>
      <Button onClick={() => setModalVisible(true)} variant="primary" size="large">
        Save Edits ({editCount})
      </Button>

      <ConfirmCellChangesModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onConfirm={handleSaveEdits}
        loading={loading}
        dirtyRows={dirtyRows}
        updateMode={updateMode}
      />
    </>
  )
}

export default InlineEditActionButtons
