import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { actions, selectors as authSelectors } from '@hixme/auth-ui'
import {
  ROLE_PLATFORM_HIXME_ADMIN,
  ROLE_BROKER,
  ROLE_CLIENT_ADMIN,
} from '@hixme/role-policy'
import { selectors as userSessionSelectors } from 'store/modules/user-session'
import { uniqBy } from 'lodash'
import { PROFILE } from 'routes/path-names'
import {
  clientAdminLink,
  clientLinks,
  CustomerManagementTab,
  DataAndReportsTab,
  EnrollmentDashboardTab,
  hixmeOnlyLinks,
  QLETab,
  textLinks,
} from '../link-data'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { datadogRum } from '@datadog/browser-rum'

const dedupeLinks = (links) => uniqBy(links, 'href')

const sortLinks = (links) =>
  links.sort((a, b) => {
    return (a.sortOrder ?? 9000) - (b.sortOrder ?? 8999)
  })

const getFlagBasedAdminLinks = (flags) => {
  return [
    flags['admin.show-reports-tab-to-hr-users'] ? DataAndReportsTab : null,
    flags['admin.show-enrollment-dashboard'] ? EnrollmentDashboardTab : null,
    flags['ui.customer.new-experience'] ? CustomerManagementTab : null,
    flags['qle-v-1'] ? QLETab : null,
  ].filter((flagBasedLink) => Boolean(flagBasedLink))
}

function useNavigationLinks() {
  const dispatch = useDispatch()
  const { flags, methods } = useFeatureFlags()
  const userId = useSelector(authSelectors.getUserId)
  const role = useSelector(authSelectors.getRole, shallowEqual)
  const credentials = useSelector(authSelectors.getCredentials)
  const firstName = credentials.firstname
  const lastName = credentials.lastname
  const currentSelectedCompanyId = useSelector(
    userSessionSelectors.getCurrentCompanyId
  )
  const custom = {
    role,
    selectedClientPK: currentSelectedCompanyId,
  }
  const identifyData = {
    key: userId,
    firstName,
    lastName,
    custom,
  }

  if (userId && role) {
    methods.identify(identifyData)
    datadogRum.setUser({
      id: userId,
      name: `${firstName} ${lastName}`,
      role: role,
    })
  }

  const defaultLinks = [
    {
      href: PROFILE,
      text: 'Profile',
    },
    {
      href: '/login',
      text: 'Sign out',
      onClick: () => dispatch(actions.logout()),
    },
  ]

  const adminLinksBasedOnFlags = getFlagBasedAdminLinks(flags)
  const hixmeAdminLinks = sortLinks(
    dedupeLinks([...hixmeOnlyLinks, ...adminLinksBasedOnFlags])
  )
  const clientAdminLinks = sortLinks(
    dedupeLinks([...clientAdminLink, ...adminLinksBasedOnFlags])
  )

  const navigationLinksRoleMapper = {
    [ROLE_PLATFORM_HIXME_ADMIN]: hixmeAdminLinks,
    [ROLE_BROKER]: clientAdminLinks,
    [ROLE_CLIENT_ADMIN]: clientAdminLinks,
  }

  const isCompanyProfileUndefined = useSelector(
    userSessionSelectors.isCurrentCompanyUnavailable
  )

  const links = navigationLinksRoleMapper[role] || clientLinks
  return {
    links: links.map((link) => ({
      ...link,
      disabled: link.href === '/company' && isCompanyProfileUndefined,
    })),
    textLinks: [...textLinks, ...defaultLinks],
  }
}

export default useNavigationLinks
