import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import { Input, Label, FormGroup } from '@hixme-ui/forms'
import { Row, Col } from 'react-flexbox-grid'
import Text from '@hixme-ui/text'

const hasError = (props) => props.touched && props.invalid

const BenefitsEffectiveDates = ({
  fields: { BenefitsEffectiveDate, EnrollmentStartDate, EnrollmentEndDate },
  fieldProps,
}) => (
  <Row>
    <Col xs={4}>
      <FormGroup>
        <Label medium thin secondary>
          Benefits effective date
        </Label>
        <Input type="date" {...fieldProps.BenefitsEffectiveDate.input} />
        {hasError(BenefitsEffectiveDate) && (
          <Text error>{BenefitsEffectiveDate.error}</Text>
        )}
      </FormGroup>
    </Col>
    <Col xs={4}>
      <FormGroup>
        <Label medium thin>
          Special enrollment start date
        </Label>
        <Input type="date" {...fieldProps.EnrollmentStartDate.input} />
        {hasError(EnrollmentStartDate) && (
          <Text error>{EnrollmentStartDate.error}</Text>
        )}
      </FormGroup>
    </Col>
    <Col xs={4}>
      <FormGroup>
        <Label medium thin>
          Special enrollment end date
        </Label>
        <Input type="date" {...fieldProps.EnrollmentEndDate.input} />
        {hasError(EnrollmentEndDate) && (
          <Text error>{EnrollmentEndDate.error}</Text>
        )}
      </FormGroup>
    </Col>
  </Row>
)

BenefitsEffectiveDates.propTypes = {
  fields: PropTypes.object.isRequired,
  fieldProps: PropTypes.object.isRequired,
}

BenefitsEffectiveDates.defaultProps = {
  fields: {},
  fieldProps: {},
}

export default BenefitsEffectiveDates
