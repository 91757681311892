import React from 'react'

import Container from '@hixme-ui/container'
import Title from '@hixme-ui/title'

const LoadingView = () => (
  <Container textCenter>
    <Title>Loading...</Title>
  </Container>
)

LoadingView.displayName = 'LoadingView'

export default LoadingView
