import { notification } from '@surecompanies/core_components'
import { useMutation } from '@tanstack/react-query'
import client from 'enrollment-platform/api/eps.client'

interface UseSmokerRatesImportProps {
    importBatchId: string;
    batchType: string;
}

interface UseSmokerRatesImportResult {
    mutate: () => void;
    data: undefined;
    isLoading: boolean;
    error: any;
}

export default function useSmokerRatesImport({ importBatchId, batchType }: UseSmokerRatesImportProps): UseSmokerRatesImportResult {
    const getImport = async (): Promise<undefined> => {
        try {
            await client.post(`/plan-management/${batchType}/import`, {
                importBatchId,
            })

            notification.success({
                message: 'Smoker Rates Import Successful',
            })
            return
        } catch (error) {
            notification.error({
                message: 'Something went wrong getting the import',
            })
            throw error
        }
    }

    const { mutate, data, status, error } = useMutation<undefined, Error, void>({
        mutationFn: getImport,
        mutationKey: ['generatediff', importBatchId],
    })

    return {
        mutate,
        data,
        isLoading: status === 'pending',
        error,
    }
}
