import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'
import { Row, Col } from 'react-flexbox-grid'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Separator from '@hixme-ui/separator'
import Text from '@hixme-ui/text'

const showCobraElections = (elections) => {
  const keys = Object.keys(elections)

  return keys.some((key) => elections[key])
}

const InfoCard = ({ children, titles, cobraContinueBenefits }) => (
  <Container background="white" heavyShadow animate rounded margin="0 0 20px 0">
    <Container flex noPadding direction="column">
      <Container padding="0px 20px" width="100%" height="100%" rounded>
        <Row>
          {titles.map((text) => (
            <Col key={uuid()} xs={12} md={4}>
              <Container padding="20px 20px 0px" direction="column">
                <Text light fontSize="12pt">
                  {text}
                </Text>
              </Container>
            </Col>
          ))}
        </Row>
        <Separator light style={{ height: '1px' }} />
      </Container>
    </Container>
    {children}
    <Container flex noPadding direction="column">
      <Container padding="15px" width="100%" height="100%" rounded>
        <Container padding="20px 20px 0px" direction="column">
          <Text light fontSize="12pt">
            COBRA Elections
          </Text>
        </Container>
        <Separator light style={{ height: '1px' }} />
        {showCobraElections(cobraContinueBenefits) ? (
          <Row>
            {['Medical', 'Vision', 'Dental', 'FSA'].map((benefit) => (
              <Col key={uuid()} xs={6} md={2}>
                <Container padding="20px 20px 0px" direction="column">
                  <span
                    style={{
                      marginRight: '5px',
                      position: 'relative',
                      top: '3px',
                    }}
                  >
                    {cobraContinueBenefits[benefit] ? (
                      <Icon size={1.1} checkMarkCircle />
                    ) : (
                      <Icon size={1.1} xCircle />
                    )}
                  </span>
                  <Text fontSize="10pt">{benefit}</Text>
                </Container>
              </Col>
            ))}
          </Row>
        ) : (
          <Container padding="20px 20px 0px" direction="column">
            <Text light fontSize="12pt">
              None
            </Text>
          </Container>
        )}
      </Container>
    </Container>
  </Container>
)

InfoCard.displayName = 'InfoCard'

InfoCard.propTypes = {
  children: PropTypes.node.isRequired,
  titles: PropTypes.arrayOf(PropTypes.string),
  cobraContinueBenefits: PropTypes.object,
}

InfoCard.defaultProps = {
  subtitle: null,
  price: null,
  list: null,
  badges: null,
  titles: null,
  rowSpacing: 30,
  cobraContinueBenefits: {},
}

export default InfoCard
