import { createReducer } from 'modules/app-core/redux-utils'

import censusReducer from './census-reducer'
import t from './actionTypes'

// initialState
const initialState = {
  list: {},
}

export default createReducer(initialState, {
  [t.ARCHIVE_CENSUS]: (state, payload) => {
    const census = state.list[payload]
    const list = {
      ...state.list,
      [payload]: {
        ...census,
        IsArchived: true,
      },
    }

    return { ...state, list }
  },
  [t.IMPORT_CENSUS]: (state, payload) => {
    const census = state.list[payload]
    const list = {
      ...state.list,
      [payload]: {
        ...census,
        IsImporting: true,
      },
    }

    return { ...state, list }
  },
  [t.IMPORT_CENSUS_DONE]: (state, payload) => {
    const census = state.list[payload.Id]
    const list = {
      ...state.list,
      [payload.Id]: {
        ...census,
        IsImporting: false,
        ...payload,
      },
    }

    return { ...state, list }
  },
  [t.RECEIVE_CENSUS]: (state, payload) => {
    const census = censusReducer(state.list[payload.Id], {
      type: t.RECEIVE_CENSUS,
      payload,
    })
    const list = {
      ...state.list,
      [payload.Id]: census,
    }

    return { ...state, list }
  },
  [t.RECEIVE_CENSUS_LIST]: (state, payload) => {
    if (payload && payload.length) {
      const list = {}
      payload.forEach((census) => {
        const stateCensus = censusReducer(state.list[census.Id], {
          type: t.RECEIVE_CENSUS,
          payload: census,
        })
        list[census.Id] = stateCensus
      })
      return { ...state, list }
    }

    return state
  },
  [t.IMPORT_CENSUS_STATUS_DONE]: (state, payload) => {
    const census = censusReducer(state.list[payload.Id], {
      type: t.IMPORT_CENSUS_STATUS_DONE,
      payload,
    })
    const list = {
      ...state.list,
      [payload.Id]: {
        ...census,
        ImportState: 'In Progress',
      },
    }

    return { ...state, list }
  },
  [t.IMPORT_CENSUS_STATUS_DONE_VIEW]: (state, payload) => {
    const census = censusReducer(state.list[payload.Id], {
      type: t.IMPORT_CENSUS_STATUS_DONE_VIEW,
      payload,
    })
    const list = {
      ...state.list,
      [payload.Id]: {
        ...census,
        ImportState: payload.Status.Status,
        DependentsAdded: payload.Status.DependentsAdded,
        WorkersAdded: payload.Status.WorkersAdded,
        Rejected: payload.Status.Rejected,
        ...payload,
      },
    }

    return { ...state, list }
  },
})
