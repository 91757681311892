// Fixed values of fields
const FieldEditConfig: Record<string, string[]> = {
  MarriageStatus: ['Single', 'Married', 'Domestic Partner', 'Divorced', 'Widowed', 'Other'],
  Gender: ['Male', 'Female'],
  Relationship: ['Employee'],
  EmailAddressType: ['Home', 'Work'],
  PhoneType: ['Mobile', 'Home', 'Work', 'Unknown'],
  EmploymentStatus: ['Full-Time', 'Part-Time'],
  IsExempt: ['TRUE', 'FALSE'],
  Smoker: ['TRUE', 'FALSE'],
  PayrollCycle: ['12', '24', '26', '52'],
}

// Set up Select field in columns with a fixed set of values
export const configureEditField = (field?: string, mode: 'edit' | 'submit' = 'submit') => {
  if (!field || !FieldEditConfig[field]) return {}

  return {
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: FieldEditConfig[field], // Dropdown options
      formatValue: (value: string) => {
        if (!value) return value
        return findMatchingOption(field, value)
      },
    },
    valueGetter: (params) => {
      const rawValue = params.data?.[field] // Show raw value in non-edit mode
      if (mode === 'submit') return rawValue
      return findMatchingOption(field, rawValue)
    },
  }
}

// This is required to allow the Select to settle on the correct option regardless of case
const findMatchingOption = (field: string, value: string): string => {
  const normalizedValue = value.toString().trim().toLowerCase()
  return FieldEditConfig[field].find(option => option.toLowerCase() === normalizedValue) ?? value
}
