import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const User = ({
  name,
  benefitEffectiveDate,
  benefitYear,
  allowance,
  benefits,
}) => (
  <Container flex noPadding flexWrap style={{ width: '100%' }}>
    <Container flex noPadding style={{ flex: 1 }} direction="column">
      <Container flex direction="column" padding="10px 0">
        <Text fontWeight="bold">Medical Plan</Text>
        <Text small>{name}</Text>
        <Text small>
          Benefits Effective -{' '}
          {moment(benefitEffectiveDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}
        </Text>
      </Container>
      {benefits.map((benefit) => (
        <Container flex flexColumn padding="10px 0" key={benefit.name}>
          <Text fontWeight="bold">{benefit.type}</Text>
          <Text small>
            {benefit.name} -{' '}
            <Text small fontWeight="bold">
              {benefit.persons}
            </Text>
          </Text>
          <Text small>{benefit.effectiveDate}</Text>
        </Container>
      ))}
    </Container>
    <Container flex noPadding style={{ flex: 1 }} direction="column">
      <Text bold>Employee Reimbursements</Text>
      <Text bold style={{ marginTop: '10px' }}>
        {benefitYear} Allowance
      </Text>
      <Container flex noPadding direction="column" margin="10px 0">
        {allowance.map((entry) => (
          <Container
            flex
            noPadding
            justifySpaceBetween
            margin="0 0 5px 0"
            key={entry.title}
          >
            <Text small light>
              {entry.title}
            </Text>
            <Text small>{entry.value}</Text>
          </Container>
        ))}
      </Container>
    </Container>
  </Container>
)

User.propTypes = {
  name: PropTypes.string.isRequired,
  benefitEffectiveDate: PropTypes.string.isRequired,
  benefitYear: PropTypes.string.isRequired,
  benefits: PropTypes.array.isRequired,
  allowance: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default User
