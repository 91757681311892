import React, { useCallback } from 'react'
import { AutoSuggest } from '@surecompanies/react-design-system'
import Label from '@hixme-ui/label'
import FormGroup from '@hixme-ui/form-group'
import Container from '@hixme-ui/container'
import { hasError } from 'modules/redux-form-utils'
import { FieldError } from 'components/ReduxFormComponents'
import styles from './AddressAutocomplete.module.scss'
import { useLazyQuery } from '@apollo/client'
import { GET_ADDRESS_AUTOCOMPLETE } from 'apollo/address'
import { AddressAutoCompleteResponse, AddressAutoCompleteEntry } from 'types/address'

export const AddressAutocomplete = ({ border, label, marginRight, minWidth, width, input, meta, excludePOBox = true, maxRecords = 5, ...rest }) => {
    const [autocompleteQuery] = useLazyQuery<AddressAutoCompleteResponse>(GET_ADDRESS_AUTOCOMPLETE, {
        variables: {
            filters: {
                maxRecords,
                excludePOBox
            }
        },
        // fetchPolicy: 'network-only'
    })

    const fetcher = useCallback((inputAddress: string) => autocompleteQuery({
        variables: {
            address: inputAddress
        }
    }).then(r => r.data?.addressAutoComplete ?? []), [autocompleteQuery])

    return (
        <FormGroup style={{ border, marginRight, minWidth, width }}>
            <Container noPadding flexColumn justifyCenter alignFirst>
                <Label medium thin>
                    {label}
                </Label>
                <div>
                    <AutoSuggest<AddressAutoCompleteEntry>
                        fetcher={fetcher}
                        status={hasError(meta) ? 'error' : ''}
                        className={styles.mimicHixmeInputStyle}
                        defaultValue={input.value}
                        optionKey={entry => entry.addressLine1}
                        {...input}
                        {...rest}
                        autoComplete={'off'}
                    />
                    <FieldError meta={meta} />
                </div>
            </Container>
        </FormGroup>
    )
}
