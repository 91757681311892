export default [
  {
    name: 'Fully Insured',
    id: 'fully_insured',
  },
  {
    name: 'Self Insured',
    id: 'self_insured',
  },
  {
    name: 'Exchange',
    id: 'exchange',
  },
]
