import { createReducer } from 'modules/app-core/redux-utils'
import t from './actionTypes'

// initialState
const initialState = {
  benefitUploaderOpen: false,
}

export default createReducer(initialState, {
  [t.TOGGLE_BENEFIT_UPLOADER]: (state) =>
    Object.assign({}, state, {
      benefitUploaderOpen: !state.benefitUploaderOpen,
    }),
  [t.CLOSE_BENEFIT_UPLOADER]: (state) =>
    Object.assign({}, state, {
      benefitUploaderOpen: false,
    }),
})
