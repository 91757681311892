import { connect } from 'react-redux'
import { compose, lifecycle, withState } from 'recompose'
import { clearModal } from '@hixme/modal'

// module
import { getStatusAvailable } from 'data/expense-request-status'
import {
  selectors as ExpenseSelectors,
  actions as ExpenseActions,
} from '../../../../../modules/expense-requests-search'
import { actions, selectors } from '../../../modules/receipt-line-items'

import ReceiptLineItemFooter from '../../ReceiptLineItemFooter/ReceitpLineItemFooter'
import {
  getIsDescriptionAvailable,
  getTextByStatus,
} from '../../../utils/Status'

const withLineItems = lifecycle({
  componentDidMount() {
    this.props.setStatus(this.props.reimbursement.status)
    this.props.setReceipts(this.props.reimbursement.receipts)
    this.props.setLineItems(this.props.reimbursement.receipts)
  },

  componentWillMount() {
    this.props.reset()
  },
})

const mapStateToProps = (state) => {
  const expense = ExpenseSelectors.getExpense(state)
  const status = selectors.getStatus(state) || ''
  const description = selectors.getDescription(state)
  const lineItems = selectors.getLineItems(state)
  const statuses = getStatusAvailable(expense && expense.reimbursement.status)
  const isDisabledByStatus = selectors.getIsDisabledByStatus(state)
  return {
    description,
    status,
    statuses,
    lineItems,
    isDisabled:
      status === '' ||
      isDisabledByStatus ||
      (expense && expense.reimbursement.status === status),
    text: getTextByStatus(status),
    reimbursement: expense && expense.reimbursement,
    isDescriptionAvailable: getIsDescriptionAvailable(status),
  }
}

const mapDispatchToProps = (dispatch) => ({
  onCloseModal: () => {
    dispatch(clearModal())
    dispatch(actions.clearLineItems())
  },
  setStatus: (status) => dispatch(actions.updateStatus(status)),
  setReceipts: (receipts) => dispatch(actions.updateReceipts(receipts)),
  setLineItems: (receipts) => {
    receipts
      .filter((receipt) => receipt.lineItems.length !== 0)
      .map((receipt) => ({
        receipt: receipt.id,
        items: receipt.lineItems.map((lineItem) => ({
          ...lineItem,
          receiptId: receipt.id,
        })),
      }))
      .forEach((lineItem) => dispatch(actions.addLineItem(lineItem)))
  },
  setDescription: (description) =>
    dispatch(actions.updateDescription(description)),
  onCreate: (status, setIsLoading) => {
    setIsLoading(true)
    dispatch(actions.createLineItemsReimbursement(status)).catch(() =>
      setIsLoading(false)
    )
  },
  reset: () => dispatch(actions.reset()),
  onDelete: (id) => dispatch(ExpenseActions.deleteExpense(id)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'setIsLoading', false),
  withLineItems
)(ReceiptLineItemFooter)
