import React from 'react'
import { Stack, Typography } from '@sureco/design-system'

export default function EnrollmentItem({ config }: { config: any }) {
  return (
    <Stack>
      <Stack direction="row" space={10} align="center">
        <Typography bold size={14}>
          Open Enrollment Period:
        </Typography>
        <Typography size={14}>
          {config.enrollmentStartDate} - {config.enrollmentEndDate}
        </Typography>
      </Stack>
      <Stack direction="row" space={10} align="center">
        <Typography bold size={14}>
          Benefit Effective Date:
        </Typography>
        <Typography size={14}>{config.benefitEffectiveDate}</Typography>
      </Stack>
      <Stack direction="row" space={10} align="center">
        <Typography bold size={14}>
          Enrollment Effective Policy:
        </Typography>
        <Typography size={14}>
          {config.benefitEffectivePolicy} {config.benefitEffectivePolicyUnit}
        </Typography>
      </Stack>
    </Stack>
  )
}
