import React from 'react'
import { Helmet } from 'react-helmet'

export type PageTitleProps = {
    title: string
}

export const PageTitle = ({ title }: PageTitleProps) => <Helmet>
    <title>{title} | SureCo</title>
</Helmet>
