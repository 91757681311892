import React from 'react'
import { Typography, Stack } from '@sureco/design-system'

export class ErrorBoundary extends React.Component<
  any,
  {
    error: any
    info: any
  }
> {
  constructor(props) {
    super(props)
    this.state = { error: null, info: null }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: error })
    console.error(error, info)
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <Stack direction="col" space={8}>
          <Typography color="error" size={18}>
            Something went wrong!
          </Typography>
          {this.state.error && this.state.error.message ? (
            <Typography>Error: {this.state.error.message}</Typography>
          ) : null}
          {this.state.info && this.state.info.componentStack ? (
            <Typography>
              Component Stack: {this.state.info.componentStack}
            </Typography>
          ) : null}
        </Stack>
      )
    }
    return this.props.children
  }
}
