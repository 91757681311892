import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import { connect } from 'react-redux'

import * as actions from 'modules/app-core/notification-manager/actions'

import Alert from './Alert'
import { NAME } from '../../constants'
import classes from './NotificationManager.module.scss'

const NotificationManager = ({
  notifications,
  hideNotifications,
  showNotifcations,
}) => (
  <div>
    <div className={showNotifcations ? classes.opened : classes.closed}>
      <ul>
        {notifications.map((notification) => (
          <li key={uuid.v1()}>
            <Alert
              message={notification.message}
              type={notification.type}
              onClose={hideNotifications}
              timeToClose={notification.timeToClose}
              autoClose={notification.autoClose}
            />
          </li>
        ))}
      </ul>
    </div>
  </div>
)

NotificationManager.propTypes = {
  notifications: PropTypes.array.isRequired,
  hideNotifications: PropTypes.func.isRequired,
  showNotifcations: PropTypes.bool,
}

NotificationManager.defaultProps = {
  showNotifcations: null,
}

const mapStateToProps = (state) => state[NAME]
export default connect(
  mapStateToProps,
  actions
)(NotificationManager)
