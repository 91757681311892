// Home
export const HOME_PATH = '/'

// Root paths
export const PROSPECTS_NAME = 'prospects'
export const APPLICATION_MANAGER_NAME = 'application-manager'
export const EXPENSE_REQUESTS_NAME = 'admin-expense-requests'
export const QUOTING = 'quoting'
export const CENSUS_MANAGEMENT = 'census-management'
export const MESSAGE_MANAGEMENT = 'message-management'
export const PROFILE = 'profile'
export const LIFE_EVENTS = 'qle'
