import { useState, useCallback } from 'react'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'
import { CellValueChangedEvent } from '@ag-grid-community/core'

// Track changes to any editable field (like "Proposed")
interface DirtyFields {
  [fieldNameValue: string]: boolean
}

export const useMarkRowDirtyUpdates = (prevRows: CensusFileRowGridData[], setCensusRows: React.Dispatch<React.SetStateAction<CensusFileRowGridData[]>>) => {
  const [dirtyRows, setDirtyRows] = useState<any[]>([]) // Generic row data to track changes
  const [editHistory, setEditHistory] = useState<
    { rowId: number; field: string, fieldName: string; previousValue: any, newValue: any }[]
  >([])

  // Mark a row as dirty when a field is edited
  const markRowDirty = useCallback((params: CellValueChangedEvent<any & { dirtyFields?: DirtyFields }>) => {
    const { newValue, data, colDef, oldValue } = params
    const fieldName = colDef.field as string // "Proposed" or any other field being edited
    const originalValue = oldValue // The original value for the field
    // If the value is unchanged, remove it from dirtyFields
    if (newValue === originalValue) {
      setEditHistory((prevHistory) =>
        prevHistory.filter(edit => !(edit.rowId === data.rowId && edit.field === data.field))
      )

      if (data.dirtyFields) {
        delete data.dirtyFields[`${data.field} ${data.newValue}`] // Remove the field from dirtyFields
      }

      // If no more dirty fields, mark row as clean
      if (Object.keys(data.dirtyFields || {}).length === 0) {
        data.isDirty = false
        setDirtyRows((prev) => prev.filter(row => row.rowId !== data.rowId))
      }

      return
    }

    // If new value is different, mark as dirty and update dirtyFields
    data.isDirty = true

    // Update dirtyFields with the field name that has been modified
    data.dirtyFields = { ...(data.dirtyFields || {}), [`${data.field} ${data.newValue}`]: true }

    setDirtyRows((prev) => {
        // Find the existing rowIndex in dirtyRows
        const rowIndex = prev.findIndex(
          (row) => row.rowId === data.rowId && row.field === data.field
        )

        if (rowIndex === -1) {
          // If the row does not exist, add it
          return [...prev, {
            ...data,
            dirtyFields: { [`${data.field} ${data.newValue}`]: true }
          }]
        } else {
          // Create a new row object
          const updatedRow = { ...prev[rowIndex] }

          // Remove old field entry in dirtyFields (if exists)
          Object.keys(updatedRow.dirtyFields || {}).forEach((key) => {
            if (key.startsWith(data.field)) {
              delete updatedRow.dirtyFields[key] // Remove previous key
            }
          })

          // Add new dirty field entry
          updatedRow.dirtyFields = {
            ...(updatedRow.dirtyFields || {}),
            [`${data.field} ${data.newValue}`]: true, // Store new field
          }

          updatedRow.newValue = data.newValue // Update the new value

          // Replace the row at rowIndex with the updated row
          const updatedRows = [...prev]
          updatedRows[rowIndex] = updatedRow
          return updatedRows
        }
    })

    // Add to history stack for undo functionality
    setEditHistory((prev) => {
        const existingEditIndex = prev.findIndex(
          (edit) => edit.rowId === data.rowId && edit.field === data.field && edit.newValue === data.newValue
        )

        if (existingEditIndex !== -1) {
          // Replace the existing entry
          const updatedHistory = [...prev]
          updatedHistory[existingEditIndex] = {
            rowId: data.rowId,
            fieldName: fieldName,
            previousValue: originalValue,
            field: data.field,
            newValue: data.newValue,

          }
          return updatedHistory
        } else {
          // Add new entry to history
          return [...prev, { rowId: data.rowId, field: data.field, fieldName: fieldName, previousValue: originalValue, newValue: data.newValue }]
        }
      })
  }, [])

  // Undo the last edit
  const undoLastEdit = useCallback(() => {
    setEditHistory((prevHistory) => {
        if (prevHistory.length === 0) return prevHistory

        const lastEdit = prevHistory[prevHistory.length - 1] // Get last edit entry

        setDirtyRows((prevDirtyRows) =>
            prevDirtyRows
                .map((row: any) => {
                    if (row.rowId === lastEdit.rowId && row.field === lastEdit.field) {
                        // Replace `newValue` with `previousValue`
                        row[lastEdit.fieldName] = lastEdit.previousValue
                        row.newValue = lastEdit.previousValue

                        // If the value is back to the original, remove it from dirty rows
                        if (row.newValue === row.proposedInitialValue) {
                            return null // Mark for removal
                        }

                        // Instead of deleting, update the dirtyFields with previousValue
                        if (row.dirtyFields) {
                            Object.keys(row.dirtyFields).forEach((key) => {
                                if (key.startsWith(lastEdit.field)) {
                                    delete row.dirtyFields[key] // Remove old field entry
                                }
                            })
                            // Replace with the previous value in `dirtyFields`
                            row.dirtyFields[`${lastEdit.field} ${lastEdit.previousValue}`] = true
                        }

                        row.isDirty = true // Ensure row remains dirty
                    }
                    return row
                })
                .filter(Boolean) // Remove `null` rows (i.e., rows that are no longer dirty)
        )

        const updatedRows = [...prevRows]
        const updatedRow = updatedRows.find((row) => row.id === lastEdit.rowId)
        if (updatedRow) {
            const updates = updatedRow.updates
            const fieldToUpdate = lastEdit.field

            if (updates && updates.updateFields?.[fieldToUpdate]) {
                // Replace `newValue` with `previousValue`
                updates.updateFields[fieldToUpdate].newValue = lastEdit.previousValue
                setCensusRows(updatedRows)
            }
        }
        return prevHistory.slice(0, -1) // Remove the last edit from history
    })
}, [prevRows])

  // Clean all rows (reset dirty state)
  const cleanAllRows = useCallback(() => {
    setDirtyRows((prevDirtyRows) => {
      prevDirtyRows.forEach(row => {
        row.isDirty = false
        if (row.dirtyFields) {
          row.dirtyFields = {} // Clear all dirty fields
        }
      })
      return []
    })
    setEditHistory([]) // Clear edit history
  }, [])

  const editCount = dirtyRows.length

  return { editCount, cleanAllRows, markRowDirty, undoLastEdit, dirtyRows, editHistory }
}
