import React from 'react'
import PropTypes from 'prop-types'
import uuidV1 from 'uuid/v1'
import { Row, Col } from 'react-flexbox-grid'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

import { CobraPriceList } from '../../components'

const CobraSummary = ({ benefits }) => (
  <Container width="100%" height="100%" rounded>
    {benefits.map((benefit) => {
      const { Cobra, Persons, Name } = benefit

      return (
        <Row key={uuidV1()} style={{ margin: '0px 0px 25px' }}>
          <Container
            flex
            noPadding
            width="46%"
            direction="column"
            style={{ borderRadius: '6px 6px 0px 0px' }}
          >
            <Row style={{ margin: '0px 0px 10px' }}>
              <Col sm={12} md={6}>
                <Text light div fontSize="12pt">
                  {Name}
                </Text>

                <Container flex noPadding width="240px" direction="column">
                  <Text light fontSize="10pt">
                    Who&apos;s covered?
                  </Text>
                </Container>

                <Container flex noPadding width="120px" direction="column">
                  <Text center light fontSize="12pt" />
                </Container>

                <Container flex noPadding width="240px" direction="column">
                  <Text primary fontSize="10pt">
                    {Persons.map((person, i) =>
                      Persons.length === i + 1
                        ? `${person.FirstName} ${person.LastName}`
                        : `${person.FirstName} ${person.LastName}, `
                    )}
                  </Text>
                </Container>
              </Col>
              <Col sm={12} md={6}>
                <div style={{ marginLeft: '40px' }}>
                  <Text center div light fontSize="12pt">
                    $
                    {typeof Cobra.BundleCobraPrice === 'number'
                      ? Cobra.BundleCobraPrice.toFixed(2)
                      : 'N/A'}
                  </Text>
                </div>
              </Col>
            </Row>
          </Container>

          <CobraPriceList {...Cobra} />
        </Row>
      )
    })}
  </Container>
)

CobraSummary.displayName = 'CobraSummary'

CobraSummary.propTypes = {
  benefits: PropTypes.array,
}

CobraSummary.defaultProps = {
  benefits: [],
}

export default CobraSummary
