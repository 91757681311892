import React from 'react'
import { Stack, TextColors, Typography } from '@sureco/design-system'
import { UserOutlined } from '@ant-design/icons'

import './Contact.scss'

type ContactProps = {
  fullName: string
  title: string
  email: string
  isPrimary: boolean
  phone: {
    number?: string
  }
}

export default function Contact({ fullName, title, email, phone, isPrimary } : ContactProps) {
  return (
    <Stack align="center" space={10} className={`contact ${isPrimary ? 'primary' : ''}`} padding={20} borderRadius={8}>
      {isPrimary && <span className='badge'>PRIMARY</span>}
      <UserOutlined style={{ fontSize: '40px', color: TextColors.primary }} />
      <Typography size={20} bold color='#003146' textAlign='center'>
        {fullName}
      </Typography>
      <Typography size={12} color='#999'>{title?.toUpperCase()}</Typography>
      <Typography size={16} color={TextColors.primary} as='a' href={`mailto:${email}`}>
        {email}
      </Typography>
      <Typography size={16} as='a' href={`tel:${phone?.number}`}>{phone?.number}</Typography>
    </Stack>
  )
}
