import React, { useEffect } from 'react'
import Page from 'layouts/Page'
import BreadcrumbNav from 'enrollment-platform/components/Breadcrumbs'
import { Button, Stack, Typography } from '@sureco/design-system'
import { useRouterContext } from 'components/RouterContext'
import usePlansAndRatesDiff from './hooks/usePlansAndRatesAnalyzeDiff'

export default function AnalyzePlansAndRates({ params }) {
  const importBatchId = params?.importBatchId || 'No importBatchId provided'
  const { mutate, data, isLoading } = usePlansAndRatesDiff({ importBatchId, batchType: 'plans-and-rates' })
  const { router } = useRouterContext()

  useEffect(() => {
    mutate()
  }, [importBatchId])

  const summary = data
    ? data.reduce(
        (acc, row) => {
          if (row.diffStatus === 'NEW') acc.new += 1
          if (row.diffStatus === 'CHANGED') acc.changed += 1
          if (row.diffStatus === 'DELETED') acc.deleted += 1
          return acc
        },
        { new: 0, changed: 0, deleted: 0 }
      )
    : { new: 0, changed: 0, deleted: 0 }

  return (
    <Page
      title="Analyze"
      breadcrumb={
        <BreadcrumbNav
          items={[
            { title: 'Plan Management', path: '/plans' },
            { title: 'Home' },
          ]}
        />
      }
    >
      <Stack space={20}>
        <Button onClick={() => router.push('/plans')}>Back to Home</Button>
        {isLoading && <div>Loading...</div>}
        {data && (
          <>
            <Typography as="h3" size={20}>
              New: {summary.new}, Changed: {summary.changed}, Deleted: {summary.deleted}
            </Typography>
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>Diff Status</th>
                  <th style={{ textAlign: 'left' }}>Hios Plan ID</th>
                  <th style={{ textAlign: 'left' }}>Carrier</th>
                  <th style={{ textAlign: 'left' }}>Plan Name</th>
                  <th style={{ textAlign: 'left' }}>Plan Type</th>
                  <th style={{ textAlign: 'left' }}>Plan Year</th>
                  <th style={{ textAlign: 'left' }}>Base Rate</th>
                  <th style={{ textAlign: 'left' }}>Level</th>
                  <th style={{ textAlign: 'left' }}>State</th>
                  <th style={{ textAlign: 'left' }}>Is For Sale</th>
                  <th style={{ textAlign: 'left' }}>HSA</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row) => (
                  <tr key={row.id}>
                    <td>{row.diffStatus}</td>
                    <td>{row.hiosPlanId}</td>
                    <td>{row.carrier}</td>
                    <td>{row.planName}</td>
                    <td>{row.planType}</td>
                    <td>{row.planYear}</td>
                    <td>{row.baseRate}</td>
                    <td>{row.level}</td>
                    <td>{row.state}</td>
                    <td>{row.isForSale}</td>
                    <td>{row.hsa}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </Stack>
    </Page>
  )
}
