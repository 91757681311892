export {
  default as validateEmployeeForm,
  validateAddress,
  checkAddressWarning,
} from './validateEmployeeForm'

/* eslint-disable */
export const sanitizeValues = (values) => {
  // Unmask values where necessary
  const undesireables = /\s|\W/g
  if (values.PhoneNumber) {
    values.PhoneNumber = values.PhoneNumber.replace(undesireables, '')
  }
  if (values.AlternatePhoneNumber) {
    values.AlternatePhoneNumber = values.AlternatePhoneNumber.replace(
      undesireables,
      ''
    )
  }

  // SSN is manadatory
  if (values.SSN) {
    values.SSN = values.SSN.replace(undesireables, '')
  }

  // Payroll frequency to number
  if (values.PayrollCycle)
    values.PayrollCycle = parseInt(values.PayrollCycle, 10)
}
