/* eslint-disable no-underscore-dangle */
export const STAGE_DEV = 'dev'
export const STAGE_INT = 'int'
export const STAGE_PROD = 'prod'

const stageMap = {
  [STAGE_DEV]: STAGE_DEV,
  [STAGE_INT]: STAGE_INT,
  [STAGE_PROD]: STAGE_PROD,
}

// Default to production
let currentStage = STAGE_PROD

if (stageMap[__STAGE__]) {
  currentStage = __STAGE__
} else if (__DEV_API__) {
  currentStage = STAGE_INT
}

export const getStage = () => currentStage
