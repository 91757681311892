import React, { useEffect, useMemo, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { GridReadyEvent, IServerSideGetRowsParams } from '@ag-grid-community/core'
import { FileOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { Button, Stack } from '@sureco/design-system'
import { getCensusFiles } from '../../api/census-enrollment-platform-api-client'
import { CensusFileRowStatusEnum } from '../../api/enums/census-file-row-status.enum'
import { SortDirection } from '../../../../types/sorted-request.dto'
import { DEFAULT_FILES_PAGINATION_LIMIT } from '../../constants'
import { relativeTimeString } from '../../../../utils/relative-time-string'
import { CensusFileWithRowStatsDto } from '../../api/dto/census-file-with-row-stats.dto'
import { withRouter, routerShape } from 'react-router'

const { Text } = Typography

type Props = {
  selectedClientId?: string
  clientNameLookup: Record<string, string>
  router: routerShape
}

const ActivityHistory = ({ selectedClientId, clientNameLookup, router }: Props) => {
  const [grid, setGrid] = useState<GridReadyEvent>()

  const datasource = useMemo(() => ({
    getRows: async (params: IServerSideGetRowsParams<CensusFileWithRowStatsDto>) => {
      const limit = DEFAULT_FILES_PAGINATION_LIMIT
      let sort: string | undefined
      let order: SortDirection | undefined
      try {
        const { startRow, sortModel } = params.request
        if (sortModel.length > 0) {
          const { colId, sort: direction } = sortModel[0] // Take the first sort entry
          sort = colId
          order = direction === 'desc' ? SortDirection.desc : SortDirection.asc
        }
        const status = [
          CensusFileRowStatusEnum.WITHDRAWN,
          CensusFileRowStatusEnum.REJECTED,
          CensusFileRowStatusEnum.COMPLETE,
          CensusFileRowStatusEnum.DELETED,
        ]

        const page = Math.floor((startRow ?? 1) / limit) + 1
        const { meta: pagination, data: files } = await getCensusFiles({
          status,
          page,
          limit,
          sort,
          order,
          clientPublicKey: selectedClientId ? [selectedClientId] : undefined,
        })

        params.success({ rowData: files, rowCount: pagination.total })
      } catch (error) {
        console.error(error)
        params.fail()
      }
    }
  }), [selectedClientId])

  useEffect(() => {
    if (grid) {
      grid.api.setGridOption('serverSideDatasource', datasource)
      grid.api.purgeInfiniteCache()
      grid.api.refreshServerSide({ purge: true })
    }
  }, [grid, datasource])

  const columnDefs = [
    {
      headerName: 'Uploaded',
      field: 'createdAt',
      flex: 1,
      cellRenderer: (params) => (
        <Text className="textSm">{relativeTimeString(params.value)}</Text>
      ),
    },
    {
      headerName: 'Submitted',
      field: 'rowLatestUpdatedAt',
      flex: 1,
      cellRenderer: (params) => (
        <Text className="textSm">{relativeTimeString(params.value)}</Text>
      ),
    },
    {
      headerName: 'Customer',
      field: 'clientPublicKey',
      flex: 1,
      sortable: false,
      cellRenderer: (params) => (
        <Text className="textEmphasis">{clientNameLookup[params.value] ?? `loading...`}</Text>
      ),
    },
    {
      headerName: 'Records',
      field: 'rowTypeCount',
      flex: 1,
      cellRenderer: (params) => (
        <Text className="textEmphasis textGray">{`${params.value} of ${params.data.rowTotalCount}`}</Text>
      ),
    },
    {
      headerName: 'File',
      field: 'fileName',
      flex: 1,
      cellRenderer: (params) => (
        <>
          <FileOutlined style={{ fontSize: '14px', marginRight: '8px' }} />
          <Text className="textSm textGray">{params.value}</Text>
        </>
      ),
    },
    {
      headerName: '',
      field: 'review',
      sortable: false,
      cellRenderer: (params) => (
        <Button onClick={() => clickView(params.data.publicKey)} style={{ marginLeft: 'auto' }}>View</Button>
      ),
      suppressHeaderMenuButton: true,
      filter: false,
      width: 120,
    },
  ]

  const clickView = (publicKey: string) => {
    router.push(`/census-review/${publicKey}/activity`)
  }
  return (
    <>
      <Stack direction="row" align="center" className="subtitle">Activity History</Stack>
      <AgGridReact
        rowStyle={{
          backgroundColor: 'white',
          marginBottom: '20px',
        }}
        className="censusReviewGrid ag-theme-alpine"
        getRowId={({ data }) => data.id}
        pagination
        paginationPageSize={10}
        cacheBlockSize={10}
        key={selectedClientId ?? 'no-client'}
        columnDefs={columnDefs}
        rowModelType="serverSide"
        onGridReady={(params) => setGrid(params)}
        domLayout="autoHeight"
        suppressHorizontalScroll
        paginationPageSizeSelector={[10, 20, 50, 100]}
      />
      <hr className="page-hr" style={{ marginTop: '10px' }} />
    </>
  )
}

export default withRouter(ActivityHistory)
