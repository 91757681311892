import React from 'react'
import { QueryBuilderAntD } from '@react-querybuilder/antd'
import { QueryBuilder } from 'react-querybuilder'
import { fields } from './fields'
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic'
import 'react-querybuilder/dist/query-builder.css'
import './style.scss'

export default function HrisRules({ rule }: { rule: any }) {
  if (!rule) {
    return null
  }
  const eligibilityRule = parseJsonLogic(rule, {
    jsonLogicOperations: {
      if: (value) => {
        const [entries] = Object.entries(value[0])
        const [key, values] = entries as [string, { var: string }[]]
        return { field: values[0].var, operator: key, value: values[1].var }
      },
    },
  })
  return (
    <QueryBuilderAntD>
      <QueryBuilder
        fields={fields}
        query={eligibilityRule}
        showCombinatorsBetweenRules
      />
    </QueryBuilderAntD>
  )
}
