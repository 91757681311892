import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getBenefitUploaderOpenState = createSelector(
  (state) => state[NAME],
  (layout) => layout.benefitUploaderOpen
)

export const getReportCardOpenState = createSelector(
  (state) => state[NAME],
  (layout) => layout.reportCardOpen
)
