import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'
import { reduxForm, getFormValues } from 'redux-form'
import { clearModal } from '@hixme/modal'

import ExpenseMessageModal from './ExpenseMessageModal'
import {
  actions,
  constants,
  selectors,
} from '../../../../modules/expense-requests-search'

const mapStateToProps = (state, props) => ({
  expense: selectors.getExpense(state),
  formValues: getFormValues('SendReimbursementMessage')(state) || {},
  person: props.person,
  isProcessing:
    getWaiter(state, constants.SEND_EXPENSE_MESSAGE).isPending || false,
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(clearModal()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'SendReimbursementMessage',
    validate: (values) => {
      const errors = {}
      if (!values.Message) {
        errors.Message = 'Message is required'
      }
      return errors
    },
    touchOnChange: true,
    onSubmit: (values, dispatch, { expense }) => {
      dispatch(
        actions.sendMessage({
          message: values.Message,
          ...(expense && {
            reimbursementPublicKey: expense.reimbursement.id,
          }),
        })
      )
    },
  })(ExpenseMessageModal)
)
