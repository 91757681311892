import { z } from 'zod'
import { EmploymentTypeEnum } from './employment.type.enum'
import { StateProvinceEnum } from './state.enum'
import { RelationshipEnum } from './relationship.enum'

export const ContributionSchema = z.object({
  employmentStatus: z.nativeEnum(EmploymentTypeEnum),
  age: z
    .coerce
    .number()
    .min(0)
    .max(100),
  group: z.string().default('Default'),
  exempt: z.union([z.literal('Exempt'), z.literal('Non-Exempt')]),
  ratingArea: z
    .number()
    .min(1)
    .max(20),
  stateCode: z.nativeEnum(StateProvinceEnum),
  relationship: z.nativeEnum(RelationshipEnum),
})
