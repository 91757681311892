import React from 'react'
import { string, number, oneOfType } from 'prop-types'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Price from '@hixme-ui/price'

const YearlyAllowanceBarCell = ({ title, value }) => {
  const val = Math.max(0, Number(value))
  return (
    <Container noPadding flex flexColumn alignItems="center" minWidth="170px">
      <Text light center>
        {title}
      </Text>
      <Price light value={val} includeCommas />
    </Container>
  )
}

YearlyAllowanceBarCell.propTypes = {
  title: string.isRequired,
  value: oneOfType([string, number]).isRequired,
}

export default YearlyAllowanceBarCell
