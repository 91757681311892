import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import NavigationLink from '../NavigationLink/NavigationLink'

const Container = styled.ul`
  margin-top: 70px;
  padding-left: 0;
  list-style-type: none;
`

const NavigationContainer = ({ links }) => (
  <Container>
    {links?.map((link) => (
      <NavigationLink role="presentation" key={link.href} link={link} />
    ))}
  </Container>
)

NavigationContainer.propTypes = {
  links: PropTypes.array.isRequired,
}

export default NavigationContainer
