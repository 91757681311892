import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'

const ExpenseCardTabContainer = ({ children }) => (
  <Container padding="35px" style={{ background: 'rgba(240,243,246,0.5)' }}>
    {children}
  </Container>
)

ExpenseCardTabContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ExpenseCardTabContainer
