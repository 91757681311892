import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import mrModalReducer, { NAME as mrModalName } from '@hixme/modal'

/* Utility modules */
import { reducer as authui } from '@hixme/auth-ui'
import { reducer as layout } from 'modules/layout'
import modal from 'modules/app-core/modal'
import notificationManager from 'modules/app-core/notification-manager'

import census from 'store/modules/census'
import companies from 'store/modules/companies'
import persons from 'store/modules/persons'
import userSession from 'store/modules/user-session'
import employee from 'store/modules/employee'

import quickSight from 'components/QuicksightDashboard/module'

// ========================================================
// The below should be migrated to be async with injectReducer
// ========================================================

import gateway from 'modules/redux-gateway'
import waiter from 'redux-waiter'

/* Prospect list, profile */
import profile from 'modules/prospect-profile'

/* employee */
import locations from 'modules/locations'
import benefitsEffectiveDates from 'modules/benefits-effective-dates'

/* router location */
import locationReducer from './location'

export const makeRootReducer = (asyncReducers) =>
  combineReducers({
    // Add sync reducers here
    // module reducers
    authui,
    form,
    layout,
    location: locationReducer,
    [benefitsEffectiveDates.constants.NAME]: benefitsEffectiveDates.reducer,
    [companies.constants.NAME]: companies.reducer,
    [modal.constants.NAME]: modal.reducer,
    [mrModalName]: mrModalReducer,
    [notificationManager.constants.NAME]: notificationManager.reducer,
    [census.constants.NAME]: census.reducer,
    [employee.constants.NAME]: employee.reducer,
    [persons.constants.NAME]: persons.reducer,
    [userSession.constants.NAME]: userSession.reducer,

    // route reducers to be migrated to use injectReducer
    [profile.constants.NAME]: profile.reducer,
    [locations.constants.NAME]: locations.reducer,

    [gateway.constants.NAME]: gateway.reducer,
    [waiter.constants.NAME]: waiter.reducer,

    [quickSight.constants.NAME]: quickSight.reducer,

    ...asyncReducers,
  })

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer // eslint-disable-line
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
