import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Label from '@hixme-ui/label'
import FormGroup from '@hixme-ui/form-group'
import Container from '@hixme-ui/container'

// project
import { FieldInput } from 'components/ReduxFormComponents'

const FormItem = ({ border, label, marginRight, minWidth, width, ...rest }) => (
  <FormGroup style={{ border, marginRight, minWidth, width }}>
    <Container noPadding flexColumn justifyCenter alignFirst>
      <Label medium thin>
        {label}
      </Label>
      <FieldInput {...rest} />
    </Container>
  </FormGroup>
)

FormItem.propTypes = {
  border: PropTypes.string,
  label: PropTypes.string.isRequired,
  marginRight: PropTypes.string,
  minWidth: PropTypes.string,
  width: PropTypes.string,
}

FormItem.defaultProps = {
  border: '',
  marginRight: '',
  minWidth: '',
  width: '',
}

export default FormItem
