import React from 'react'
import { connect } from 'react-redux'

// project
import { connectRequest, getRequestByName } from 'modules/redux-gateway'
import { getFamilyByEmployeeId } from '../actions'
import { getFamilyByEmployeeId as getFamilyByEmployeeIdSelector } from '../selectors'
import { GET_FAMILY } from '../constants'

function noop() {}

export default function PersonFamilyProvider(
  mapStateToProps = noop,
  mapDispatchToProps
) {
  return (InnerComponent) => {
    function getRequestName(props) {
      return `${GET_FAMILY}:${props.personId}`
    }

    return connect(
      (state, ownProps) => {
        const props = mapStateToProps(state, ownProps) || {}
        return {
          ...props,
          family: getFamilyByEmployeeIdSelector(state)(
            props.personId || ownProps.personId
          ),
        }
      },
      mapDispatchToProps
    )(
      connectRequest({
        name: (state, props) => getRequestName(props),
        onMount: (props, dispatch) => {
          dispatch((_, getState) => {
            const { isComplete, isProcessing } = getRequestByName(
              getState(),
              getRequestName(props)
            )
            if (!isComplete && !isProcessing) {
              dispatch(getFamilyByEmployeeId(props.personId))
            }
          })
        },
        processingView: () => <span>...</span>,
      })(InnerComponent)
    )
  }
}
