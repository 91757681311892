import { change } from 'redux-form'
import { createRequest } from 'modules/redux-gateway'
import moment from 'moment'
import * as constants from './constants'
import t from './actionTypes.js'

const MMDDYYDate = (value, key) => {
  const filterdValues = ['TerminationDate', 'CobraLetterPostDate']

  if (value && !filterdValues.includes(key)) {
    return moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')
  }

  return value
}

const YYMMDDDate = (value) =>
  value ? moment(value, 'MM/DD/YYYY').format('YYYY-MM-DD') : ''

export const loadEmployeeBenefits = (benefits) => ({
  type: t.LOAD_BENEFITS,
  payload: benefits,
})

export const loadCobraBenefits = (benefits) => ({
  type: t.LOAD_COBRA_BENEFITS,
  payload: benefits,
})

export const loadPerson = (person) => ({
  type: t.LOAD_PERSON,
  payload: person,
})

export const getEffectiveDates = () => (dispatch, getState) => {
  const state = getState()
  const terminationDate = state.form.StopCoverage.values.TerminationDate
  const cobraLetterPostDate = state.form.StopCoverage.values.CobraLetterPostDate

  if (
    terminationDate &&
    moment(terminationDate, 'MM/DD/YYYY', true).isValid()
  ) {
    const body = {
      ClientPublicKey: state.userSession.currentCompanyId,
      EmploymentStatus: 'Terminated',
      TerminationDate: state.form.StopCoverage.values.TerminationDate,
    }

    if (
      cobraLetterPostDate &&
      moment(cobraLetterPostDate, 'MM/DD/YYYY', true).isValid()
    ) {
      Object.assign(body, {
        CobraLetterPostDate: state.form.StopCoverage.values.CobraLetterPostDate,
      })
    }

    const req = dispatch(
      createRequest({
        route: constants.POST_EFFECTIVE_DATES,
        body,
      })
    )

    return req.then((response) => {
      const filteredResponse = {}

      if (state.form.StopCoverage.values.CobraStatus === 'Ineligible') {
        Object.assign(filteredResponse, {
          PreviousCoverageEndDate: response.PreviousCoverageEndDate,
          TerminationDate: response.TerminationDate,
          CobraLetterPostDate: '',
          CobraBenefitsEligibleStartDate: '',
          CobraBenefitsEligibleEndDate: '',
          CobraBenefitsEffectiveDate: '',
        })
      } else {
        const {
          CobraBenefitsEligibleStartDate,
          CobraBenefitsEligibleEndDate,
          CobraBenefitsEffectiveDate,
          PreviousCoverageEndDate,
        } = response

        Object.assign(filteredResponse, {
          CobraBenefitsEligibleStartDate,
          CobraBenefitsEligibleEndDate,
          CobraBenefitsEffectiveDate,
          PreviousCoverageEndDate,
        })
      }

      const objectKeys = Object.keys(filteredResponse)

      objectKeys.forEach((key) => {
        dispatch(
          change('StopCoverage', key, MMDDYYDate(filteredResponse[key], key))
        )
      })
    })
  }

  return Promise.reject(false)
}

export const postToPersonsTable = (
  {
    CobraBenefitsEffectiveDate,
    CobraBenefitsEligibleEndDate,
    CobraBenefitsEligibleStartDate,
    CobraContinueBenefits,
    CobraLetterPostDate,
    CobraStatus,
    PreviousCoverageEndDate,
    TerminationDate,
    EmploymentStatus,
  },
  { Id }
) => (dispatch, getState) => {
  const state = getState()

  const body = {
    CobraBenefitsEffectiveDate: YYMMDDDate(CobraBenefitsEffectiveDate),
    CobraBenefitsEligibleEndDate: YYMMDDDate(CobraBenefitsEligibleEndDate),
    CobraBenefitsEligibleStartDate: YYMMDDDate(CobraBenefitsEligibleStartDate),
    PreviousCoverageEndDate: YYMMDDDate(PreviousCoverageEndDate),
    TerminationDate: YYMMDDDate(TerminationDate),
    ClientPublicKey: state.userSession.currentCompanyId,
    Id,
    EmployeePublicKey: Id,
    Relationship: 'Employee',
    CobraStatus,
    EmploymentStatus,
  }

  if (CobraLetterPostDate) {
    Object.assign(body, {
      CobraLetterPostDate: YYMMDDDate(CobraLetterPostDate),
    })
  }

  /* eslint-disable no-param-reassign */
  if (CobraContinueBenefits) {
    // Remove false Cobra continue benefits
    Object.keys(CobraContinueBenefits).forEach((objKey) => {
      if (CobraContinueBenefits[objKey] === false) {
        delete CobraContinueBenefits[objKey]
      }
    })
    Object.assign(body, {
      CobraContinueBenefits,
    })
  }
  /* eslint-disable no-param-reassign */

  const req = dispatch(
    createRequest({
      route: constants.PUT_COBRA_BENEFITS,
      body,
    })
  )

  return req.then((response) => {
    const objectKeys = Object.keys(response)
    objectKeys.forEach((key) => {
      dispatch(change('StopCoverage', key, response[key]))
    })

    dispatch(loadPerson(response))
    return response
  })
}
