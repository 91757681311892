import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import { IconContainer, NavLink } from './style'

const NavigationLink = ({ link }) => (
  <NavLink
    role="presentation"
    key={link.href}
    icon={link.icon}
    onClick={link?.onClick}
  >
    {link.icon && (
      <IconContainer>
        <img src={link.icon} alt="nav-link-icon" />
      </IconContainer>
    )}

    <Link to={link.href} disabled={link.disabled}>
      <span>{link.text}</span>
    </Link>
  </NavLink>
)

NavigationLink.propTypes = {
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }).isRequired,
}

export default memo(NavigationLink)
