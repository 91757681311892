import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'

import { selectors } from 'store/modules/user-session'

import classes from './Header.module.scss'
import { HeaderCompanySelectorContainer } from 'components/HeaderCompanySelector/HeaderCompanySelectorContainer'

export const Header = () => {
  const companyName = useSelector((state) => {
    const company = selectors.getCurrentCompanyNew(state)
    return company?.companyName
  })
  return (
    <div className={classes.default}>
      <Container
        flex
        noPadding
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Container flex noPadding alignItems="center">
          <Container flex noPadding margin="0 0 0 20px">
            <div className={classes.separator} />
          </Container>
          <div className={classes.homeLink}>
            <Container flex noPadding margin="0 10px 0 20px">
              <Container flex noPadding alignCenter margin="0 20px 0 0">
                <Link to="/">
                  <img
                    alt="SureCo Enrollment Platform Home"
                    className={classes.logo}
                    src="/sureco_ep_logo.png"
                  />
                </Link>
                <div className={classes.separator} />
                <Container flex noPadding margin="0 30px">
                  <HeaderCompanySelectorContainer>
                    <Text
                      capitalize
                      jumbo
                      color="#3A4951"
                      style={{ cursor: 'pointer' }}
                    >
                      {companyName}
                    </Text>
                  </HeaderCompanySelectorContainer>
                </Container>
              </Container>
            </Container>
          </div>
        </Container>
        <Container flex noPadding>
          <Link to="/employees">
            <div className={classes.circleButton}>
              <Icon magnifyingGlass light />
            </div>
          </Link>
        </Container>
      </Container>
    </div>
  )
}

export default Header
