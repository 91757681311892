import React from 'react'
import styles from './LoadingOverlay.module.scss'

type LoadingOverlayProps = {
  isLoading: boolean
  message?: string
}

export function LoadingOverlay({ isLoading, message = 'Processing...' }: LoadingOverlayProps) {
  if (!isLoading) return null

  return (
    <div className={styles.overlay}>
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner} />
        <p className={styles.message}>{message}</p>
      </div>
    </div>
  )
}
