import React, { useContext, createContext } from 'react'
import { withRouter } from 'react-router'

const RouterContext = createContext<{ router: any, routes: any[], location: any, params: any }>({ router: null, routes: [], location: null, params: null })

export const useRouterContext = () => useContext(RouterContext)

const RouterContextComponent = ({ children, router, routes, location, params }) => {
    return <RouterContext.Provider value={{
        router, routes, location, params
    }}>
        {children}
    </RouterContext.Provider>
}

// What this is doing is pulling the router instance out and save it into react context
// then we can use useRouterContext to pull the router instance
// since our react-router version is too old and does not have an easy way to pull router instance
// without hopping through loops via withRouter HoC
export const RouterContextProvider = withRouter(RouterContextComponent)
