import { useQuery } from '@tanstack/react-query'
import client from 'enrollment-platform/api/eps.client'
import { useMemo } from 'react'

export type useContributionsProps = {
  id: string
  year?: number
}

export default function useContributions({
  id,
  year = new Date().getFullYear(),
}: useContributionsProps) {
  async function getContributions(id: string, year: number) {
    const { data } = await client.get(`/customers/${id}/contributions/${year}`)
    return data
  }

  const { isLoading, error } = useQuery({
    queryKey: ['getContributions', id, year],
    queryFn: () => getContributions(id, year),
    gcTime: 0,
  })

  const yearOptions = useMemo(() => {
    return Array.from({ length: 2 }, (_, i) => ({
      value: new Date().getFullYear() - i,
      label: new Date().getFullYear() - i,
    })).map((option) => ({
      value: option.value,
      label: option.value,
    }))
  }, [])

  return {
    isLoading,
    error,
    data: [{ hehe: 'dsds' }],
    yearOptions,
    defaultYear: new Date().getFullYear(),
  }
}
