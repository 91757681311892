import React from 'react'
import { Stack, Typography } from '@sureco/design-system'
import ContentSection from 'layouts/ContentSection/ContentSection'
import { Button, Divider, Input, Select, Upload } from '@surecompanies/core_components'
import { UploadOutlined } from '@ant-design/icons'
import Grid from 'components/Grid/Grid'
import SelectionPane from './components/SelectionPane'
import EmployeeResult from './components/EmployeeResult'
import useContributions from './hooks/useContributions'
import SearchableByExample from './components/SearchableByExample/SearchableByExample'

export default function ContributionTab({ id }: { id: string }) {
  const { isLoading, yearOptions, defaultYear, data } = useContributions({
    id,
  })
  if (isLoading) {
    return <span>Loading...</span>
  }
  return (
    <ContentSection
      title="Contributions"
      actions={
        <Stack direction="row" align="center" space={20}>
          <Select options={yearOptions} defaultValue={defaultYear} />
          <Upload accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
            <Button type="primary" icon={<UploadOutlined />}>
              Upload Contribution
            </Button>
          </Upload>
        </Stack>
      }
    >
      {data?.length > 0 ? (
        <>
          <Divider />
          <Stack space={20}>
            <Stack direction="row" justify="space-between">
              <Stack>
                <Typography>Select Model to Preview</Typography>
                <Select
                  options={[
                    {
                      value: 'Allergius-Contribution',
                      label: <span>Allergius-Contribution-2025.csv</span>,
                    },
                  ]}
                />
              </Stack>
              <Stack direction="row" align="center" space={10}>
                <Button variant="solid" type="primary">
                  Download
                </Button>
                <Button variant="solid" type="primary">
                  Edit
                </Button>
              </Stack>
            </Stack>
            <Stack direction="row" align="center" space={5}>
              <UploadOutlined style={{ fontSize: 20, color: '#999' }} />
              <Typography size={12} color="#999">
                11/01/24 at 5:55PM by Sarah Jones
              </Typography>
            </Stack>
            <Grid<any>
              wrapper={{
                style: { height: '300px' },
              }}
              columnDefs={[
                {
                  headerName: 'Min Age',
                  field: 'minAge',
                },
                {
                  headerName: 'Max Age',
                  field: 'maxAge',
                },
                {
                  headerName: 'Relationship',
                  field: 'relationship',
                },
                {
                  headerName: 'State',
                  field: 'state',
                },
                {
                  headerName: 'RA',
                  field: 'ratingArea',
                },
                {
                  headerName: 'Employment',
                  field: 'employment',
                },
                {
                  headerName: 'Exempt',
                  field: 'exempt',
                },
                {
                  headerName: 'Contribution Amount',
                  field: 'contributionAmount',
                },
              ]}
              rowData={[
                {
                  minAge: 16,
                  maxAge: 100,
                  relationship: 'Employee',
                  state: 'CA',
                  ratingArea: '1',
                  employment: 'Full-Time',
                  exempt: 'Exempt',
                  contributionAmount: '70%',
                },
              ]}
            />
            <ContentSection title="Preview" padding={0}>
              <Divider />
              <Stack direction="row" space={20}>
                <SelectionPane
                  title="By Employee"
                  style={{ maxHeight: '400px' }}
                >
                  <Typography color="#003146" size={14}>
                    Find by Name
                  </Typography>
                  <Input placeholder="Search by name" />
                </SelectionPane>
                <Stack style={{ width: '100%' }} space={10}>
                  <Typography color="#003146" size={16} bold>
                    Monthly Employer Contribution
                  </Typography>
                  <EmployeeResult
                    fullname="Akkiko Rodriguez"
                    relationship="employee"
                    age={46}
                    amount={375}
                  />
                  <EmployeeResult
                    fullname="Bradley Rodriguez"
                    relationship="spouse"
                    age={42}
                    amount={375}
                  />
                  <EmployeeResult
                    fullname="Raymond Rodriguez"
                    relationship="child"
                    age={26}
                    amount={0}
                  />
                  <EmployeeResult
                    fullname="Sammy Rodriguez"
                    relationship="child"
                    age={8}
                    amount={450}
                  />
                  <EmployeeResult
                    fullname="Akkiko Rodriguez"
                    relationship="employee"
                    age={46}
                    amount={1200}
                  />
                </Stack>
              </Stack>
              <Divider />
              <Stack direction="row" space={20}>
                <SearchableByExample id={id} />
              </Stack>
            </ContentSection>
          </Stack>
        </>
      ) : (
        <Stack
          direction="row"
          justify="center"
          align="center"
          style={{ height: '100%' }}
        >
          <Typography size={14} color="#003146">
            You haven't uploaded any contributions yet
          </Typography>
        </Stack>
      )}
    </ContentSection>
  )
}
