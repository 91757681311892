import React from 'react'
import PropTypes from 'prop-types'

import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'

const AttachedFile = ({ text, iconStyle, onClick }) => (
  <Container noPadding flex>
    <Icon paperclip {...iconStyle} />
    <Text
      cursor="pointer"
      primary
      style={{
        borderBottom: '1px dashed',
        marginLeft: '5px',
      }}
      onClick={onClick}
    >
      {text}
    </Text>
  </Container>
)

AttachedFile.propTypes = {
  text: PropTypes.string.isRequired,
  iconStyle: PropTypes.object,
  onClick: PropTypes.func,
}

AttachedFile.defaultProps = {
  iconStyle: {
    color: '#8F9DA8',
    size: 1.5,
  },
  onClick: () => {},
}

export default AttachedFile
