import React from 'react'
import { FieldValues } from 'react-hook-form'
import RHFSelect, { RHFSelectProps } from '../hookform/Select/RHFSelect'
import options from './industry.data'

export default function IndustrySelect<
  TFieldValues extends FieldValues = FieldValues
>({ control, name, ...props }: RHFSelectProps<TFieldValues>) {
  return (
    <RHFSelect control={control} name={name} {...props} options={options} />
  )
}
