import { useSelector } from 'react-redux'

import { selectors } from 'store/modules/user-session'
import { LIFE_EVENTS, MESSAGE_MANAGEMENT } from 'routes/path-names'
import {
  Census,
  CompanyAcctSettingPNG,
  ManageEmployeePNG,
  Message,
  ReportsPNG,
  QLEImagePNG,
  EnrollmentDashboard,
} from '../../../assets'
import useFeatureFlags from 'hooks/useFeatureFlags'

export default function useTiles() {
  const companyProfileUndefined = useSelector(
    selectors.isCurrentCompanyUnavailable
  )

  const { flags } = useFeatureFlags()
  const showEnrollmentDashboard = flags['admin.show-enrollment-dashboard']
  const showReportsTab = flags['admin.show-reports-tab-to-hr-users']
  const showQLE = flags['qle-v-1']

  const tiles = [
    {
      href: '/employees',
      image: ManageEmployeePNG,
      title: 'Our People',
      line1: 'Search and manage all of your',
      line2: 'company employees in a list.',
    },
    {
      href: '/enrollmentdashboard',
      image: EnrollmentDashboard,
      title: 'Enrollment Dashboard',
      line1: 'View the progress',
      line2: 'of your enrollment.',
      visibleForRoles: {
        platformHixmeAdmin: showEnrollmentDashboard,
        platformClientAdmin: showEnrollmentDashboard,
        platformBroker: showEnrollmentDashboard,
      },
    },
    {
      href: MESSAGE_MANAGEMENT,
      image: Message,
      title: 'Message Management',
      line1: 'Review and decision incoming',
      line2: 'employee messages.',
      visibleForRoles: { platformHixmeAdmin: true },
    },
    {
      href: '/census-management',
      image: Census,
      title: 'Census Management',
      line1: 'Send and view census files of your',
      line2: 'employees.',
    },
    {
      href: '/dataandreports',
      image: ReportsPNG,
      title: 'Reports Requests',
      line1: 'View the breakdown',
      line2: 'of your company data.',
      visibleForRoles: {
        platformHixmeAdmin: true,
        platformBroker: true,
        platformClientAdmin: showReportsTab,
      },
    },
    {
      href: '/company',
      image: CompanyAcctSettingPNG,
      title: 'Company Profile',
      line1: 'Manage your company settings',
      line2: 'and admins.',
      visibleForRoles: {
        platformHixmeAdmin: true,
        platformClientAdmin: true,
        platformBroker: true,
      },
      disabled: companyProfileUndefined,
    },
    {
      href: `/${LIFE_EVENTS}`,
      image: QLEImagePNG,
      title: 'Qualifying Life Events',
      line1: 'Manage qualifying life',
      line2: 'events.',
      visibleForRoles: {
        platformHixmeAdmin: showQLE,
        platformClientAdmin: showQLE,
        platformBroker: showQLE,
      },
    },
  ]

  return tiles
}
