import { delay } from 'lodash'
import { findDOMNode } from 'react-dom'
import ReactTooltip from 'react-tooltip'

const CopyText = (e) => {
  const textArea = document.createElement('textarea')
  // no flashing
  textArea.style.position = 'fixed'
  textArea.style.top = 0
  textArea.style.left = 0
  textArea.style.width = '2em'
  textArea.style.height = '2em'
  textArea.style.padding = 0
  textArea.style.border = 'none'
  textArea.style.outline = 'none'
  textArea.style.boxShadow = 'none'
  textArea.style.background = 'transparent'
  textArea.value = e.currentTarget.text
  document.body.appendChild(textArea)
  textArea.select()
  document.execCommand('copy')
  /* eslint-disable react/no-find-dom-node */
  ReactTooltip.show(findDOMNode(e.currentTarget))
  const ev = e.currentTarget
  delay(() => ReactTooltip.hide(findDOMNode(ev)), 2000)
  document.body.removeChild(textArea)
}

export const copyToClipboard = (text) => {
  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(text)
  } else {
    document.execCommand('copy', true, text)
  }
  setTimeout(ReactTooltip.hide, 1000)
}

export default CopyText
