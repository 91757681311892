import { datadogRum } from '@datadog/browser-rum'
import dayjs from 'dayjs'
import UTCPlugin from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { storeInstance } from 'store/createStore'
import { DateTimeFormats } from './datetime'
import { Store } from 'redux'

dayjs.extend(UTCPlugin)
dayjs.extend(timezone)

export const CustomActionNames = {
    SendICHRANotice: 'SEND_ICHRA_NOTICE',
    SendWelcomeEmail: 'SEND_WELCOME_EMAIL',
    LoginAsWorker: 'LOGIN_AS_WORKER',
    AddEmployee: 'ADD_EMPLOYEE',
    EditWorkerInfo: 'EDIT_WORKER_INFO',
    StopCoverage: 'STOP_COVERAGE',
    ResetSignInCreds: 'RESET_SIGNIN_CREDENTIALS',
    UnlockCart: 'UNLOCK_USER_CART',
    ResetAccount: 'RESET_ACCOUNT'
} as const

const getStore = () => {
    return storeInstance as Store<any, any> | null
}

const extraPersonInfo = (worker) => {
    return {
        id: worker.id ?? worker.Id,
        email: worker.emailAddress ?? worker.EmailAddress
    }
}

const getCommonAttributes = () => {
    const { userSession, companies, authui } = getStore()?.getState() ?? {}

    const timestamp = {
        datetime: dayjs().format(DateTimeFormats.c),
        timezone: dayjs.tz.guess()
    }

    if (!userSession || !companies || !authui) {
        datadogRum.addError(new Error('Failed to load redux store'))
        return {
            timestamp
        }
    }

    const { credentials } = authui
    const { currentCompanyId } = userSession
    const currentSelectedCompany = companies?.list?.[currentCompanyId] ?? {}
    return {
        user: {
            id: credentials.personPublicKey,
            name: `${credentials.firstname} ${credentials.lastname}`,
            role: credentials.userRole
        },
        currentSelectedCompany: {
            id: currentCompanyId,
            name: currentSelectedCompany.companyName,
            oep: {
                start: currentSelectedCompany.enrollmentStartDate,
                end: currentSelectedCompany.enrollmentEndDate
            },
        },
        timestamp
    }
}

const customAction = (name: typeof CustomActionNames[keyof typeof CustomActionNames], attributes: Record<string, any> = {}) => {
    const attrs = Object.assign({}, getCommonAttributes(), attributes)
    datadogRum.addAction(name, attrs)
}

export const datadog = {
    extraPersonInfo,
    getCommonAttributes,
    customAction,
    ...datadogRum
}
