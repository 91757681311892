import { createReducer } from 'modules/app-core/redux-utils'
import t from './actionTypes'

const initialState = {
  list: [],
  lineItems: [],
  status: null,
  description: '',
  loading: {},
}

export default createReducer(initialState, {
  [t.UPDATE_RECEIPTS]: (state, payload) => ({
    ...state,
    list: payload,
  }),
  [t.UPDATE_STATUS]: (state, payload) => ({
    ...state,
    description: payload !== 'Need More Info' ? '' : state.description,
    status: payload,
  }),
  [t.UPDATE_DESCRIPTION]: (state, payload) => ({
    ...state,
    description: payload,
  }),
  [t.ADD_LINE_ITEM]: (state, payload) => ({
    ...state,
    lineItems: [...state.lineItems, payload],
    loading: {
      ...state.loading,
      ...payload.items.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: false }),
        {}
      ),
    },
  }),
  [t.CLEAR_LINE_ITEMS]: (state) => ({
    ...state,
    lineItems: [],
  }),
  [t.RESET]: () => ({
    ...initialState,
  }),
  [t.ADD_LOADING]: (state, payload) => ({
    ...state,
    loading: {
      ...state.loading,
      [payload.id]: payload.value,
    },
  }),
  [t.REMOVE_LINE_ITEM]: (state, payload) => {
    const receipt = state.lineItems.find(
      (line) => line.receipt === payload.receiptId
    )
    const items = receipt.items.filter((item) => item.id !== payload.id)
    const hasItems = items.length > 0
    const newStateItem = {
      ...receipt,
      items,
    }

    const lineItems = state.lineItems.map((lineItem) =>
      lineItem.receipt === newStateItem.receipt ? newStateItem : lineItem
    )

    return {
      ...state,
      lineItems: hasItems
        ? lineItems
        : state.lineItems.filter(
            (lineItem) => lineItem.receipt !== receipt.receipt
          ),
    }
  },
})
