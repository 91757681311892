/* eslint-disable no-param-reassign, import/prefer-default-export */
import * as actions from 'modules/app-core/notification-manager/actions'
import isUndefined from 'lodash/isUndefined'

export const hixmeNotifications = (store) => (next) => (action) => {
  if (action.notification) {
    if (isUndefined(action.notification.autoClose)) {
      action.notification.autoClose = true
    }
    store.dispatch(actions.createNotification(action.notification))
  }

  const result = next(action)
  return result
}

export const dispatchEvents = (emitter) => (store) => (next) => (action) => {
  const result = next(action)
  if (action.type) {
    emitter.emit(action.type, action, store)
  }
  return result
}
