import React from 'react'
import PropTypes from 'prop-types'

import Text from '@hixme-ui/text'

const FieldText = ({ meta: { error, warning } }) => (
  <div>
    {error && <Text error>{error}</Text>}
    {warning && <Text warning>{warning}</Text>}
  </div>
)

FieldText.propTypes = {
  meta: PropTypes.object,
}

FieldText.defaultProps = {
  meta: {},
}

export default FieldText
