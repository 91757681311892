import { useState, useCallback } from 'react'
import { CellValueChangedEvent } from '@ag-grid-community/core'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'

interface DirtyFields {
  [fieldName: string]: boolean
}

// Keep track of changes to cell data in census file rows, allowing undo functionality
export const useMarkRowDirty = () => {
  const [dirtyRows, setDirtyRows] = useState<CensusFileRowGridData[]>([])
  const [editHistory, setEditHistory] = useState<
    { rowId: number; fieldName: string; previousValue: any }[]
  >([])

  const markRowDirty = useCallback((params: CellValueChangedEvent<CensusFileRowGridData & { dirtyFields?: DirtyFields }>) => {
    const { newValue, data, colDef } = params
    const fieldName = colDef.field as string
    const originalValue = data.rowData?.[fieldName]

    if (newValue === originalValue) {
      // Remove all previous edits for this field since it's back to the original value
      setEditHistory((prevHistory) =>
        prevHistory.filter(edit => !(edit.rowId === data.id && edit.fieldName === fieldName))
      )

      // Reset field tracking
      if (data.dirtyFields) {
        delete data.dirtyFields[fieldName]
      }

      if (Object.keys(data.dirtyFields || {}).length === 0) {
        data.isDirty = false
        setDirtyRows((prev) => prev.filter(row => row.id !== data.id))
      }

      return
    }

    data.isDirty = true
    data.dirtyFields = { ...(data.dirtyFields || {}), [fieldName]: true }

    setDirtyRows((prev) => {
      if (!prev.find((row) => row.id === data.id)) {
        return [...prev, data]
      }
      return prev
    })

    // Add to history stack for undo
    setEditHistory((prev) => [...prev, { rowId: data.id, fieldName, previousValue: originalValue }])
  }, [])

  const undoLastEdit = useCallback(() => {
    setEditHistory((prevHistory) => {
      if (prevHistory.length === 0) return prevHistory // Nothing to undo

      const lastEdit = prevHistory[prevHistory.length - 1] // Get last edit
      setDirtyRows((prevDirtyRows) =>
        prevDirtyRows.map((row: CensusFileRowGridData & { dirtyFields?: DirtyFields }) => {
          if (row.id === lastEdit.rowId) {
            // Restore the previous value
            row[lastEdit.fieldName] = lastEdit.previousValue

            // Remove from dirty fields if it matches original data
            if (row.rowData?.[lastEdit.fieldName] === lastEdit.previousValue && row.dirtyFields) {
              delete row.dirtyFields[lastEdit.fieldName]
            }

            // If no more dirty fields, mark row as not dirty
            if (Object.keys(row.dirtyFields || {}).length === 0) {
              row.isDirty = false
              return null as any
            }
          }
          return row
        }).filter(Boolean)
      )

      // Remove the last edit from history
      return prevHistory.slice(0, -1)
    })
  }, [])

  const cleanAllRows = useCallback(() => {
    setDirtyRows((prevDirtyRows) => {
      prevDirtyRows.forEach(row => {
        row.isDirty = false
        if (row.dirtyFields) {
          row.dirtyFields = {}
        }
      })
      return []
    })
    setEditHistory([]) // Clear edit history
  }, [])

  const editCount = editHistory.length

  return { editCount, cleanAllRows, markRowDirty, undoLastEdit, dirtyRows, editHistory }
}
