import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Checkbox from '@hixme-ui/checkbox'

import { FieldError } from 'components/ReduxFormComponents'

const FieldCheckbox = ({ input, meta, ...restProps }) => {
  const isChecked = typeof input.value === 'boolean' && input.value
  return (
    <div>
      <Checkbox {...input} {...restProps} checked={isChecked} />
      <FieldError meta={meta} />
    </div>
  )
}

FieldCheckbox.displayName = 'FieldCheckbox'
FieldCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export default FieldCheckbox
