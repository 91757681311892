import * as _actions from './actions'
import actionTypes from './actionTypes'
import * as _constants from './constants'
import _reducer from './reducer'
import * as _selectors from './selectors'
import * as _providers from './providers'

export const actions = _actions
export const constants = _constants
export const reducer = _reducer
export const selectors = _selectors
export const providers = _providers

export default {
  actions,
  actionTypes,
  constants,
  reducer,
  selectors,
  providers,
}
