import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory, Router } from 'react-router'
import { Provider } from 'react-redux'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

// SureCo's Antd react component library
// @surecompanies/core_components = antd v5 lib + any custom components
// @surecompanies/theme = theme values pulled from @hixme-ui/theme and set as defaults for antd v5 ConfigProvider
import { ThemeProvider as SureCoAntDThemeProvider } from '@surecompanies/theme'
import 'antd/dist/reset.css'
import { DesignSystemProvider } from '@surecompanies/react-design-system'

import theme, { ThemeProvider } from '@hixme-ui/theme'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { ToastContainer } from 'react-toastify'
import { clearModal } from '@hixme/modal'

import modal from 'modules/app-core/modal'

import tryScroll from 'helpers/tryScroll'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import appConfig from '../modules/app-config'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { defaultFontFamily, TextColors } from '@sureco/design-system'

const handleRouteError = () => (error) => {
  if (parseInt(error.status, 10) === 401) {
    browserHistory.push('/not-found')
  }
}

function onUpdate(store) {
  return () => {
    store.dispatch(modal.actions.hideModal())
    store.dispatch(clearModal())
    tryScroll()
  }
}

// Create a client
const queryClient = new QueryClient()

const AppContainer = ({ routes, store }) => (
  <Provider store={store}>
    <DesignSystemProvider>
      <SureCoAntDThemeProvider
        theme={{
          token: {
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadiusXS: 4,
            fontFamily: defaultFontFamily,
            colorPrimary: TextColors.primary,
            colorError: TextColors.error,
            Table: {
              borderColor: TextColors.tertiary,
            },
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider
            theme={{
              ...theme,
              fontFamily: {
                thinnest: defaultFontFamily,
                thinner: defaultFontFamily,
                thin: defaultFontFamily,
                normal: defaultFontFamily,
                bold: defaultFontFamily,
                bolder: defaultFontFamily,
                boldest: defaultFontFamily,
              },
            }}
          >
            <div style={{ height: '100%' }}>
              <ToastContainer theme="colored" position="bottom-right" />
              {injectStyle()}
              <ErrorBoundary>
                <Router
                  history={browserHistory}
                  onError={handleRouteError(store)}
                  onUpdate={onUpdate(store)}
                >
                  {routes}
                </Router>
              </ErrorBoundary>
            </div>
          </ThemeProvider>
        </QueryClientProvider>
      </SureCoAntDThemeProvider>
    </DesignSystemProvider>
  </Provider>
)

AppContainer.propTypes = {
  routes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
}

// Update the export default to use your environment-specific client ID and a sample context:
export default withLDProvider({
  clientSideID: appConfig.getLaunchDarklyConfig().clientSideID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(AppContainer)
