import React from 'react'
import { UploadOutlined } from '@ant-design/icons'
import type { UploadProps } from 'antd'
import { Button, message, Upload } from 'antd'
import { RcFile } from 'antd/es/upload'

interface UploadButtonProps {
  onImageUploaded: (base64: string) => void
}

const UploadButton: React.FC<UploadButtonProps> = ({ onImageUploaded }) => {
  const getBase64 = (file: RcFile): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })
  }

  const props: UploadProps = {
    name: 'file',
    beforeUpload: async (file) => {
      if (!file.type.startsWith('image/')) {
        message.error('You can only upload image files!')
        return Upload.LIST_IGNORE
      }

      try {
        const base64 = await getBase64(file)
        onImageUploaded(base64)
        message.success('Image processed successfully')
      } catch (err) {
        message.error('Error processing image')
      }

      return false
    },
    maxCount: 1,
    accept: 'image/*'
  }

  return (
    <Upload {...props}>
      <Button icon={<UploadOutlined />}>Click to Upload Logo</Button>
    </Upload>
  )
}

export default UploadButton
