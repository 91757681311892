import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import FormGroup from '@hixme-ui/form-group'
import Select from '@hixme-ui/select'
import Label from '@hixme-ui/label'
import { getStatuses } from '../../data/expense-request-status'

const emptyOption = [{ id: '', name: 'Select' }]

export const ExpenseRequestStatusFormGroup = ({ input }) => (
  <FormGroup>
    <Label thin>Status</Label>
    <ExpenseStatusDropdown {...input} />
  </FormGroup>
)
ExpenseRequestStatusFormGroup.displayName = 'ExpenseRequestStatusFormGroup'
ExpenseRequestStatusFormGroup.propTypes = {
  input: PropTypes.object.isRequired,
}

const ExpenseStatusDropdown = (props) => {
  // eslint-disable-next-line react/prop-types
  const list = props.list ? props.list : getStatuses()
  return <Select {...props} list={[...emptyOption, ...list]} />
}

export default ExpenseStatusDropdown
