import React, { useState } from 'react'
import { Stack, Typography } from '@sureco/design-system'
import ContributionForm from './ContributionForm/ContributionForm'
import epsClient from 'enrollment-platform/api/eps.client'
import EmployeeResult from '../EmployeeResult'
import SelectionPane from '../SelectionPane'

export default function SearchableByExample({
  id,
  year = new Date().getFullYear(),
}: {
  id: string
  year?: number
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [contribution, setContribution] = useState<any>(null)
  const [selectedContribution, setSelectedContribution] = useState<any>(null)
  const getContribution = async (data: any) => {
    try {
      setIsLoading(true)
      setSelectedContribution(data)
      const qs = new URLSearchParams(data)
      const response = await epsClient.get(
        `/customers/${id}/contributions/${year}/preview?${qs.toString()}`
      )
      setContribution(response.data)
      return response
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Stack
      style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', maxHeight: '400px' }}
      space={10}
    >
        <SelectionPane title="By Example">
            <ContributionForm
            isLoading={isLoading}
            onFormSubmit={(data: any) => getContribution(data)}
            />
      </SelectionPane>
      <Stack style={{ width: '100%' }} space={10}>
        <Typography color="#003146" size={16} bold>
          Monthly Employer Contribution
        </Typography>
        {selectedContribution ? (
          <EmployeeResult
            relationship={selectedContribution?.relationship}
            age={selectedContribution?.age}
            amount={contribution}
            isLoading={isLoading}
          />
        ) : (
          <Typography color="#666" size={14}>
            Please select an example to preview
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
