import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

export const DateTimeFormats = {
  a: 'YYYY-MM-DD',
  b: 'YYYY/MM/DD',
  c: 'YYYY-MM-DD HH:mm:ss',
  d: 'MM/DD/YYYY HH:mm:ss',
  e: 'YYYY-MM-DD h:mm A',
  f: 'MM/DD/YYYY h:mm A',
  g: 'YYYY-MM-DD HH:mm',
} as const

export const determineAge = (
  dateOfBirth: string,
  parseFormat: string = DateTimeFormats.a
) => {
  const now = dayjs()
  const dob = dayjs(dateOfBirth, parseFormat)
  if (!dob.isValid()) {
    console.error('Invalid Date of Birth provided!')
    return -1
  }

  return now.diff(dob, 'years')
}

export const convertFromISODate = (
  date: string,
  parseFormat: string = DateTimeFormats.a,
  resultFormat: string = DateTimeFormats.d
) => dayjs(date, parseFormat).format(resultFormat)

export const convertToLocalFromUTCISODate = (
  date: string,
  parseFormat: string = DateTimeFormats.c,
  resultFormat: string = DateTimeFormats.f
) => dayjs.utc(date, parseFormat).local().format(resultFormat)

export const convertToText = (
  date: string,
  parseFormat: string = DateTimeFormats.a
) => dayjs(date, parseFormat).format('dddd, MMMM D, YYYY')

export default dayjs
