import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Input from '@hixme-ui/input'

import { FieldError } from 'components/ReduxFormComponents'

const SocialSecurityInput = ({ input, meta, ...restProps }) => (
  <div>
    <Input
      ssn
      name="card"
      pattern="^\d{3} - ?\d{2} - ?\d{4}$"
      error={meta.touched ? meta.error : null}
      {...input}
      {...restProps}
    />
    <FieldError meta={meta} />
  </div>
)

SocialSecurityInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
}

SocialSecurityInput.defaultProps = {
  input: {},
  meta: {},
}

export default SocialSecurityInput
