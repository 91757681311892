import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import RadioButton from '@hixme-ui/radio-button'

import { FieldError } from 'components/ReduxFormComponents'

const FieldRadioButton = ({ input, meta, list, ...restProps }) => (
  <div>
    {list.map((radio, indx) => (
      <div key={`${restProps.name}_${indx.toString()}`}>
        <RadioButton
          {...input}
          {...restProps}
          {...radio}
          checked={radio.value === input.value}
        />
      </div>
    ))}
    <FieldError meta={meta} />
  </div>
)

FieldRadioButton.displayName = 'FieldRadioButton'
FieldRadioButton.propTypes = {
  input: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
}

export default FieldRadioButton
