import { createSelector } from 'reselect'
import { getWaiter } from 'redux-waiter'
import {
  GET_ALL_PERSONS,
  GET_ALL_PERSONS_BY_COMPANY_ID,
  GET_PERSON,
  GET_FAMILY,
} from 'store/modules/persons/constants'

import { NAME } from './constants'

export const personMapSelector = createSelector(
  (state) => state[NAME],
  (persons) => persons.list
)

export const personCollectionSelector = createSelector(
  personMapSelector,
  (persons) => Object.values(persons)
)

export const personByIdSelector = createSelector(
  personMapSelector,
  (persons) => (id) => persons[id]
)

export const getFamilyByEmployeeId = createSelector(
  personCollectionSelector,
  (persons) => (id) => {
    // Return family with Employee at zero
    let family = persons.filter((person) => person.EmployeePublicKey === id)
    family.forEach((person, index) => {
      if (person.Relationship === 'Employee') {
        family = family.splice(index, 1).concat(family)
      }
    })
    return family
  }
)

export const getPersonsByCompanyIdWaiter = createSelector(
  (state) => state,
  (state) => getWaiter(state, GET_ALL_PERSONS_BY_COMPANY_ID)
)

export const getAllPersonsWaiter = createSelector(
  (state) => state,
  (state) => getWaiter(state, GET_ALL_PERSONS)
)

export const getFamilyByEmployeeIdWaiter = createSelector(
  (state) => state,
  (state) => (id) => getWaiter(state, `${GET_FAMILY}:${id}`)
)

export const getPersonByIdWaiter = createSelector(
  (state) => state,
  (state) => (id) => getWaiter(state, `${GET_PERSON}:${id}`)
)
