import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

// hixme-ui
import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import { copyToClipboard } from '../CopyLink/CopyText'
import { removeLineBreaks } from 'modules/string-utils'

const CopyToClipboard = ({ text, icon, onCopy, children, ...props }) => {
  const textToCopy = removeLineBreaks(text)
  return textToCopy ? (
    <Container flex noPadding justifySpaceBetween width="100%" {...props}>
      <div
        role="none"
        data-iscapture
        data-tip="Copied to Clipboard"
        data-event="click
      focus"
        data-for={textToCopy}
        onClick={() =>
          onCopy ? onCopy(textToCopy) : copyToClipboard(textToCopy)
        }
        style={{ cursor: 'pointer', width: '100%', display: 'flex', gap: 5 }}
      >
        {children || textToCopy}
        {icon ? <Icon newWindow primary /> : null}
        <ReactTooltip id={textToCopy} effect="solid" />
      </div>
    </Container>
  ) : (
    children || text
  )
}

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  place: PropTypes.string,
  icon: PropTypes.bool,
  onCopy: PropTypes.func,
  children: PropTypes.node,
}

CopyToClipboard.defaultProps = {
  icon: true,
  children: undefined,
  onCopy: null,
  place: 'top',
}

export default CopyToClipboard
