import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import FormGroup from '@hixme-ui/form-group'
import Label from '@hixme-ui/label'
import Select from '@hixme-ui/select'

import { FieldError } from 'components/ReduxFormComponents'

export const SalesDirectorFormGroup = (props) => {
  const { input, meta, ...restProps } = props
  return (
    <FormGroup>
      <Label>Sales directors</Label>
      <SalesDirectorDropdown {...input} list={restProps.list} />
      <FieldError meta={meta} />
    </FormGroup>
  )
}

SalesDirectorFormGroup.displayName = 'SalesDirectorFormGroup'
SalesDirectorFormGroup.propTypes = {
  input: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
}

const SalesDirectorDropdown = ({ list, ...props }) => {
  const directors = [
    { id: '', name: 'Select' },
    ...list.map((rep) => ({
      id: rep.publicKey,
      name: `${rep.firstName} ${rep.lastName}`,
    })),
  ]

  return <Select list={directors} {...props} />
}

SalesDirectorDropdown.displayName = 'SalesDirectorDropdown'
SalesDirectorDropdown.propTypes = {
  list: PropTypes.array.isRequired,
}

export default { SalesDirectorFormGroup }
