import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'
import { withRouter } from 'react-router'

import { actions, selectors } from '../'
import { WAITER_GET_EMPLOYEE } from '../constants'

const mapStateToProps = (state, props) => {
  const employeePublicKeyParam = props.params.id
  const employee = selectors.getEmployee(state)
  const gettingEmployee = getWaiter(
    state,
    `${WAITER_GET_EMPLOYEE}${employeePublicKeyParam}`
  ).isPending
  return {
    employee,
    gettingEmployee,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEmployee: (EmployeePublicKey) => {
    dispatch(actions.getEmployeeFamily(EmployeePublicKey))
  },
  clearAll: () => {
    dispatch(actions.clearAll())
  },
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)
