import { Stack } from '@sureco/design-system'
import ContentSection from 'layouts/ContentSection/ContentSection'
import React from 'react'
import { groupBy } from 'lodash'
import ContactList from '../components/ContactList'
import CustomerDetails from '../components/CustomerDetails'

const ProfileTab = ({ customer, contacts, configuration }) => {
  const groupedContacts = Object.entries(groupBy(contacts, 'type') ?? {})
  return (
    <Stack space={20}>
      <ContentSection title="Customer Details">
        <CustomerDetails customer={customer} configuration={configuration}/>
      </ContentSection>
      {groupedContacts.map(([key, contacts]) => {
        return <ContentSection title={`${key} Contacts`} key={key}>
            <ContactList contacts={contacts}/>
        </ContentSection>
            })}
    </Stack>
  )
}

export default ProfileTab
