import { createReducer } from 'modules/app-core/redux-utils'
import t from './actionTypes'

const initialState = {
  // locations: {},
}

export default createReducer(initialState, {
  [t.LOAD_LOCATIONS]: (state, { locations, postalCode }) => ({
    ...state,
    [postalCode]: locations,
  }),
})
