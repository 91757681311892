import { callWaiter } from 'redux-waiter'

import { createErrorNotification } from 'modules/app-core/notification-manager/actions'
import { downloadLink } from 'helpers/download-file'

import * as censusApi from './api'
import {
  ARCHIVE_CENSUS,
  IMPORT_CENSUS,
  DOWNLOAD_CENSUS,
  IMPORT_CENSUS_STATUS,
  GET_ALL_CENSUS,
  GET_CENSUS,
  POST_CENSUS,
} from './constants'

import t from './actionTypes.js'

const acceptedParams = {
  ValidationErrorsFile: 'File',
}

const importingCensus = (censusId) => ({
  type: t.IMPORT_CENSUS,
  payload: censusId,
})

export const importedCensus = (payload) => ({
  type: t.IMPORT_CENSUS_DONE,
  payload,
})

const receiveCensusList = (census) => ({
  type: t.RECEIVE_CENSUS_LIST,
  payload: census,
})

const receiveCensus = (census) => ({
  type: t.RECEIVE_CENSUS,
  payload: census,
})

export const archivedCensus = (census) => ({
  type: t.ARCHIVE_CENSUS,
  payload: census,
})

export const importedCensusStatus = (payload) => ({
  type: t.IMPORT_CENSUS_STATUS_DONE_VIEW,
  payload,
})

export const importResults = ({ Id, DataType, include = [] }) => (
  dispatch,
  getState
) => {
  const census = getState().census.list[Id]
  const params = {
    Id,
    DataType,
  }
  include.forEach((param) => {
    if (acceptedParams[param]) params[acceptedParams[param]] = census[param]
  })
  return dispatch(
    callWaiter(`${IMPORT_CENSUS_STATUS}:${params.Id}`, {
      requestCreator: () =>
        censusApi
          .downloadImport(params)
          .then((importStatus) => {
            dispatch(importedCensusStatus(importStatus))
          })
          .catch((e) => {
            dispatch(
              createErrorNotification(
                'We had trouble downloading this import status. Please try again.'
              )
            )
            throw e
          }),
    })
  )
}

export const downloadCsv = ({ Id, DataType, include = [] }) => (
  dispatch,
  getState
) => {
  const census = getState().census.list[Id]
  const params = {
    Id,
    DataType,
  }
  include.forEach((param) => {
    if (acceptedParams[param]) params[acceptedParams[param]] = census[param]
  })
  return dispatch(
    callWaiter(`${DOWNLOAD_CENSUS}:${params.Id}`, {
      requestCreator: () =>
        censusApi
          .downloadCsv(params)
          .then(({ fileUrl = '' }) => {
            if (fileUrl) {
              downloadLink(fileUrl, 'census.csv')
            }
          })
          .catch((e) => {
            dispatch(
              createErrorNotification(
                'We had trouble downloading this csv. Please try again.'
              )
            )
            throw e
          }),
    })
  )
}

export const archiveCensus = (censusId) => (dispatch) =>
  dispatch(
    callWaiter(`${ARCHIVE_CENSUS}:${censusId}`, {
      requestCreator: () =>
        censusApi
          .archiveCensus(censusId)
          .then((response) => {
            if (!response.IsArchived) {
              dispatch(
                createErrorNotification(
                  'We had trouble archiving this census. Please try again.'
                )
              )
            }
            dispatch(archivedCensus(censusId))
          })
          .catch((e) => {
            dispatch(
              createErrorNotification(
                `We had trouble archiving this census: ${
                  e.type
                }. Please try again.`
              )
            )
            throw e
          }),
    })
  )

export const importCensus = (censusId) => (dispatch) => {
  dispatch(importingCensus(censusId))
  return dispatch(
    callWaiter(`${IMPORT_CENSUS}:${censusId}`, {
      requestCreator: () =>
        censusApi
          .importCensus(censusId)
          .then((response) => {
            if (!response.IsCompleted) {
              dispatch(
                createErrorNotification(
                  'This census contains errors. Please fix and reupload.'
                )
              )
            }
            dispatch(importedCensus({ ...response, Id: censusId }))
          })
          .catch((error) => {
            dispatch(importedCensus({ Id: censusId }))
            const err =
              (error.message && `${error.message}. Please try again.`) ||
              (error.type &&
                `We had trouble importing this census: ${
                  error.type
                }. Please try again.`) ||
              'We had trouble importing this census. Please try again.'

            dispatch(createErrorNotification(err))
            throw error
          }),
    })
  )
}

export const getCensusById = (censusId) => (dispatch) =>
  dispatch(
    callWaiter(`${GET_CENSUS}:${censusId}`, {
      requestCreator: () =>
        censusApi
          .getCensusById(censusId)
          .then((census) => {
            dispatch(receiveCensus(census))
            return census
          })
          .catch((e) => {
            dispatch(
              createErrorNotification(
                'We had trouble getting this census. Please try again.'
              )
            )
            throw e
          }),
    })
  )

export const getAllCensus = () => (dispatch) =>
  dispatch(
    callWaiter(GET_ALL_CENSUS, {
      requestCreator: () =>
        censusApi
          .getAllCensus()
          .then((response) => {
            const census = Object.keys(response).map((key) => response[key])
            dispatch(receiveCensusList(census))
            return response
          })
          .catch((e) => {
            dispatch(
              createErrorNotification(
                'We had trouble loading the census. Please reload the page.'
              )
            )
            throw e
          }),
    })
  )

export const uploadFile = (body) => (dispatch) =>
  dispatch(
    callWaiter(POST_CENSUS, {
      requestCreator: () => censusApi.postCensus(body),
    })
  )
