export default [
  {
    name: '52',
    id: '52',
  },
  {
    name: '26',
    id: '26',
  },
  {
    name: '24',
    id: '24',
  },
  {
    name: '12',
    id: '12',
  },
  {
    name: 'Varies',
    id: '0',
  },
]
