import React, { useEffect, useState } from 'react'
import styles from './NewHome.module.scss'
import Page from '../../../layouts/Page'
import { useSelector } from 'react-redux'
import { selectors as userSessionSelectors } from '../../../store/modules/user-session'
import { Button, Typography } from '@sureco/design-system'

const NewHome = () => {
  const [count, setCount] = useState(0)
  const handleButtonClick = () => {
    console.log('Button clicked!')
    setCount(count + 1)
  }
  const currentCompany: {
    Id: string;
    CompanyName: string
  } = useSelector((state) => userSessionSelectors.getCurrentCompany(state)) as any
  const [company, setCompany] = useState('')

  useEffect(() => {
    const { Id, CompanyName } = currentCompany
    setCompany(`${CompanyName}:(${Id})`)
  }, [currentCompany])

  return (
    <Page title={'New Home'} action={(
      <Button onClick={handleButtonClick}>
        Click Me
      </Button>
    )}>
      <div className={styles.container}>
        <Typography as="h1" size={16} bold>Welcome to the Boilerplate Page</Typography>
        <Typography as="p">
          You clicked {count} times.
        </Typography>
        <Typography as="p" className={styles.description}>
          You have {company} selected.
        </Typography>
      </div>
    </Page>
  )
}

export default NewHome
