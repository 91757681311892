import { createReducer } from 'modules/app-core/redux-utils'
import t from './actionTypes'

// initialState
const initialState = {
  benefits: [],
  person: {},
}

export default createReducer(initialState, {
  [t.LOAD_COBRA_BENEFITS]: (state, benefits) =>
    Object.assign({}, state, {
      cobraBenefits: benefits,
    }),
  [t.LOAD_PERSON]: (state, person) =>
    Object.assign({}, state, {
      person,
    }),
})
