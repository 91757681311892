import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getLocationsListSelector = createSelector(
  (state) => state[NAME],
  (loc) => loc.list
)

export const getLocationsListSelectorByPostalCode = (postalCode, state) =>
  createSelector(
    () => state[NAME],
    (locations) => {
      return locations[postalCode]
    }
  )(state)

export const getCountyHashSelector = createSelector(
  (state) => state[NAME],
  (locations) => {
    const hash = {}
    for (const [_, location] of Object.entries(locations)) {
      location.forEach((loc) => {
        hash[loc.County] = loc
      })
    }

    return hash
  }
)
