import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { MrModal } from '@hixme/modal'

import { SalesDirectorContainer } from 'containers'

const EditHixmeInfo = ({ handleSubmit, isProcessing }) => (
  <MrModal
    dialog
    small
    title="Edit Info"
    confirmHandler={handleSubmit}
    confirmLabel="Submit"
    confirmProcessing={isProcessing}
  >
    <div style={{ minWidth: '500px' }}>
      <form onSubmit={handleSubmit}>
        <Field
          style={{ width: '100%' }}
          name="SalesDirectorPublicKey"
          component={SalesDirectorContainer}
        />
      </form>
    </div>
  </MrModal>
)

EditHixmeInfo.displayName = 'EditHixmeInfo'
EditHixmeInfo.propTypes = {
  handleSubmit: PropTypes.func,
  isProcessing: PropTypes.bool,
}

EditHixmeInfo.defaultProps = {
  handleSubmit: () => null,
  isProcessing: false,
}

export default EditHixmeInfo
