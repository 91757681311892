import { Button } from '@sureco/design-system'
import React, { useCallback, useRef, useState } from 'react'
import BulkEditDatesModal from '../components/BulkEditDatesModel'
import ReviewRecordsBase from '../components/CensusReviewRecordsBase'
import { CensusFileRowTypeEnum } from '../../api/enums/census-file-row-type.enum'
import { CellValueChangedEvent } from '@ag-grid-community/core'
import { updateCensusFileRows } from '../../api/census-enrollment-platform-api-client'
import { useMarkRowDirty } from '../../hooks/use-mark-row-dirty'
import { CensusFileRowGridData } from '../../types/census-file-row-grid-data'
import { CensusFileRowDto } from '../../api/dto/census-file-row.dto'
import { mapGridDataToCensusFileRowDto } from '../../utils/map-grid-data-to-census-file-row-dto'
import ToggleEditModeButton from '../../components/ToggleEditModeButton'
import InlineEditActionButtons from '../../components/InlineEditActionButtons'
import { LoadingOverlay } from '../../components/LoadingOverlay/LoadingOverlay'
import RejectSelectedButton from '../../components/RejectSelectedButton'
import { CensusFileRowStatusEnum } from '../../api/enums/census-file-row-status.enum'
import ApproveNewRecordsButton from '../components/ApproveNewRecordsButton'
import { reviewNewRecordsModifyColDefs } from '../utils/review-new-records-modify-col-defs'
import ConfirmCellChangesModal from '../../components/ConfirmCellChangesModal'
import { message } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'

const submitCorrections = async (rowsToSubmit: CensusFileRowGridData[], publicKey: string) => {
  const updatedRows: CensusFileRowDto[] = rowsToSubmit.map(mapGridDataToCensusFileRowDto)
  await updateCensusFileRows(updatedRows, publicKey)
}

const ReviewNewRecords = (props: Record<string, never> & { routeParams: never }) => {
  const { markRowDirty, dirtyRows, cleanAllRows, undoLastEdit, editCount } = useMarkRowDirty()
  const [mode, setMode] = useState<'edit' | 'submit'>('submit')
  const [showCellChangeModal, setShowCellChangeModal] = useState<boolean>(false)
  const [loading, setLoading] = useState(false) // Track submission state
  const { importPublicKey: publicKey } = props.routeParams
  const [showBulkEditModal, setShowBulkEditModal] = useState(false)
  const [parentSelectedRows, setParentSelectedRows] = useState<CensusFileRowGridData[]>([])
  const gridUtilsRef = useRef<{ resetGrid:() => void; updateGridCell:(rowId: string, columnKey: string, newValue: any) => void }>()
  const handleGridUtilsReady = (utils) => {
    gridUtilsRef.current = utils
  }
  const [bulkUpdateDateRows, setBulkUpdateDateRows] = useState<CensusFileRowGridData[]>([])

  const handleCellValueChanged = useCallback((params: CellValueChangedEvent<CensusFileRowGridData>) => {
    markRowDirty(params)
  }, [])
  const revertBulkUpdateDateRows = () => {
    bulkUpdateDateRows.forEach((row) => {
      row['BenefitsEffectiveDate'] = row.rowData.BenefitsEffectiveDate
      row['EnrollmentStartDate'] = row.rowData.EnrollmentStartDate
      row['EnrollmentEndDate'] = row.rowData.EnrollmentEndDate
      row.dirtyFields = {}
    })
    setBulkUpdateDateRows([])
  }

  const bulkUpdateConfirm = ({ benefitsEffectiveDate, enrollmentStartDate, enrollmentEndDate, selectedRows }) => {
    selectedRows.forEach((row) => {
      row['BenefitsEffectiveDate'] = benefitsEffectiveDate
      row['EnrollmentStartDate'] = enrollmentStartDate
      row['EnrollmentEndDate'] = enrollmentEndDate
      row.dirtyFields = {
        BenefitsEffectiveDate: true,
        EnrollmentStartDate: true,
        EnrollmentEndDate: true,
      }
    })
    setBulkUpdateDateRows(selectedRows)
    setShowBulkEditModal(false)
    setShowCellChangeModal(true)
  }

  const handleBulkDatesSave = async () => {
    setLoading(true)
    try {
      await submitCorrections(bulkUpdateDateRows, publicKey)
    } catch (error) {
      console.log(error)
      message.error('An error occurred while submitting')
    } finally {
      revertBulkUpdateDateRows()
      setLoading(false)
      setShowBulkEditModal(false)
      setShowCellChangeModal(false)
      gridUtilsRef.current?.resetGrid()
      message.success('Dates updated successfully')
    }
  }

  return (
    <>
  <LoadingOverlay isLoading={loading} message="Loading..." />
      <BulkEditDatesModal
        selectedRows={parentSelectedRows}
        visible={showBulkEditModal}
        onCancel={() => setShowBulkEditModal(false)}
        bulkUpdateConfirm={bulkUpdateConfirm}
      />
      <ConfirmCellChangesModal
        visible={showCellChangeModal}
        onCancel={() => { setShowCellChangeModal(false); revertBulkUpdateDateRows() }}
        onConfirm={handleBulkDatesSave}
        loading={loading}
        dirtyRows={bulkUpdateDateRows}
        updateMode={false}
      />

      <ReviewRecordsBase
        onGridUtilsReady={handleGridUtilsReady}
        onSelectionChangedCallback={(selected) => {
          // do something with selected rows in the parent
          setParentSelectedRows(selected)
        }}
      tabKey={CensusFileRowTypeEnum.DRAFT_EMPLOYEE}
      {...props}
      rowQueryParams={{
        censusFileRowStatuses: [CensusFileRowStatusEnum.IN_REVIEW],
        censusRowType: CensusFileRowTypeEnum.DRAFT_EMPLOYEE,
      }}
      modifyColDefs={(coldefs) => reviewNewRecordsModifyColDefs(coldefs, mode)}
      actionButton={({ selectedRows, handleSubmit }) => {
        // @ts-ignore
        return (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <ToggleEditModeButton dirtyRows={dirtyRows} selectedRows={selectedRows} mode={mode} setMode={setMode} />
            {mode === 'edit' ? (
                <InlineEditActionButtons
                  undoLastEdit={undoLastEdit}
                  editCount={editCount}
                  dirtyRows={dirtyRows}
                  handleSubmit={handleSubmit}
                  cleanAllRows={cleanAllRows}
                  submitCorrections={(rows) => submitCorrections(rows, publicKey)}
                  updateMode={false}
                />
            ) : (
              <>
                <RejectSelectedButton
                  publicKey={publicKey}
                  selectedRows={selectedRows}
                  handleSubmit={handleSubmit}
                  cleanAllRows={cleanAllRows}
                  setLoading={setLoading}
                />
                {selectedRows.length > 0 && (
                  <Button
                    size="large"
                    variant="primary"
                    onClick={() => setShowBulkEditModal(!showBulkEditModal)}
                  >
                    <CalendarOutlined style={{ marginRight: '8px' }} />Edit Dates ({selectedRows.length})
                  </Button>
                )}
                <ApproveNewRecordsButton
                  publicKey={publicKey}
                  selectedRows={selectedRows}
                  handleSubmit={handleSubmit}
                  cleanAllRows={cleanAllRows}
                  setLoading={setLoading}
                />
              </>
            )}
          </div>
        )
      }}
      handleCellValueChanged={handleCellValueChanged}
    />
    </>
  )
}

export default ReviewNewRecords
