import axios from 'axios'
import { callWaiter } from 'redux-waiter'
import { isDevelopment } from 'modules/app-config/environment'
import {
  ENROLLME_SOURCE_SYSTEM,
  CALLER,
  EMPLOYEE_CARD_UPDATE,
  GET_TRANSACTIONS,
} from '../../constants'

const BASE_PATH = isDevelopment()
  ? 'https://vcn-service.qa.surecolabs.com'
  : 'https://vcn-service.docday.com'

const axiosConfig = () => ({
  baseURL: BASE_PATH,
})

const postRequest = ({ url, data }) =>
  axios({
    ...axiosConfig(),
    method: 'post',
    url,
    data,
  }).then((response) => response.data)

const getRequest = ({ url, params }) =>
  axios({
    ...axiosConfig(),
    method: 'get',
    url,
    params,
  }).then((response) => response.data)

export const getCardState = ({ userId }) =>
  axios.get(
    `${BASE_PATH}/card/paymentuser/sourcesystem/${ENROLLME_SOURCE_SYSTEM}/sourceuserid/${userId}/cardStatus`
  )

export const getCardDetails = () => {
  axios.post(`${BASE_PATH}/card/showdetails`)
}

export const performCardAction = ({
  Action,
  cardId,
  reason = 'User Action',
  personId: source_user_id,
}) => (dispatch) =>
  dispatch(
    callWaiter(EMPLOYEE_CARD_UPDATE, {
      requestCreator: () =>
        postRequest({
          url: `/card/${Action}`,
          data: {
            card_public_key: cardId,
            reason,
            source_user_id,
            caller_name: CALLER,
            source_system_id: ENROLLME_SOURCE_SYSTEM,
          },
        }),
    })
  )

export const getTransactions = (benefitId) => (dispatch) =>
  dispatch(
    callWaiter(GET_TRANSACTIONS, {
      requestCreator: () =>
        getRequest({
          url: `/card/transactions/sourcePublicKey/${benefitId}`,
        }),
    })
  )

export default {
  performCardAction,
  getTransactions,
}
