import moment from 'moment'

import t from './actionTypes'

export const personModel = {
  Id: null,
  FirstName: '',
  LastName: '',
  FullName: '',
}

const personMap = {
  [t.RECEIVE_PERSON]: (state, payload) => {
    // Formatted DOB, BenEffDt & append State Rating area
    const {
      StateProvince,
      RatingArea,
      BenefitsEffectiveDate,
      DateOfBirth,
      OriginalHireDate,
    } = payload

    return {
      ...state,
      ...payload,
      StateRatingArea:
        StateProvince && RatingArea
          ? `${StateProvince}-${RatingArea}`
          : undefined,
      BenefitsEffectiveFormatted: BenefitsEffectiveDate
        ? moment(BenefitsEffectiveDate).format('MM/DD/YYYY')
        : undefined,
      DateOfBirthFormatted: DateOfBirth
        ? moment(DateOfBirth).format('MM/DD/YYYY')
        : undefined,
      HireDateFormatted: OriginalHireDate
        ? moment(OriginalHireDate).format('MM/DD/YYYY')
        : undefined,
    }
  },
}

export default (state = personModel, action) => {
  const reducer = personMap[action.type]

  return reducer ? reducer(state, action.payload) : state
}
