import React from 'react'
import { Controller, Control, UseControllerProps } from 'react-hook-form'
import { DatePicker, GetProps } from '@surecompanies/core_components'
import dayjs, { Dayjs } from 'dayjs'
import { Stack, TextColors, Typography } from '@sureco/design-system'

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>

type RHFRangePickerProps = Omit<RangePickerProps, 'name'> & {
  name: string
  control: Control<any, object> // unable to make it work using generics yet Control<T>
  rules?: UseControllerProps['rules']
  label?: string
  isBeforeTodayAvailable?: boolean
}
const { RangePicker } = DatePicker
export default function RHFRangePicker({
  name,
  control,
  rules,
  label,
  isBeforeTodayAvailable = true,
  ...rest
}: RHFRangePickerProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <Stack space={2}>
            {label && <Typography>{label}</Typography>}
            <RangePicker
              name={field.name}
              status={fieldState.error ? 'error' : undefined}
              onBlur={field.onBlur}
              value={
                field.value
                  ? [dayjs(field.value[0]), dayjs(field.value[1])]
                  : null
              }
              ref={field.ref}
              onChange={(dates: null | (Dayjs | null)[]) => {
                field.onChange(dates)
              }}
              disabledDate={(current) => {
                if (!isBeforeTodayAvailable) {
                  return current.isBefore(dayjs())
                }
                return false
              }}
              {...rest}
            />
            {fieldState.error && (
              <Typography color={TextColors.error}>
                {fieldState.error.message}
              </Typography>
            )}
          </Stack>
        )
      }}
    />
  )
}
