import apiClient from '../../api/enrollment-platform-client/api-client'
import { SimpleCustomerDto } from './dto/simple-customer.dto'

// Cache storage
let cachedClients: SimpleCustomerDto[] | null = null
let cacheTimestamp: number | null = null

// Cache expiration time in milliseconds (5 minutes)
const CACHE_DURATION = 5 * 60 * 1000

// The clients the current user can 'administer'
export const getMyClients = async (): Promise<SimpleCustomerDto[]> => {
  const now = Date.now()

  if (cachedClients && cacheTimestamp && (now - cacheTimestamp < CACHE_DURATION)) {
    return cachedClients
  }

  // If cache is expired or doesn't exist, fetch from API
  const url = 'members/me/administered-customers'
  const response = await apiClient.get<SimpleCustomerDto[]>(url)

  // Update the cache
  cachedClients = response.data.filter((c) => c.isActive).map(({ id, name, isActive }) => ({ id, name, isActive }))
  cacheTimestamp = now

  return cachedClients
}
