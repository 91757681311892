import { createSelector } from 'reselect'
import { NAME } from './constants'

// return company list by Id
export const companyMapSelector = createSelector(
  (state) => state[NAME],
  (companies) => companies.list
)

// return company list as array
export const companyCollectionSelector = createSelector(
  companyMapSelector,
  (companies) => Object.values(companies)
)

// return company list as array
export const companyCollectionKeysSelector = createSelector(
  companyMapSelector,
  (companies) => Object.keys(companies)
)

export const getCompanyById = createSelector(
  companyMapSelector,
  (companies) => (companyId) => companies[companyId]
)

export const getCompanyNameById = createSelector(
  companyMapSelector,
  (companies) => (companyId) => companies[companyId].CompanyName
)
