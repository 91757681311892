import React, { useState } from 'react'
import {
  Button,
  Tag,
  Tooltip,
  Typography,
  notification,
} from '@surecompanies/core_components'
import {
  CheckCircleOutlined,
  EditOutlined,
  MinusCircleOutlined,
  EyeOutlined,
} from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Stack } from '@sureco/design-system'
import { PaginationRequest } from 'enrollment-platform/types/pagination-response-dto'
import client from 'enrollment-platform/api/eps.client'

export const IconStatusMapper = {
  active: {
    color: '#98CE24',
    text: '#fff',
    icon: <CheckCircleOutlined />,
  },
  draft: {
    color: '#FAAD14',
    text: '#fff',
    icon: <EditOutlined />,
  },
  inactive: { color: '#999', text: '#fff', icon: <MinusCircleOutlined /> },
}

type UseListCustomerProps = {
  onView: (e: any) => void
  onEdit: (e: any) => void
}

type CustomerListResult = {
  id: string
  employee_count: number
  status: string
  name: string
}

type CustomerListResultDTO = {
  data: CustomerListResult[]
}

export default function useListCustomer({
  onView,
  onEdit,
}: UseListCustomerProps) {
  const [customers, setCustomers] = useState<{ text: string; value: string }[]>(
    []
  )
  async function listCustomer({
    limit,
  }: PaginationRequest): Promise<CustomerListResult[]> {
    try {
      const result = await client.get<CustomerListResultDTO>('/customers', {
        params: {
          limit,
        },
      })
      setCustomers(
        result.data.data.map((item) => ({
          text: item.name,
          value: item.id,
        }))
      )
      return result.data.data
    } catch (err) {
      notification.error({
        message: 'Something went wrong displaying customers',
      })
      setCustomers([])
      return []
    }
  }

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ['getCustomers'],
    queryFn: () => listCustomer({ limit: 100 }),
    staleTime: 0,
  })

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: 'Draft',
          value: 'DRAFT',
        },
        {
          text: 'Active',
          value: 'ACTIVE',
        },
        {
          text: 'Inactive',
          value: 'INACTIVE',
        },
      ],
      onFilter: (value, { status }) => status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (_, { status }) => {
        const config = IconStatusMapper[status.toLowerCase()]
        return (
          <>
            <Tag
              icon={config.icon}
              color={config.color}
              style={{ borderRadius: 18 }}
            >
              <Typography.Text
                type={config.color}
                style={{
                  color: config.text,
                  fontSize: 14,
                  fontWeight: 'bolder',
                }}
              >
                {status}
              </Typography.Text>
            </Tag>
          </>
        )
      },
    },
    {
      title: 'Company Name',
      dataIndex: 'name',
      filterSearch: true,
      filters: customers,
      render: (_, { name }) => <Typography.Text strong>{name}</Typography.Text>,
      onFilter: (value, { id }) => id === value,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Employees',
      dataIndex: 'employee_count',
      sorter: (a, b) => a.employee_count - b.employee_count,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Stack direction="row" space={10}>
          <Tooltip title="View">
            <Button
              type="primary"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => onView(record)}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => onEdit(record)}
            />
          </Tooltip>
        </Stack>
      ),
    },
  ]

  return {
    dataSource: data,
    isLoading: isPending,
    error,
    columns,
    refetch,
  }
}
