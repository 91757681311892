import { z } from 'zod'
import { ContactType } from '../enums/contact.type'
import { BusinessStructureEnum } from '../enums/bussiness.type'
import industryData from 'enrollment-platform/components/form/IndustrySelect/industry.data'
import { HRIS } from 'enrollment-platform/components/form/HrisSelect'
import validator from 'validator'

export const CustomerSchema = z.object({
  name: z.string().min(1, 'Please enter a valid value'),
  isActive: z.boolean().optional(),
  contacts: z.array(
    z
      .object({
        type: z.nativeEnum(ContactType),
        email: z.string().email('Please enter a valid email'),
        title: z.string().min(1, { message: 'Title is required' }),
        phone: z.object({
          number: z.string().refine((val) => validator.isMobilePhone(val), {
            message: 'Please enter a valid phone number',
          }),
        }),
        fullName: z.string().min(1, { message: 'Name is required' }),
        isPrimary: z.boolean(),
        fundingReportDistribution: z.boolean(),
      })
      .optional()
  ),
  industry: z
    .string()
    .refine((val) => industryData.some((industry) => industry.value === val))
    .optional(),
  payrollVendorName: z
    .string()
    .refine((val) => HRIS.some((industry) => industry.value === val))
    .optional(),
  businessStructure: z.nativeEnum(BusinessStructureEnum).optional(),
  address: z.object({
    streetAddressLine1: z.string(),
    streetAddressLine2: z.string().optional(),
    stateProvince: z.string(),
    postalCode: z.string().refine((val) => validator.isPostalCode(val, 'US'), {
      message: 'Please enter a valid postal code',
    }),
    county: z.string(),
    city: z.string(),
  }),
  webSiteUrl: z
    .string({ coerce: true })
    .refine((val) => validator.isURL(val), {
      message: 'Please enter a valid URL',
    })
    .optional(),
  logo: z.string().optional(),
})
