import startCase from 'lodash/startCase'

const visibleFields = ['approved', 'inProgress']

export const getAllowanceRowFromExpense = (expense) => {
  const { benefitEffectiveDate, benefitAmount } = expense
  return Object.keys(expense.sums).reduce(
    (arr, key) => {
      if (!visibleFields.includes(key)) return arr
      arr.push({
        title: startCase(key),
        value: expense.sums[key],
      })
      return arr
    },
    [
      {
        title: benefitEffectiveDate,
        value: benefitAmount || 0,
      },
    ]
  )
}
