import React from 'react'
import { Stack, TextColors, Typography } from '@sureco/design-system'
import { Divider, Image, Switch } from '@surecompanies/core_components'
import { HRISMap } from 'enrollment-platform/components/form/HrisSelect'
import { industryMap } from 'enrollment-platform/components/form/IndustrySelect/industry.data'

export default function CustomerDetails({ customer, configuration }) {
  return (
  <>
    <div
      style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', columnGap: 10 }}
    >
      <Stack space={10}>
        {customer.companyLogoUrl && <Image
          width={200}
          src={customer.companyLogoUrl}
          style={{ border: '1px solid #AAB5BD' }}
        />}
        <Typography color={TextColors.primary} size={16}>
          {customer.webSiteUrl}
        </Typography>
      </Stack>
      <Stack style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
        <Stack space={10}>
          <Stack>
            <Typography color="#999" size={16}>
              {customer.internalCompanyName}
            </Typography>
            <Stack>
              <Typography size={16} bold color="#003146">
                Industry
              </Typography>
              <Typography color="#003146" size={16}>
                {industryMap[customer.industry] ?? ''}
              </Typography>
            </Stack>
            <Stack>
              <Typography size={16} bold color="#003146">
                Business Structure
              </Typography>
              <Typography color="#003146" size={16}>
                {customer.businessStructure}
              </Typography>
            </Stack>
            <Stack>
              <Typography size={16} bold color="#003146">
                Payroll / HRIS Vendor
              </Typography>
              <Typography color="#003146" size={16}>
                {HRISMap[customer.payrollVendorName] ?? ''}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <Stack>
            <Typography size={16} bold color="#003146">
              Address
            </Typography>
            <Typography color="#003146" size={16}>
              {customer.address?.streetAddressLine1} {' '}
              {customer.address?.streetAddressLine2}
            </Typography>
            <Typography color="#999" size={16}>
              {customer.address?.city}, {customer.address?.stateProvince} {customer.address?.postalCode}
            </Typography>
          </Stack>
          <Stack>
            <Typography size={16} bold color="#003146">
              Phone
            </Typography>
            <Typography color="#003146" size={16}>
              {customer.phone}
            </Typography>
          </Stack>
          {customer.taxId && (
            <Stack>
              <Typography size={16} bold color="#003146">
                TaxID
              </Typography>
              <Typography color="#003146" size={16}>
                {customer.taxId}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </div>
      <Divider />
      <Stack direction='row' align="center" space={10} style={{ flex: 3 }}>
        <Stack direction="row" align="center" space={10} style={{ flex: 1 }}>
          <Switch
            value={configuration.isIchraNoticesEnabled}
            onChange={(event) => {
              console.log(event)
            }}
          />
          <Typography size={16} color='#003146'>ICHRA Notices <Typography color={configuration.isIchraNoticesEnabled ? TextColors.primary : TextColors.error} size={16} bold>{configuration.isIchraNoticesEnabled ? 'ON' : 'OFF'}</Typography></Typography>
        </Stack>
        <Stack direction="row" align="center" space={10} style={{ flex: 1 }}>
          <Switch
            value={configuration.isWelcomeEmailsEnabled}
            onChange={(event) => {
              console.log(event)
            }}
          />
          <Typography size={16} color='#003146'>Send Welcome Notices <Typography color={configuration.isWelcomeEmailsEnabled ? TextColors.primary : TextColors.error} size={16} bold>{configuration.isWelcomeEmailsEnabled ? 'ON' : 'OFF'}</Typography></Typography>
        </Stack>
        <Stack style={{ flex: 1 }} justify='center'>
          <Typography size={16} color='#003146'>Stripe Link: <Typography size={14} color='#999'>{configuration.stripeUrl}</Typography></Typography>
          {!configuration.stripeUrl && <Stack direction="row" justify="space-between" align="center" space={10}>
            <Typography size={14} color='#999'>Must be created:</Typography>
            <Typography as='a' size={14} color={TextColors.primary} href={configuration.stripeUrl}>Create</Typography>
          </Stack>}
        </Stack>
      </Stack>
    </>
  )
}
