import React from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'

interface GridWrapperProps<T> {
  columnDefs: ColDef<T>[];
  onGridReady: (params: GridReadyEvent) => void;
  rowSelection?: 'single' | 'multiple' | undefined;
  onSelectionChanged?: (any) => void; // Add selection change handler
  onCellValueChanged?: (params) => void; // Add selection change handler
  isRowSelectable?: (params?: any) => any
  additionalProps?: Record<string, any>;
}

function GridWrapper<T>({
                          onCellValueChanged,
                          columnDefs,
                          onGridReady,
                          onSelectionChanged,
                          rowSelection = 'multiple',
                          additionalProps = {},
                          isRowSelectable,
                        }: GridWrapperProps<T>) {
  return (
    <div className="ag-theme-alpine" style={{
      height: '80vh',
    }}>
      <AgGridReact<any>
        getRowId={({ data }) => data.id} // or data.rowId, or data.CompanyEmployeeId
        isRowSelectable={isRowSelectable}
        suppressRowTransform={false} // Prevents row flickering due to transforms
        singleClickEdit={true}
        suppressCellFocus={false} // Stops cell focus from causing re-renders
        debug={false}
        pagination={true}
        rowModelType="serverSide"
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        rowSelection={rowSelection}
        onSelectionChanged={onSelectionChanged}
        onCellValueChanged={onCellValueChanged}
        suppressRowClickSelection={true}
        paginationPageSizeSelector={[20, 50, 100, 500]}
        // sideBar={{
        // toolPanels: [
        // {
        // id: 'columns',
        // labelDefault: 'Columns',
        // labelKey: 'columns',
        // iconKey: 'columns',
        // toolPanel: 'agColumnsToolPanel',
        // toolPanelParams: {
        // suppressRowGroups: true,
        // suppressValues: true,
        // suppressColumnGroups: true,
        // }
        // },
        // ],
        // // hiddenByDefault: false,
        // }}
        defaultColDef={{
          sortable: false,
          filter: false,
          resizable: true,
          enableRowGroup: true, // Required for drag & drop pinning
          enablePivot: false, // Required for advanced column management
          enableValue: true,
        }}
        {...additionalProps}
      />
    </div>
  )
}

export default GridWrapper
