import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

// hixme-ui

import Container from '@hixme-ui/container'
import Currency from '@hixme-ui/currency'
import Text from '@hixme-ui/text'
import ReceiptLineItem from 'components/ReceiptLineItem/ReceiptLineItem'
import AttachedFile from 'components/ExpenseCard/components/AttachedFile/AttachedFile'

const ReceiptLineItems = ({
  lineItems,
  totalReimbursed,
  removeLineItem,
  onEdit,
  loadingLineItems,
}) => (
  <Container flex noPadding margin="20px 0" width="100%" direction="column">
    {isEmpty(lineItems) ? (
      <Container
        flex
        width="100%"
        noPadding
        style={{ borderBottom: '2px dashed #b9c5cd' }}
      >
        <Text large light style={{ fontStyle: 'italic', marginBottom: '10px' }}>
          Add line items to create reimbursement
        </Text>
      </Container>
    ) : (
      <Container
        flex
        noPadding
        width="100%"
        margin="10px 0"
        direction="column"
        style={{ borderBottom: '2px dashed #b9c5cd' }}
      >
        {!isEmpty(lineItems) &&
          lineItems.map((lineItem) => (
            <Container key={lineItem.receipt.id}>
              <AttachedFile
                text={lineItem.receipt.name}
                onClick={() => window.open(lineItem.receipt.url, '_blank')}
              />
              {lineItem.items.map((item) => (
                <ReceiptLineItem
                  key={item.id}
                  isLoading={loadingLineItems[item.id]}
                  item={item}
                  onRemove={removeLineItem}
                  onEdit={onEdit}
                />
              ))}
            </Container>
          ))}
      </Container>
    )}
    <Container flex noPadding justifySpaceBetween margin="10px 0">
      <Text large>Net Reimbursement:</Text>
      <Currency large>{totalReimbursed}</Currency>
    </Container>
  </Container>
)

ReceiptLineItems.propTypes = {
  totalReimbursed: PropTypes.number,
  lineItems: PropTypes.array,
  loadingLineItems: PropTypes.object,
  removeLineItem: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
}

ReceiptLineItems.defaultProps = {
  totalReimbursed: 0,
  loadingLineItems: {},
  lineItems: [],
  onEdit: null,
}

export default ReceiptLineItems
