import Modal from 'modules/app-core/modal'
import EmployeeFormContainer from './EmployeeFormContainer/EmployeeFormContainer'

export default Modal.containers.Modal({
  content: {
    position: 'top',
    styles: {
      margin: '50px 0 0 0',
    },
  },
  overlay: {
    hideCloseButton: false,
    routeOnHide: false,
  },
})(EmployeeFormContainer)
