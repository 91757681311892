import React from 'react'
import { Controller, Control, UseControllerProps } from 'react-hook-form'
import { DatePicker, DatePickerProps } from '@surecompanies/core_components'
import dayjs, { Dayjs } from 'dayjs'
import { Stack, TextColors, Typography } from '@sureco/design-system'

type RHFDatePickerProps = Omit<DatePickerProps, 'name'> & {
  name: string
  control: Control<any, object> // unable to make it work using generics yet Control<T>
  rules?: UseControllerProps['rules']
  label?: string
  isBeforeTodayAvailable?: boolean
}
export default function RHFDatePicker ({
  name,
  control,
  rules,
  label,
  isBeforeTodayAvailable = true,
  ...rest
}: RHFDatePickerProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
            <Stack space={2}>
                {label && <Typography>{label}</Typography>}
                <DatePicker
                    name={field.name}
                    status={fieldState.error ? 'error' : undefined}
                    onBlur={field.onBlur}
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(date: Dayjs) => { field.onChange(date?.valueOf() ?? null) }}
                    minDate={isBeforeTodayAvailable ? dayjs() : undefined}
                    ref={field.ref}
                    {...rest}
                />
                {fieldState.error && <Typography color={TextColors.error}>{fieldState.error.message}</Typography>}
          </Stack>
        )
      }}
    />
  )
}
