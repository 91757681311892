import { gql } from '@apollo/client'
// eslint-disable-next-line import/prefer-default-export
export const GET_CLIENT_DATA_BY_ID = gql`
  query GetClient($getClientId: String!) {
    getClient(id: $getClientId) {
      id
      benefitsEffectiveDate
      benefitPolicyDays
      benefitsEffectivePolicyUnit
      benefitsEffectivePolicy
      enrollmentStartDate
      enrollmentEndDate
      payrollCycle
      fein
      migrationYear
      county
      companyName
      address {
        line1
        line2
        city
        state
        county
        postalCode
      }
      billingContact {
        fullName
        emailAddress
        phone {
          phoneNumber
          extension
        }
        updatedOn
      }
      sureCoContact {
        fullName
        emailAddress
        phone {
          phoneNumber
          extension
        }
        updatedOn
      }
      hrContact {
        fullName
        emailAddress
        phone {
          phoneNumber
          extension
        }
        updatedOn
      }
      dateCreated
      ebamGroupId
      isActive
      salesDirectorPublicKey
      isProspect
      clientServicesRepPublicKey
      companyLogoUrl
      contactLastName
      contactPhoneNumber
      hixmeAccidentGroupId
      payrollConfigPublicKey
      CurrentHealthPlanDesigns
      hixmeGroupNumber
      clientServicesRepLastName
      salesDirectorFirstName
      contactFirstName
      salesDirectorLastName
      contactEmailAddress
      benefitPolicyName
      customerServicePhoneNumber
    }
  }
`

export const GET_ALL_CLIENT_DATA = gql`
  query GetAllClients {
    getAllClients {
      id
      benefitPolicyDays
      benefitsEffectivePolicyUnit
      benefitsEffectivePolicy
      enrollmentStartDate
      enrollmentEndDate
      payrollCycle
      fein
      migrationYear
      county
      companyName
      address {
        line1
        line2
        city
        state
        county
        postalCode
      }
      billingContact {
        fullName
        emailAddress
        phone {
          phoneNumber
          extension
        }
        updatedOn
      }
      sureCoContact {
        fullName
        emailAddress
        phone {
          phoneNumber
          extension
        }
        updatedOn
      }
      hrContact {
        fullName
        emailAddress
        phone {
          phoneNumber
          extension
        }
        updatedOn
      }
      dateCreated
      ebamGroupId
      isActive
      salesDirectorPublicKey
      isProspect
      clientServicesRepPublicKey
      companyLogoUrl
      contactLastName
      contactPhoneNumber
      hixmeAccidentGroupId
      payrollConfigPublicKey
      CurrentHealthPlanDesigns
      hixmeGroupNumber
      clientServicesRepLastName
      salesDirectorFirstName
      contactFirstName
      salesDirectorLastName
      contactEmailAddress
      benefitPolicyName
      customerServicePhoneNumber
      isCensusSyncEnabled
      censusSyncWebhookUrl
      hrisEligibilityRule
      isHRISNotificationDisabled
      contributionStrategyByYear {
        year
        strategy
      }
    }
  }
`

export const SAVE_CLIENT_INFO = gql`
  mutation SaveClientInfo($input: SaveClientInfoInput!) {
    saveClientInfo(input: $input) {
      id
      benefitsEffectiveDate
      benefitPolicyDays
      benefitsEffectivePolicyUnit
      benefitsEffectivePolicy
      enrollmentStartDate
      enrollmentEndDate
      payrollCycle
      fein
      migrationYear
      companyName
      address {
        line1
        line2
        city
        state
        county
        postalCode
      }
      billingContact {
        fullName
        emailAddress
        phone {
          phoneNumber
          extension
        }
        updatedOn
      }
      sureCoContact {
        fullName
        emailAddress
        phone {
          phoneNumber
          extension
        }
        updatedOn
      }
      dateCreated
    }
  }
`

export const SAVE_CLIENT_CONTACT = gql`
  mutation SaveClientContact($input: SaveClientContactInput!) {
    saveClientContact(input: $input) {
      fullName
      emailAddress
      phone {
        phoneNumber
        extension
      }
      updatedOn
    }
  }
`

export const DELETE_CLIENT_CONTACT = gql`
  mutation DeleteClientContact($input: DeleteClientContactInput!) {
    deleteClientContact(input: $input)
  }
`
export const UPDATE_CLIENT_BENEFITENTENROLLMENT = gql`
  mutation UpdateClientBenefitEnrollment($input: UpdateClientBenefitInput!) {
    updateClientBenefitEnrollment(input: $input) {
      clientPublicKey
      dateCreated
      group
      enrollments {
        benefitEffectiveDate
        benefitEffectivePolicy
        benefitEffectivePolicyUnit
        benefitEndDate
        clientBenefitsPublicKey
        hsaSwap
        benefits {
          autoAddToCart
          benefitPublicKey
          benefitType
          canDecline
          canRemoveFromCart
          carrier
          createdDate
          description
          isActive
          maxAnnualVisits
          name
          note
          officeVisit
          SBCUrl
        }
        # configuration {
        #   employeeProfileReadOnly
        # }
      }
    }
  }
`
