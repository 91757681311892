import { createReducer } from 'modules/app-core/redux-utils'
import t from './actionTypes'

// initialState
const initialState = {
  benefits: [],
  bundles: [],
  enrollmentId: null,
  enrollments: [],
  family: [],
  waivedBenefits: [],
}

export default createReducer(initialState, {
  [t.LOAD_BENEFIT]: (state, payload) => ({
    ...state,
    benefits: state.benefits.map((item) => {
      if (item.Id === payload.Id) {
        return payload
      }
      return item
    }),
  }),
  [t.SET_BUNDLES]: (state, payload) => ({
    ...state,
    bundles: payload,
  }),
  [t.SET_EMPLOYEE_FAMILY]: (state, payload) => ({
    ...state,
    family: payload,
  }),
  [t.SET_EMPLOYEE_BENEFITS]: (state, payload) => ({
    ...state,
    benefits: payload,
  }),
  [t.SET_EMPLOYEE_WAIVED_BENEFITS]: (state, payload) => ({
    ...state,
    waivedBenefits: payload,
  }),
  [t.SET_ENROLLMENT_ID]: (state, payload) => ({
    ...state,
    enrollmentId: payload,
  }),
  [t.SET_ENROLLMENTS]: (state, payload) => ({
    ...state,
    enrollments: payload,
  }),
  [t.SET_TO_INITIAL_STATE]: (state) => ({
    ...state,
    ...initialState,
  }),
})
