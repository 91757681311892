import React from 'react'
import { Button } from '@surecompanies/core_components'

export default function EditableEnrollmentButton({
  isEditing,
  onEdit,
  onCancel,
}: {
  isEditing: boolean
  onEdit: () => void
  onCancel: () => void
}) {
  return (
    <Button
      variant="outlined"
      type="default"
      onClick={() => (isEditing ? onCancel() : onEdit())}
    >
      {isEditing ? 'Cancel' : 'Edit'}
    </Button>
  )
}
