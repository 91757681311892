import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import {
  createRequest,
  getRequestByName,
  getResponseByRequestName,
} from 'modules/redux-gateway'
import { clearModal } from '@hixme/modal'

import { actions as companyActions } from 'store/modules/companies'
import { selectors as userSessionSelectors } from 'store/modules/user-session'

import EditHixmeInfo from '../components/EditHixmeInfo/EditHixmeInfo'
import client from '../../../apollo/client'
import { GET_CLIENT_DATA_BY_ID } from '../../../routes/Company/queries/queries'

const mapStateToProps = (state) => ({
  initialValues: userSessionSelectors.getCurrentCompany(state),
  salesDirectors: getResponseByRequestName(state, 'salesdirectorsGet'),
  isProcessing: getRequestByName(state, 'clientPost').isProcessing,
})

export default connect(mapStateToProps)(
  reduxForm({
    form: 'EditHixmeInfo',
    onSubmit: (values, dispatch, props) => {
      const { salesDirectors } = props
      const selectedDirector = salesDirectors.find(
        (director) => director.publicKey === values.SalesDirectorPublicKey
      )
      const body = {
        ...values,
        SalesDirectorFirstName: selectedDirector.firstName,
        SalesDirectorLastName: selectedDirector.lastName,
      }

      return dispatch(
        createRequest({
          route: 'clientPost',
          body,
        })
      ).then(async (response) => {
        const res = await client.query({
          query: GET_CLIENT_DATA_BY_ID,
          variables: {
            getClientId: response.Id,
          },
        })
        dispatch(companyActions.receiveCompany(res?.data.getClient))
        dispatch(clearModal())
      })
    },
  })(EditHixmeInfo)
)
