import { createSelector } from 'reselect'
import { selectors as companySelectors } from 'store/modules/companies'
import { NAME } from './constants'

const transformToOldNotation = (company) => ({
  Id: company.id,
  BenefitsEffectiveDate: company.benefitsEffectiveDate,
  BenefitPolicyDays: company.benefitPolicyDays,
  BenefitsEffectivePolicyUnit: company.benefitsEffectivePolicyUnit,
  BenefitsEffectivePolicy: company.benefitsEffectivePolicy,
  EnrollmentStartDate: company.enrollmentStartDate,
  EnrollmentEndDate: company.enrollmentEndDate,
  PayrollCycle: company.payrollCycle,
  FEIN: company.fein,
  MigrationYear: company.migrationYear,
  CompanyName: company.companyName,
  Address: company.address,
  BillingContact: company.billingContact,
  SureCoContact: company.sureCoContact,
  DateCreated: company.dateCreated,
  EBAMGroupId: company.ebamGroupId,
  IsActive: company.isActive,
  SalesDirectorPublicKey: company.salesDirectorPublicKey,
  IsProspect: company.isProspect,
  ClientServicesRepPublicKey: company.clientServicesRepPublicKey,
  CompanyLogoUrl: company.companyLogoUrl,
  ContactLastName: company.contactLastName,
  ContactPhoneNumber: company.contactPhoneNumber,
  HixmeAccidentGroupId: company.hixmeAccidentGroupId,
  PayrollConfigPublicKey: company.payrollConfigPublicKey,
  CurrentHealthPlanDesigns: company.CurrentHealthPlanDesigns,
  HixmeGroupNumber: company.hixmeGroupNumber,
  ClientServicesRepLastName: company.clientServicesRepLastName,
  SalesDirectorFirstName: company.salesDirectorFirstName,
  ContactFirstName: company.contactFirstName,
  SalesDirectorLastName: company.salesDirectorLastName,
  ContactEmailAddress: company.contactEmailAddress,
  BenefitPolicyName: company.benefitPolicyName,
  CustomerServicePhoneNumber: company.customerServicePhoneNumber,
})

export const getSession = createSelector((state) => state[NAME])

export const sessionStarted = createSelector(
  (state) => state[NAME],
  (session) => session.userInitialized
)

export const isCurrentCompanyUnavailable = createSelector(
  (state) => state[NAME].currentCompanyId,
  (companyId) => companyId === undefined
)

export const getCurrentCompanyId = createSelector(
  (state) => state[NAME].currentCompanyId,
  (companyId) => companyId
)

export const hasSingleCompany = createSelector(
  companySelectors.companyCollectionSelector,
  (companies) => companies.length < 2
)

export const getCurrentCompany = createSelector(
  getCurrentCompanyId,
  companySelectors.companyMapSelector,
  (companyId, companies) =>
    companies[companyId] ? transformToOldNotation(companies[companyId]) : {}
)

export const getSelectedQle = createSelector(
  (state) => state[NAME].qleId,
  (qleId) => qleId
)

export const getCurrentCompanyNew = createSelector(
  getCurrentCompanyId,
  companySelectors.companyMapSelector,
  (companyId, companies) => companies[companyId] || {}
)

export const getCurrentCompanyPlans = createSelector(
  getCurrentCompany,
  (company) => company.CurrentHealthPlanDesigns
)

export const currentCompanyHasPlans = createSelector(
  getCurrentCompany,
  (company) =>
    company &&
    company.CurrentHealthPlanDesigns &&
    company.CurrentHealthPlanDesigns.length > 0
)
