import styled from 'styled-components'

import Container from '@hixme-ui/container'

export const LineItemsContainer = styled(Container)`
  background-color: #f2f6f9;
  border-radius: 6px;
  display: flex;
  margin: 20px 0;
  text-align:left;
  width: 100%;
`
