/* eslint-disable */
// singleton gateway interface
export let gateway = null

// set client config
export const setGateway = (apigClient) => {
  gateway = apigClient
}

/* eslint-disable no-param-reassign */
const createError = (message) => {
  let status = 500

  if (message === 'Process exited before completing request') {
    message = 'An unknown error occurred'
    status = 520
  }

  const e = {
    status,
    statusText: message,
    message,
  }

  return e
}

export const gatewayRequest = (
  route,
  { params, body, additionalParams } = {}
) =>
  new Promise((resolve, reject) => {
    gateway[route](params, body, additionalParams).then(
      (response) => {
        const { data } = response

        // if data is null - throw error :: data should not be null
        // else if data.errorMessage is present - throw error
        // else resolve
        if (data === null) {
          console.error(`
            API error: route: ${route} - returned null.
            Should never return null.
            Tell a friend.
            API should never return null.
            Repeat after me.
            API should never return null.`)

          reject(
            createError('Bad response from server. API should not return null')
          )
        } else if (data.errorMessage) {
          reject(createError(data.errorMessage))
        } else {
          resolve(data)
        }
      },
      (error) => {
        reject(createError(error))
      }
    )
  })
