import React from 'react'
import PropTypes from 'prop-types'
import applicationstatus from 'data/application-status'
import { FormGroup, Select, Label } from '@hixme-ui/forms'

// prepend an empty option to the applicationstatus list
applicationstatus.unshift({
  id: '',
  name: 'Select',
})

export const ApplicationStatusFormGroup = (props) => (
  <FormGroup>
    <Label>Application Status</Label>
    <ApplicationStatusDropdown {...props.input} />
  </FormGroup>
)
ApplicationStatusFormGroup.displayName = 'ApplicationStatusFormGroup'
ApplicationStatusFormGroup.propTypes = {
  input: PropTypes.object.isRequired,
}

const ApplicationStatusDropdown = (props) => (
  <Select {...props} list={applicationstatus} />
)

export default ApplicationStatusDropdown
