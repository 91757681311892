import { connect } from 'react-redux'
import Allow from 'components/Allow/Allow'
import { selectors as authSelectors } from '@hixme/auth-ui'
import roles from '@hixme/role-policy'

const lowerFirstLetter = (str) => {
  const [char, ...rest] = [...str]
  return [char.toLowerCase(), ...rest].join('')
}

const roleValues = Object.values(roles)
  .filter((val) => typeof val === 'string')
  .map((val) => lowerFirstLetter(val))

const mapStateToProps = (state, ownProps) => {
  const foundAuths = Object.keys(ownProps).reduce(
    (prev, key) => (roleValues.includes(key) ? [key, ...prev] : prev),
    []
  )

  if (foundAuths.length > 0) {
    const role = lowerFirstLetter(authSelectors.getRole(state))
    return { isAuth: ownProps[role] === undefined ? false : ownProps[role] }
  } else if (foundAuths.length === 0) {
    return { isAuth: false }
  }

  return { isAuth: true }
}

export default connect(mapStateToProps)(Allow)
