import React from 'react'
import { Modal, Table } from '@surecompanies/core_components'
import { Button } from '@sureco/design-system'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'

interface ConfirmCellChangesModalProps {
  visible: boolean
  onCancel: () => void
  onConfirm: () => void
  loading: boolean
  dirtyRows: CensusFileRowGridData[]
  updateMode: boolean
}

const ConfirmCellChangesModal = (
  {
    visible,
    onCancel,
    onConfirm,
    loading,
    dirtyRows,
    updateMode,
  }: ConfirmCellChangesModalProps) => {
  const modalColumns = [
    { title: 'Employee ID', dataIndex: 'employeeId', key: 'employeeId' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Field Changed', dataIndex: 'field', key: 'field' },
    { title: 'Original', dataIndex: 'beforeValue', key: 'beforeValue' },
    { title: 'New Value', dataIndex: 'afterValue', key: 'afterValue' },
  ]

  const modalDataSource = dirtyRows.flatMap(row => {
    if (updateMode) {
      return Object.entries(row.dirtyFields || {}).map(([field]) => ({
        key: `${row.rowId}-${field}`,
        employeeId: row.employeeId || '',
        name: `${row.employeeName}`,
        field: row.field,
        beforeValue: row.proposedInitialValue,
        afterValue: row.newValue,
      }))
    }

    return Object.entries(row.dirtyFields || {}).map(([field]) => ({
      key: `${row.id}-${field}`,
      employeeId: row.CompanyEmployeeId || '',
      name: `${row.firstName} ${row.lastName}`,
      field,
      beforeValue: row.rowData[field],
      afterValue: row[field],
    }))
  })

  return (
    <Modal
      title="Confirm Changes"
      open={visible}
      onCancel={onCancel}
      width={1000}
      footer={[
        <div
          key="footer"
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
          <Button type="outline" variant="danger" onClick={onCancel} size="large">
            Cancel
          </Button>
          <Button onClick={onConfirm} loading={loading} variant="primary" size="large">
            Save Changes
          </Button>
        </div>,
      ]}
    >
      <p>Review the changes before saving:</p>
      <Table
        scroll={{ x: 'max-content', y: 400 }}
        columns={modalColumns}
        dataSource={modalDataSource}
        pagination={false}
      />
    </Modal>
  )
}

export default ConfirmCellChangesModal
