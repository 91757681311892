export const NAME = 'employeeBenefits'

export const EMPLOYEE_BENEFITS_GET = 'employeeBenefitsGet'
export const EMPLOYEE_BENEFIT_POST = 'employeeBenefitPost'
export const EMPLOYEE_BENEFITS_POST = 'employeeBenefitsPost'

export const EMPLOYEE_BENEFITS_BENEFICIARIES_PUT =
  'employeeBenefitsBeneficiariesPut'

export const EMPLOYEE_DOCUMENTS_POST = 'employeeDocumentsPost'
export const EMPLOYEE_DOCUMENTS_GET = 'employeeDocumentsGet'
export const EMPLOYEE_DOCUMENTS_PUT = 'employeeDocumentsPut'
export const EMPLOYEE_CARD_UPDATE = 'UPDATE_CARD_STATUS'
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'

export const ENROLLME_SOURCE_SYSTEM = 3
export const CALLER = 'Enrollme | Admin'

export const VCN_STATUSES = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  TERMINATED: 'TERMINATED',
}

const VCN_COLORS = {
  [VCN_STATUSES.ACTIVE]: '#22D2AC',
  [VCN_STATUSES.SUSPENDED]: '#FAAD14',
  [VCN_STATUSES.TERMINATED]: '#FF1442',
}

export const getVCNColorByStatus = (status) => VCN_COLORS[status] || '#2EAE8F'
