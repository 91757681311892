import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

// hixme-ui
import Button from '@hixme-ui/button'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Input from '@hixme-ui/input'

import ExpenseStatusDropdown from 'components/ExpenseRequestStatus/ExpenseRequestStatus'

const ReceiptLineItemFooter = ({
  setStatus,
  status,
  isLoading,
  setIsLoading,
  statuses,
  onCloseModal,
  text,
  isDisabled,
  onCreate,
  onDelete,
  reimbursement,
  setDescription,
  description,
  isDescriptionAvailable,
}) => (
  <Container noPadding margin="20px 0" width="100%">
    <Row between="xs">
      <Col xs={12} md={6}>
        <Container
          flex
          noPadding
          style={{ flexBasis: '50%' }}
          alignItems="center"
        >
          <Text style={{ width: '80%', textAlign: 'left' }} large>
            Set Status to:
          </Text>
          <Container flex noPadding direction="column" width="100%">
            <ExpenseStatusDropdown
              value={status}
              list={statuses}
              onChange={(e) => {
                setStatus(e.target.value)
              }}
            />
            {isDescriptionAvailable && (
              <Container flex noPadding margin="10px 0">
                <Input
                  value={description}
                  placeholder={
                    status === 'Declined'
                      ? 'Enter message to employee on why request has been declined.'
                      : 'describe what you need from employee(no sensitive info though)'
                  }
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Container>
            )}
          </Container>
        </Container>
      </Col>
      <Col xs={12} md={6} style={{ textAlign: 'right' }}>
        <Button outline onClick={onCloseModal} style={{ marginRight: '20px' }}>
          Cancel
        </Button>
        {status !== 'Delete' && (
          <Button
            primary
            disabled={isDisabled}
            loading={isLoading}
            loadingText="Processing..."
            onClick={() => onCreate(status, setIsLoading)}
          >
            {text}
          </Button>
        )}
        {status === 'Delete' && (
          <Button outline danger onClick={() => onDelete(reimbursement.id)}>
            {text}
          </Button>
        )}
      </Col>
    </Row>
  </Container>
)

ReceiptLineItemFooter.propTypes = {
  reimbursement: PropTypes.object,
  status: PropTypes.string,
  description: PropTypes.string,
  text: PropTypes.string,
  isDisabled: PropTypes.bool,
  isDescriptionAvailable: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  statuses: PropTypes.array,
  onCloseModal: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
}

ReceiptLineItemFooter.defaultProps = {
  reimbursement: {},
  status: '',
  text: '',
  description: '',
  statuses: [],
  isDisabled: false,
  isDescriptionAvailable: false,
}

export default ReceiptLineItemFooter
