import { callWaiter } from 'redux-waiter'
import { reimbursementService } from '@hixme/api'
import {
  DELETE_LINE_ITEMS_REIMBURSEMENT,
  CREATE_LINE_ITEMS_REIMBURSEMENT,
  UPDATE_REIMBURSEMENT,
} from './constants'

export const updateExpense = (body, params) => (dispatch) =>
  dispatch(
    callWaiter(UPDATE_REIMBURSEMENT, {
      requestCreator: () =>
        reimbursementService({
          route:
            'reimbursements/admin/clientId/{clientId}/employeeId/{employeeId}/reimbursementId/{reimbursementId}',
          method: 'PATCH',
          params,
          body,
        }),
    })
  )

export const createLineItems = (body, params) => (dispatch) =>
  dispatch(
    callWaiter(`${CREATE_LINE_ITEMS_REIMBURSEMENT}_${params.receiptId}`, {
      requestCreator: () =>
        reimbursementService({
          route: 'reimbursements/{reimbursementId}/receipts/{receiptId}/items',
          method: 'POST',
          params,
          body,
        }),
    })
  )

export const deleteLineItem = (params) => (dispatch) =>
  dispatch(
    callWaiter(`${DELETE_LINE_ITEMS_REIMBURSEMENT}_${params.lineItemId}`, {
      requestCreator: () =>
        reimbursementService({
          route:
            'reimbursements/{reimbursementId}/receipts/{receiptId}/items/{lineItemId}',
          method: 'DELETE',
          params,
        }),
    })
  )
