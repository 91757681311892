import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { Field } from 'redux-form'

// hixme-ui
import Button from '@hixme-ui/button'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'
import { MrModal } from '@hixme/modal'
import { FormGroup } from '@hixme-ui/forms'

// project
import { FieldInput } from 'components/ReduxFormComponents'

const ExpenseMessageModal = ({
  handleSubmit,
  closeModal,
  formValues,
  isProcessing,
}) => (
  <MrModal>
    <Container flex noPadding justifyContent="center" margin="0 0 50px 0">
      <Title>Send Reimbursement Notification</Title>
    </Container>
    <Container flex noPadding width="100%" justifyContent="center">
      <Row middle="xs" center="xs">
        <Col xs={6}>
          <Text light large>
            Message to Employee:
          </Text>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Field
              name="Message"
              value={formValues.Message}
              component={FieldInput}
              textarea
              normalize={(val) => val && val.slice(0, 300)}
              rows="3"
              cols={50}
              style={{ minHeight: 'inherit', height: 'auto' }}
            />
          </FormGroup>
        </Col>
      </Row>
    </Container>
    <Container
      flex
      noPadding
      width="100%"
      justifyContent="center"
      margin="20px 0"
      style={{ gap: '20px' }}
    >
      <Button outline onClick={closeModal}>
        Cancel
      </Button>
      <Button
        primary
        style={{ padding: '0 40px' }}
        disabled={!formValues.Message}
        onClick={handleSubmit}
        submitting={isProcessing}
      >
        Send and Close
      </Button>
    </Container>
  </MrModal>
)

ExpenseMessageModal.displayName = 'ExpenseMessageModal'

ExpenseMessageModal.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default ExpenseMessageModal
