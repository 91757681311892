import React, { ReactElement } from 'react'
import { Stack, Typography } from '@sureco/design-system'

type ContentPaneProps = {
  title: string
  children: React.ReactNode
  header?: ReactElement<unknown>
}

export default function ContentPane({
  title,
  children,
  header,
}: ContentPaneProps) {
  return (
    <Stack
      space={20}
      borderRadius={8}
      padding={20}
      style={{
        background: '#E0EAF1',
      }}
    >
      <Stack direction="row" align="center" justify="space-between">
        <Typography size={16} bold color="#103044">
          {title}
        </Typography>
        {header}
      </Stack>
      {children}
    </Stack>
  )
}
