import React from 'react'
import { jwtDecode } from 'jwt-decode'

const withAuthorization = (allowedRoles) => (WrappedComponent) => {
  return (props) => {
    const fullToken = localStorage.getItem('admin-token')

    if (!fullToken) {
      return <h2>Access Denied</h2>
    }

    try {
      const { idToken } = JSON.parse(fullToken ?? '')
      const decoded = jwtDecode(idToken)
      const userRole = decoded?.['custom:user-role']

      if (!allowedRoles.includes(userRole)) {
        return <h2>Access Denied</h2>
      }

      return <WrappedComponent {...props} />
    } catch (error) {
      console.error('Error decoding token:', error)
      return <h2>Access Denied</h2>
    }
  }
}

export default withAuthorization
