import axios from 'axios'
import { isProduction } from 'modules/app-config/environment'

const epsClient = axios.create({
  baseURL: getUrl(),
  headers: { 'Content-Type': 'application/json' },
})

epsClient.interceptors.request.use(
  (config) => {
    const { idToken } = JSON.parse(localStorage.getItem('admin-token') || '{}')
    if (idToken) {
      config.headers.Authorization = idToken
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export function getUrl() {
  if (isProduction()) {
    return 'https://api.docday.com' // replace with prod one when available
  }
  return 'https://api.dev.surecolabs.com'
}

export default epsClient
