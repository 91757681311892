import { namespaceActions } from 'modules/app-core/redux-utils'
import { NAME } from './constants'

const LOAD_BENEFITS = 'LOAD_BENEFITS'
const LOAD_COBRA_BENEFITS = 'LOAD_COBRA_BENEFITS'
const LOAD_BENEFIT = 'LOAD_BENEFIT'
const LOAD_PERSON = 'LOAD_PERSON'

export default namespaceActions(NAME)([
  LOAD_BENEFITS,
  LOAD_BENEFIT,
  LOAD_PERSON,
  LOAD_COBRA_BENEFITS,
])
