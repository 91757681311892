import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Select from '@hixme-ui/select'

import { FieldError } from 'components/ReduxFormComponents'

const FieldSelect = ({ input, list, meta, children, ...restProps }) => {
  const props = meta.touched
    ? { ...input, ...meta, ...restProps }
    : { ...input, ...restProps }
  if (list) {
    return (
      <div>
        <Select list={list} {...props} />
        <FieldError meta={meta} />
      </div>
    )
  }
  return (
    <div>
      <Select {...props}>{children}</Select>
      <FieldError meta={meta} />
    </div>
  )
}

FieldSelect.displayName = 'FieldSelect'
FieldSelect.propTypes = {
  children: PropTypes.array,
  input: PropTypes.object.isRequired,
  list: PropTypes.array,
  meta: PropTypes.object.isRequired,
}

FieldSelect.defaultProps = {
  children: [],
  list: [],
}

export default FieldSelect
