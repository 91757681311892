import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import RadioButton from '@hixme-ui/radio-button'

import { FieldError } from 'components/ReduxFormComponents'

const GenderRadioInput = ({ input, meta, ...rest }) => (
  <Container flex noPadding direction="column">
    <Container flex justifyCenter alignCenter padding="8px 0" {...rest}>
      <div style={{ width: '80px', marginRight: '15px' }}>
        <RadioButton
          name="male"
          {...input}
          text="Male"
          {...rest}
          value="Male"
          checked={input.value === 'Male'}
        />
      </div>
      <div style={{ width: '80px', marginRight: '15px' }}>
        <RadioButton
          name="female"
          {...input}
          {...rest}
          text="Female"
          value="Female"
          checked={input.value === 'Female'}
        />
      </div>
    </Container>
    <FieldError meta={meta} />
  </Container>
)

GenderRadioInput.propTypes = {
  value: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
}

GenderRadioInput.defaultProps = {
  value: '',
  input: {},
  meta: {},
}

export default GenderRadioInput
