import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'
import { withRouter } from 'react-router'

import { actions, selectors } from '../'
import { WAITER_GET_ENROLLMENTS } from '../constants'

const mapStateToProps = (state, props) => {
  const employeePublicKeyParam = props.params.id
  const enrollmentList = selectors.getEnrollments(state)
  const gettingEnrollmentList = getWaiter(
    state,
    `${WAITER_GET_ENROLLMENTS}${employeePublicKeyParam}`
  ).isPending
  return {
    enrollmentList,
    gettingEnrollmentList,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEnrollments: (EmployeePublicKey) => {
    dispatch(actions.getEnrollments(EmployeePublicKey))
  },
  clearAll: () => {
    dispatch(actions.clearAll())
  },
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)
