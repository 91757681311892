import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { isEmployee } from '@hixme/person-utils'

import Text from '@hixme-ui/text'
import Icon from '@hixme-ui/icon'

import PersonInfo from '../PersonInfo'
import classes from './PersonInfoTooltip.module.scss'
import personPropTypes from '../../person-prop-types'

const closeMenuOnOutsideClick = (tooltipId) => (event) => {
  const tooltipAnchor = document.querySelector(`a[data-for="${tooltipId}"]`)
  const elementClicked = event.target
  const tooltip = tooltipAnchor && tooltipAnchor.nextElementSibling

  if (!tooltipAnchor) {
    document.removeEventListener('click', closeMenuOnOutsideClick(tooltipId))
    return false
  }

  if (
    !tooltipAnchor.contains(elementClicked) &&
    !tooltip.contains(elementClicked)
  ) {
    ReactTooltip.hide(tooltipAnchor)
  }

  return false
}

const editEmployee = (handleEdit, tooltipId) => () => {
  handleEdit()
  ReactTooltip.hide(document.querySelector(`a[data-for="${tooltipId}"]`))
}

const editDependent = (handleEditDependent, tooltipId) => () => {
  handleEditDependent()
  ReactTooltip.hide(document.querySelector(`a[data-for="${tooltipId}"]`))
}

const afterShow = (tooltipId) => () => {
  document.addEventListener('click', closeMenuOnOutsideClick(tooltipId))
}

const afterHide = (tooltipId) => () => {
  document.removeEventListener('click', closeMenuOnOutsideClick(tooltipId))
}

const PersonInfoTooltip = ({
  fullName,
  person,
  handleEdit,
  handleEditDependent,
  tooltipId,
  hasPersonData,
}) => (
  <div>
    <a
      id={tooltipId}
      data-tip
      data-for={tooltipId}
      data-event="click"
      data-isCapture
      style={{ cursor: 'pointer' }}
    >
      <Icon light={!(person && person.Relationship === 'Employee')} user />
      <Text style={{ marginLeft: '10px' }}>{fullName}</Text>
    </a>
    {hasPersonData && (
      <ReactTooltip
        id={tooltipId}
        type="light"
        place="right"
        effect="solid"
        afterShow={afterShow(tooltipId)}
        afterHide={afterHide(tooltipId)}
        class={classes.noBackground}
      >
        <PersonInfo
          {...person}
          isWorker={isEmployee(person)}
          handleEdit={editEmployee(handleEdit, tooltipId)}
          handleEditDependent={editDependent(handleEditDependent, tooltipId)}
        />
      </ReactTooltip>
    )}
  </div>
)

PersonInfoTooltip.displayName = 'PersonInfoTooltip'

PersonInfoTooltip.propTypes = {
  fullName: PropTypes.string.isRequired,
  tooltipId: PropTypes.string.isRequired,
  handleEdit: PropTypes.func,
  ...personPropTypes,
}

PersonInfoTooltip.defaultProps = {
  person: {},
  handleEdit: () => {},
}

export default PersonInfoTooltip
