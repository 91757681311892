import { callWaiter, clearWaiter } from 'redux-waiter'
import {
  benefitService,
  benefitPutService,
  bundleService,
  enrollmentService,
  personService,
} from '@hixme/api'

import { toast } from 'react-toastify'

import {
  WAITER_GET_BUNDLES,
  WAITER_GET_EMPLOYEE_BENEFITS,
  WAITER_GET_EMPLOYEE,
  WAITER_GET_ENROLLMENTS,
  WAITER_PUT_BENEFIT,
} from './constants'

import types from './actionTypes.js'

export const setFamily = (family) => ({
  type: types.SET_EMPLOYEE_FAMILY,
  payload: family,
})

export const setBenefits = (benefits) => ({
  type: types.SET_EMPLOYEE_BENEFITS,
  payload: benefits,
})

export const setBundles = (bundles) => ({
  type: types.SET_BUNDLES,
  payload: bundles,
})

export const loadBenefit = (benefit) => ({
  type: types.LOAD_BENEFIT,
  payload: benefit,
})

export const setAllCompaniesList = (allCompaniesList) => ({
  type: types.SET_ALL_COMPANIES_LIST,
  payload: allCompaniesList,
})

export const setEnrollments = (enrollments) => ({
  type: types.SET_ENROLLMENTS,
  payload: enrollments,
})

export const setEnrollmentId = (id) => ({
  type: types.SET_ENROLLMENT_ID,
  payload: id,
})

export const setWaviedBenefits = (waivedBenefits) => ({
  type: types.SET_EMPLOYEE_WAIVED_BENEFITS,
  payload: waivedBenefits,
})

export const setToInitialState = () => ({
  type: types.SET_TO_INITIAL_STATE,
})

export const clearAll = () => (dispatch) => {
  dispatch(setToInitialState())
}

export const getEmployeeBenefits = (EmployeePublicKey, EffectiveOn = '') => (
  dispatch
) =>
  dispatch(
    callWaiter(`${WAITER_GET_EMPLOYEE_BENEFITS}${EmployeePublicKey}`, {
      requestCreator: () =>
        benefitService({
          route: '{EmployeePublicKey}',
          params: { EmployeePublicKey, EffectiveOn },
        }).then((response) => {
          dispatch(setBenefits(response.Benefits))
          dispatch(setWaviedBenefits(response.WaivedBenefits))
          dispatch(
            clearWaiter(`${WAITER_GET_EMPLOYEE_BENEFITS}${EmployeePublicKey}`)
          )
        }),
    })
  )

export const getEmployeeFamily = (id) => (dispatch) =>
  dispatch(
    callWaiter(`${WAITER_GET_EMPLOYEE}${id}`, {
      requestCreator: () =>
        personService({
          route: `persons/${id}/family`,
        })
          .then((response) => {
            dispatch(setFamily(response.Persons))
            dispatch(clearWaiter(`${WAITER_GET_EMPLOYEE}${id}`))
          })
          .catch(() =>
            toast.error('There was an error getting Employee family')
          ),
    })
  )

export const putBenefit = ({ Id, BenefitEffectiveDate, BenefitEndDate }) => (
  dispatch
) =>
  dispatch(
    callWaiter(`${WAITER_PUT_BENEFIT}${Id}`, {
      requestCreator: () =>
        benefitPutService({
          route: `benefit/${Id}`,
          method: 'PUT',
          body: {
            BenefitEffectiveDate,
            BenefitEndDate,
          },
        }),
    })
  )
    .then((response) => {
      toast.info('Benefit saved successfully')
      dispatch(loadBenefit(response))
      dispatch(clearWaiter(`${WAITER_PUT_BENEFIT}${Id}`))
    })
    .catch(() => toast.error('There was an error saving the Benefit'))

export const getEnrollments = (Id) => (dispatch) =>
  dispatch(
    callWaiter(`${WAITER_GET_ENROLLMENTS}${Id}`, {
      requestCreator: () =>
        enrollmentService({
          route: 'enrollments/{Id}',
          params: { Id },
        })
          .then((list) => {
            dispatch(setEnrollments(list.Enrollments))
            dispatch(clearWaiter(`${WAITER_GET_ENROLLMENTS}${Id}`))
          })
          .catch(() => {
            toast.error('There was an error getting Enrollments')
          }),
    })
  )

export const getBundles = (Id) => (dispatch) =>
  dispatch(
    callWaiter(`${WAITER_GET_BUNDLES}${Id}`, {
      requestCreator: () =>
        bundleService({
          route: 'bundles',
          params: { enrollmentPublicKey: Id },
        })
          .then((bundleList) => {
            dispatch(setBundles(bundleList))
            dispatch(setEnrollmentId(Id))
            dispatch(clearWaiter(`${WAITER_GET_BUNDLES}${Id}`))
          })
          .catch(() => {
            toast.error('There was an error getting Bundles')
          }),
    })
  )
