import AdminLayout from 'layouts/AdminLayout/AdminLayoutContainer'
import AuthenticatedRoute from 'layouts/AuthenticatedRoute/AuthenticatedRouteContainer'
import Home from './Home'
import platformRoutes from '../enrollment-platform/routes'

const adminRoutes = (store) => ({
  component: AdminLayout,
  path: '/',
  title: 'Home',
  indexRoute: Home,
  getChildRoutes(_, next) {
    require.ensure([], (require) => {
      const routes = [require('./Home').default].concat(
        require('./ApplicationManager').default(store),
        require('./Expenses').default(store),
        require('./Census').default(store),
        require('./CensusManagement').default(store),
        require('./Company').default(store),
        require('./DigitalMailManagement').default(store),
        require('./Employees').default(store),
        require('./EmployeeReport').default(store),
        require('./EnrollmentDashboard').default(store),
        require('./HealthPlans').default(store),
        require('./Prospects').default(store),
        require('./Analytics').default(store),
        require('./Tools').default(store),
        require('./Profile').default(store),
        require('./DataAndReports').default(store),
        require('./LifeEvents').default(store),
        ...platformRoutes()
      )
      next(null, routes)
    })
  },
})

const popOutRoutes = (store) => ({
  component: AuthenticatedRoute,
  getChildRoutes(_, next) {
    require.ensure([], (require) => {
      const routes = [
        require('./ApplicationManager/routes/ApplicationDetail').default(store),
        require('./LifeEvents/QLERecordPopupView/route').default(store),
      ]
      next(null, routes)
    })
  },
})

export const createRoutes = (store) => {
  const routes = {
    getChildRoutes(_, next) {
      require.ensure([], (require) => {
        const childRoutes = [adminRoutes(store), popOutRoutes(store)].concat(
          require('./Auth').default(store),
          require('./Onboarding').default(store),
          require('./Quoting').default(store),
          require('./NotFound').default(store)
        )
        next(null, childRoutes)
      })
    },
  }
  return routes
}

export default createRoutes
