export const tryScroll = (
  options = {
    top: 0,
    left: 0,
  }
) => {
  let isSmoothScrollSupported = false

  if (document && document.documentElement) {
    isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style
  }

  if (isSmoothScrollSupported && (window && window.scrollTo)) {
    window.scrollTo({
      ...options,
      behavior: 'smooth',
    })
  } else if (window && window.scrollTo) {
    window.scrollTo(options.left, options.top)
  }
}

export default tryScroll
