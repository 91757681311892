import React from 'react'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'

import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Button from '@hixme-ui/button'

import YearlyAllowanceBarCell from '../YearlyAllowanceBarCell/YearlyAllowanceBarCell'

import classes from './YearlyAllowanceBarBody.module.scss'
import { getAllowanceRowFromExpense } from '../utils'

const YearlyAllowanceBarBody = ({
  data,
  hasOlderReimbursements,
  onClickView,
  onViewOlder,
}) => (
  <Container className={classes.YearlyAllowanceBarBodyContainer}>
    {data.map((item) => (
      <Container
        flex
        noPadding
        alignItems="center"
        justifySpaceBetween
        flexWrap
        margin="0 0 20px 0"
        key={item.benefitDate}
        className={classes.YearlyAllowanceBarBodyInnerContainer}
      >
        {getAllowanceRowFromExpense(item).map(({ title, value }) => (
          <YearlyAllowanceBarCell
            title={title}
            value={value}
            key={`body-${item.id}-${title}`}
          />
        ))}
        {onClickView && (
          <Text
            blue
            large
            cursor="pointer"
            onClick={() => onClickView(item)}
            className={classes.YearlyAllowanceBarBodyView}
          >
            View
          </Text>
        )}
      </Container>
    ))}
    {hasOlderReimbursements && (
      <Container flex justifyCenter noPadding>
        <Button
          outline
          padding="10px"
          center
          onClick={onViewOlder}
          className={classes.YearlyAllowanceBarBodyViewMore}
        >
          <Text>View older</Text>
        </Button>
      </Container>
    )}
  </Container>
)

YearlyAllowanceBarBody.propTypes = {
  hasOlderReimbursements: bool.isRequired,
  onClickView: func,
  onViewOlder: func,
  data: arrayOf(
    shape({
      title: string,
      value: number,
    })
  ).isRequired,
}

YearlyAllowanceBarBody.defaultProps = {
  onClickView: () => {},
  onViewOlder: () => {},
}

export default YearlyAllowanceBarBody
