import React from 'react'
import PropTypes from 'prop-types'
import { FieldSelect } from 'components/ReduxFormComponents'
import months from 'data/month-list'

// prepend an empty option to the metals list
const select = { id: '0', name: 'Select' }

export const MonthsFormGroup = (props) => <MonthsDropdown {...props} />

MonthsFormGroup.propTypes = {
  label: PropTypes.string,
}

MonthsFormGroup.defaultProps = {
  label: 'Month',
}

export const MonthsDropdown = (props) => (
  <FieldSelect {...props} list={[select, ...months]} />
)

export default { MonthsDropdown, MonthsFormGroup }
