import { CUSTOMER_MANAGEMENT } from './paths'
import CustomerGate from './pages/CustomerGate'
import NewCustomer from './pages/NewCustomer/NewCustomer'
import ViewCustomer from './pages/ViewCustomer/ViewCustomer'
import EditCustomer from './pages/EditCustomer/EditCustomer'
import ListCustomer from './pages/ListCustomer/ListCustomer'

const CustomerRoutes = {
  path: CUSTOMER_MANAGEMENT,
  component: CustomerGate,
  indexRoute: {
    component: ListCustomer,
  },
  childRoutes: [
    {
      path: 'new',
      component: NewCustomer,
    },
    {
      path: ':id/view',
      component: ViewCustomer,
    },
    {
      path: ':id/edit',
      component: EditCustomer,
    },
  ],
}

export default CustomerRoutes
