import { createSelector } from 'reselect'
import { NAME } from './constants'

export const censusMapSelector = createSelector(
  (state) => state[NAME],
  (census) => census.list
)

export const censusCollectionSelector = createSelector(
  censusMapSelector,
  (census) => Object.values(census)
)

export const censusByIdSelector = createSelector(
  censusMapSelector,
  (census) => (id) => census[id]
)
