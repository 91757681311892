import React from 'react'

// hixme-ui
import Container from '@hixme-ui/container'
import Card from '@hixme-ui/card'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'
import { titleFontFamily } from '@sureco/design-system'

const CartTile = ({ image, title, line1, line2 }) => (
  <Card height="250px" width="350px" textCenter heavyShadow animate>
    <Container>
      <img src={image} alt="card-icon" />
      <Container padding="10px">
        <Title
          smaller
          color="#3A4951"
          fontWeight="500"
          fontFamily={titleFontFamily}
        >
          {title}
        </Title>
      </Container>
      <>
        <Text color="#8F9DA8">{line1}</Text>
        <br />
        <Text color="#8F9DA8">{line2}</Text>
      </>
    </Container>
  </Card>
)

export default CartTile
