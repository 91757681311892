import { useQuery } from '@tanstack/react-query'
import epsClient from 'enrollment-platform/api/eps.client'

type useViewCustomerProps = {
  id: string | number
}

export default function useViewCustomer({ id }: useViewCustomerProps) {
  const { isPending, error, data } = useQuery({
    queryKey: ['getCustomer', id],
    queryFn: async () => {
      const result = await epsClient.get(`/customers/${id}`)
      return result.data
    },
    staleTime: Infinity,
  })
  return {
    isLoading: isPending,
    error,
    data,
  }
}
