import { notification } from '@surecompanies/core_components'
import { useMutation } from '@tanstack/react-query'
import client from 'enrollment-platform/api/eps.client'

export interface ServiceAreasDiffDataRow {
    rowNumber: number;
    planServiceAreaId: string;
    year: string;
    state: string;
    issuerId: string;
    sourceName: string;
    hiosPlanId: string;
    serviceAreaId: string;
    serviceAreaName: string;
    coverEntireState: string;
    countyCode: string;
    partialCounty: string;
    zipcode: string;
    isActive: string;
    diffStatus: string;
}

interface UseAnalyzeDiffProps {
    importBatchId: string;
    batchType: string;
}

interface UseAnalyzeDiffResult {
    mutate: () => void;
    data: ServiceAreasDiffDataRow[] | undefined;
    isLoading: boolean;
    error: any;
}

interface DiffResponse {
    rows: ServiceAreasDiffDataRow[];
}

// This function is used to get the diff data from the API
export default function useServiceAreasDiff({ importBatchId, batchType }: UseAnalyzeDiffProps): UseAnalyzeDiffResult {
    const getDiff = async (): Promise<ServiceAreasDiffDataRow[]> => {
        try {
            const result = await client.post<DiffResponse>(`/plan-management/${batchType}/diff`, {
                importBatchId,
            })
            return result.data.rows
        } catch (error) {
            notification.error({
                message: 'Something went wrong getting the diff',
            })
            throw error
        }
    }

    const { mutate, data, status, error } = useMutation<ServiceAreasDiffDataRow[], Error, void>({
        mutationFn: getDiff,
        mutationKey: ['generatediff', importBatchId],
    })

    return {
        mutate,
        data,
        isLoading: status === 'pending',
        error,
    }
}
