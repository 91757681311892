export enum CensusFileRowTypeEnum {
  DRAFT_EMPLOYEE = 'DRAFT_EMPLOYEE', // potential employee to be added
  DUPLICATE_EMPLOYEE = 'DUPLICATE_EMPLOYEE', // identified employee already exists, and all data is identical ( no updates )
  UPDATE_EMPLOYEE_BENEFITS = 'UPDATE_EMPLOYEE_BENEFITS', // identified employee already exists, some data needs to be updated, the user has benefits ( health, dental? )
  UPDATE_EMPLOYEE_NO_BENEFITS = 'UPDATE_EMPLOYEE_NO_BENEFITS', // identified employee already exists, some data needs to be updated, no benefits
  UNDETERMINED = 'UNDETERMINED', // Initial state, still processing and haven't yet determined type
  TERMINATION = 'TERMINATION', // Employee to be terminated
  DUPLICATE_IN_FILE = 'DUPLICATE_IN_FILE', // Found a duplicate in the census file - same ssn, employeeId or ??. need to identify unique keys within a client
  ERROR = 'ERROR', // there's an. error in the row.
  WITHDRAWN = 'WITHDRAWN', // Don't process the file
}
