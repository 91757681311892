// @ts-nocheck
import React from 'react'
import Page from 'layouts/Page'
import useViewCustomer from './hooks/useViewCustomer'
import { Button, Spin, Tabs, TabsProps, Tag } from '@surecompanies/core_components'
import ProfileTab from './tabs/ProfileTab'
import { IconStatusMapper } from '../ListCustomer/hooks/useListCustomer'
import { Typography } from '@sureco/design-system'
import BreadcrumbNav from 'enrollment-platform/components/Breadcrumbs'
import { CUSTOMER_MANAGEMENT } from '../../paths'
import EnrollmentTab from './tabs/Enrollment/EnrollmentTab'
import AdminTab from './tabs/Admin/AdminTab'
import HrisPayrollTab from './tabs/HrisPayroll/HrisPaytollTab'
import ContributionTab from './tabs/Contribution/ContributionTab'

export default function ViewCustomer({ params }) {
  const { isLoading, data } = useViewCustomer({ id: params.id })
  const status = data?.status?.toLowerCase()
  const config = IconStatusMapper[status]

  const items: TabsProps['items'] = [
    {
      key: 'company',
      label: 'Company',
      children: <ProfileTab customer={data} contacts={data?.contacts ?? []} configuration={data?.configuration ?? {}}/>,
    },
    {
      key: 'ancillary',
      label: 'Ancillary',
      children: 'Content of Ancillary Pane',
    },
    {
      key: 'enrollment',
      label: 'Enrollment',
      children: <EnrollmentTab id={params.id} />,
    },
    {
      key: 'admin',
      label: 'Admin',
      children: <AdminTab id={params.id} />,
    },
    {
      key: 'hris/payroll',
      label: 'HRIS/Payroll',
      children: <HrisPayrollTab id={params.id} />,
    },
    {
      key: 'contribution',
      label: 'Contribution',
      children: <ContributionTab id={params.id} />,
    },
  ]

  return isLoading ? (
    <Spin spinning fullscreen />
  ) : (
    <Page
      title={data?.name}
      breadcrumb={
        <BreadcrumbNav
          items={[
            { title: 'Customers', path: `/${CUSTOMER_MANAGEMENT}` },
            { title: data?.name },
          ]}
        />
      }
      headerContent={
        <Tag icon={config.icon} color={config.color}>
          <Typography
            size={16}
            style={{ textTransform: 'capitalize' }}
          >
            {status}
            </Typography>
          </Tag>
      }
      action={
        <Button type='primary' onClick={() => {
          window.open(`/${CUSTOMER_MANAGEMENT}/${params.id}/edit`)
        }}>Edit</Button>
      }
    >
      <Tabs type='card' defaultActiveKey='profile' items={items} destroyInactiveTabPane/>
    </Page>
  )
}
