import React, { useRef } from 'react'
import { browserHistory } from 'react-router'
import { Stack } from '@sureco/design-system'
import Page from 'layouts/Page'

import useWindowDimensions from 'hooks/useWindowDimensions'
import { RouterContextProvider } from 'components/RouterContext'
import { CUSTOMER_MANAGEMENT } from '../../paths'
import CustomerForm, { FormRef } from '../../components/CustomerForm'
import { Button } from '@surecompanies/core_components'
import useNewCustomer from './hooks/useNewCustomer'
import BreadcrumbNav from 'enrollment-platform/components/Breadcrumbs'

export default function NewCustomer() {
  const { width } = useWindowDimensions()
  const { mutate, isPending } = useNewCustomer()
  const buttonSize = width > 600 ? 'middle' : 'large'
  const formRef = useRef<FormRef>(null)
  return (
    <RouterContextProvider>
      <Page
        title={'Add New Customer'}
        action={
          <Stack direction='row' space={10}>
            <Button
              type='default'
              color='default'
              size={buttonSize}
              onClick={() => browserHistory.push(`/${CUSTOMER_MANAGEMENT}`)}
            >
              Cancel
            </Button>
            <Button type='primary' size={buttonSize} htmlType='submit' onClick={() => formRef?.current?.submit()} loading={isPending}>
              Add Customer
            </Button>
          </Stack>
        }
        breadcrumb={
          <BreadcrumbNav
            items={[
              { title: 'Customers', path: `/${CUSTOMER_MANAGEMENT}` },
              { title: 'New Customer' },
            ]}
          />
        }
      >
          <CustomerForm ref={formRef} onFormSubmit={(data) => mutate(data)} isLoading={isPending}/>
      </Page>
    </RouterContextProvider>
  )
}
