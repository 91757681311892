import React from 'react'
import { Controller, Control, UseControllerProps } from 'react-hook-form'
import PhoneInput, { PhoneInputProps, PhoneNumber } from 'antd-phone-input'
import { Stack, TextColors, Typography } from '@sureco/design-system'

type RHFMobileInputProps = Omit<PhoneInputProps, 'name'> & {
  name: string
  control: Control<any, object> // unable to make it work using generics yet Control<T>
  rules?: UseControllerProps['rules']
}
export default function RHFMobileInput({
  name,
  control,
  rules,
  ...rest
}: RHFMobileInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <Stack space={2}>
            <PhoneInput
              name={field.name}
              status={fieldState.error ? 'error' : undefined}
              onBlur={field.onBlur}
              value={field.value}
              onChange={({
                countryCode,
                areaCode,
                phoneNumber,
              }: PhoneNumber) => {
                const number = `+${countryCode ?? ''}${areaCode ?? ''}${phoneNumber ?? ''}`
                field.onChange(number)
              }}
              ref={field.ref}
              country="us"
              {...rest}
            />
            {fieldState.error && (
              <Typography color={TextColors.error}>
                {fieldState.error.message}
              </Typography>
            )}
          </Stack>
        )
      }}
    />
  )
}
