import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getId = createSelector(
  (state) => state[NAME],
  ({ id }) => id || ''
)

export const statusSelector = createSelector(
  (state) => state[NAME],
  ({ status }) => status || ''
)

export const getErrors = createSelector(
  (state) => state[NAME],
  ({ errors }) => errors || []
)

export const getErrorCount = createSelector(
  (state) => state[NAME],
  ({ errors }) => errors.length || 0
)

export default {
  statusSelector,
  getErrors,
  getErrorCount,
}
