import React, { useState } from 'react'
import Page from 'layouts/Page'
import { Button, Stack, Typography } from '@sureco/design-system'
import { Select } from 'antd'
import BreadcrumbNav from 'enrollment-platform/components/Breadcrumbs'
import UploadBox from '@hixme-ui/upload-box'
import { toast } from 'react-toastify'
import { getApiUrl } from 'modules/enrollment-platform-api'
import { getAuthorizationToken } from 'enrollment-platform/modules/authorization'
import { useRouterContext } from 'components/RouterContext'
import ContentSection from 'layouts/ContentSection/ContentSection'
import useDownloadCsv from './hooks/useDownloadCsv'
import { BatchType } from '../../types'

const idToken = getAuthorizationToken()
const enrollmentPlatformApiUrl = getApiUrl()
const planManagementApiUrl = `${enrollmentPlatformApiUrl}/plan-management`

const { Option } = Select

type UploadBatchType = {
  id?: BatchType
  name: string
  analyzePath: string
  active: boolean
}

export const uploadBatchTypes : UploadBatchType[] = [
  { name: 'Select Upload Type', analyzePath: '', active: true },
  { id: BatchType.PLANS_AND_RATES, name: 'Plans And Rates', analyzePath: '/plans/plans-and-rates/analyze', active: true },
  { id: BatchType.PLAN_BENEFITS, name: 'Plan Benefits', analyzePath: '/plans/plan-benefits/analyze', active: false },
  { id: BatchType.SERVICE_AREAS, name: 'Plan Service Areas', analyzePath: '/plans/service-areas/analyze', active: true },
  { id: BatchType.SMOKER_RATES, name: 'Smoker Rates', analyzePath: '/plans/smoker-rates/analyze', active: true },
]

export const downloadBatchTypes = [
  { id: BatchType.PLANS_AND_RATES, name: 'Plans And Rates', active: true },
  { id: BatchType.PLAN_BENEFITS, name: 'Plan Benefits', active: false },
  { id: BatchType.SERVICE_AREAS, name: 'Plan Service Areas', active: true },
  { id: BatchType.SMOKER_RATES, name: 'Smoker Rates', active: true },
]

// Alphabetic States dropdown for download
export const states = [
  { id: '', name: 'Nationwide' },
  { id: 'AL', name: 'AL' },
  { id: 'AK', name: 'AK' },
  { id: 'AZ', name: 'AZ' },
  { id: 'AR', name: 'AR' },
  { id: 'CA', name: 'CA' },
  { id: 'CO', name: 'CO' },
  { id: 'CT', name: 'CT' },
  { id: 'DE', name: 'DE' },
  { id: 'FL', name: 'FL' },
  { id: 'GA', name: 'GA' },
  { id: 'HI', name: 'HI' },
  { id: 'ID', name: 'ID' },
  { id: 'IL', name: 'IL' },
  { id: 'IN', name: 'IN' },
  { id: 'IA', name: 'IA' },
  { id: 'KS', name: 'KS' },
  { id: 'KY', name: 'KY' },
  { id: 'LA', name: 'LA' },
  { id: 'ME', name: 'ME' },
  { id: 'MD', name: 'MD' },
  { id: 'MA', name: 'MA' },
  { id: 'MI', name: 'MI' },
  { id: 'MN', name: 'MN' },
  { id: 'MS', name: 'MS' },
  { id: 'MO', name: 'MO' },
  { id: 'MT', name: 'MT' },
  { id: 'NE', name: 'NE' },
  { id: 'NV', name: 'NV' },
  { id: 'NH', name: 'NH' },
  { id: 'NJ', name: 'NJ' },
  { id: 'NM', name: 'NM' },
  { id: 'NY', name: 'NY' },
  { id: 'NC', name: 'NC' },
  { id: 'ND', name: 'ND' },
  { id: 'OH', name: 'OH' },
  { id: 'OK', name: 'OK' },
  { id: 'OR', name: 'OR' },
  { id: 'PA', name: 'PA' },
  { id: 'RI', name: 'RI' },
  { id: 'SC', name: 'SC' },
  { id: 'SD', name: 'SD' },
  { id: 'TN', name: 'TN' },
  { id: 'TX', name: 'TX' },
  { id: 'UT', name: 'UT' },
  { id: 'VT', name: 'VT' },
  { id: 'VA', name: 'VA' },
  { id: 'WA', name: 'WA' },
  { id: 'WV', name: 'WV' },
  { id: 'WI', name: 'WI' },
  { id: 'WY', name: 'WY' },
]

export default function PlanManagementHome() {
  const [uploadBatchType, setUploadBatchType] = useState<BatchType | null>(null)
  const [canUpload, setCanUpload] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [validationMessages, setValidationMessages] = useState([])
  const [columns, setColumns] = useState([])
  const [importBatchIdState, setImportBatchIdState] = useState('')
  const [isBatchValidated, setIsBatchValidated] = useState(false)

  const [downloadBatchType, setDownloadBatchType] = useState<BatchType>(BatchType.PLANS_AND_RATES)
  const [downloadState, setDownloadState] = useState('')
  const [downloadYear, setDownloadYear] = useState(2025)

  const { router } = useRouterContext()

  const handleAnalyzeClick = () => {
    const analyzePath = uploadBatchTypes.find((type) => type.id === uploadBatchType)?.analyzePath
    router.push(`${analyzePath}/${importBatchIdState}`)
  }

  const downloadCsv = useDownloadCsv({ batchType: downloadBatchType, planYear: downloadYear, state: downloadState })

  async function uploadCsvFile() {
    // Get presigned URL
    const { presignedUploadUrl, importBatchId, status } = await getPresignedUrl()
    if (status === 'ERROR') {
      return
    }

    setImportBatchIdState(importBatchId)

    const uploadSuccess = await uploadCsvFileUsingPresignedUrl(presignedUploadUrl)
    if (!uploadSuccess) {
      return
    }

    if (uploadBatchType === null) {
      toast.error('Select a batch type')
      return
    }

    const batch = await createNewBatch(uploadBatchType, importBatchId)
    if (!batch) {
      return
    }
    const validationResults = await validateBatch(importBatchId)

    if (!validationResults) {
      return
    }
    setValidationMessages(validationResults.messages)
    setColumns(validationResults.columns)
  }

  async function validateBatch(importBatchId: string) {
    const url = `${planManagementApiUrl}/validate`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: idToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        importBatchId: importBatchId
      })
    })

    if (response.status !== 201) {
      toast.error('Failed to validate batch')
      return
    }

    toast.success('Batch validated')

    const data = await response.json()

    setIsBatchValidated(true)

    return data
  }

  async function createNewBatch(batchType: BatchType, importBatchId: string) {
    const url = `${planManagementApiUrl}/create`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: idToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        batchType: batchType,
        importBatchId: importBatchId
      })
    })

    if (response.status !== 201) {
      toast.error('Failed to create new batch')
      return
    }

    toast.success('New batch created')

    const data = await response.json()

    return data
  }

  async function uploadCsvFileUsingPresignedUrl(presignedUrl: string) {
    let uploadSuccess = false

    if (!file) {
      toast.error('No file selected')
      return uploadSuccess
    }

    try {
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'text/csv',
        },
      })

      if (response.ok) {
        toast.success('File uploaded successfully')
        uploadSuccess = true
      } else {
        toast.error('Failed to upload file')
      }
    } catch (error) {
      toast.error('Error uploading file')
      console.error('Error uploading file', error)
    }
    return uploadSuccess
  }

  async function getPresignedUrl() {
    // Get presigned URL
    const url = `${planManagementApiUrl}/url`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: idToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        batchType: uploadBatchType
      })
    })

    if (response.status !== 201) {
      toast.error('Failed to get presigned URL')
      return {
        presignedUploadUrl: '',
        importBatchId: '',
        status: 'ERROR'
      }
    }

    return response.json()
  }

  function onDrop(files: File[]) {
    // Multiple files can be uploaded at once, but we only want the first one
    setFile(files[0])

    setCanUpload(true)
  }

  return (
    <Page
      title="Plan Management"
      breadcrumb={
        <BreadcrumbNav
          items={[
            { title: 'Plan Management', path: '/plans' },
            { title: 'Home' },
          ]}
        />
      }
    >
  <Stack space={20}>
    <ContentSection title='Upload'>
    <Stack>
      <Typography as={'h3'} size={20}>Validation</Typography>
      {isBatchValidated && validationMessages.length === 0 && <Typography size={16}>No validation issues found</Typography>}
      {validationMessages.map(({ rowNumber, message }) => <div key={rowNumber}><Typography size={16}>row: {rowNumber} <b>{message}</b></Typography></div>)}
      <Typography as={'h3'} size={20}>Columns</Typography>
      {isBatchValidated && columns.length === 0 && <Typography size={16}>No issues found with columns</Typography>}
      {columns.map(({ name, type }) => <div key={name}><Typography size={16}><b>{name}</b> {type}</Typography></div>)}
    </Stack>
      <Select
        id="upload-batch-type-select"
        value={uploadBatchType}
        onChange={(value) => setUploadBatchType(value)}
        style={{ width: 200 }}
      >
        {uploadBatchTypes.map((type) => (
          <Option key={type.id} value={type.id} disabled={!type.active}>
            {type.name}
          </Option>
        ))}
      </Select>
      <UploadBox onDrop={onDrop} accept=".csv" />
      <Button onClick={uploadCsvFile} disabled={!canUpload || uploadBatchType === null}>
        Upload CSV {file ? `${file.name}` : ''}
      </Button>
      <Button onClick={handleAnalyzeClick} disabled={!isBatchValidated || validationMessages.length > 0}>Analyze</Button>
      </ContentSection>
      <ContentSection title='Download'>
        <Stack space={20}>
        <Select
            id="download-batch-type-select"
            value={downloadBatchType}
            onChange={(value) => setDownloadBatchType(value)}
            style={{ width: 200 }}
          >
            {downloadBatchTypes.map((type) => (
              <Option key={type.id} value={type.id} disabled={!type.active}>
                {type.name}
              </Option>
            ))}
          </Select>
          <Select
            id="download-state-select"
            value={downloadState}
            onChange={(value) => setDownloadState(value)}
            style={{ width: 200 }}
          >
            {states.map((state) => (
              <Option key={state.id} value={state.id}>
                {state.name}
              </Option>
            ))}
          </Select>
          <Select
            id="download-year-select"
            value={downloadYear}
            onChange={(value) => setDownloadYear(value)}
            style={{ width: 200 }}
          >
            <Option value="2023">2023</Option>
            <Option value="2024">2024</Option>
            <Option value="2025">2025</Option>
          </Select>
          <Button onClick={downloadCsv} disabled={!downloadBatchType || !downloadYear}>
            Download
          </Button>
        </Stack>
      </ContentSection>
    </Stack>
    </Page>
  )
}
