import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash'

// hixme-ui
import Container from '@hixme-ui/container'
import Currency from '@hixme-ui/currency'
import Text from '@hixme-ui/text'
import { FormatDate } from '@hixme-ui/dates'

import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard'
import NoCard from './NoCard'
import GeneratorLoader from './GeneratorLoader'
import CardBody from './CardBody'

const mask = (cardNumber) =>
  `${cardNumber.slice(0, 4)} **** **** ${cardNumber.slice(
    cardNumber.length - 4
  )}`

const formatCCNumber = (cardNumber) =>
  `${cardNumber.slice(0, 4)} ${cardNumber.slice(4, 8)}
    ${cardNumber.slice(8, 12)} ${cardNumber.slice(12)}`

const generateCardBody = (card, person = {}) => {
  const [showCardNumber, setShowCardNumber] = useState(false)
  const cardNumber = showCardNumber ? formatCCNumber(card.pan) : mask(card.pan)
  const cardHolder =
    card.firstName && card.lastName
      ? `${card.firstName} ${card.lastName}`
      : person.FullName

  return [
    {
      key: 'Cardholder name',
      value: (
        <CopyToClipboard text={cardHolder}>
          <Text fontWeight="600">{cardHolder}</Text>
        </CopyToClipboard>
      ),
    },
    {
      key: 'Card Number',
      value: (
        <div>
          <CopyToClipboard text={card.pan}>
            <Text fontWeight="600">{cardNumber}</Text>
          </CopyToClipboard>
          <Text
            small
            primary
            onClick={() => setShowCardNumber(!showCardNumber)}
            cursor="pointer"
          >
            {showCardNumber ? 'Hide' : 'Show'}
          </Text>
        </div>
      ),
    },
    {
      key: 'Expiration Date',
      value: (
        <CopyToClipboard
          text={FormatDate.format({
            date: card.expiration_time,
            format: 'MM/YYYY',
          })}
        >
          <FormatDate
            fontWeight="600"
            date={card.expiration_time}
            format="MM/YYYY"
          />
        </CopyToClipboard>
      ),
    },
    {
      key: 'CVV',
      value: (
        <CopyToClipboard text={card.cvv_number}>
          <Text fontWeight="600">{card.cvv_number}</Text>
        </CopyToClipboard>
      ),
    },
    {
      key: 'Monthly card limit',
      value: <Currency fontWeight="600">{card.monthly_card_limit}</Currency>,
    },
    {
      key: 'Card status',
      value: <Text fontWeight="600">{capitalize(card.state)}</Text>,
    },
    {
      key: 'Created/Last updated',
      value: (
        <Text>
          <FormatDate fontWeight="600" date={card.created_time} /> /{' '}
          <FormatDate fontWeight="600" date={card.last_modified_time} />
        </Text>
      ),
    },
    {
      key: 'TokenID',
      value: <Text fontWeight="600">{card.token}</Text>,
    },
  ]
}

const VirtualCard = ({
  benefitId,
  card,
  onGenerate,
  isGenerating,
  slim,
  person,
  isCreateAvailable,
  currentHealthPlanId,
}) => (
  <Container flex alignCenter noPadding>
    {card.token ? (
      <Container flex noPadding alignCenter width="100%">
        {isGenerating && <GeneratorLoader />}
        {!isGenerating && (
          <CardBody data={generateCardBody(card, person)} slim={slim} />
        )}
      </Container>
    ) : (
      <NoCard
        benefitId={benefitId}
        employeePublicKey={person.EmployeePublicKey}
        onGenerateCard={onGenerate}
        isCreateAvailable={isCreateAvailable}
        currentHealthPlanId={currentHealthPlanId}
      />
    )}
  </Container>
)

VirtualCard.propTypes = {
  benefitId: PropTypes.string.isRequired,
  currentHealthPlanId: PropTypes.string.isRequired,
  card: PropTypes.shape({
    id: PropTypes.string,
  }),
  person: PropTypes.object.isRequired,
  onGenerate: PropTypes.func.isRequired,
  isCreateAvailable: PropTypes.bool.isRequired,
  isGenerating: PropTypes.bool,
  slim: PropTypes.bool,
}

VirtualCard.defaultProps = {
  card: {},
  slim: false,
  isGenerating: false,
}

export default VirtualCard
