import React from 'react'
import { Stack, TextColors, Typography } from '@sureco/design-system'
import { Switch, SwitchProps } from '@surecompanies/core_components'
import { Control, Controller, UseControllerProps } from 'react-hook-form'

type RHFToggleProps = Omit<SwitchProps, 'name'> & {
  name: string
  control: Control<any, object> // unable to make it work using generics yet Control<T>
  rules?: UseControllerProps['rules']
}

export default function RHFToggle({
  name,
  control,
  rules,
  ...rest
}: RHFToggleProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <Stack style={{ display: 'inline' }}>
            <Switch
              value={field.value}
              onChange={(event) => {
                field.onChange(event)
              }}
              ref={field.ref}
              {...rest}
            />
            {fieldState.error && (
              <Typography color={TextColors.error}>
                {fieldState.error.message}
              </Typography>
            )}
          </Stack>
        )
      }}
    />
  )
}
