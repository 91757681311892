import { namespaceActions } from 'modules/app-core/redux-utils'
import { NAME } from './constants'

const REQUEST_INIT = 'REQUEST_INIT'
const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
const REQUEST_FAILURE = 'REQUEST_FAILURE'
const REQUEST_COMPLETE = 'REQUEST_COMPLETE'
const REQUEST_CLEAR = 'REQUEST_CLEAR'

export default namespaceActions(NAME)([
  REQUEST_INIT,
  REQUEST_SUCCESS,
  REQUEST_FAILURE,
  REQUEST_COMPLETE,
  REQUEST_CLEAR,
])
