import React, { useState } from 'react'
import { CensusFileRowTypeEnum } from '../api/enums/census-file-row-type.enum'
import { LoadingOverlay } from '../components/LoadingOverlay/LoadingOverlay'
import { CensusFileRowStatusEnum } from '../api/enums/census-file-row-status.enum'
import ReviewRecordsBaseUpdate from '../components/ReviewUpdatesRecordsBase'

const ReviewRecordsUpdateEmployeeNoBenefits = (props: Record<string, never> & { routeParams: never }) => {
  const [loading, setLoading] = useState(false) // Track submission state

  return (
    <div>
      <LoadingOverlay isLoading={loading} message="Loading updates..." />
      <ReviewRecordsBaseUpdate
        {...props}
        rowQueryParams={{
          censusFileRowStatuses: [CensusFileRowStatusEnum.IN_PROCESS], // Only include rows with this status (as per the Update tab)
          censusRowType: CensusFileRowTypeEnum.UPDATE_EMPLOYEE_NO_BENEFITS, // Use the correct `censusRowType` for the Update tab
        }}
        setLoading={setLoading} // Handle cell value changes (if needed)
      />
    </div>
  )
}

export default ReviewRecordsUpdateEmployeeNoBenefits
