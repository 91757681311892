/* eslint-disable global-require, no-underscore-dangle */
import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import createStore from 'store/createStore'
import Emitter from 'events'
import { ApolloProvider } from '@apollo/client'
import { ModuleRegistry } from '@ag-grid-community/core'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { RichSelectModule } from '@ag-grid-enterprise/rich-select'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { SideBarModule } from '@ag-grid-enterprise/side-bar'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { datadogRum } from '@datadog/browser-rum'

import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'

import { config as configureApiBuilder } from '@hixme/api'

// project
// import analytics from 'modules/analytics'
import client from 'apollo/client'
import appConfig from 'modules/app-config'
import AppContainer from 'containers/AppContainer'
import {
  config as authConfig,
  restoreLoginState,
  refreshAuth,
  selectors,
} from '@hixme/auth-ui'
import 'styles/core.scss'

// initialize and config logger
import logConfig from 'modules/app-core/log'
import axios from 'axios'
import { initializeLDClient } from 'helpers/launchDarklyClient'

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-062492}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SureCo}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{SureCo_Application}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{SureCo_Application}_need_to_be_licensed___{SureCo_Application}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{1_September_2025}____[v3]_[01]_MTc1NjY4MTIwMDAwMA==9da6cecd756a515d9aaa2d6223327958'
)

ModuleRegistry.registerModules([
  ColumnsToolPanelModule,
  CsvExportModule,
  ClientSideRowModelModule,
  RichSelectModule,
  ServerSideRowModelModule,
  SideBarModule,
  SetFilterModule,
  FiltersToolPanelModule,
  RangeSelectionModule,
  ExcelExportModule,
  StatusBarModule,
])

// analytics.initialize({ga: 'UA-58480436-2'})

const stage = appConfig.getStage()

// auth setup
authConfig({
  stage, // auth module follows stage of app
})

datadogRum.init({
  applicationId: process.env.DATADOG_APP_ID,
  clientToken: process.env.DATADOG_CLIENT_TOKEN,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: 'admin-enrollment-platform',
  env: process.env.ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
})

// emitter setup
const emitter = new Emitter()

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.__INITIAL_STATE__
let store = {}

const getAuthToken = () => ({
  Authorization: selectors.getToken(store.getState()),
})
const api = configureApiBuilder({ stage, getAuthToken })

store = createStore(initialState, { emitter, api })

logConfig({
  store,
})

// Restore and refresh the user's auth status
store.dispatch(restoreLoginState())
store.dispatch(refreshAuth())

// send auth token with each request
axios.interceptors.request.use(
  (config) => {
    const Authorization = selectors.getCredentials(store.getState() || {})
      .idToken
    config.headers.Authorization = Authorization || ''
    return config
  },
  (error) => Promise.reject(error)
)
// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')

// for React 18 to work as React 18, uncomment when enzyme issues are fixed (no enzyme support for React 18)
const root = createRoot(MOUNT_NODE)

const render = async () => {
  const routes = require('./routes/index').default(store)

  // initialize LD Client for no react hook LD calls
  // I.E. in mapDispatchToProps etc
  try {
    await initializeLDClient()
  } catch (e) {
    console.error(e)
  }

  root.render(
    <ApolloProvider client={client}>
      <AppContainer emitter={emitter} store={store} routes={routes} />
    </ApolloProvider>
  )
}

render()
