import React from 'react'
import { browserHistory } from 'react-router'
import { Button, Spin, Table } from '@surecompanies/core_components'
import Page from 'layouts/Page'
import { CUSTOMER_MANAGEMENT, NEW_CUSTOMER } from '../../paths'
import useListCustomer from './hooks/useListCustomer'
import { useRouterContext } from 'components/RouterContext'
import BreadcrumbNav from 'enrollment-platform/components/Breadcrumbs'

export default function ListCustomer() {
  const { router } = useRouterContext()
  const { dataSource, error, columns, isLoading } = useListCustomer({
    onView: (record) => {
      router.push(`/${CUSTOMER_MANAGEMENT}/${record.id}/view`)
    },
    onEdit: (record) => {
      router.push(`/${CUSTOMER_MANAGEMENT}/${record.id}/edit`)
    },
  })

  return isLoading ? (
    <Spin spinning fullscreen />
  ) : (
    <Page
      title={'Customer Management'}
      action={
        <Button
          type="primary"
          disabled={isLoading}
          onClick={() => browserHistory.push(NEW_CUSTOMER)}
        >
          Add New Customer
        </Button>
      }
      breadcrumb={
        <BreadcrumbNav
          items={[{ title: 'Home', path: `/` }, { title: 'Customers' }]}
        />
      }
      error={
        error ? { message: (error as unknown) as string, closable: true } : null
      }
    >
      <Table
        dataSource={dataSource}
        columns={columns as any}
        rowKey={(record) => record.id}
        loading={isLoading}
        locale={{
          emptyText: 'No Data',
        }}
        onRow={(record) => ({
          onClick: (e) => {
            if ((e.target as HTMLElement).closest('button')) {
              return
            }
            router.push(`/${CUSTOMER_MANAGEMENT}/${record.id}/view`)
          },
        })}
      />
    </Page>
  )
}
