import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import Input from '@hixme-ui/input'
import Label from '@hixme-ui/label'
import Text from '@hixme-ui/text'

import { PureFieldComponent } from './'

const DefaultComponent = (props) => {
  const {
    divProps,
    children,
    label,
    meta: { touched, error },
    altError,
    preError,
    noError,
    ...restProps
  } = props

  return (
    <div {...divProps}>
      {label && label instanceof String ? <Label>{label}</Label> : label}
      {Children.count(children) > 1 ? Children.toArray(children) : children}
      <PureFieldComponent {...restProps} />

      {(!noError &&
        ((altError && altError({ touched, error })) ||
          (touched && error && (
            <Text div error>
              {error}
            </Text>
          )))) ||
        (preError && preError())}
    </div>
  )
}

const FieldComponent = ({ component, inputComponent, normalize, ...props }) => (
  <Field
    component={component}
    InputComponent={inputComponent}
    normalize={normalize}
    {...props}
  />
)

FieldComponent.displayName = 'FieldComponent'
FieldComponent.propTypes = {
  inputComponent: PropTypes.func,
  component: PropTypes.func,
  normalize: PropTypes.func,
}

DefaultComponent.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  meta: PropTypes.object,
  divProps: PropTypes.object,
  preError: PropTypes.func,
  altError: PropTypes.func,
  noError: PropTypes.bool,
}

FieldComponent.defaultProps = {
  inputComponent: Input,
  component: DefaultComponent,
  normalize: null,
}

DefaultComponent.defaultProps = {
  label: '',
  children: '',
  meta: {},
  divProps: {},
  preError: null,
  altError: null,
  noError: null,
}

export default FieldComponent
