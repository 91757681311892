import { connect } from 'react-redux'
import { compose } from 'recompose'
import isArray from 'lodash/isArray'
import {
  reduxForm,
  getFormValues,
  getFormMeta,
  SubmissionError,
  change,
} from 'redux-form'
import { callWaiter, getWaiter } from 'redux-waiter'
import { personService } from '@hixme/api'
import moment from 'moment'
import {
  ROLE_EMPLOYEE,
  ROLE_PLATFORM_HIXME_ADMIN,
  ROLE_CLIENT_ADMIN,
  ROLE_BROKER,
} from '@hixme/role-policy'

// project
import { LoadingView, EmployeeForm } from 'components'
import { getRequestByName } from 'modules/redux-gateway'
import { actions as personActions } from 'store/modules/persons'
import { selectors as userSessionSelectors } from 'store/modules/user-session'
import { actions as employeeBenefitsActions } from 'routes/Employees/Employee/CobraCoverage/modules/cobra-coverage'
import locations from 'modules/locations'
import modal from 'modules/app-core/modal'
import { actions as notification } from 'modules/app-core/notification-manager'
import { actions as BenefitsEffectiveDateActions } from 'modules/benefits-effective-dates'
import { POST_BENEFITEFFECTIVEDATES_ROUTE } from 'modules/benefits-effective-dates/constants'
import { personByIdSelector } from 'store/modules/persons/selectors'
import { GET_ALL_PERSONS } from 'store/modules/persons/constants'
import PersonProvider from 'store/modules/persons/providers/PersonProvider'
import { selectors as authSelectors } from '@hixme/auth-ui'

// module
import {
  sanitizeValues,
  validateEmployeeForm,
  validateAddress,
  checkAddressWarning,
} from './utils'
import { datadog, CustomActionNames } from 'helpers/datadog'
import useFeatureFlags from 'hooks/useFeatureFlags'

const POST_ROUTE = 'personPost'
const FORM_NAME = 'addEmployee'

const mapStateToProps = (state, props) => {
  const isHixmeAdmin =
    [ROLE_PLATFORM_HIXME_ADMIN, ROLE_BROKER].indexOf(
      authSelectors.getRole(state)
    ) > -1
  const isPlatformAdmin = authSelectors.getRole(state) === ROLE_CLIENT_ADMIN
  const { flags } = useFeatureFlags()
  const isSalaryUnavailable = flags['admin.salary.unavailable']

  const company = userSessionSelectors.getCurrentCompany(state)

  // Redux form selectors
  const formValues = getFormValues(FORM_NAME)(state) || {}
  const metaValues = getFormMeta(FORM_NAME)(state) || {}

  const benefitsEffectiveDateMeta = metaValues.BenefitsEffectiveDate || {}
  const { action, person } = props

  // Show the warning checkbox only if we're editing a worker, the BED
  // is before 01/01/2018, and we've edited the BED field
  const showBEDCheckbox =
    action === 'edit' &&
    moment(formValues.BenefitsEffectiveDate).isBefore('2018-01-01', 'day') &&
    (benefitsEffectiveDateMeta.touched || false)

  const defaultValues = {
    BenefitsEligible: true,
    CompanyName: company.CompanyName,
    ExistingMedicalPlanWaived: true,
    IsExempt: false,
    IsTestUser: false,
    PayrollCycle: 0,
    ReimbursementEligible: true,
    Smoker: false,
    UserRole: ROLE_EMPLOYEE,
  }

  const editProps = () => {
    const prsn = personByIdSelector(state)(props.id)
    if (action === 'edit') {
      return prsn || {}
    }
    return {}
  }

  const initialValues = {
    ...defaultValues,
    ...editProps(),
  }

  return {
    companyHasPlans: !!userSessionSelectors.currentCompanyHasPlans(state),
    companyId: company.Id,
    CompanyName:
      userSessionSelectors.getCurrentCompany(state).CompanyName || '',
    countyHash: locations.selectors.getCountyHashSelector(state) || [],
    fetchingBenefitEffectiveDates: getRequestByName(
      state,
      POST_BENEFITEFFECTIVEDATES_ROUTE
    ).isProcessing,
    formValues: getFormValues(FORM_NAME)(state),
    initialValues,
    isGetting: getWaiter(state, GET_ALL_PERSONS).isPending || false,
    isProcessing: getWaiter(state, POST_ROUTE).isPending || false,
    LoadingView,
    originalHireDate:
      person && moment(person.OriginalHireDate).format('M/D/YYYY'),
    personBED: formValues.BenefitsEffectiveDate,
    showBEDCheckbox,
    isHixmeAdmin,
    isPlatformAdmin,
    isSalaryUnavailable,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onCancel(e) {
    e.preventDefault()
    dispatch(modal.actions.hideModal())
  },
  onAddressOptionSelected: (opt) => {
    dispatch(change(FORM_NAME, 'StreetAddress', `${opt.premise} ${opt.street}`))
    dispatch(change(FORM_NAME, 'StreetAddressExt', opt.suiteOrAptNumber))
    dispatch(change(FORM_NAME, 'City', opt.city))
    dispatch(change(FORM_NAME, 'PostalCode', opt.zipCode.split('-')[0]))
    dispatch(change(FORM_NAME, 'StreetInputHasChanged', true))
    dispatch(change(FORM_NAME, 'County', opt.county))
    dispatch(change(FORM_NAME, 'StateProvince', opt.stateCode))
  },
  onStreetAddressHasChanged: (hasChanged) => {
    dispatch(change(FORM_NAME, 'StreetInputHasChanged', hasChanged))
  },
  onMailAddressOptionSelected: (opt) => {
    const addressLine = opt.street
      ? `${opt.premise} ${opt.street}`
      : opt.addressLine1
    dispatch(change(FORM_NAME, 'MailingStreetAddress', addressLine))
    dispatch(change(FORM_NAME, 'MailingStreetAddressExt', opt.suiteOrAptNumber))
    dispatch(change(FORM_NAME, 'MailingCity', opt.city))
    dispatch(change(FORM_NAME, 'MailingPostalCode', opt.zipCode.split('-')[0]))
    dispatch(change(FORM_NAME, 'MailingCounty', opt.county))
    dispatch(change(FORM_NAME, 'MailingStateProvince', opt.stateCode))
  },
  getBenefitsEffectiveDates: (e) => {
    const hireDate = e.target.value
    dispatch((_, getState) => {
      const formValues = getFormValues(FORM_NAME)(getState()) || {}
      const { AccountCreated, HireDate, EmploymentStatus } = formValues
      const body = {
        ...(AccountCreated && { accountCreated: AccountCreated }),
        ...(HireDate && { hireDate: HireDate }),
        ...(hireDate && { hireDate }),
        ...(EmploymentStatus && { employmentStatus: EmploymentStatus }),
      }

      return dispatch(
        BenefitsEffectiveDateActions.getBenefitsEffectiveDates(body)
      ).then((response) => {
        dispatch(
          change(
            FORM_NAME,
            'BenefitsEffectiveDate',
            response.BenefitsEffectiveDate
          )
        )
        dispatch(
          change(FORM_NAME, 'EnrollmentStartDate', response.EnrollmentStartDate)
        )
        dispatch(
          change(FORM_NAME, 'EnrollmentEndDate', response.EnrollmentEndDate)
        )
      })
    })
  },
})

const composedEmployeeForm = compose(
  PersonProvider((state, props) => ({
    fullProfile: true,
    personId: props.id,
  })),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: validateEmployeeForm,
    asyncValidate: validateAddress,
    // asyncBlurFields is empty on purpose, because validateAddress need to run AFTER all related fields are updated,
    // otherwise you can get mixed of new data and old data, leading to validation error
    asyncBlurFields: [],
    asyncChangeFields: [
      'PostalCode',
      'County',
      'MailingPostalCode',
      'MailingCounty',
    ], // 'County' field doesnt work with asyncBlurFields for some reason
    warn: checkAddressWarning,
    onSubmit: (values, dispatch, { isSalaryUnavailable, ...props }) => {
      const { countyHash, action, companyId } = props
      const { ExistingMedicalPlanWaived, Salary, ...newValues } = values
      sanitizeValues(newValues)
      const body = {
        ...newValues,
        Id: props.id,
        StateProvince: countyHash[values.County].StateProvince,
        RatingArea: countyHash[values.County].RatingAreaNumeric,
        MailingStateProvince:
          countyHash[values.MailingCounty]?.StateProvince ?? '',
        Relationship: 'Employee',
        ClientPublicKey: companyId,
        ...(!isSalaryUnavailable && { Salary }),
        ...(action === 'edit' && { Id: props.id, EmployeePublicKey: props.id }),
      }
      return dispatch(
        callWaiter(POST_ROUTE, {
          requestCreator: () =>
            personService({
              route: 'persons',
              method: 'POST',
              body,
            }),
        })
      )
        .then((response) => {
          const message =
            action === 'edit'
              ? 'Successfully edited employee profile'
              : 'Successfully inserted a new employee'
          datadog.customAction(
            action === 'edit'
              ? CustomActionNames.EditWorkerInfo
              : CustomActionNames.AddEmployee,
            {
              values: body,
            }
          )
          dispatch(notification.createSuccessNotification(message))
          dispatch(personActions.getPersonsByCompanyId(companyId))

          // this dispatch to employee benefits is to keep the benefit data in sync
          // until we get a persons store module rolled out. BT
          dispatch(employeeBenefitsActions.loadPerson(response))
          dispatch(modal.actions.hideModal())

          // shows the stop coverage modal when EmployeeStatus is equal to Terminated or Part-Time
          // if (showStopCoverageValues.includes(response.EmploymentStatus)) {
          //   dispatch(setModal(STOP_COVERAGE_MODAL))
          // }
        })
        .catch((error) => {
          // 400
          if (error.status === 400 && isArray(error.errors)) {
            // set errors
            const errors = {}
            error.errors.forEach((err) => {
              errors[err.property] = err.message
            })
            throw new SubmissionError(errors)
          }

          let message =
            action === 'edit'
              ? 'Error editing employee profile'
              : 'Error adding new employee'
          if (error.message) {
            // 406
            if (error.message.status === 406) {
              message +=
                error.message.data && error.message.data.errors
                  ? error.message.data.errors[0].message
                  : ''
            }
          }
          dispatch(notification.createErrorNotification(message))
        })
    },
  })
)

export default composedEmployeeForm(EmployeeForm)
