import { callWaiter } from 'redux-waiter'
import { createErrorNotification } from 'modules/app-core/notification-manager/actions'

import { GET_ALL_COMPANIES } from './constants'
import t from './actionTypes.js'

import { GET_ALL_CLIENT_DATA } from '../../../routes/Company/queries/queries'
import client from '../../../apollo/client'

export const resetCompanyList = () => ({
  type: t.RESET_COMPANY_LIST,
})

export const receiveCompanyList = (companyList) => ({
  type: t.RECEIVE_COMPANY_LIST,
  payload: companyList,
})

export const receiveCompany = (company) => ({
  type: t.RECEIVE_COMPANY,
  payload: company,
})

export const getAllCompanies = () => (dispatch) =>
  dispatch(
    callWaiter(GET_ALL_COMPANIES, {
      requestCreator: async () => {
        try {
          const result = await client.query({
            query: GET_ALL_CLIENT_DATA,
            errorPolicy: 'all',
          })
          dispatch(receiveCompanyList(result?.data?.getAllClients))
          return result?.data?.getAllClients
        } catch (error) {
          dispatch(
            createErrorNotification(
              'We had trouble loading persons. Please reload the page'
            )
          )
          throw error
        }
      },
    })
  )
