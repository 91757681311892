import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { browserHistory } from 'react-router'
import throttle from 'lodash/throttle'
import { isDevelopment, isStaging } from 'modules/app-config/environment'

// ========================================================
// BEGIN store setup additions
// ========================================================
import { hixmeNotifications, dispatchEvents } from 'middleware/middleware'
import userSession from 'store/modules/user-session'
import makeRootReducer from './reducers' // eslint-disable-line import/no-named-as-default
import { updateLocation } from './location'

// Load session data from local storage
const persistedState = userSession.utils.loadState()
// ========================================================
// END store setup additions
// ========================================================

export let storeInstance = null

export default (initialState = {}, extras = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================

  // SureCo change - adding of hixmeNotifications
  const middleware = [
    thunk.withExtraArgument(extras),
    hixmeNotifications,
    dispatchEvents(extras.emitter),
  ]

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []
  if (isDevelopment() || isStaging()) {
    // eslint-disable-next-line no-underscore-dangle
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer(),
    // SureCo change - adding of persistedState for session handling
    Object.assign({}, initialState, persistedState),
    compose(
      applyMiddleware(...middleware),
      ...enhancers
    )
  )
  store.asyncReducers = {}
  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store.unsubscribeHistory = browserHistory.listen(updateLocation(store))

  // ========================================================
  // BEGIN SureCo store subscribe
  // ========================================================
  store.subscribe(
    throttle(() => {
      userSession.utils.saveState({
        userSession: store.getState().userSession,
      })
    }, 1000)
  )
  // ========================================================
  // END SureCo store subscribe
  // ========================================================

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default // eslint-disable-line global-require
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  storeInstance = store
  return store
}
