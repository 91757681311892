import { connectRequest } from 'modules/redux-gateway'
import notification from 'modules/app-core/notification-manager'
import { LoadingView, SalesDirectorFormGroup } from 'components'

export default connectRequest({
  route: 'salesdirectorsGet',
  requestOnMount: true,
  processingView: LoadingView,
  clearOnMount: true,
  onFailure: (request, dispatch) => {
    dispatch(
      notification.actions.createNotification({
        type: 'error',
        message: 'Sorry, we could not retrieve company list.',
      })
    )
  },
  mapStateToProps: (state, props, request) => ({
    list: request.response || [],
  }),
})(SalesDirectorFormGroup)
