import React from 'react'
import { Stack, Typography } from '@sureco/design-system'

export default function SelectionPane({
  title,
  children,
  style,
}: {
  title: string
  children: React.ReactNode
  style?: React.CSSProperties
}) {
  return (
    <Stack
      style={{
        background: '#F5F5F5',
        border: '1px solid #AAB5BD',
        width: '100%',
        ...style,
      }}
      borderRadius={8}
      padding={20}
      space={10}
    >
      <Typography color="#003146" size={16} bold>
        {title}
      </Typography>
      {children}
    </Stack>
  )
}
