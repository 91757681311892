import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'

dayjs.extend(relativeTime)
dayjs.extend(isToday)
dayjs.extend(isYesterday)

export const relativeTimeString = (udt: string, days = 2): string => {
  const pastDate = dayjs.utc(udt).local()
  const now = dayjs()
  const diffInDays = now.diff(pastDate, 'day')

  if (diffInDays > days) {
    return pastDate.format('MM/DD/YY h:mm A') // fallback for older dates
  }
  return pastDate.from(now)
}
