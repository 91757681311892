import { createReducer } from 'modules/app-core/redux-utils'
import t from './actionTypes'

const initialState = {
  count: null,
  list: [],
  initialized: false,
  searchKeyword: '',
  showFilters: false,
  pagination: {
    page: 1,
  },
  filters: {},
  companyId: null,
  sortBy: 'lastUpdate',
  sortDescending: true,
}

export default createReducer(initialState, {
  [t.RESET_STATE]: () => ({
    ...initialState,
  }),
  [t.ADD_TO_LIST]: (state, payload) => ({
    ...state,
    list: [...state.list, ...payload.reimbursements],
  }),
  [t.LOAD_LIST]: (state, payload) => ({
    ...state,
    list: payload.reimbursements,
    count: payload.reimbursementsCount,
    initialized: true,
  }),
  [t.LOAD_EXPENSE]: (state, payload) => ({
    ...state,
    expense: payload,
    initialized: true,
  }),
  [t.LOAD_RECEIPTS]: (state, payload) => ({
    ...state,
    expense: {
      ...state.expense,
      receipts: payload,
    },
  }),
  [t.VIEW_MORE]: (state) => ({
    ...state,
    pagination: {
      page: state.pagination.page + 1,
    },
  }),
  [t.RESET_PAGINATION]: (state) => ({
    ...state,
    pagination: initialState.pagination,
  }),
  [t.UPDATE_SEARCH]: (state, searchKeyword) => ({
    ...state,
    searchKeyword,
  }),
  [t.TOGGLE_FILTERS]: (state) => ({
    ...state,
    showFilters: !state.showFilters,
  }),
  [t.UPDATE_FILTER]: (state, payload) => ({
    ...state,
    filters: { ...state.filters, ...payload },
    pagination: {
      page: 1,
    },
  }),
  [t.RESET_FILTERS]: (state) => ({
    ...state,
    filters: {},
    pagination: initialState.pagination,
  }),
  [t.SET_COMPANY_ID]: (state, payload) => ({
    ...state,
    companyId: payload,
  }),
  [t.CHANGE_SORT]: (state, payload) => {
    const sameSortBySelected = payload === state.sortBy
    return {
      ...state,
      sortBy: payload,
      sortDescending: sameSortBySelected ? !state.sortDescending : true,
    }
  },
})
