import React, { ReactElement, ReactNode } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from '@surecompanies/core_components'
import { Stack, Typography, pageTitleFontFamily } from '@sureco/design-system'

type ContentSectionProps = {
  title?: string
  children: ReactNode
  actions?: ReactElement<unknown>
  padding?: number
  loading?: boolean
}

export default function ContentSection({
  title,
  children,
  actions,
  loading,
  padding = 20,
}: ContentSectionProps) {
  return (
    <Stack
      direction="col"
      space={20}
      padding={padding}
      borderRadius={8}
      style={{ background: 'white' }}
    >
      {loading ? (
        <Spin indicator={<LoadingOutlined spin />} size="large" />
      ) : (
        <>
          <Stack direction="row" align="center" justify="space-between">
            <Typography size={20} style={{ fontFamily: pageTitleFontFamily }}>
              {title}
            </Typography>
            {actions}
          </Stack>
          {children}
        </>
      )}
    </Stack>
  )
}
