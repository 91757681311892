import { namespaceActions } from 'modules/app-core/redux-utils'
import { NAME } from './constants'

const RESET_COMPANY_LIST = 'RESET_COMPANY_LIST'
const RECEIVE_COMPANY_LIST = 'RECEIVE_COMPANY_LIST'
const RECEIVE_COMPANY = 'RECEIVE_COMPANY'

export default namespaceActions(NAME)([
  RESET_COMPANY_LIST,
  RECEIVE_COMPANY_LIST,
  RECEIVE_COMPANY,
])
