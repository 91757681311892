import { callWaiter } from 'redux-waiter'
import { financeService } from '@hixme/api'

import notifications from 'modules/app-core/notification-manager'
import { setModal } from '@hixme/modal'

// project
import { VCN_TRANSACTIONS } from 'modal-constants'

import t from './actionTypes.js'
import * as constants from './constants'

export const loadVirtualCard = (payload) => ({
  type: t.LOAD_VIRTUAL_CARD,
  payload,
})

export const loadCancelVirtualCard = (payload) => ({
  type: t.LOAD_CANCEL_VIRTUAL_CARD,
  payload,
})

export const updateVirtualCard = (payload) => ({
  type: t.UPDATE_VIRTUAL_CARD,
  payload,
})

export const viewTransactions = (id) => (dispatch) =>
  dispatch(setModal(VCN_TRANSACTIONS, { id }))

export const loadEmployeeFamilyVirtualCard = (payload) => ({
  type: t.GET_EMPLOYEE_FAMILY_VIRTUAL_CARDS,
  payload,
})

export const getVirtualCard = (Id) => (dispatch) => {
  const req = dispatch(
    callWaiter(`${constants.VCN_GET}-${Id}`, {
      requestCreator: () =>
        financeService({
          route: `benefit/${Id}/virtual-card`,
        })
          .then((res) => {
            dispatch(loadVirtualCard({ ...res.VirtualCard, Id }))
            return res
          })
          .catch((e) => {
            dispatch(
              notifications.actions.createErrorNotification(
                'Could not retrieve virtual card.'
              )
            )
            throw e
          }),
    })
  )
  return req
}

export const cancelVirtualCard = (Id) => (dispatch) => {
  const req = dispatch(
    callWaiter(constants.VCN_GET, {
      requestCreator: () =>
        financeService({
          route: `vcn/${Id}`,
          method: 'DELETE',
        }).catch((e) => {
          dispatch(
            notifications.actions.createErrorNotification(
              'Could not delete virtual card.'
            )
          )
          throw e
        }),
    })
  )
  return req
}

export const reuseVirtualCard = ({
  Id,
  cardId,
  cardPublicKey,
  UserPublicKey,
}) => (dispatch) => {
  const req = dispatch(
    callWaiter(`${constants.VCN_POST}-${Id}`, {
      requestCreator: () =>
        financeService({
          route: `benefit/${Id}/virtual-card/reuse`,
          method: 'POST',
          body: {
            VirtualCardId: cardId,
            VirtualCardPublicKey: cardPublicKey,
            UserPublicKey,
          },
        })
          .then((res) => {
            dispatch(loadVirtualCard({ ...res.VirtualCard, Id }))
            dispatch(updateVirtualCard({ ...res.VirtualCard, Id }))
            return res
          })
          .catch((e) => {
            dispatch(
              notifications.actions.createErrorNotification(
                'Could not reuse virtual card.'
              )
            )
            throw e
          }),
    })
  )
  return req
}

export const createVirtualCard = (Id) => (dispatch) => {
  const req = dispatch(
    callWaiter(`${constants.VCN_POST}-${Id}`, {
      requestCreator: () =>
        financeService({
          route: `benefit/${Id}/virtual-card`,
          method: 'POST',
        })
          .then((res) => {
            dispatch(loadVirtualCard({ ...res.VirtualCard, Id }))
            return res
          })
          .catch((e) => {
            dispatch(
              notifications.actions.createErrorNotification(
                'Could not create virtual card.'
              )
            )
            throw e
          }),
    })
  )
  return req
}

// gets all virtual cards for an employee
export const getVirtualCardsForEmployee = (employeePublicKey) => (dispatch) => {
  const req = dispatch(
    callWaiter(`${constants.VCN_GET}-family-cards-${employeePublicKey}`, {
      requestCreator: () =>
        financeService({
          route: `family/${employeePublicKey}/virtual-card`,
        })
          .then((res) => {
            const { Cards } = res
            const availableCards = Cards.map((card) => ({
              id: card.card_public_key,
              cardToken: card.token,
              card: card.pan,
              HIOS: card.metadata.HiosId,
              name: `${card.firstName} ${card.lastName}`,
              userPublicKey: card.user_public_key,
              status: card.state,
            }))

            dispatch(loadEmployeeFamilyVirtualCard(availableCards))
          })
          .catch((e) => {
            dispatch(
              notifications.actions.createErrorNotification(
                'Could not load related virtual cards'
              )
            )
            throw e
          }),
    })
  )
  return req
}
