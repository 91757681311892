import React, { CSSProperties, FC, HTMLProps, ReactNode } from 'react'

export type StackProps = {
  style?: CSSProperties | undefined
  children: ReactNode
  space?: number
  direction?: 'col' | 'row'
  borderStyle?: CSSProperties['borderStyle'] | undefined
  borderColor?: CSSProperties['borderColor'] | undefined
  padding?: number
  borderRadius?: number
  justify?: CSSProperties['justifyContent'] | undefined
  wrap?: CSSProperties['flexWrap'] | undefined
  align?: CSSProperties['alignItems'] | undefined
  className?: HTMLProps<HTMLElement>['className']
  margin?: CSSProperties['margin']
  onClick?: () => void
}

export const Stack: FC<StackProps> = ({
  style,
  children,
  space = 0,
  direction = 'col',
  borderStyle = undefined,
  borderColor = undefined,
  justify = undefined,
  wrap = undefined,
  align = undefined,
  padding = 0,
  margin = 0,
  borderRadius = 0,
  className,
  onClick,
}) => {
  const isColumn = direction === 'col'
  return (
    <div
      onClick={onClick}
      className={className}
      style={{
        display: 'flex',
        flexDirection: isColumn ? 'column' : 'row',
        borderStyle,
        borderColor,
        padding,
        borderRadius,
        justifyContent: justify,
        alignItems: align,
        flexWrap: wrap,
        gap: space,
        margin,
        ...style,
      }}
    >
      {children}
    </div>
  )
}
