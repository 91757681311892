import React from 'react'
import PropTypes from 'prop-types'
import { withState } from 'recompose'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import { colorTheme } from '@hixme-ui/theme'

import classes from './Tabs.module.scss'

const enhanced = withState(
  'activeTabIndex',
  'setActiveTabIndex',
  ({ activeTabIndex }) => activeTabIndex
)
const borderStyles = { borderBottom: `1px solid ${colorTheme.lighter}` }

const Tabs = enhanced(
  ({ list, setActiveTabIndex, activeTabIndex, withBorder, noBackground }) => {
    const onUpdateTab = (tabIndex) => {
      setActiveTabIndex(tabIndex)
    }
    return (
      <Container flex noPadding direction="column" width="100%">
        <div style={withBorder ? borderStyles : {}}>
          <Container flex noPadding margin="0">
            {list &&
              list.map((tab, index) => {
                const classState =
                  activeTabIndex === index ? classes.active : classes.default
                return (
                  <Container
                    flex
                    noPadding
                    margin="0 25px 0 0"
                    key={tab.id || index}
                  >
                    <div className={classState}>
                      <Text
                        cursor="pointer"
                        onClick={() => {
                          onUpdateTab(index)
                        }}
                      >
                        {tab.component || tab.name}
                      </Text>
                    </div>
                  </Container>
                )
              })}
          </Container>
        </div>
        <Container flex noPadding margin="20px 0 0 0">
          {list &&
            list.map((tab, index) => {
              if (activeTabIndex === index && tab.content) {
                return (
                  <div
                    className={classes.tabBody}
                    key={tab.id || index}
                    style={{
                      background: noBackground ? 'transparent' : '#f0f3f6',
                    }}
                  >
                    {tab.content}
                  </div>
                )
              }
              return null
            })}
        </Container>
      </Container>
    )
  }
)
Tabs.displayName = 'Tabs'

Tabs.propTypes = {
  list: PropTypes.array,
  setActiveTabIndex: PropTypes.func,
  activeTabIndex: PropTypes.number,
  withBorder: PropTypes.bool,
  withBackground: PropTypes.bool,
}

Tabs.defaultProps = {
  list: [],
  setActiveTabIndex: null,
  activeTabIndex: 0,
  withBorder: false,
  withBackground: true,
}

export default Tabs
