import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

import Container from '@hixme-ui/container'
import Icon from '@hixme-ui/icon'
import SocialSecurity from '@hixme-ui/social-security'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'
import { Button } from '@hixme-ui/forms'
import { FormatDate } from '@hixme-ui/dates'

import { InfoCard } from 'components/CobraCard'

const stopCoverageText = (person) => {
  const showManage = ['Part-Time', 'Terminated']

  return showManage.includes(person.EmploymentStatus)
    ? 'Manage Coverage'
    : 'Stop Coverage'
}

const WorkerInfoCard = ({ person, onStopCoverage }) => {
  const rowSpacing = 30

  return (
    <div>
      <Container flex noPadding alignItems="center" margin="0 0 20px 0">
        <Icon user light size={2} />
        <Title light smallest margin="10px">
          Worker Information
        </Title>
        <Button
          small
          style={{ marginLeft: 'auto' }}
          onClick={onStopCoverage}
          outline
        >
          {stopCoverageText(person)}
        </Button>
      </Container>

      <InfoCard
        rowSpacing={rowSpacing}
        titles={['Key information', 'Employment dates', 'COBRA dates']}
        cobraContinueBenefits={person.CobraContinueBenefits}
      >
        <Container flex noPadding direction="column">
          <Container padding="10px 20px 0px" width="100%" height="100%" rounded>
            <Row>
              <Col xs={12} md={4} style={{ padding: '0px 28px' }}>
                <Container
                  flex
                  noPadding
                  margin="0 0 20px 0"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Text style={{ width: '115px' }} light>
                    SSN
                  </Text>
                  <SocialSecurity
                    style={{ width: '115px' }}
                    showFull
                    number={person.SSN || 'SSN Not Found'}
                  />
                </Container>
                <Container
                  flex
                  noPadding
                  margin="0 0 20px 0"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Text style={{ width: '115px' }} light>
                    Birthdate
                  </Text>
                  <FormatDate date={person.DateOfBirth} />
                </Container>
                <Container
                  flex
                  noPadding
                  margin="0 0 20px 0"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Text style={{ width: '115px' }} light>
                    Address
                  </Text>
                  <Container flex noPadding direction="column">
                    <Text style={{ width: '115px' }}>
                      {`${person.StreetAddress}`}
                    </Text>
                    <Text style={{ width: '115px' }}>
                      {person.StreetAddressExt &&
                        `Apt/Suite ${person.StreetAddressExt}`}
                    </Text>
                    <Text style={{ width: '115px' }}>
                      {`${person.City}, ${person.StateProvince} ${
                        person.PostalCode
                      }`}
                    </Text>
                  </Container>
                </Container>
              </Col>

              <Col xs={12} md={4} style={{ padding: '0px 28px' }}>
                <Container
                  flex
                  noPadding
                  margin="0 0 20px 0"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Text style={{ width: '180px' }} light>
                    Hire date
                  </Text>
                  {person.HireDate && <FormatDate date={person.HireDate} />}
                </Container>
                <Container
                  flex
                  noPadding
                  margin="0 0 20px 0"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Text style={{ width: '180px' }} light>
                    Terminated date
                  </Text>
                  {person.TerminationDate && (
                    <FormatDate date={person.TerminationDate} />
                  )}
                </Container>
                <Container
                  flex
                  noPadding
                  margin="0 0 20px 0"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Text style={{ width: '180px' }} light>
                    Employer contribution ends
                  </Text>
                  {person.PreviousCoverageEndDate && (
                    <FormatDate date={person.PreviousCoverageEndDate} />
                  )}
                </Container>
                <Container
                  flex
                  noPadding
                  margin="0 0 20px 0"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Text style={{ width: '180px' }} light>
                    Letter posted on
                  </Text>
                  {person.CobraLetterPostDate && (
                    <FormatDate date={person.CobraLetterPostDate} />
                  )}
                </Container>
              </Col>

              <Col xs={12} md={4} style={{ padding: '0px 28px' }}>
                <Container
                  flex
                  noPadding
                  margin="0 0 20px 0"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Text style={{ width: '150px' }} light>
                    Election period start
                  </Text>
                  {person.CobraBenefitsEligibleStartDate && (
                    <FormatDate date={person.CobraBenefitsEligibleStartDate} />
                  )}
                </Container>
                <Container
                  flex
                  noPadding
                  margin="0 0 20px 0"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Text style={{ width: '150px' }} light>
                    Election period end
                  </Text>
                  {person.CobraBenefitsEligibleEndDate && (
                    <FormatDate date={person.CobraBenefitsEligibleEndDate} />
                  )}
                </Container>
                <Container
                  flex
                  noPadding
                  margin="0 0 20px 0"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Text style={{ width: '150px' }} light>
                    Cobra will be effective
                  </Text>
                  {person.CobraBenefitsEffectiveDate && (
                    <FormatDate date={person.CobraBenefitsEffectiveDate} />
                  )}
                </Container>
              </Col>
            </Row>
          </Container>
        </Container>
      </InfoCard>
    </div>
  )
}

WorkerInfoCard.displayName = 'WorkerInfoCard'

WorkerInfoCard.propTypes = {
  person: PropTypes.object,
  onStopCoverage: PropTypes.func.isRequired,
}

WorkerInfoCard.defaultProps = {
  person: {
    TerminationDate: '',
    PreviousCoverageEndDate: '',
    CobraLetterPostDate: '',
    CobraBenefitsEligibleStartDate: '',
    CobraBenefitsEligibleEndDate: '',
    CobraBenefitsEffectiveDate: '',
    EmployeeStatus: '',
    StreetAddressExt: '',
    SSNLastFour: '',
    DateOfBirth: '',
    StreetAddress: '',
    City: '',
    StateProvince: '',
    PostalCode: '',
  },
}

export default WorkerInfoCard
