import type { Field } from 'react-querybuilder'
import { toFullOption } from 'react-querybuilder'
import { employmentStatus } from './employmentStatus'
import { employmentType } from './employmentType'

export const fields = (
    [
      {
        name: 'EmploymentStatus',
        label: 'Employment Status',
        valueEditorType: 'select',
        values: employmentStatus,
        operators: [
          { name: '=', label: 'is' }
        ]
      },
      {
        name: 'EmploymentType',
        label: 'Employment Type',
        valueEditorType: 'select',
        values: employmentType,
        operators: [
            { name: '=', label: 'is' }
        ]
      },
      {
        name: 'Eligibility_1',
        label: 'Eligibility 1',
        operators: [
            { name: '=', label: 'is' }
        ]
      },
      {
        name: 'Eligibility_2',
        label: 'Eligibility 2',
        operators: [
            { name: '=', label: 'is' }
        ]
      },
      {
        name: 'HireDate',
        label: 'Hire Date',
        operators: [
          { name: '<=', label: 'Same or Before' },
        ],
      },
    ] satisfies Field[]
  ).map((o) => toFullOption(o))
