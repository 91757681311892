import { useState } from 'react'
import { notification } from '@surecompanies/core_components'
import { useMutation, useQuery } from '@tanstack/react-query'
import client from 'enrollment-platform/api/eps.client'
import { debounce } from 'lodash'
export default function useAdmins(customerId: string) {
  const [loadingAdminId, setLoadingAdminId] = useState<string | null>(null)
  const [admins, setAdmins] = useState<any[]>([])
  const [suggestion, setSuggestion] = useState<any[]>([])

  async function getAdmins(id: string) {
    const { data } = await client.get(`/customers/${id}/admins`)
    setAdmins(data)
    return data
  }

  async function updateAdmin({
    id,
    value,
  }: {
    id: string
    value: { isAdmin: boolean }
  }) {
    try {
      setLoadingAdminId(id)
      const { data } = await client.patch(
        `/customers/${customerId}/admins/${id}`,
        value
      )
      notification.success({
        message: 'Admin updated successfully',
      })
      const newAdmins = admins.map(
        (admin) => (admin.id === id ? { ...admin, ...data } : admin)
      )
      refetchAdmins()
      setAdmins(newAdmins)
      return data
    } catch (error) {
      notification.error({
        message: 'Failed to update admin',
      })
      throw error
    } finally {
      setLoadingAdminId(null)
    }
  }

  const { isLoading, refetch: refetchAdmins } = useQuery({
    queryKey: ['admins', customerId],
    queryFn: () => getAdmins(customerId),
  })

  const {
    mutate: updateAdminMutation,
    isPending: isUpdatingAdmin,
  } = useMutation({
    mutationFn: updateAdmin,
    mutationKey: ['updateAdmin', customerId],
  })

  async function searchPerson(name: string) {
    if (name.length < 3) {
      setSuggestion([])
      return
    }
    const { data } = await client.get(
      `/customers/${customerId}/members?name=${name}`
    )
    console.log(data)
    setSuggestion(data.map((item: any) => ({
      label: `${item.fullName} - ${item.email}`,
      value: `${item.fullName} - ${item.email}`,
      id: item.id,
    })))
  }

  const onSearchPerson = debounce((name: string) => {
    searchPerson(name)
  }, 200)

  return {
    admins,
    isLoading,
    isUpdatingAdmin,
    isUpdatingAdminId: loadingAdminId,
    onUpdateAdmin: updateAdminMutation,
    onSearchPerson: onSearchPerson,
    suggestion,
  }
}
