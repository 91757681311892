import React from 'react'
import { branch, compose, lifecycle, renderComponent } from 'recompose'
import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'

// module
import { getCensusById } from '../actions'
import { GET_CENSUS } from '../constants'
import { censusByIdSelector } from '../selectors'

const noop = () => ({})

const censusMapStateToProps = (state, ownProps) => {
  const { isCompleted, isPending, isRejected } =
    getWaiter(state, `${GET_CENSUS}:${ownProps.censusId}`) || {}
  const {
    LoadingView = () => <span>Loading...</span>,
    RejectedView = () => <span>Could not find census.</span>,
    showLoadingView = true,
  } = ownProps

  return {
    isCompleted,
    isPending,
    isRejected,
    LoadingView,
    RejectedView,
    census: censusByIdSelector(state)(ownProps.censusId),
    showLoadingView,
  }
}

const CensusProvider = (mapStateToProps = noop, mapDispatchToProps) => (
  InnerComponent
) =>
  compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    connect(censusMapStateToProps),
    lifecycle({
      componentDidMount() {
        const { dispatch, isCompleted, isPending, censusId } = this.props
        if (censusId) {
          dispatch((_, getState) => {
            const census = censusByIdSelector(getState())(censusId)
            if ((!isCompleted && !isPending) || (!census && !isPending)) {
              dispatch(getCensusById(censusId))
            }
          })
        }
      },
    }),
    branch(
      ({ isPending, showLoadingView }) => isPending && showLoadingView,
      renderComponent(({ LoadingView }) => <LoadingView />)
    ),
    branch(
      ({ isRejected }) => isRejected,
      renderComponent(({ RejectedView }) => <RejectedView />)
    )
  )(InnerComponent)

export default CensusProvider
