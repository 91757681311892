import { connect } from 'react-redux'
import { clearModal } from '@hixme/modal'

import { selectors } from '../../../../modules/expense-requests-search'
import ReceiptItemsModal from './ReceiptItemsModal'

const mapStateToProps = (state) => {
  const expense = selectors.getExpense(state)
  return {
    reimbursement: expense && expense.reimbursement,
  }
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(clearModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptItemsModal)
