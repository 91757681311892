import { namespaceActions } from 'modules/app-core/redux-utils'
import { NAME } from './constants'

const LOAD_PERSON_STATUS = 'LOAD_PERSON_STATUS'
const RECEIVE_PERSON = 'RECEIVE_PERSON'
const UPDATE_PERSON = 'UPDATE_PERSON'
const RECEIVE_PERSON_LIST = 'RECEIVE_PERSON_LIST'

export default namespaceActions(NAME)([
  LOAD_PERSON_STATUS,
  RECEIVE_PERSON,
  UPDATE_PERSON,
  RECEIVE_PERSON_LIST,
])
