/* eslint-disable max-len */

// modals
import CensusErrorsModalContainer from './routes/CensusManagement/CensusSummary/containers/CensusErrorsModalContainer'
import EditContactInfoContainer from './routes/Company/containers/EditContactInfoContainer'
import EditHixmeInfoContainer from './routes/Company/containers/EditHixmeInfoContainer'
import HistoryModal from './routes/Employees/Employee/EmployeeHistory/components/HistoryModal/HistoryModal'
import StopCoverageContainer from './routes/Employees/Employee/CobraCoverage/containers/StopCoverageContainer'
import ExpenseMessageModalContainer from './routes/Expenses/routes/ExpenseRequestsSummary/components/ExpenseMessageModal/ExpenseMessageModalContainer'
import ReceiptItemsModalContainer from './routes/Expenses/routes/ExpenseRequestsSummary/components/ReceiptItemsModal/ReceiptItemsModalContainer'
import TransactionModal from './routes/Employees/Employee/EmployeeCoverage/components/benefitcards/HealthBenefitCard/TabContent/PaymentInformationTab/components/TransactionModal/TransactionModal'

// modal string constants
export const CENSUS_UPLOAD_ERROR_MODAL = 'CENSUS_UPLOAD_ERROR_MODAL'
export const CUSTOM_CONTRIBUTIONS_WARNING = 'CUSTOM_CONTRIBUTIONS_WARNING'
export const DELETE_MODEL = 'DELETE_MODEL'
export const DELETE_SCENARIO = 'DELETE_SCENARIO'
export const EDIT_CONTACT_INFO = 'EDIT_CONTACT_INFO'
export const EDIT_HIXME_INFO = 'EDIT_HIXME_INFO'
export const HISTORY_MODAL = 'HISTORY_MODAL'
export const PROSPECT_CENSUS_ERRORS_MODAL = 'PROSPECT_CENSUS_ERRORS_MODAL'
export const PROSPECT_SCENARIO_SAVE_MODAL = 'PROSPECT_SCENARIO_SAVE_MODAL'
export const STOP_COVERAGE_MODAL = 'STOP_COVERAGE_MODAL'
export const EXPENSE_MESSAGE_MODAL = 'EXPENSE_MESSAGE_MODAL'
export const RECEIPT_LINE_ITEMS_MODAL = 'RECEIPT_LINE_ITEMS_MODAL'
export const VCN_TRANSACTIONS = 'VCN_TRANSACTIONS'

export const modals = {
  [CENSUS_UPLOAD_ERROR_MODAL]: CensusErrorsModalContainer,
  [EDIT_CONTACT_INFO]: EditContactInfoContainer,
  [EDIT_HIXME_INFO]: EditHixmeInfoContainer,
  [HISTORY_MODAL]: HistoryModal,
  [STOP_COVERAGE_MODAL]: StopCoverageContainer,
  [EXPENSE_MESSAGE_MODAL]: ExpenseMessageModalContainer,
  [RECEIPT_LINE_ITEMS_MODAL]: ReceiptItemsModalContainer,
  [VCN_TRANSACTIONS]: TransactionModal,
}
