import React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Stack, Typography } from '@sureco/design-system'
import { useWatch } from 'react-hook-form'

import RHFSelect from 'enrollment-platform/components/form/hookform/Select/RHFSelect'
import RHFInput from 'enrollment-platform/components/form/hookform/Input/RHFInput'
import { ContactType } from '../enums/contact.type'
import RHFToggle from 'enrollment-platform/components/form/hookform/Toogle/RHFToogle'
import RHFMobileInput from 'enrollment-platform/components/form/hookform/MobileInput/RHFMobileInput'

type ContactFormProps = {
  control: any
  name: string
  onRemove?: () => void
  contacts: any[]
}

export default function ContactForm({
  name,
  control,
  onRemove,
  contacts,
}: ContactFormProps) {
  const { isPrimary } = useWatch({
    control,
    name,
  })
 const hasPrimary = contacts?.some(contact => contact.isPrimary)
  return (
    <Stack
      space={10}
      direction="row"
      borderRadius={8}
      padding={20}
      style={{
        alignItems: 'end',
        position: 'relative',
        display: 'grid',
        background: '#E0EAF1',
        gridTemplateColumns: 'repeat(6, auto)',
      }}
    >
      <Stack>
        <Typography size={14}>Contact Name</Typography>
        <RHFInput control={control} name={`${name}.fullName`} />
      </Stack>
      <Stack>
        <Typography size={14}>Department</Typography>
        <RHFSelect
          control={control}
          name={`${name}.type`}
          placeholder="Type"
          options={[
            { label: 'HR', value: ContactType.HR },
            { label: 'Billing', value: ContactType.BILLING },
            { label: 'Sureco', value: ContactType.SURECO },
            { label: 'Broker', value: ContactType.BROKER },
          ]}
        />
      </Stack>
      <Stack>
        <Typography size={14}>Title</Typography>
        <RHFInput control={control} name={`${name}.title`} />
      </Stack>
      <Stack>
        <Typography size={14}>Phone</Typography>
        <RHFMobileInput control={control} name={`${name}.phone.number`} />
      </Stack>
      <Stack>
        <Typography size={14}>Email</Typography>
        <RHFInput control={control} name={`${name}.email`} />
      </Stack>

      <Stack style={{ display: 'inline-flex' }}>
        <CloseOutlined
          onClick={onRemove}
          style={{
            fontSize: '20px',
            color: '#999',
            position: 'relative',
            bottom: '5px',
          }}
        />
      </Stack>
      <Stack direction="row" space={10}>
        <Typography size={14}>Set as Primary</Typography>
        <RHFToggle
          control={control}
          name={`${name}.isPrimary`}
          disabled={hasPrimary && !isPrimary}
        />
      </Stack>
      <Stack direction="row" space={10}>
        <Typography size={14}>Funding Report Distribution</Typography>
        <RHFToggle
          control={control}
          name={`${name}.fundingReportDistribution`}
        />
      </Stack>
    </Stack>
  )
}
