import React, { useEffect, useMemo, useState } from 'react'
import { Layout } from '@surecompanies/core_components'
import { Button, Stack, Typography } from '@sureco/design-system'
import Page from 'layouts/Page'
import PageWrapper from '../../../PageWrapper'
import BreadcrumbNav from 'enrollment-platform/components/Breadcrumbs'
import { createCensusFile, getCensusFiles } from '../api/census-enrollment-platform-api-client'
import { CensusFilesResponseDto } from '../api/dto/census-files-response.dto'
import dayjs from 'dayjs'
import { message } from 'antd'
import { SimpleCustomerDto } from '../../../api/persons/dto/simple-customer.dto'
import { getMyClients } from 'enrollment-platform/api/persons'
import CensusManagementUploadModal from '../components/CensusManagementUploadModal'
import { CensusFileRowTypeEnum } from '../api/enums/census-file-row-type.enum'
import { withRouter } from 'react-router'

const { Content } = Layout

function ManageRecords({ router }) {
  const [imports, setImports] = useState<CensusFilesResponseDto>()
  const [isModalVisible, setIsModalVisible] = useState(false) // State to control modal visibility
  const clickView = (publicKey: string) => {
    router.push(`/v2-census-management/${publicKey}/${CensusFileRowTypeEnum.ERROR}`)
  }
  const fetchData = async () => {
    const data = await getCensusFiles()
    setImports(data)
  }

  const [clients, setClients] = useState<SimpleCustomerDto[]>([])
  const fetchClients = async () => {
    const data = await getMyClients()
    const sortedClients = data.sort((a, b) => a.name.localeCompare(b.name))
    setClients(sortedClients)
  }

  const clientNameLookup = useMemo(() => {
    return clients.reduce((acc, client) => {
      acc[client.id] = client.name
      return acc
    }, {})
  }, [clients]) // Rebuild lookup only if clients change

  const clientName = (key) => {
    return clientNameLookup[key] ?? 'loading...'
  }

  useEffect(() => {
    fetchData()
    fetchClients()
  }, [])

  /* ToDo: pagination
  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page)
    if (pageSize) {
      setPageSize(pageSize)
    }
  }
   */
  const uploadDateTimeString = (udt: string) => {
    // ToDo - > 1 day ago, just show date/time
    const pastDate = dayjs.utc(udt).local()
    const now = dayjs()
    const lastModified = pastDate.from(now) // Relative time (e.g., "2 days ago")
    const timeString = pastDate.format('h:mm A') // Format time as "hh:mm AM/PM"
    return `${lastModified} at ${timeString}`
  }

  const handleUpload = async (formData: FormData) => {
    try {
      await createCensusFile(formData)
      message.success('File uploaded successfully!')
      setIsModalVisible(false)
      fetchData()
    } catch (error) {
      const msg = (error as any)?.response?.data?.message ?? error
      message.error(`Failed to upload file: ${msg}`)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <PageWrapper>
      <Page title="Manage Records"
            action={(
              <Button onClick={() => setIsModalVisible(true)}>
                Upload CSV
              </Button>
            )}
            breadcrumb={<BreadcrumbNav
              items={[
                { title: 'Census Management' },
              ]}
            />}
      >
        <CensusManagementUploadModal
          open={isModalVisible}
          onOk={handleUpload}
          onCancel={handleCancel}
        />

        <Layout style={{ backgroundColor: '#f6f9fc' }}>
          <Content style={{ margin: '16px' }}>
            <Typography as={'h2'} size={24}>Drafts <Typography size={24} as={'span'}
                                                               style={{ color: '#b0b0b0' }}>({imports?.meta.total})</Typography></Typography>
            {/* Table Headings */}
            <Stack
              direction="row"
              space={16}
              style={{
                marginBottom: '16px',
                padding: '16px',
                borderRadius: '8px',
              }}
            >
              <Typography
                as={'h2'}
                size={22}
                bold
                style={{ flex: 1, margin: 0, textAlign: 'left', opacity: '0.45' }}
              >
                Uploaded
              </Typography>
              <Typography
                as={'h2'}
                size={22}
                bold
                style={{ flex: 2, margin: 0, textAlign: 'left', opacity: '0.45' }}
              >
                Details
              </Typography>
              <div style={{ flex: 1, textAlign: 'right' }}></div>
            </Stack>
            {/* White Bars */}
            <Stack direction="col" space={16} style={{ width: '100%' }}>
              {imports?.data.map(({ rowCount, fileName, clientPublicKey, createdAt: uploadDateTime, publicKey }, _index) => (
                <Stack
                  key={publicKey}
                  direction={'row'}
                  space={16}
                  style={{
                    backgroundColor: '#fff',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    alignItems: 'center',
                  }}
                >
                  {/* Left Column */}
                  <Stack style={{ flex: 1, textAlign: 'left' }}>
                    <Typography size={16} style={{ marginBottom: '8px' }}>
                      {uploadDateTimeString(uploadDateTime)}
                    </Typography>
                  </Stack>

                  <Stack direction={'row'} style={{ flex: 2, textAlign: 'left' }}>
                    <Typography bold size={16} style={{ marginBottom: '8px' }}>
                      {clientName(clientPublicKey)} <Typography size={16} as={'span'}
                                               style={{ color: '#b0b0b0' }}>({rowCount} Records) from
                      file {fileName}</Typography>

                    </Typography>
                  </Stack>
                  <Stack direction={'row'} justify="flex-end" align="right" style={{ flex: 1 }}>
                    <Button onClick={() => clickView(publicKey)} style={{ marginLeft: 'auto' }}>View</Button>
                  </Stack>
                </Stack>
              ))}
            </Stack>
            {/* Add Pagination */}
          </Content>
        </Layout>
      </Page>
    </PageWrapper>
  )
}

export default withRouter(ManageRecords)
