import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

// hixme-ui
import Currency from '@hixme-ui/currency'
import Text from '@hixme-ui/text'

const propTypes = {
  created_on: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  transaction_amount: PropTypes.number.isRequired,
}

const TransactionRow = ({
  created_on: date,
  transaction_amount: amount,
  type,
  status,
}) => (
  <tr>
    <td style={{ textAlign: 'left' }}>
      <div style={{ padding: '10px' }}>
        <Text thin>
          {moment(date)
            .local()
            .format('YYYY-MM-DD hh:mm A')}
        </Text>
      </div>
    </td>
    <td>
      <Currency>{amount}</Currency>
    </td>
    <td>
      <Text thin>{status}</Text>
    </td>

    <td>
      <Text thin>{type}</Text>
    </td>
  </tr>
)

TransactionRow.propTypes = propTypes

export default TransactionRow
