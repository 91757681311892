import React from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { ContributionSchema } from './contribution.schema'
import { EmploymentTypeEnum } from './employment.type.enum'
import { StateProvinceEnum } from './state.enum'
import { RelationshipEnum, RelationshipOptions } from './relationship.enum'
import { Stack } from '@sureco/design-system'
import RHFSelect from 'enrollment-platform/components/form/hookform/Select/RHFSelect'
import RHFInput from 'enrollment-platform/components/form/hookform/Input/RHFInput'
import { Button } from '@surecompanies/core_components'

type FormData = z.infer<typeof ContributionSchema>

export default function ContributionForm({
  onFormSubmit,
  isLoading,
}: {
  onFormSubmit?: (data: any) => Promise<any>
  isLoading?: boolean
}) {
  const { handleSubmit, control } = useForm<FormData>({
    defaultValues: {
      employmentStatus: EmploymentTypeEnum.FULL_TIME,
      age: 20,
      group: 'Default',
      exempt: 'Exempt',
      ratingArea: 1,
      stateCode: StateProvinceEnum.AZ,
      relationship: RelationshipEnum.EMPLOYEE,
    },
    resolver: zodResolver(ContributionSchema),
  })
  return (
    <Stack space={20}>
      <Stack
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10 }}
      >
        <RHFSelect
          control={control}
          name="employmentStatus"
          label="Employment"
          options={Object.values(EmploymentTypeEnum).map((type) => ({
            label: type,
            value: type,
          }))}
        />
        <RHFSelect
          control={control}
          name="exempt"
          label="Exempt"
          options={[
            { label: 'Exempt', value: 'Exempt' },
            { label: 'Non-Exempt', value: 'Non-Exempt' },
          ]}
        />
        <RHFInput control={control} name="group" label="Group" />
        <RHFInput
          control={control}
          name="age"
          label="Age"
          type="number"
          min={0}
          max={100}
        />
        <RHFSelect
          control={control}
          name="stateCode"
          label="State"
          options={Object.values(StateProvinceEnum).map((type) => ({
            label: type,
            value: type,
          }))}
        />
        <RHFSelect
          control={control}
          name="ratingArea"
          label="Rating Area"
          options={Array.from({ length: 20 }, (_, i) => ({
            label: i + 1,
            value: i + 1,
          }))}
        />
      </Stack>
      <RHFSelect
        control={control}
        name="relationship"
        label="Relationship"
        options={RelationshipOptions}
      />
      <Button
        type="primary"
        onClick={handleSubmit((data: any) => onFormSubmit?.(data))}
        loading={isLoading}
      >
        Preview Employer Contribution
      </Button>
    </Stack>
  )
}
