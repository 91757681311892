import React from 'react'
import { connectWaiter } from 'redux-waiter'

// hixme-ui
import Container from '@hixme-ui/container'
import Title from '@hixme-ui/title'

// module
import { getAllCensus } from '../actions'
import { GET_ALL_CENSUS } from '../constants'

export default (InnerComponent) =>
  connectWaiter({
    name: GET_ALL_CENSUS,
    requestCreator: (params, dispatch) => dispatch(getAllCensus()),
    requestOnMount: true,
    pendingView: () => (
      <Container textCenter>
        <Title>Loading...</Title>
      </Container>
    ),
  })(InnerComponent)
