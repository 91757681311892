import { actions as companyActions } from 'store/modules/companies'
import notification from 'modules/app-core/notification-manager'
import { browserHistory } from 'react-router'
import * as selectors from './selectors'

// module
import t from './actionTypes.js'

export const userInitialized = () => ({
  type: t.USER_INITIALIZED,
})

export const setCurrentCompany = (companyId) => ({
  type: t.SET_CURRENT_COMPANY,
  payload: companyId,
})

export const setQle = (qleId) => ({
  type: t.SET_QLE,
  payload: qleId,
})

export const initializeUser = () => (dispatch, getState) => {
  dispatch(companyActions.resetCompanyList())

  return dispatch(companyActions.getAllCompanies())
    .then(async (companies) => {
      dispatch(userInitialized())
      const clients = companies

      if (clients.length > 0) {
        const currentCompanyId = selectors.getCurrentCompanyId(getState())

        const foundCompany = clients.find(
          (company) => company.id === currentCompanyId
        )

        if (!currentCompanyId || !foundCompany) {
          dispatch(setCurrentCompany(clients[0].id))
        }
      } else {
        dispatch(
          notification.actions.createNotification({
            type: 'error',
            message: "You don't have access to any companies.",
          })
        )
        browserHistory.push('/session-error')
      }
    })
    .catch(() => {
      dispatch(
        notification.actions.createNotification({
          type: 'error',
          message:
            'We had trouble loading your session. Please reload the page',
        })
      )

      browserHistory.push('/session-error')
    })
}
