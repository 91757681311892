import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getWaiter } from 'redux-waiter'
import { withRouter } from 'react-router'

import { actions, selectors } from '../'
import { WAITER_GET_EMPLOYEE_BENEFITS } from '../constants'

const mapStateToProps = (state, props) => {
  const employeePublicKeyParam = props.params.id
  const benefitList = selectors.getBenefits(state)
  const gettingBenefitList = getWaiter(
    state,
    `${WAITER_GET_EMPLOYEE_BENEFITS}${employeePublicKeyParam}`
  ).isPending
  return {
    benefitList,
    gettingBenefitList,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBenefits: (EmployeePublicKey, EffectiveOn) => {
    dispatch(actions.getEmployeeBenefits(EmployeePublicKey, EffectiveOn))
  },
  clearAll: () => {
    dispatch(actions.clearAll())
  },
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)
