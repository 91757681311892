import React, { useState } from 'react'
import { Modal, DatePicker, Row, Col, Typography, Space, message } from 'antd'
import { Button } from '@sureco/design-system'
import dayjs from 'dayjs'
import { CensusFileRowGridData } from '../../types/census-file-row-grid-data'

const { Title, Text } = Typography

interface BulkEditDatesModalProps {
  visible: boolean
  selectedRows: CensusFileRowGridData[]
  onCancel: () => void
  bulkUpdateConfirm: (params: {
    benefitsEffectiveDate: string | null
    enrollmentStartDate: string | null
    enrollmentEndDate: string | null
    selectedRows: CensusFileRowGridData[]
  }) => void
}

const BulkEditDatesModal = ({
    visible,
    selectedRows,
    onCancel,
    bulkUpdateConfirm,
  }: BulkEditDatesModalProps) => {
  const [benefitsEffectiveDate, setBenefitsEffectiveDate] = useState<string | null>(null)
  const [enrollmentStartDate, setEnrollmentStartDate] = useState<string | null>(null)
  const [enrollmentEndDate, setEnrollmentEndDate] = useState<string | null>(null)

  const resetState = (): void => {
    setBenefitsEffectiveDate(null)
    setEnrollmentEndDate(null)
    setEnrollmentStartDate(null)
  }

  const handleConfirm = () => {
    if (
      enrollmentStartDate &&
      enrollmentEndDate &&
      dayjs(enrollmentEndDate).isBefore(dayjs(enrollmentStartDate))
    ) {
      message.error('Enrollment end date must be after start date.')
      return
    }

    if (
      benefitsEffectiveDate &&
      enrollmentEndDate &&
      dayjs(benefitsEffectiveDate).isBefore(dayjs(enrollmentEndDate))
    ) {
      message.error('Benefit effective date must be after enrollment end date.')
      return
    }

    bulkUpdateConfirm({
      benefitsEffectiveDate,
      enrollmentStartDate,
      enrollmentEndDate,
      selectedRows,
    })
    resetState()
  }

  const pluralize = (count: number, singular: string, plural: string) =>
    `${count} ${count === 1 ? singular : plural}`

  return (
    <Modal
      open={visible}
      onCancel={() => { resetState(); onCancel() }}
      footer={null}
      title={<Title level={4}>Edit Dates</Title>}
      width={600}
    >
      <div
        style={{
          backgroundColor: '#e6f7ff',
          padding: '12px 16px',
          borderRadius: 4,
          marginBottom: 24,
        }}
      >
        <Text>
          Bulk editing for{' '}
          <strong>{pluralize(selectedRows.length, 'employee', 'employees')}</strong>
        </Text>
      </div>

      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <div>
          <Text strong>Benefit effective date</Text>
          <DatePicker
            style={{ width: '100%' }}
            value={benefitsEffectiveDate ? dayjs(benefitsEffectiveDate) : null}
            onChange={(date) => setBenefitsEffectiveDate(date ? date.format('YYYY-MM-DD') : null)}
            disabledDate={(current) => {
              const tooEarly = enrollmentEndDate && current.isBefore(dayjs(enrollmentEndDate))
              const tooEarly2 = enrollmentStartDate && current.isBefore(dayjs(enrollmentStartDate))
              return Boolean(tooEarly2 || tooEarly)
            }}
          />
        </div>

        <div>
          <Text strong>Enrollment</Text>
          <Row gutter={16}>
            <Col span={12}>
              <Text>Start date</Text>
              <DatePicker
                style={{ width: '100%' }}
                value={enrollmentStartDate ? dayjs(enrollmentStartDate) : null}
                onChange={(date) => setEnrollmentStartDate(date ? date.format('YYYY-MM-DD') : null)}
                disabledDate={(current) => {
                  const tooLate = enrollmentEndDate && current.isAfter(dayjs(enrollmentEndDate))
                  const tooLate2 = benefitsEffectiveDate && current.isAfter(dayjs(benefitsEffectiveDate))
                  return Boolean(tooLate2 || tooLate)
                }}
              />
            </Col>
            <Col span={12}>
              <Text>End date</Text>
              <DatePicker
                style={{ width: '100%' }}
                value={enrollmentEndDate ? dayjs(enrollmentEndDate) : null}
                onChange={(date) => setEnrollmentEndDate(date ? date.format('YYYY-MM-DD') : null)}
                disabledDate={(current) => {
                  const tooEarly = enrollmentStartDate && current.isBefore(dayjs(enrollmentStartDate))
                  const tooLate = benefitsEffectiveDate && current.isAfter(dayjs(benefitsEffectiveDate))
                  return Boolean(tooEarly || tooLate)
                }}
              />
            </Col>
          </Row>
        </div>

        <Row justify="end" gutter={8}>
          <Col>
            <Button variant="secondary" onClick={() => { resetState(); onCancel() }}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button variant="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </Col>
        </Row>
      </Space>
    </Modal>
  )
}

export default BulkEditDatesModal
