import React from 'react'
import { Link } from 'react-router'
import {
  Breadcrumb as AntdBreadcrumb,
  BreadcrumbProps,
  Typography,
} from '@surecompanies/core_components'
import { RouterContextProvider, useRouterContext } from 'components/RouterContext'

export default function Breadcrumb(props: BreadcrumbProps) {
return <RouterContextProvider>
            <BreadcrumbComponent {...props}/>
        </RouterContextProvider>
}

function ItemRender(currentRoute) {
    const { routes } = useRouterContext()
    const availablesRoutes = routes?.filter(route => route.path)
    const currentRouteIndex = availablesRoutes.findIndex(route => route.path === currentRoute.path)
    const isLast = currentRouteIndex === availablesRoutes.length - 1
    return isLast ? (
        <Typography.Link strong disabled>{currentRoute.title}</Typography.Link>
      ) : (
        <Link to={currentRoute.path}>{currentRoute.title}</Link>
      )
}

function BreadcrumbComponent({ items, itemRender, ...props } : BreadcrumbProps) {
    const { routes } = useRouterContext()
    const availablesRoutes = routes?.filter(route => route.path)
    const breadcrumbRoutes = availablesRoutes.map(route => ({
        path: route.path,
        title: route.title
    }))
    const collections = items ?? breadcrumbRoutes
    return <AntdBreadcrumb items={collections} itemRender={itemRender ?? ItemRender} {...props}/>
}
