import React from 'react'

// hixme-ui
import Container from '@hixme-ui/container'
import Label from '@hixme-ui/label'
import Icon from '@hixme-ui/icon'
import Text from '@hixme-ui/text'
import RadioButton from '@hixme-ui/radio-button'

// project
import { hasError } from 'modules/redux-form-utils'

export type RadioOptionProps = {
    options: { label: string, value: string }[]
    [key: string]: any
}

export const RadioOption = (props: RadioOptionProps) => {
    const { input, meta, label, tooltipId, Tooltip = null, options, ...rest } = props

    return (
        <Container noPadding>
            <Label>{label}</Label>
            {tooltipId && (
                <span data-tip data-for={tooltipId} style={{ cursor: 'pointer', marginLeft: '4px' }}>
                    <Icon info light size={1.3} />
                </span>
            )}

            <Container flexRow padding="20px 0" width="100%" style={{ gap: 16 }}>
                {options.map((option) =>
                (
                    <RadioButton
                        key={`${input.name}-radio-${option.label}`}
                        {...input}
                        {...rest}
                        text={option.label}
                        value={option.value}
                        checked={input.value === option.value}
                    />
                )
                )}
            </Container>
            {hasError(meta) && (
                <Text error style={{ marginRight: '10px' }}>
                    {meta.error}
                </Text>
            )}
            {Tooltip && <Tooltip tooltipId={tooltipId} />}
        </Container>
    )
}
