import { connect } from 'react-redux'

import YearlyAllowanceBar from 'components/YearlyAllowanceBar'
// module
import { selectors as expenseSelectors } from 'routes/Expenses/modules/expense-requests-search'
import { getAllowanceData } from 'components/ExpenseCard/utils/expense'

const mapStateToProps = (state) => {
  const expenseRequest = expenseSelectors.getExpense(state)
  const header = getAllowanceData(expenseRequest, false)
  return {
    header,
    collapsible: false,
  }
}

export default connect(mapStateToProps, null)(YearlyAllowanceBar)
