/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect'
import { NAME } from './constants'

export const getBenefitsEffectiveDates = createSelector(
  (state) => state[NAME],
  (beds) => beds
)

// export const getCountyHashSelector = createSelector(
//   state => state[NAME],
//   benefitsEffectiveDates => {
//     let hash = {}
//     benefitsEffectiveDates.list.forEach(loc => { hash[loc.County] = loc })
//     return hash
//   }
// )
