import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const Notifications = ({ list }) => (
  <Container padding="20px 0" flex direction="column">
    {isEmpty(list) && <Text>Nothing in here</Text>}
    {list &&
      list.map((notification) => (
        <Text key={notification}>{notification}</Text>
      ))}
  </Container>
)

Notifications.propTypes = {
  list: PropTypes.array,
}

Notifications.defaultProps = {
  list: [],
}

export default Notifications
