import { callWaiter } from 'redux-waiter'
import { reimbursementService } from '@hixme/api'
import {
  GET_EXPENSE_RECEIPTS_NAME,
  GET_EXPENSE_REQUESTS_NAME,
  GET_EXPENSE_REQUEST_NAME,
  SEND_EXPENSE_MESSAGE,
} from './constants'

export const getExpenseRequestsData = (params) => (dispatch) =>
  dispatch(
    callWaiter(GET_EXPENSE_REQUESTS_NAME, {
      requestCreator: () =>
        reimbursementService({
          route: 'reimbursements/admin',
          method: 'GET',
          params,
        }),
    })
  )

export const getExpenseRequestData = (id) => (dispatch) =>
  dispatch(
    callWaiter(GET_EXPENSE_REQUEST_NAME, {
      requestCreator: () =>
        reimbursementService({
          route: 'reimbursements/admin/reimbursementId/{id}',
          method: 'GET',
          params: {
            id,
          },
        }),
    })
  )

export const getReceiptsByReimbursement = (reimbursementPublicKey) => (
  dispatch
) =>
  dispatch(
    callWaiter(`${GET_EXPENSE_RECEIPTS_NAME}-${reimbursementPublicKey}`, {
      requestCreator: () =>
        reimbursementService({
          route: 'reimbursements/{reimbursementPublicKey}/receipts',
          method: 'GET',
          params: {
            reimbursementPublicKey,
          },
        }),
    })
  )

export const sendReimbursementMessage = (body, params) => (dispatch) =>
  dispatch(
    callWaiter(SEND_EXPENSE_MESSAGE, {
      requestCreator: () =>
        reimbursementService({
          route: 'reimbursements/admin/{reimbursementId}/messages',
          method: 'POST',
          body,
          params,
        }),
    })
  )
