import React from 'react'

import classes from './SearchTable.module.scss'

export type SearchTableProps = Omit<
  React.HTMLAttributes<HTMLTableElement>,
  'className'
>

const SearchTable = ({ children, ...restProps }: SearchTableProps) => (
  <table className={classes.default} {...restProps}>
    {children}
  </table>
)

SearchTable.displayName = 'SearchTable'

export default SearchTable
