const applicationstatus = [
  'Not Started',
  'App Submitted',
  'App Completed',
  'Application Needs Attention',
]

export default applicationstatus.map((item) => ({
  id: item,
  name: item,
}))
