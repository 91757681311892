import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'

// hixme-ui
import BenefitPrice from '@hixme-ui/benefit-price'
import BulletList from '@hixme-ui/bullet-list'
import Card from '@hixme-ui/card'
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'
import Title from '@hixme-ui/title'

import { constants } from 'modules/benefits'
import { Badge } from '../Badge'

import BulletItem from './components/BulletItem/BulletItem'
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard'
import VCNBadgeContainer from '../../routes/Employees/Employee/EmployeeCoverage/modules/employee-benefits/containers/VCNBadgeContainer'

const BenefitCard = ({
  children,
  title,
  subtitle,
  price,
  payCycle,
  list,
  outOfNetworkList,
  badges,
  benefitWindow,
  id,
}) => (
  <Card margin="30px 0" padding="30px" animate heavyShadow>
    <Container
      flex
      noPadding
      justifyContent="space-between"
      margin="0 0 20px 0"
    >
      <Container flex noPadding direction="column" width="100%">
        <Title margin="0 0 10px 0" smaller>
          {title}
        </Title>
        <Text style={{ margin: '0 0 5px 0' }} light>
          {subtitle}
        </Text>
        {list && outOfNetworkList ? (
          <div>
            <Text p primary>
              In Network
            </Text>
            <BulletList
              bulletArray={list.map((item) => (
                <BulletItem id={uuid()} item={item} />
              ))}
            />
            <Text p primary>
              Out Of Network
            </Text>
            <BulletList
              bulletArray={outOfNetworkList.map((item) => (
                <BulletItem id={uuid()} item={item} />
              ))}
            />
          </div>
        ) : (
          <BulletList
            bulletArray={list.map((item) => (
              <BulletItem id={uuid()} item={item} />
            ))}
          />
        )}
      </Container>
      <Container flex noPadding width="100%">
        {badges.length > 0 &&
          badges.map((badge) => (
            <Container flex noPadding key={uuid()} marginRight="5px">
              <Badge
                line1={badge.line1}
                line2={badge.line2}
                color={badge.color}
                color2={badge.color2 || badge.color}
              />
            </Container>
          ))}
        <Container flex noPadding key={uuid()} marginRight="5px">
          <VCNBadgeContainer benefitId={id} />
        </Container>
      </Container>
      <Container flex noPadding flexColumn alignCenter>
        <BenefitPrice payCycle={payCycle} value={price} />
        {benefitWindow === constants.BENEFIT_EFFECTIVE_WINDOW_UPCOMING && (
          <Text jumbo blue bold>
            UPCOMING
          </Text>
        )}
        {benefitWindow === constants.BENEFIT_EFFECTIVE_WINDOW_AFTER && (
          <Text jumbo red bold>
            ENDED
          </Text>
        )}
      </Container>
    </Container>
    {children}
    <Container
      style={{ float: 'right' }}
      noPadding
      textAlign="right"
      marginTop="5px"
    >
      <CopyToClipboard text={id}>
        <Text small lighter>
          {id}
        </Text>
      </CopyToClipboard>
    </Container>
  </Card>
)

BenefitCard.displayName = 'BenefitCard'

BenefitCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  payCycle: PropTypes.number,

  subtitle: PropTypes.string,
  price: PropTypes.number,
  list: PropTypes.arrayOf(PropTypes.any),
  outOfNetworkList: PropTypes.arrayOf(PropTypes.any),
  badges: PropTypes.arrayOf(PropTypes.object),
  benefitWindow: PropTypes.string,
  id: PropTypes.string,
}

BenefitCard.defaultProps = {
  payCycle: 12,
  subtitle: null,
  price: null,
  list: [],
  badges: [],
  outOfNetworkList: null,
  benefitWindow: null,
  id: null,
}

export default BenefitCard
