import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

// hixme tools
import adminGateway from '@hixme/admin-api-gateway'
import {
  AuthenticatedComponent,
  selectors as authSelectors,
  logout,
} from '@hixme/auth-ui'
import {
  ROLE_BROKER,
  ROLE_PLATFORM_HIXME_ADMIN,
  ROLE_CLIENT_ADMIN,
  ROLE_HIXME_SALES_REP,
} from '@hixme/role-policy'
import { setGateway } from 'modules/redux-gateway'
import appConfig from 'modules/app-config'

// project
import { selectors as companySelectors } from 'store/modules/companies'
import { actions as layoutActions } from 'modules/layout'
import userSession from 'store/modules/user-session'
import modal from 'modules/app-core/modal'

import CoreLayout from '../CoreLayout/CoreLayout'

const { JodalContainer } = modal.containers

class AuthenticatedRouteContainer extends Component {
  componentWillMount() {
    const { dispatch } = this.props
    // TODO: remove this when the API can hinder logins for other roles
    // Redirect user to login if not a hixme admin
    if (
      ![
        ROLE_PLATFORM_HIXME_ADMIN,
        ROLE_CLIENT_ADMIN,
        ROLE_HIXME_SALES_REP,
        ROLE_BROKER,
      ].includes(this.props.credentials.userRole)
    ) {
      this.props.dispatch(this.props.logout())
      window.location = '/login'
    }

    // turn off session until user roles are defined
    this.initializeAPIGateway(this.props)
    dispatch(userSession.actions.initializeUser())
  }

  componentWillReceiveProps(props) {
    if (props.token !== this.props.token) {
      this.initializeAPIGateway(props)
    }
  }

  initializeAPIGateway(props) {
    // Setup AWS API Gateway and redux-gateway
    const { credentials } = props
    if (credentials) {
      const gateway = adminGateway.newClient(
        this.props.credentials,
        appConfig.getStage()
      )
      setGateway(gateway)

      const ldClient = this.props.ldClient
      if (ldClient) {
        const identifyData = {
          key: credentials.personPublicKey,
          firstName: credentials.firstname,
          lastName: credentials.lastname,
          custom: {
            role: credentials.userRole,
          },
        }
        ldClient.identify(identifyData)
      }
    }
  }

  render() {
    const { children, ...restProps } = this.props
    return (
      <CoreLayout {...restProps}>
        <div>
          <JodalContainer {...restProps} />
          {children}
        </div>
      </CoreLayout>
    )
  }
}

AuthenticatedRouteContainer.propTypes = {
  children: PropTypes.node.isRequired,
  credentials: PropTypes.object,
  token: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  companies: PropTypes.array,
}

AuthenticatedRouteContainer.defaultProps = {
  credentials: {},
  token: null,
  companies: [],
}

const mapStateToProps = (state) => ({
  companies: companySelectors.companyCollectionSelector(state),
  credentials: authSelectors.getCredentials(state),
  token: authSelectors.getToken(state),
})

export default withLDConsumer()(
  connect(
    mapStateToProps,
    (dispatch) =>
      Object.assign(bindActionCreators(layoutActions, dispatch), {
        dispatch,
        logout,
      })
  )(AuthenticatedComponent(AuthenticatedRouteContainer))
)
