import t from './actionTypes.js'

export const showQuicksightDashboard = (id) => ({
  type: t.SHOW_QUICKSIGHT_DASHBOARD,
  payload: id,
})

export const hideQuicksightDashboard = (id) => ({
  type: t.HIDE_QUICKSIGHT_DASHBOARD,
  payload: id,
})

export default {
  showQuicksightDashboard,
  hideQuicksightDashboard,
}
