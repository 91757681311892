import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

const CardBody = ({ data, slim }) => {
  if (!data) {
    return null
  }
  return (
    <Container noPadding width="100%">
      {data.map((item, idx) => (
        <Container
          key={item.key}
          padding={slim ? '2px 5px' : '10px'}
          white={idx % 2 === 0}
        >
          <Row middle="xs">
            <Col xs>
              <Text color="#8F9DA8">{item.key}</Text>
            </Col>
            <Col xs>
              <Text color="#3A4951">{item.value}</Text>
            </Col>
          </Row>
        </Container>
      ))}
    </Container>
  )
}

CardBody.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })
  ),
  slim: PropTypes.bool.isRequired,
}

CardBody.defaultProps = {
  data: null,
}

export default CardBody
