import React from 'react'
import { connectWaiter } from 'redux-waiter'

import { selectors as userSessionSelectors } from 'store/modules/user-session'

// hixme-ui
import Container from '@hixme-ui/container'
import Title from '@hixme-ui/title'

// module
import { getPersonsFromAllCompanies } from '../actions'
import { GET_ALL_PERSONS_BY_COMPANY_ID } from '../constants'

export default (InnerComponent) =>
  connectWaiter({
    name: GET_ALL_PERSONS_BY_COMPANY_ID,
    requestCreator: (params, dispatch) =>
      dispatch(getPersonsFromAllCompanies()),
    requestOnMountParams: (props) =>
      props.dispatch((dispatch, getState) => {
        const currentCompanyId = userSessionSelectors.getCurrentCompanyId(
          getState()
        )
        return {
          currentCompanyId,
        }
      }),
    pendingView: () => (
      <Container textCenter>
        <Title>Loading...</Title>
      </Container>
    ),
  })(InnerComponent)
