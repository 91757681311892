import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { clearModal } from '@hixme/modal'

// import modal from 'modules/app-core/modal'
import { createRequest, getRequestByName } from 'modules/redux-gateway'
import { actions as companyActions } from 'store/modules/companies'
import { selectors as userSessionSelectors } from 'store/modules/user-session'

import EditContactInfo from '../components/EditContactInfo/EditContactInfo'
import client from '../../../apollo/client'
import { GET_CLIENT_DATA_BY_ID } from '../../../routes/Company/queries/queries'

/* eslint-disable no-param-reassign */

const mapStateToProps = (state) => ({
  initialValues: userSessionSelectors.getCurrentCompany(state),
  isProcessing: getRequestByName(state, 'clientPost').isProcessing,
})
export default connect(mapStateToProps)(
  reduxForm({
    form: 'EditContactInfo',
    onSubmit: (values, dispatch) => {
      Object.keys(values).forEach((key) => {
        if (values[key] === undefined || values[key] === '') {
          delete values[key]
        }
        if (key === 'ContactPhoneNumber') {
          values[key] = values[key].replace(/\D/g, '')
        }
      })

      return dispatch(
        createRequest({
          route: 'clientPost',
          body: values,
        })
      ).then(async (response) => {
        const res = await client.query({
          query: GET_CLIENT_DATA_BY_ID,
          variables: {
            getClientId: response.Id,
          },
        })
        dispatch(companyActions.receiveCompany(res?.data.getClient))
        dispatch(clearModal())
      })
    },
  })(EditContactInfo)
)
