import React, { useEffect } from 'react'
import Page from 'layouts/Page'
import BreadcrumbNav from 'enrollment-platform/components/Breadcrumbs'
import { Button, Stack, Typography } from '@sureco/design-system'
import { useRouterContext } from 'components/RouterContext'
import useServiceAreasDiff from './hooks/useServiceAreasAnalyzeDiff'

export default function AnalyzeServiceAreas({ params }) {
  const importBatchId = params?.importBatchId || 'No importBatchId provided'
  const { mutate: diffMutate, data: diffData, isLoading: diffIsLoading } = useServiceAreasDiff({ importBatchId, batchType: 'service-areas' })
  const { router } = useRouterContext()

  useEffect(() => {
    diffMutate()
  }, [importBatchId])

  const summary = diffData
    ? diffData.reduce(
        (acc, row) => {
          if (row.diffStatus === 'NEW') acc.new += 1
          if (row.diffStatus === 'CHANGED') acc.changed += 1
          if (row.diffStatus === 'DELETED') acc.deleted += 1
          return acc
        },
        { new: 0, changed: 0, deleted: 0 }
      )
    : { new: 0, changed: 0, deleted: 0 }

  return (
    <Page
      title="Analyze"
      breadcrumb={
        <BreadcrumbNav
          items={[
            { title: 'Plan Management', path: '/plans' },
            { title: 'Home' },
          ]}
        />
      }
    >
      <Stack space={20}>
        <Button onClick={() => router.push('/plans')}>Back to Home</Button>
        {/* <Button onClick={importMutate} loading={importIsLoading}>Import Smoker Rates</Button> */}
        {diffIsLoading && <div>Loading...</div>}
        {diffData && (
          <>
            <Typography as="h3" size={20}>
              New: {summary.new}, Changed: {summary.changed}, Deleted: {summary.deleted}
            </Typography>
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>Diff Status</th>
                  <th style={{ textAlign: 'left' }}>PlanServiceAreaID</th>
                  <th style={{ textAlign: 'left' }}>Year</th>
                  <th style={{ textAlign: 'left' }}>State</th>
                  <th style={{ textAlign: 'left' }}>IssuerID</th>
                  <th style={{ textAlign: 'left' }}>SourceName</th>
                  <th style={{ textAlign: 'left' }}>HiosPlanID</th>
                  <th style={{ textAlign: 'left' }}>ServiceAreaID</th>
                  <th style={{ textAlign: 'left' }}>ServiceAreaName</th>
                  <th style={{ textAlign: 'left' }}>CoverEntireState</th>
                  <th style={{ textAlign: 'left' }}>CountyCode</th>
                  <th style={{ textAlign: 'left' }}>PartialCounty</th>
                  <th style={{ textAlign: 'left' }}>Zipcode</th>
                  <th style={{ textAlign: 'left' }}>IsActive</th>
                </tr>
              </thead>
              <tbody>
                {diffData.map((row) => (
                  <tr key={row.planServiceAreaId}>
                    <td>{row.diffStatus}</td>
                    <td>{row.planServiceAreaId}</td>
                    <td>{row.year}</td>
                    <td>{row.state}</td>
                    <td>{row.issuerId}</td>
                    <td>{row.sourceName}</td>
                    <td>{row.hiosPlanId}</td>
                    <td>{row.serviceAreaId}</td>
                    <td>{row.serviceAreaName}</td>
                    <td>{row.coverEntireState}</td>
                    <td>{row.countyCode}</td>
                    <td>{row.partialCounty}</td>
                    <td>{row.zipcode}</td>
                    <td>{row.isActive}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </Stack>
    </Page>
  )
}
