import t from './actionTypes'

export const censusModel = {
  Id: null,
  Census: '',
}

const CensusMap = {
  [t.RECEIVE_CENSUS]: (state, payload) => {
    const { Census, Validation, CensusDate, UploadDate, ...rest } = payload
    const additional = {}
    if (Census !== 'removed') {
      additional.Census = Census
    }
    if (Validation !== 'removed') {
      additional.Validation = Validation
    }

    if (CensusDate) {
      additional.CensusDate = CensusDate.substring(0, 10)
      additional.CensusDateFormatted = additional.CensusDate
    }
    if (UploadDate) {
      additional.UploadDate = UploadDate.substring(0, 10)
      additional.UploadDateFormatted = additional.UploadDate
    }

    return {
      ...state,
      ...rest,
      ...additional,
    }
  },
}

export default (state = censusModel, action) => {
  const reducer = CensusMap[action.type]

  return reducer ? reducer(state, action.payload) : state
}
