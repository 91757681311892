import validator from 'validator'

export default (values) => {
  const errors = {}

  const { ReceiptUpload, Description, Amount, Decision, Reason } = values
  const isDeclined = Decision === '20'

  if (!ReceiptUpload) {
    errors.ReceiptUpload = 'A receipt upload is required'
  }
  if (!Description) {
    errors.Description = 'Description is required'
  }
  if (!Decision) {
    errors.Decision = 'Decision is required'
  }

  if (isDeclined && !Reason) {
    errors.Reason = 'Reason is required'
  }

  if (!Amount && !isDeclined) {
    errors.Amount = 'Amount is required'
  }

  if (Amount && !validator.isCurrency(Amount) && !isDeclined) {
    errors.Amount = 'Invalid Amount'
  }

  return errors
}
