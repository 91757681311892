import React from 'react'
import { Stack, Typography } from '@sureco/design-system'

export default function RowSection({
  title,
  children,
}: {
  title: string
  children?: React.ReactNode
}) {
  return (
    <Stack
      space={10}
      style={{
        borderTop: '1px solid #DDD',
      }}
    >
      <Typography as="h3" color="#003146" size={16} bold>
        {title}
      </Typography>
      {children}
    </Stack>
  )
}
