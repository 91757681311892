// Currency
const onlyMoney = /([1-9]\d*([.]\d{0,2})?|0)/
const onlyOneDecimalValue = /^0*?(0|[1-9]\d*\.?\d{0,1})$/g
const camelCase = /([A-Z]{1}[a-z]*)/g

// finds digits in value that match 00.00 or 0. or 0.0 and tacks on a $, removing non-digits
// ex: '111.00' will result in '$111.00', '111.1' => '$111.1'
// This allows for almost complete currency values.
export const currencyInput = (val) => {
  const matches = onlyMoney.exec(val)
  return `$${matches ? matches[1] : 0}`
}

// normalize currency to an number-like values, '$g11f1' => '111'
// used to normalize bad input (pasted), ex: '12/12/12' => '121212', '$234.2.1' => '234.2.1'
// used in conjunction with currencyInput to figure currency values
export const normalizeCurrency = (value, prev) => {
  if (value === undefined) {
    return prev
  }
  const cleanString = value.replace(/[^\d.]/g, '').replace(/\.+/g, '.')
  const cleanStringSplit = cleanString.split('.')
  const newString =
    cleanStringSplit.length > 2
      ? `${cleanStringSplit[0]}.${cleanStringSplit.slice(1).join('')}`
      : cleanStringSplit.join('.')
  return newString || '0'
}

export const normalizeCurrencyInt = (value, prev) => {
  if (value === undefined) {
    return prev
  }
  const cleanNumber = Number(value.replace(/[^\d]/g, ''))
  return cleanNumber || 0
}

export const normalizeActuarialValue = (value, prev) => {
  if (value === undefined) {
    return prev
  }
  if (value === '') {
    return '0'
  }

  onlyOneDecimalValue.lastIndex = 0
  const matches = onlyOneDecimalValue.exec(value)
  return matches ? matches[1] : prev
}

// Strings

// remove duplicate spaces when only one space is needed between words
// ex: 'hello   you    beast' will result in 'hello you beast'
export const oneSpace = (str) => str.replace(/\s+/g, ' ')

export const spaceCamelCase = (str = '') => {
  const matches = str.match(camelCase)
  return matches.join(' ')
}

export const removeLineBreaks = (value) => value?.replace(/(\r\n|\n|\r)/gm, ' ')

export const boolToYesNo = (value) => {
  if (typeof value !== 'boolean') return null
  return value ? 'Yes' : 'No'
}

export default {
  currencyInput,
  normalizeActuarialValue,
  normalizeCurrency,
  normalizeCurrencyInt,
  oneSpace,
  spaceCamelCase,
}
