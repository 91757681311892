import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import notification from 'modules/app-core/notification-manager'
import * as actions from '../actions'
import { getLocationsListSelectorByPostalCode } from '../selectors'
import { LocationsDropdown } from '../components'

const VALID_POSTALCODE_LENGTH = 5

class LocationsContainer extends Component {
  static propTypes = {
    postalCode: PropTypes.string.isRequired,
    getLocations: PropTypes.func.isRequired,
    createNotification: PropTypes.func.isRequired,
  }

  static defaultProps = {
    list: [],
  }

  constructor(props) {
    super(props)
    this.previousPostalCode = props.postalCode
  }

  componentWillMount() {
    this.props.getLocations(this.props.postalCode)
    this.previousPostalCode = this.props.postalCode
  }

  // Reload county/state list if postal code changes.
  componentWillReceiveProps(nextProps) {
    if (
      this.previousPostalCode !== nextProps.postalCode &&
      nextProps.postalCode.length === VALID_POSTALCODE_LENGTH
    ) {
      this.props.getLocations(nextProps.postalCode).catch(() => {
        this.props.createNotification({
          type: 'error',
          message: 'Error getting updated county/state locations',
        })
      })
      this.previousPostalCode = nextProps.postalCode
    }
  }

  render() {
    return <LocationsDropdown {...this.props} />
  }
}

const mapStateToProps = (state, { postalCode }) => ({
  list: getLocationsListSelectorByPostalCode(postalCode, state),
})

export default connect(
  mapStateToProps,
  {
    ...actions,
    ...notification.actions,
  }
)(LocationsContainer)
