
import ManageRecords from './pages/ManageRecords'
import CensusReview from './CensusReview/pages/CensusReview'
import ReviewNewRecords from './CensusReview/pages/ReviewNewRecords'
import CensusReviewRecordsError from './CensusReview/pages/CensusReviewRecordsError'
import ReviewRecordsError from './pages/ReviewRecordsError'
import ReviewRecordsDraftEmployee from './pages/ReviewRecordsDraftEmployee'
import withAuth from '../../hooks/withAuthorization'
import { ROLE_PLATFORM_HIXME_ADMIN } from '@hixme/role-policy'
import ReviewRecordsUpdateEmployeeBenefits from './pages/ReviewRecordsUpdateBenefits'
import ReviewRecordsUpdateEmployeeNoBenefits from './pages/ReviewRecordsUpdateNoBenefits'
import ReviewRecordsDuplicateEmployee from './pages/ReviewRecordsDuplicate'
import ReviewRecordsActivity from './pages/ReviewRecordsActivity'
import CensusReviewRecordsActivity from './CensusReview/pages/CensusReviewRecordsActivity'

const Manage = withAuth([ROLE_PLATFORM_HIXME_ADMIN])(ManageRecords)
const Error = withAuth([ROLE_PLATFORM_HIXME_ADMIN])(ReviewRecordsError)
const Draft = withAuth([ROLE_PLATFORM_HIXME_ADMIN])(ReviewRecordsDraftEmployee)
const UpdateBenefits = withAuth([ROLE_PLATFORM_HIXME_ADMIN])(ReviewRecordsUpdateEmployeeBenefits)
const UpdateNoBenefits = withAuth([ROLE_PLATFORM_HIXME_ADMIN])(ReviewRecordsUpdateEmployeeNoBenefits)
const Duplicate = withAuth([ROLE_PLATFORM_HIXME_ADMIN])(ReviewRecordsDuplicateEmployee)
const Activity = withAuth([ROLE_PLATFORM_HIXME_ADMIN])(ReviewRecordsActivity)
const NewRecords = withAuth([ROLE_PLATFORM_HIXME_ADMIN])(ReviewNewRecords)
const CensusReviewActivity = withAuth([ROLE_PLATFORM_HIXME_ADMIN])(CensusReviewRecordsActivity)
const CensusReviewError = withAuth([ROLE_PLATFORM_HIXME_ADMIN])(CensusReviewRecordsError)

const CensusManagementRoutes = [
  {
    path: '/v2-census-management',
    component: Manage,
  },
  {
    path: '/v2-census-management/:importPublicKey/ERROR',
    component: Error,
  },
  {
    path: '/v2-census-management/:importPublicKey/DRAFT_EMPLOYEE',
    component: Draft,
  },
  {
    path: '/v2-census-management/:importPublicKey/UPDATE_EMPLOYEE_BENEFITS',
    component: UpdateBenefits,
  },
  {
    path: '/v2-census-management/:importPublicKey/UPDATE_EMPLOYEE_NO_BENEFITS',
    component: UpdateNoBenefits,
  },
  {
    path: '/v2-census-management/:importPublicKey/DUPLICATE_EMPLOYEE',
    component: Duplicate,
  },
  {
    path: '/v2-census-management/:importPublicKey/activity',
    component: Activity,
  },
  {
    path: '/census-review',
    component: CensusReview,
  },
  {
    path: '/census-review/:importPublicKey/DRAFT_EMPLOYEE',
    component: NewRecords,
  },
  {
    path: '/census-review/:importPublicKey/ERROR',
    component: CensusReviewError,
  },
  {
    path: '/census-review/:importPublicKey/activity',
    component: CensusReviewActivity,
  },
]

export default CensusManagementRoutes
