import t from './actionTypes'

// Actions
export const toggleBenefitUploader = () => ({
  type: t.TOGGLE_BENEFIT_UPLOADER,
})

export const closeBenefitUploader = () => ({
  type: t.CLOSE_BENEFIT_UPLOADER,
})
