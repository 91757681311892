import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import style from '../pages/CensusManagement.module.scss'
import { Layout as AdLayout } from '@surecompanies/core_components'
import Page from 'layouts/Page'
import PageWrapper from '../../../PageWrapper'
import BreadcrumbNav from 'enrollment-platform/components/Breadcrumbs'
import FileInfo from '../components/FileInfo'
import ReviewTabs from '../components/ReviewTabs'
import GridWrapper from '../components/GridWrapper'
import { CensusFileDto } from '../api/dto/census-file.dto'
import { SimpleCustomerDto } from '../../../api/persons/dto/simple-customer.dto'
import { getMyClients } from '../../../api/persons'
import { CENSUS_MANAGEMENT_PATH, DEFAULT_ROWS_PAGINATION_LIMIT } from '../constants'
import {
  CellValueChangedEvent,
  ColDef,
  GridReadyEvent,
  IServerSideGetRowsParams,
  SelectionChangedEvent,
} from '@ag-grid-community/core'
import { CensusFileRowTypeEnum } from '../api/enums/census-file-row-type.enum'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'
import { colDefsFromData } from '../utils/col-defs-from-data'
import { CensusFileRowStatusEnum } from '../api/enums/census-file-row-status.enum'
import { SortDirection } from 'enrollment-platform/types/sorted-request.dto'
import { CensusFileRowCountsDto } from '../api/dto/census-file-row-counts.dto'
import { getInProcessPageData } from '../utils/get-in-process-page-data'

const { Content } = AdLayout

interface ReviewRecordsBaseProps {
  tabKey: string
  routeParams: { importPublicKey: string }
  actionButton?: ({ censusRows, handleSubmit, selectedRows }: {
    censusRows: CensusFileRowGridData[],
    selectedRows: CensusFileRowGridData[],
    handleSubmit: () => void
  }) => ReactElement<any>
  rowQueryParams: {
    censusRowType?: string,
    censusFileRowStatuses: CensusFileRowStatusEnum[],
  }
  handleCellValueChanged?: (cellValueChangedEvent: CellValueChangedEvent) => void
  // function called by ReviewRecordsBase to customize base column definitions
  modifyColDefs?: (baseColDefs: ColDef<CensusFileRowGridData>[]) => ColDef<CensusFileRowGridData>[];
}

// If sorting  by a column name that has data in 'rowData', we have to apply a prefix to the field name so that the backend recognizes that the
// field is in 'rowData'
const getSortParams = (params: IServerSideGetRowsParams<CensusFileRowGridData>): [string, SortDirection] => {
  const sortModel = params.request.sortModel

  const { colId, sort: direction } = sortModel[0] // Take the first sort entry
  const colDef = params.api.getColumnDef(colId)
  const prefix = colDef?.['sortPrefix'] ?? ''

  const sort = `${prefix}${colId}`
  const order = direction.toUpperCase() === 'DESC' ? SortDirection.desc : SortDirection.asc
  return [sort, order]
}

function ReviewRecordsBase({
                             routeParams,
                             actionButton,
                             rowQueryParams,
                             handleCellValueChanged,
                             modifyColDefs,
                             tabKey,
                           }: ReviewRecordsBaseProps) {
  const { censusRowType: paramCensusRowType, censusFileRowStatuses } = rowQueryParams
  const [counts, setCounts] = useState<CensusFileRowCountsDto | undefined>()
  const [censusFile, setCensusFile] = useState<CensusFileDto | undefined>()
  const { importPublicKey: publicKey } = routeParams
  const [censusRowType, setCensusRowType] = useState<string | undefined>(paramCensusRowType)
  const [selectedRows, setSelectedRows] = useState<CensusFileRowGridData[]>([])
  const [censusRows, setCensusRows] = useState<CensusFileRowGridData[]>([])
  const gridRef = useRef<GridReadyEvent | undefined>(undefined)
  const [clients, setClients] = useState<SimpleCustomerDto[]>([])
  // State to store dynamically generated column definitions
  const [dynamicColDefs, setDynamicColDefs] = useState<ColDef<CensusFileRowGridData>[]>([])

  // Get client name helper
  const clientName = (key) => clients.find((c) => c.id === key)?.name ?? '...'

  const resetGrid = useCallback(() => {
    gridRef.current?.api.refreshServerSide({ purge: true })
    gridRef.current?.api.deselectAll()
    setSelectedRows([])
  }, [])

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridRef.current = params
  }, [])

  const onTabChange = useCallback((tab: string) => {
    setCensusRowType(tab)
    setSelectedRows([])
    gridRef.current?.api.refreshServerSide({ purge: true })
  }, [])

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api.updateGridOptions({
        serverSideDatasource: { getRows },
      })
    }
  }, [gridRef.current])

  // Function to fetch and set rows, also dynamically generates `colDefs`
  const getRows = async (params: IServerSideGetRowsParams<CensusFileRowGridData>) => {
    const limit = DEFAULT_ROWS_PAGINATION_LIMIT
    let sort: string | undefined
    let order: SortDirection | undefined
    try {
      const { startRow, sortModel } = params.request
      const page = Math.floor((startRow ?? 1) / limit) + 1

      if (sortModel.length > 0) {
        [sort, order] = getSortParams(params)
      }
      const {
        rowCounts,
        data,
        pagination,
        censusFile,
      } = await getInProcessPageData({ limit, page, publicKey, sort, order, censusRowType, censusFileRowStatuses })

      setCounts(rowCounts)
      setCensusFile(censusFile)
      const mergedData: CensusFileRowGridData[] = data.map((d) => ({ ...d, ...d.rowData }))
      setCensusRows(mergedData)

      if (mergedData.length > 0 && dynamicColDefs.length === 0) {
        let newColDefs = colDefsFromData(mergedData[0].rowData)

        // if this is the Error page, order error columns to the front
        if (censusRowType === CensusFileRowTypeEnum.ERROR) {
          const errorKeys = new Set<string>()
          mergedData.forEach(row => {
            if (row.errors) {
              Object.keys(row.errors).forEach((errorKey) => errorKeys.add(errorKey))
            }
          })
          newColDefs = [
            ...newColDefs.filter((col) => errorKeys.has(col.field ?? '')), // Columns with errors first
            ...newColDefs.filter((col) => !errorKeys.has(col.field ?? '')) // Other columns after
          ]
        }

        setDynamicColDefs(newColDefs)
      }

      params.success({
        rowData: mergedData,
        rowCount: pagination.total,
      })
    } catch (error) {
      console.error('Error fetching data:', error)
      params.fail()
    }
  }

  const onCellValueChanged = useCallback((params: CellValueChangedEvent<CensusFileRowGridData>) => {
    handleCellValueChanged && handleCellValueChanged(params)
  }, [])

  const fetchClients = async () => {
    const data = await getMyClients()
    setClients(data.sort((a, b) => a.name.localeCompare(b.name)))
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchClients()
    }, 1)
    return () => clearTimeout(timer)
  }, [])

  // Apply `modifyColDefs` function if provided
  const finalColDefs = modifyColDefs ? modifyColDefs(dynamicColDefs) : dynamicColDefs

  // keep track of rows selected in grid
  const onSelectionChanged = useCallback((event: SelectionChangedEvent<CensusFileRowGridData>) => {
    setTimeout(() => {
      const selectedRows: CensusFileRowGridData[] = []

      event.api.forEachNode((node) => {
        if (node.isSelected() && node.data) {
          selectedRows.push(node.data)
        }
      })

      setSelectedRows(selectedRows)
    }, 0)
  }, [])

  return (
    <PageWrapper>
      <Page
        title="Review Records"
        action={actionButton && actionButton({
          censusRows,
          selectedRows,
          handleSubmit: resetGrid,
        })}
        breadcrumb={
          <BreadcrumbNav
            items={[
              { title: 'Census Management', path: CENSUS_MANAGEMENT_PATH },
              { title: 'Review Records' },
            ]}
          />
        }
      >
        <AdLayout>
          <Content className={style.content}>
            <FileInfo data={censusFile} clientName={clientName(censusFile?.clientPublicKey)} style={{ padding: '15px 0 0 15px' }} />
            <ReviewTabs
              publicKey={publicKey}
              counts={counts}
              onTabChange={onTabChange}
              currentTab={tabKey}
              style={{ padding: '15px 0 0 15px' }}
            />
            <GridWrapper
              onSelectionChanged={onSelectionChanged}
              columnDefs={finalColDefs}
              onGridReady={onGridReady}
              rowSelection="multiple"
              onCellValueChanged={onCellValueChanged}
            />
          </Content>
        </AdLayout>
      </Page>
    </PageWrapper>
  )
}

export default ReviewRecordsBase
