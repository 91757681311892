import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import Text from '@hixme-ui/text'

// module
import ExpenseCardTabContainer from '../../ExpenseCardTabContainer'
import AttachedFile from '../AttachedFile/AttachedFile'

const Receipts = ({ receipts }) => (
  <ExpenseCardTabContainer>
    <Container noPadding flex direction="column">
      <Container noPadding flex direction="column">
        {receipts &&
          receipts.map((file) => (
            <Container
              padding="10px 0"
              flex
              flexWrap
              alignItems="center"
              justifySpaceBetween
              key={file.name}
            >
              <Container
                flex
                noPadding
                alignItems="center"
                style={{ flexBasis: '25%' }}
              >
                <AttachedFile
                  text={file.name}
                  onClick={() => window.open(file.url, '_blank')}
                />
              </Container>
              <Text center style={{ flexBasis: '25%' }}>
                {file.description}
              </Text>
              <Text center style={{ flexBasis: '25%' }}>
                {file.name}
              </Text>
              <Text center style={{ flexBasis: '25%' }}>
                ${file.amount}
              </Text>
            </Container>
          ))}
      </Container>
    </Container>
  </ExpenseCardTabContainer>
)

Receipts.propTypes = {
  receipts: PropTypes.array,
}

Receipts.defaultProps = {
  receipts: [],
}

Receipts.displayName = 'Receipts'

export default Receipts
