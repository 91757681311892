import _FieldCheckbox from './FieldCheckbox'
import _FieldRadioButton from './FieldRadioButton'
import _FieldError from './FieldError'
import _FieldSelect from './FieldSelect'
import _FieldInput from './FieldInput'
import _FieldText from './FieldText'
import _FieldToggle from './FieldToggle'
import _FieldArrayComponent from './FieldArrayComponent'
import _FieldComponent from './FieldComponent'
import _FieldRadioGroup from './FieldRadioGroup'
import _FieldUploadBox from './FieldUploadBox'
import _PureFieldComponent from './PureFieldComponent'

export const FieldCheckbox = _FieldCheckbox
export const FieldRadioButton = _FieldRadioButton
export const FieldRadioButtonButton = _FieldRadioButton
export const FieldError = _FieldError
export const FieldSelect = _FieldSelect
export const FieldInput = _FieldInput
export const FieldText = _FieldText
export const FieldToggle = _FieldToggle
export const FieldArrayComponent = _FieldArrayComponent
export const FieldComponent = _FieldComponent
export const FieldRadioGroup = _FieldRadioGroup
export const FieldUploadBox = _FieldUploadBox
export const PureFieldComponent = _PureFieldComponent

export default {
  FieldArrayComponent,
  FieldCheckbox,
  FieldComponent,
  FieldError,
  FieldInput,
  FieldRadioButton,
  FieldRadioGroup,
  FieldSelect,
  FieldText,
  FieldToggle,
  FieldUploadBox,
  PureFieldComponent,
}
