import React from 'react'
import { createClasses } from 'design-system/helper'
import styles from './Button.module.scss'

export type ButtonProps = {
  size?: 'large' | 'medium'
  type?: 'flat' | 'outline'
  bold?: boolean
  uppercase?: boolean
  variant?: 'primary' | 'secondary' | 'special' | 'link' | 'ghost' | 'danger' | 'black'
  loading?: boolean
  /**
   * The "type" attribute from html button
   */
  htmlType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>

/**
 * Button component based on SureCo Design System. React HTML button attributes are inherited.
 */
export const Button = ({
                         type = 'flat',
                         size = 'medium',
                         htmlType = 'button',
                         variant = 'primary',
                         bold = false,
                         uppercase = false,
                         className,
                         disabled,
                         loading = false,
                         children,
                         ...props
                       }: ButtonProps) => {
  return (
    <button
      className={createClasses(
        styles.button,
        styles[size],
        bold ? styles.bold : '',
        uppercase ? styles.uppercase : '',
        loading ? styles.loading : '',
        variant === 'link' || variant === 'ghost'
          ? styles[variant]
          : styles[`${variant}-${type}`],
        className
      )}
      type={htmlType}
      disabled={disabled || loading}
      {...props}
    >
      <span className={styles.buttonContent}>{children}</span>
      {loading && <span className={styles.spinner} />}
    </button>
  )
}
