/* eslint-disable react/require-default-props */
import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'redux-form'

const renderAddButton = (component, fields, isAdding) => {
  if (component.addButton && fields.length === 0) {
    return <component.addButton fields={fields} isAdding={isAdding} />
  }

  return undefined
}

const DefaultComponent = ({
  fields,
  inputComponent,
  fieldProps: { addButton, isAdding, ...restProps },
}) => {
  const component = { inputComponent }

  return (
    <div>
      {fields.map((name, index, field) => (
        <component.inputComponent
          name={name}
          key={`${name}-${index.toString()}`}
          field={field}
          index={index}
          isAdding={isAdding}
          {...restProps}
          removeField={function removeField() {
            fields.remove(index)
          }}
        />
      ))}
      {addButton && renderAddButton({ addButton }, fields, isAdding)}
    </div>
  )
}

const FieldArrayComponent = ({ name, inputComponent, ...fieldProps }) => (
  <FieldArray
    name={name}
    inputComponent={inputComponent}
    component={DefaultComponent}
    fieldProps={fieldProps}
  />
)

FieldArrayComponent.displayName = 'FieldArrayComponent'
FieldArrayComponent.propTypes = {
  name: PropTypes.string.isRequired,
  inputComponent: PropTypes.func.isRequired,
  fieldProps: PropTypes.object,
}

DefaultComponent.propTypes = {
  fields: PropTypes.object,
  fieldProps: PropTypes.object,
  inputComponent: PropTypes.func,
}

export default FieldArrayComponent
