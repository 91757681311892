import { connect } from 'react-redux'
import { reduxForm, getFormValues, change, touch } from 'redux-form'
import moment from 'moment'
import { clearModal } from '@hixme/modal'

import { getRequestByName } from 'modules/redux-gateway'
import CurrentEmployeeProvider from 'routes/Employees/providers/CurrentEmployeeProvider'
import { actions as personActions } from 'store/modules/persons'
import notification from 'modules/app-core/notification-manager'

import { actions, selectors } from '../modules/cobra-coverage'
import { StopCoverage } from '../components'
import { PUT_COBRA_BENEFITS } from '../modules/cobra-coverage/constants'

const validate = ({
  TerminationDate,
  CobraStatus,
  EmploymentStatus,
  PreviousCoverageEndDate,
  CobraBenefitsEligibleStartDate,
  CobraBenefitsEligibleEndDate,
  CobraBenefitsEffectiveDate,
  CobraContinueBenefits,
}) => {
  const errors = {}

  if (
    !TerminationDate ||
    !moment(TerminationDate, 'MM/DD/YYYY', true).isValid()
  ) {
    errors.TerminationDate = 'Valid Termination Date is required'
  }

  if (!CobraStatus) {
    errors.CobraStatus = 'Cobra Status is required'
  }

  if (!EmploymentStatus) {
    errors.EmploymentStatus = 'Employment Status is required'
  }

  if (!TerminationDate && !PreviousCoverageEndDate) {
    errors.TerminationDate = 'Valid Termination Date is required'
  }

  if (!TerminationDate && !CobraBenefitsEligibleStartDate) {
    errors.TerminationDate = 'Valid Termination Date is required'
  }

  if (!TerminationDate && !CobraBenefitsEligibleEndDate) {
    errors.TerminationDate = 'Valid Termination Date is required'
  }

  if (!TerminationDate && !CobraBenefitsEffectiveDate) {
    errors.TerminationDate = 'Valid Termination Date is required'
  }

  if (CobraStatus === 'Elected' && !CobraContinueBenefits) {
    errors.CobraContinueBenefits = 'At least 1 benefit is required'
  }

  return errors
}

const clearDates = (dispatch) => {
  const autoFillList = [
    'PreviousCoverageEndDate',
    'CobraBenefitsEligibleStartDate',
    'CobraBenefitsEligibleEndDate',
    'CobraBenefitsEffectiveDate',
    'CobraContinueBenefits',
  ]

  autoFillList.forEach((item) => {
    dispatch(change('StopCoverage', item, ''))
  })
}

const formatDisplayDates = (date) => {
  const splitDate = date.split('-')

  return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`
}

const convertDates = (person) => {
  const keys = Object.keys(person)
  const personDup = Object.assign({}, person)

  keys.forEach((key) => {
    if (key.includes('Date')) {
      Object.assign(personDup, {
        [key]: formatDisplayDates(person[key]),
      })
    }
  })

  return personDup
}

const mapStateToProps = (state, props) => ({
  formValues: getFormValues('StopCoverage')(state) || {},
  initialValues: {
    ...convertDates(props.person),
  },
  person: props.person,
  benefits: selectors.getBenefits(state),
  isProcessing: getRequestByName(state, PUT_COBRA_BENEFITS).isProcessing,
})

const getEffectiveDates = (dispatch, value) => {
  dispatch(actions.getEffectiveDates(value)).catch((err) => {
    if (err) {
      dispatch(
        notification.actions.createNotification({
          type: 'error',
          message: 'Sorry, we could not retrieve your COBRA dates.',
        })
      )
    }
  })
}

const mapDispatchToProps = (dispatch) => ({
  onEmployeeStateChange: () => {
    dispatch(change('StopCoverage', 'CobraStatus', 'Pending'))
  },
  getEffectiveDates: (e) => {
    const target = e.currentTarget
    const name = target.name
    const value = target.value.replace(/_/g, '')

    dispatch(touch('StopCoverage', name))
    dispatch(change('StopCoverage', name, value))

    if (name === 'TerminationDate') {
      getEffectiveDates(dispatch, value)
    }

    if (value.length === 9 || moment(value, 'MM/DD/YYYY', true).isValid()) {
      clearDates(dispatch)
      getEffectiveDates(dispatch, value)
    }
  },
  resetCobraInfo: (e, value) => {
    clearDates(dispatch)
    dispatch(change('StopCoverage', 'CobraStatus', value))
    getEffectiveDates(dispatch, value)
  },
  editDate: (e) => {
    const target = e.currentTarget
    const parentElement = target.parentElement.parentElement
    const input = parentElement.children[1]

    target.style.zIndex = -1
    input.focus()
  },
})

export default CurrentEmployeeProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'StopCoverage',
      validate,
      touchOnChange: true,
      onSubmit: (values, dispatch, props) => {
        dispatch(actions.postToPersonsTable(values, props.person))
          .then((updatedPerson) => {
            dispatch(personActions.receivePerson(updatedPerson.person))
            dispatch(clearModal())
          })
          .catch(() => {
            dispatch(
              notification.actions.createNotification({
                type: 'error',
                message: 'Sorry, we could not save your information.',
              })
            )
          })
      },
    })(StopCoverage)
  )
)
