import React from 'react'
import PropTypes from 'prop-types'

import Text from '@hixme-ui/text'
import Container from '@hixme-ui/container'
import ContentContainer from '@hixme-ui/content-container'
import Separator from '@hixme-ui/separator'
import { PageTitle } from 'components/PageTitle'

const Page = ({ title, subtitle, children, navComponent, linksComponent }) => (
  <>
    <PageTitle title={title} />
    <ContentContainer contentWidth="1024px">
      <Container noPadding>
        <Container
          flex
          noPadding
          justifyContent="space-between"
          alignItems="flex-start"
          margin="0 0 10px 0"
        >
          <Text larger>{title}</Text>
          {navComponent}
        </Container>

        <Container
          flex
          noPadding
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Text medium light>
            {subtitle}
          </Text>
          {linksComponent}
        </Container>
        <Separator size="1px" />
        <Container noPadding flex flexWrap margin="20px 0 0 0" height="100%">
          {children}
        </Container>
      </Container>
    </ContentContainer>
  </>
)

Page.displayName = 'Page'
Page.propTypes = {
  children: PropTypes.node,
  linksComponent: PropTypes.node,
  navComponent: PropTypes.node,
  title: PropTypes.node,
  subtitle: PropTypes.node,
}
Page.defaultProps = {
  children: 'children',
  linksComponent: null,
  navComponent: null,
  title: 'Title',
  subtitle: null,
}

export default Page
