import { useCallback, useEffect, useState } from 'react'

const sortByField = (field, ascending = true) => (a, b) =>
  ascending ? a[field] - b[field] : b[field] - a[field]

const sortByString = (field, ascending = true) => (a, b) =>
  ascending
    ? a[field].localeCompare(b[field])
    : b[field].localeCompare(a[field])

const sortByDate = (field, ascending = true) => (a, b) =>
  ascending
    ? Date.parse(a[field]) - Date.parse(b[field])
    : Date.parse(b[field]) - Date.parse(a[field])

const sortItems = {
  transaction_amount: {
    ascending: sortByField('transaction_amount'),
    descending: sortByField('transaction_amount', false),
  },
  status: {
    ascending: sortByString('status'),
    descending: sortByString('status', false),
  },
  created_on: {
    ascending: sortByDate('created_on'),
    descending: sortByDate('created_on', false),
  },
  type: {
    ascending: sortByString('type'),
    descending: sortByString('type', false),
  },
}

function useTableState({ defaultSortField = 'created_on' } = {}) {
  const [sortBy, setSortBy] = useState(defaultSortField)
  const [sortDescending, setSortDescending] = useState(true)
  const sortDirection = sortDescending ? 'descending' : 'ascending'
  const [transactions, setTransactions] = useState([])

  const onColumnClick = useCallback(
    (column) => {
      const isSameColumn = sortBy === column
      if (!isSameColumn) setSortBy(column)
      setSortDescending(isSameColumn ? !sortDescending : true)
    },
    [sortDescending, sortBy]
  )

  const setData = (data) => setTransactions(data)

  useEffect(() => {
    setTransactions(transactions.sort(sortItems[sortBy][sortDirection]))
  }, [sortBy, sortDescending, sortDirection, transactions])

  return {
    sortBy,
    onChangeColumn: onColumnClick,
    isSortDescending: sortDescending,
    transactions,
    useTableState,
    setData,
  }
}

export default useTableState
