import { withRouter } from 'react-router'
import { providers } from 'store/modules/persons'

export default (InnerComponent) =>
  withRouter(
    providers.PersonProvider((state, props) => ({
      personId: props.params.id,
      fullProfile: true,
    }))(InnerComponent)
  )
