import React from 'react'
import PropTypes from 'prop-types'

// hixme-ui
import Container from '@hixme-ui/container'
import Currency from '@hixme-ui/currency'
import Text from '@hixme-ui/text'

const ReceiptLineItemHeader = ({ reimbursement }) => (
  <Container
    flex
    noPadding
    margin="30px 0"
    width="100%"
    justifyContent="space-between"
  >
    <Text jumbo>
      Requested Amount ({reimbursement.receipts.length}{' '}
      {reimbursement.receipts.length === 1 ? 'receipt' : 'receipts'})
    </Text>
    <Currency jumbo>{reimbursement.amount}</Currency>
  </Container>
)

ReceiptLineItemHeader.propTypes = {
  reimbursement: PropTypes.object.isRequired,
}

export default ReceiptLineItemHeader
