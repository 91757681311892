import { createReducer } from 'modules/app-core/redux-utils'
import { LOAD_BENEFITEFFECTIVEDATES } from './actionTypes'

const initialState = {
  // benefitsEffectiveDates: {}
}

export default createReducer(initialState, {
  [LOAD_BENEFITEFFECTIVEDATES]: (state, payload) =>
    Object.assign({}, state, payload),
})
