import React, { useState } from 'react'
import { Button } from '@sureco/design-system'
import { message } from 'antd'
import { CensusFileRowGridData } from '../types/census-file-row-grid-data'
import { CensusFileRowDto } from '../api/dto/census-file-row.dto'
import { updateCensusFileRows } from '../api/census-enrollment-platform-api-client'
import { CensusFileRowStatusEnum } from '../api/enums/census-file-row-status.enum'
import { mapGridDataToCensusFileRowDto } from '../utils/map-grid-data-to-census-file-row-dto'
import { PatchActionEnum } from '../api/enums/patch-action.enum'

interface SendSelectedForReviewButtonProps {
  publicKey: string;
  selectedRows: CensusFileRowGridData[]
  handleSubmit: (rows: CensusFileRowGridData[]) => void
  cleanAllRows: () => void
  setLoading: (loading: boolean) => void // Prop to update loading state in parent
}

const sendForReview = async (rowsToSubmit: CensusFileRowGridData[], publicKey: string) => {
  const acceptedRows: CensusFileRowDto[] = rowsToSubmit.map((gridRow) => ({
    ...mapGridDataToCensusFileRowDto(gridRow),
    status: CensusFileRowStatusEnum.IN_REVIEW,
  }))
  await updateCensusFileRows(acceptedRows, publicKey, PatchActionEnum.SUBMIT_FOR_REVIEW)
}

const SendSelectedForReviewButton = ({
                                       selectedRows,
                                       publicKey,
                                       handleSubmit,
                                       cleanAllRows,
                                       setLoading
                                     }: SendSelectedForReviewButtonProps) => {
  const [loading, setButtonLoading] = useState(false)

  const handleClick = async () => {
    let timer: NodeJS.Timeout | null = null
    let shouldShowLoading = false

    // Start a delayed loading indicator (only if the request takes longer than 1000ms)
    timer = setTimeout(() => {
      setButtonLoading(true)
      setLoading(true)
      shouldShowLoading = true
    }, 800)

    try {
      await sendForReview(selectedRows, publicKey)
      message.success(`${selectedRows.length} records submitted successfully`, 3)
    } catch (error) {
      message.error(`Failed to submit records: '${(error as Error).message}'`)
    } finally {
      // Clear loading state if it was set
      if (shouldShowLoading) {
        setButtonLoading(false)
        setLoading(false)
      }

      // Ensure the timer doesn't trigger the loading state if completed early
      if (timer) {
        clearTimeout(timer)
      }

      handleSubmit(selectedRows)
      cleanAllRows()
    }
  }

  if (selectedRows.length === 0) return null

  return (
    <Button onClick={handleClick} size="large" loading={loading}>
      {loading ? 'Submitting...' : `Send For Review (${selectedRows.length})`}
    </Button>
  )
}

export default SendSelectedForReviewButton
