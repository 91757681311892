import { createReducer } from 'modules/app-core/redux-utils'
import t from './actionTypes'
import { STATUSES } from './constants'

const initialState = {
  id: '',
  showErrors: false,
  errors: [],
  status: STATUSES.NO_ERROR,
}

export default createReducer(initialState, {
  [t.CENSUS_UPLOAD_ERROR]: (state, errors) => ({
    ...state,
    status: STATUSES.CENSUS_VALIDATION_ERROR,
    errors,
  }),
  [t.SHOW_ERROR_MODAL]: (state, id) => ({
    ...state,
    status: STATUSES.SHOW_ERROR_MODAL,
    id,
  }),
  [t.HIDE_ERROR_MODAL]: (state) => ({
    ...state,
    status: STATUSES.CENSUS_VALIDATION_ERROR,
  }),
})
