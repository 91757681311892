import React from 'react'

import Container from '@hixme-ui/container'

import Placeholder from 'components/Placeholder'

const TransactionModalLoader = () => (
  <Container flex noPadding direction="column" width="100%">
    <Container flex noPadding justifySpaceBetween>
      <Placeholder width="100%" height="40px" />
    </Container>
    <Container marginTop="3px" flex noPadding flexColumn style={{ gap: '2px' }}>
      <Placeholder width="100%" height="30px" />
      <Placeholder width="100%" height="30px" />
      <Placeholder width="100%" height="30px" />
      <Placeholder width="100%" height="30px" />
      <Placeholder width="100%" height="30px" />
      <Placeholder width="100%" height="30px" />
    </Container>
  </Container>
)

export default TransactionModalLoader
