import React from 'react'
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react'
import '@ag-grid-community/styles/ag-theme-quartz.css'
import styles from './Grid.module.scss'

export type GridProps<DataType> = AgGridReactProps<DataType> & {
  wrapper?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>
}

const Grid = <DataType extends Record<string, unknown>>({ wrapper, ...props }: GridProps<DataType>) => {
  const { style: wrapperStyle, className: wrapperClassName } = wrapper ?? {}
  return (
    <div
      className={`ag-theme-quartz ${styles.surecoGrid} ${wrapperClassName ?? ''}`}
      style={{
        padding: '0',
        height: '80vh',
        flex: '1 1 auto ',
        overflowY: 'auto',
        ...wrapperStyle
      }}
    >
      <AgGridReact<DataType> {...props} />
    </div>
  )
}

export default Grid
