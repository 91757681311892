import React from 'react'
import { FieldValues } from 'react-hook-form'
import RHFSelect, { RHFSelectProps } from './hookform/Select/RHFSelect'

export const HRIS = [
  {
    label: 'ADP WFN',
    value: 'adp_wfn',
  },
  {
    label: 'Paylocity',
    value: 'paylocity',
  },
  {
    label: 'Paycom',
    value: 'paycom',
  },
  {
    label: 'Paychex',
    value: 'paychex',
  },
  {
    label: 'Paycor',
    value: 'paycor',
  },
  {
    label: 'UKG Ready',
    value: 'ukg_ready',
  },
  {
    label: 'UKG Pro',
    value: 'ukg_pro',
  },
  {
    label: 'Workday',
    value: 'workday',
  },
]

export const HRISMap = HRIS.reduce((prev, curr) => {
  return { ...prev, [curr.value]: curr.label }
}, {})

export default function HrisSelect<
  TFieldValues extends FieldValues = FieldValues
>({ control, name, ...props }: RHFSelectProps<TFieldValues>) {
  return <RHFSelect control={control} name={name} {...props} options={HRIS} />
}
